import React, { ChangeEvent, useEffect, useState } from 'react';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import LayoutWrapper from 'componets/layoutWrapper';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { getUser } from '../../store/reducers/user/selector';
import InputRow from '../../componets/inputRow';
import SelectDropdown from '../../componets/selectDropdown';
import { getUserCountryLocationList } from '../../store/reducers/registerData/selector';
import { setCountryUserLocationStart } from '../../store/reducers/registerData/actions/userLocationList';
import { uploadRegistrationFilesConfig } from '../../config/registrations/uploadFileConfig';
import ImageCropper from '../../componets/imageCropper';
import {
  ROUT_HOME,
  URL_CITY_LOCATION,
  URL_EXPERT_DETAILS_FILE,
  URL_REGION_LOCATION,
  URL_USER_INFO,
} from '../../const';
import Button, { buttonTypes } from '../../componets/button';
import { useNavigate } from 'react-router-dom';
import API from '../../services/API';
import { setUser } from '../../store/reducers/user';
import { userRolesEnum } from '../dashboardPage';
import Spinner from 'componets/spiner';

const initFormErrors = {
  avatarError: false,
};

const EditProfile = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(getUser);
  const userCountryList = useSelector(getUserCountryLocationList);
  const navigate = useNavigate();

  const { profile_ava } = uploadRegistrationFilesConfig;
  const { acceptFileType, limit, type, name: avaName } = profile_ava;

  const [formState, setFormState] = useState(user);
  const [userCountry, setUserCountry] = useState({
    id: 0,
    name: '',
  });
  const [userAvatar, setUserAvatar] = useState<null | File>(null);
  const [userCityList, setUserCityList] = useState([]);
  const [userRegionList, setUserRegionList] = useState([]);
  const [isExpert, setUserRole] = useState(false);
  const [userCity, setUserCity] = useState<{
    id: undefined | number;
    name: undefined | string;
  }>({ id: undefined, name: undefined });

  const [userRegion, setUserRegion] = useState<{
    id: undefined | number;
    name: undefined | string;
  }>({ id: undefined, name: undefined });

  const [loading, setLoading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState(initFormErrors);

  const setCitiesList = (regionId: number, cityId: number | null) => {
    const path = `${URL_CITY_LOCATION}?filter[state_id]=${regionId}`;
    API.getStaticApiData(path).then((res) => {
      setUserCityList(res);
      if (cityId) {
        const userCity = res?.find(
          (item: { id: number }) => item?.id === cityId
        );
        if (userCity) {
          setUserCity(userCity);
        }
      }
    });
  };

  const getAndSetRegion = (countryId: number, regionId: number | null) => {
    const path = `${URL_REGION_LOCATION}?filter[country_id]=${countryId}`;
    API.getStaticApiData(path).then((res) => {
      setUserRegionList(res);
      if (regionId) {
        const userRegion = res?.find(
          (item: { id: number }) => item?.id === regionId
        );
        if (userRegion) {
          setUserRegion(userRegion);
        }
      }
    });
  };

  useEffect(() => {
    dispatch(setCountryUserLocationStart());
    if (user) {
      // @ts-ignore
      setUserRole(user?.roles?.name === userRolesEnum.Expert);
      setFormState(user);
    }
  }, [user]);

  useEffect(() => {
    if (user && isExpert) {
      // @ts-ignore
      const { expertDetails } = user;
      const { city_id, country_id, state_id } = expertDetails;
      // @ts-ignore
      getAndSetRegion(country_id, state_id);
      setCitiesList(state_id, city_id);
    }
  }, [isExpert]);

  useEffect(() => {
    const countryId = isExpert
      ? // @ts-ignore
        user?.expertDetails?.country_id
      : user?.country_id;
    const initialUserCountry = userCountryList?.find(
      (country) => country?.id === countryId
    );

    if (initialUserCountry) {
      setUserCountry(initialUserCountry);
    }
  }, [userCountryList]);

  if (!formState) {
    return null;
  }

  // @ts-ignore
  const { name, surname, avatar, roles, country_id, expertDetails } = formState;
  // @ts-ignore
  const src = avatar?.temporary_download;

  const uploadMessages = {
    defaultLabel: intl.formatMessage({ id: 'image-upload-placeholder' }),
    errorLabel: intl.formatMessage({ id: 'input-upload-placeholder-error' }),
    loadOtherPhotoLabel: intl.formatMessage({
      id: 'register.step-11.select_other_photo',
    }),
    uploadingLabel: intl.formatMessage({
      id: 'input-upload-placeholder-loading',
    }),
    btnLabel: intl.formatMessage({ id: 'btn-upload' }),
  };

  const onNameChange = (key: string, value: string) => {
    setFormState((state: any) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };
  const onSelectCountryChange = (id: string, name: string) => {
    setUserCountry({
      id: Number.parseInt(id),
      name,
    });
    if (isExpert) {
      getAndSetRegion(Number.parseInt(id), null);
    }

    setUserRegion({
      id: undefined,
      name: undefined,
    });

    setUserCity({
      id: undefined,
      name: undefined,
    });
  };
  const onChangeFile = (name: string, data: File) => {
    setUserAvatar(data);
  };

  const onSelectCityChange = (value: string, label: string) => {
    setUserCity({ id: parseInt(value), name: label });
  };

  const onSelectRegionChange = (value: string, label: string) => {
    if (isExpert) {
      setCitiesList(Number.parseInt(value), null);
    }

    setUserCity({
      id: undefined,
      name: undefined,
    });

    setUserRegion({ id: parseInt(value), name: label });
  };

  const onclickSave = async () => {
    if (userAvatar?.name === 'undefined') {
      setFormErrors((state) => ({
        ...state,
        avatarError: true,
      }));
      return;
    }
    const userState =
      // @ts-ignore
      user?.roles?.name === userRolesEnum.Expert
        ? {
            state_id: userRegion?.id,
          }
        : {};
    setLoading(true);
    let imageUploadData = null;
    if (userAvatar) {
      imageUploadData = await API.sendFormData(URL_EXPERT_DETAILS_FILE, {
        file: userAvatar,
      });
    }

    const userInfo = {
      name: formState?.name,
      surname: formState?.surname ? formState?.surname : '',
      country_id: userCountry?.id,
      ...userState,
    };

    if (imageUploadData) {
      // @ts-ignore
      Object.assign(userInfo, {
        'picture[media_client_name]': imageUploadData.data.client_name,
        'picture[media_storage_name]': imageUploadData.data.path,
      });
    }

    if (userCity.id) {
      // @ts-ignore
      userInfo.city_id = userCity?.id;
    }

    API.sendFormData(URL_USER_INFO, userInfo)
      .then((response) => {
        dispatch(setUser(response.data));
      })
      .finally(() => {
        setLoading(false);
        setFormErrors(initFormErrors);
        navigate(ROUT_HOME);
      });
  };

  return (
    <LayoutWrapper>
      <LayoutMainContent nativeStyles={styles.hideBefore}>
        {loading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
        <LayoutContainer>
          <div className={styles.topBlockLabel}>
            <FormattedMessage id="dashboard.button.button-edit-profile" />
          </div>
        </LayoutContainer>
        <LayoutContainer nativeStyles={styles.colStart}>
          <div className={styles.sidebarContainer}>
            {formErrors.avatarError ? (
              <div className={styles.error}>
                {intl.formatMessage({ id: 'edit-profile.avatar-error' })}
              </div>
            ) : null}

            <ImageCropper
              onChange={onChangeFile}
              type={type}
              acceptFileType={acceptFileType}
              name={avaName}
              limit={limit}
              labels={uploadMessages}
              initialImageSrc={src}
            />
          </div>
          <div className={styles.stepsContent}>
            <InputRow
              nativeStyles={styles.inputRow}
              title={
                isExpert ? 'edit-profile.name-expert' : 'edit-profile.name'
              }
              name="name"
              placeholder="create-new-project.step-1.input-placeholder-name"
              onChange={onNameChange}
              value={name || ''}
            />
            {!isExpert && (
              <InputRow
                nativeStyles={styles.inputRow}
                title="edit-profile.surname"
                name="surname"
                placeholder="create-new-project.step-1.input-placeholder-surname"
                onChange={onNameChange}
                value={surname || ''}
              />
            )}

            <div className={styles.inputRow}>
              <SelectDropdown
                title="edit-profile.country"
                options={userCountryList}
                onSelectChange={onSelectCountryChange}
                placeholder={intl.formatMessage({
                  id: 'register.step-3.country-select-placeholder',
                })}
                value={{
                  value: userCountry?.id,
                  label: userCountry?.name,
                }}
              />
            </div>
            {userRegionList?.length > 0 && (
              <div className={styles.inputRow}>
                <SelectDropdown
                  options={userRegionList}
                  onSelectChange={onSelectRegionChange}
                  placeholder={intl.formatMessage({
                    id: 'register.step-3.region-select-placeholder',
                  })}
                  value={{
                    value: userRegion?.id,
                    label: userRegion?.name,
                  }}
                />
              </div>
            )}
            {userCityList?.length > 0 && (
              <div className={styles.inputRow}>
                <SelectDropdown
                  options={userCityList}
                  onSelectChange={onSelectCityChange}
                  placeholder={intl.formatMessage({
                    id: 'register.step-3.city-select-placeholder',
                  })}
                  value={{
                    value: userCity?.id,
                    label: userCity?.name,
                  }}
                />
              </div>
            )}
          </div>
        </LayoutContainer>
        <LayoutContainer>
          <div className={styles.sidebarContainer} />
          <div className={styles.stepsContent}>
            <div className={styles.buttonsRow}>
              <Button
                disabled={loading}
                onClick={() => {
                  navigate(ROUT_HOME);
                }}
                type={buttonTypes.transparent}
              >
                {intl.formatMessage({
                  id: 'edit-profile.cancel',
                })}
              </Button>
              <Button
                onClick={onclickSave}
                type={buttonTypes.danger}
                nativeStyles={styles.ml}
                disabled={
                  !(
                    userAvatar?.name &&
                    name.trim().length &&
                    Boolean(userCountry?.id) &&
                    (!isExpert ? surname : true) &&
                    (isExpert ? userCity?.id : true)
                  ) || loading
                }
              >
                {intl.formatMessage({
                  id: 'edit-profile.save',
                })}
              </Button>
            </div>
          </div>
        </LayoutContainer>
      </LayoutMainContent>
    </LayoutWrapper>
  );
};

export default EditProfile;
