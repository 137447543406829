import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import RoundImage from 'componets/roundImage';
import ExpertBlockInfo from 'pages/profilePreviewPage/asideContent/expertBlock/expertBlockInfo';
import { getFormData } from 'store/reducers/registerData/selector';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import RateBlock from 'componets/rateBlock';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { toBase64 } from 'services/helpers';
import { useLocation, useParams } from 'react-router-dom';
import {
  ROUT_EXPERT_PAGE,
  ROUT_FIND_EXPERT,
  ROUT_USER_PAGE,
  ROUT_VIEW_PROFILE,
} from 'const';
import RateRow from 'pages/dashboardPage/components/rateRow';

const ExpertBlock = () => {
  const {
    areaExpertises,
    userCountry,
    userRegion,
    userCity,
    minRate,
    maxRate,
    picture,
    user: viewedUser,
  } = useSelector(getFormData);
  const { pathname } = useLocation();
  const params = useParams();

  const [base64Img, setBase64Img] = useState('');
  const user = useSelector(getUser);

  const isExpertPage =
    // @ts-ignore
    pathname === `${ROUT_USER_PAGE}/${params?.userId}` ||
    pathname === ROUT_EXPERT_PAGE;

  useEffect(() => {
    if (isExpertPage) {
      // @ts-ignore
      setBase64Img(picture ? picture[0]?.temporary_download : '');
      return;
    }
    if (pathname === ROUT_VIEW_PROFILE) {
      setBase64Img(picture?.filePath);
    }
    // @ts-ignore
  }, [picture?.fileData, picture[0]?.temporary_download]);

  return (
    <div className={styles.expertBlock}>
      <div className={styles.blockWrapper}>
        <RoundImage src={base64Img} nativeStyles={styles.image} alt={''} />
        {isExpertPage &&
        // @ts-ignore
        viewedUser?.rating?.count ? (
          <div className={styles.column}>
            <RateRow
              rate={
                // @ts-ignore
                viewedUser?.rating
              }
            />
          </div>
        ) : null}
      </div>
      <div className={styles.contentCenter}>
        <ExpertBlockInfo
          name={isExpertPage ? viewedUser?.name : user?.name}
          surname={isExpertPage ? viewedUser?.surname : user?.surname}
          areaExpertises={areaExpertises}
          userCountry={userCountry}
          userRegion={userRegion}
          userCity={userCity}
        />
      </div>
      <div className={classNames(styles.contentCenter, styles.contentRight)}>
        <div className={styles.columnWrapper}>
          <RateBlock minRate={minRate} maxRate={maxRate} />
        </div>
      </div>
    </div>
  );
};

export default ExpertBlock;
