import React, { useEffect } from 'react';
import LayoutWrapper from 'componets/layoutWrapper';
import StepsAside from 'componets/stepsAside';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import StepsContent from 'componets/stepsContent';
import { formRegistrationStepsConfig } from 'config/registrations/form-registration-steps.config';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentRegisterStep } from 'store/reducers/registerData/selector';
import { stepsAsideConfig } from 'config/registrations/steps-aside.config';
import {
  setFormDataFromStorage,
  setRegisterStep,
} from 'store/reducers/registerData';
import { currentStepSelectorKeys } from 'services/enums';
import StorageService from 'services/storageService';
import { getUser } from 'store/reducers/user/selector';
import { useNavigate } from 'react-router-dom';
import { userStatus } from 'store/reducers/user/types';
import { ROUT_HOME, ROUT_VIEW_PROFILE } from 'const';

export interface toggleStepsInterface {
  title: string;
  Component: React.FC<any>;
  subtitle?: string;
  subtitleValues?: { [key: string]: number | undefined };
}

const RegistrationPage = () => {
  const currentIndexStep = useSelector(getCurrentRegisterStep) - 1;
  const data: toggleStepsInterface =
    formRegistrationStepsConfig[currentIndexStep];
  const { title, subtitle, Component, subtitleValues } = data;
  const dispatch = useDispatch();
  const setStepFunction = (index: number) => {
    dispatch(setRegisterStep({ nextStep: index + 1, error: '' }));
  };
  const user = useSelector(getUser);
  const navigate = useNavigate();
  useEffect(() => {
    StorageService.getByKey('formData').then((res) => {
      if (res) {
        dispatch(setFormDataFromStorage(JSON.parse(res)));
      }
    });
    StorageService.getByKey('currentRegisterStep').then((res) => {
      if (res) {
        setStepFunction(Number.parseInt(res));
      }
    });
    StorageService.getByKey('preliminaryCompleted').then((res) => {
      if (JSON.parse(res)) {
        navigate(ROUT_VIEW_PROFILE);
      }
    });
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    const { status } = user;
    if (status === userStatus.waitingConfirmation) {
      navigate(ROUT_HOME);
    }
  }, [user?.status]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [currentIndexStep]);

  return (
    <LayoutWrapper>
      <LayoutMainContent>
        <LayoutContainer>
          <StepsAside
            config={stepsAsideConfig}
            setStepFunction={setStepFunction}
            stepKey={currentStepSelectorKeys.register}
          />
          <StepsContent
            title={title}
            subtitle={subtitle}
            subtitleValues={subtitleValues}
            stepsButton
            stepKey={currentStepSelectorKeys.register}
          >
            <Component />
          </StepsContent>
        </LayoutContainer>
      </LayoutMainContent>
    </LayoutWrapper>
  );
};

export default RegistrationPage;
