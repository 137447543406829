import { fetchActiveChat } from 'store/reducers/chat/actions';
import { chatsType } from 'store/reducers/chat/index';

export const setActiveChatStart = (
  id: string | null,
  page: number,
  cb?: () => void
) => {
  return {
    type: fetchActiveChat.FetchStart,
    payload: { id, page, cb },
  };
};

export const setActiveChatSuccess = (data: chatsType[]) => {
  return {
    type: fetchActiveChat.FetchSuccess,
    payload: data,
  };
};

export const setActiveChatError = (value: Error) => {
  return {
    type: fetchActiveChat.FetchError,
    payload: value,
  };
};
