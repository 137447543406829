import React, { useState } from 'react';
import MoreDots from 'svgComponent/MoreDots';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage/index';
import ButtonLink from 'componets/buttonLink';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import GrayStar from 'svgComponent/GrayStar';
import { projectStatusEnum } from 'services/enums';
import NoteRemoveIcon from 'svg-icons/NoteRemoveIcon';
import UserWithFind from 'svg-icons/UserWithFind';
import EditFileIcon from 'svg-icons/EditFileIcon';
import API from 'services/API';
import {
  setEditedProjectStart,
  setFounderProjectStart,
} from 'store/reducers/globalRequestedData/actions/projectActions';
import {
  ROUT_EDIT_PROJECT,
  ROUT_FIND_EXPERT,
  URL_STARTUP_PROJECT,
} from 'const';
import {
  setCreatedProjectId,
  setCreateProjectStep,
  setDraftProject,
} from 'store/reducers/createProjects';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewProfileDropdown from 'componets/viewProfileDropdown';
import Popup, { popupTypesEnum } from 'componets/popup';
import FormError from 'componets/stripeForm/FormError';
import FlagIcon from 'svgComponent/FlagIcon';
import { setAvailableChatsStart } from 'store/reducers/chat/actions/availableChats';

const { REACT_APP_API_STARTUP_PROJECT } = process.env;

type MoreBlockProps = {
  projectStatus: string;
  onRateClick(): void;
  projectId: number | undefined;
  opponentId: number | undefined;
  userId: number | undefined;
};

const onRemoveClick = async (id: number | undefined) => {
  const path = `${URL_STARTUP_PROJECT}/${id}`;
  await API.callDeleteUrl(path);
};

const MoreBlock = (props: MoreBlockProps) => {
  const {
    projectStatus,
    projectId,
    opponentId,
    userId,
    onRateClick = () => {},
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  // @ts-ignore
  const { roles, id } = useSelector(getUser);
  const [isActive, setIsActive] = useState(false);
  const [isActivePopup, setIsActivePopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(getUser);
  const onFindExpertClick = (id: number | undefined) => {
    if (!id) {
      return;
    }
    dispatch(setCreatedProjectId(id));
    navigate(ROUT_FIND_EXPERT);
  };

  const onEditClick = (id: number | undefined) => {
    if (!id) {
      return;
    }
    dispatch(setEditedProjectStart(id));
    dispatch(setCreatedProjectId(id));
    dispatch(setCreateProjectStep({ nextStep: 1, error: '' }));
    dispatch(setDraftProject(true));
    navigate(ROUT_EDIT_PROJECT);
  };
  return (
    <div className={styles.wrapper}>
      <button
        className={styles.btn}
        onClick={() => {
          setIsActive((state) => !state);
        }}
      >
        <MoreDots focusable={roles.name === userRolesEnum.Expert} />
      </button>
      {roles.name === userRolesEnum.Founder && (
        <div className={styles.popover}>
          {projectStatus === projectStatusEnum.draft ? (
            <>
              <ButtonLink
                onClick={() => {
                  setIsActivePopup(true);
                }}
                nativeStyles={styles.navItemLink}
              >
                <NoteRemoveIcon />
                <div>
                  {intl.formatMessage({
                    id: 'dashboard.button.button-note-remove',
                  })}
                </div>
              </ButtonLink>
              <ButtonLink
                onClick={async () => {
                  await onFindExpertClick(projectId);
                }}
                nativeStyles={styles.navItemLink}
              >
                <UserWithFind />
                <div>
                  {intl.formatMessage({
                    id: 'dashboard.button.button-find-expert',
                  })}
                </div>
              </ButtonLink>
              <ButtonLink
                onClick={async () => {
                  await onEditClick(projectId);
                }}
                nativeStyles={styles.navItemLink}
              >
                <EditFileIcon />
                <div>
                  {intl.formatMessage({
                    id: 'dashboard.button.button-edit-project',
                  })}
                </div>
              </ButtonLink>
            </>
          ) : (
            <ViewProfileDropdown
              opponentId={opponentId}
              projectId={projectId}
              userId={userId}
              options={{ prevState: location.pathname }}
            />
          )}
        </div>
      )}
      {isActivePopup && (
        <Popup
          name={''}
          popupType={popupTypesEnum.confirmChoose}
          buttonLabel={intl.formatMessage({
            id: 'component.button.pay-ok',
          })}
          buttonAbortLabel={intl.formatMessage({
            id: 'component.labels.cancel',
          })}
          onButtonClick={async () => {
            await onRemoveClick(projectId);
            dispatch(
              setFounderProjectStart(id, () => {
                dispatch(setAvailableChatsStart(1, id));
              })
            );
            setIsActivePopup(false);
          }}
          onAbortBtnClick={() => {
            setIsActivePopup(false);
          }}
          setPopupVisible={() => {
            setIsActivePopup(false);
          }}
          userRoles={
            // @ts-ignore
            roles?.name ? roles.name : userRolesEnum.Founder
          }
        >
          <FormError
            message={intl.formatMessage({
              id: 'popup.popup-message.message-remove-project',
            })}
          />
        </Popup>
      )}
    </div>
  );
};

export default MoreBlock;
