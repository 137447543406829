import {
  expertDetailsType,
  ICreateProjectInterface,
  itemWithNameType,
  projectDataType,
} from 'store/reducers/createProjects/types';
import { dataItemType } from 'store/reducers/registerData/types';

export const getNewProjectName = (state: {
  createProjectData: ICreateProjectInterface;
}): string => state.createProjectData?.projectData?.name;

export const getNewProjectStartupType = (state: {
  createProjectData: ICreateProjectInterface;
}): itemWithNameType | null => state.createProjectData.projectData.typeStartup;

export const getNewProjectTypeOfExpert = (state: {
  createProjectData: ICreateProjectInterface;
}): itemWithNameType[] | null =>
  state.createProjectData.projectData.typeOfExpert;

export const getNewProjectTypeOfGoals = (state: {
  createProjectData: ICreateProjectInterface;
}): itemWithNameType[] | null =>
  state.createProjectData.projectData.typeOfGoals;

export const getNewProjectTypeOfAchieves = (state: {
  createProjectData: ICreateProjectInterface;
}): itemWithNameType[] | null =>
  state.createProjectData.projectData.typeOfAchieves;

export const getNewProjectMinRate = (state: {
  createProjectData: ICreateProjectInterface;
}): number | string => state.createProjectData.projectData.minRate;

export const getNewProjectMaxRate = (state: {
  createProjectData: ICreateProjectInterface;
}): number | string => state.createProjectData.projectData.maxRate;

export const getNewProjectBudget = (state: {
  createProjectData: ICreateProjectInterface;
}): number | string => state.createProjectData.projectData.budget;

export const getNewProjectSkills = (state: {
  createProjectData: ICreateProjectInterface;
}): dataItemType[] | null => state.createProjectData.projectData.skills;

export const getNewProjectCurrentStep = (state: {
  createProjectData: ICreateProjectInterface;
}): number => state.createProjectData.stepsData.step;

export const getExpertDetailsList = (state: {
  createProjectData: ICreateProjectInterface;
}): {
  data: expertDetailsType[] | null;
  meta: {
    currentPage: number;
    last_page: number;
  };
} => state?.createProjectData?.projectData?.expertDetails;

export const getNewProjectData = (state: {
  createProjectData: ICreateProjectInterface;
}): projectDataType => state.createProjectData.projectData;

export const getCreatedNewProjectId = (state: {
  createProjectData: ICreateProjectInterface;
}): number | null => state.createProjectData.createdNewProjectId;

export const getNewProjectDraftState = (state: {
  createProjectData: ICreateProjectInterface;
}): boolean => state.createProjectData.projectInDraft;
