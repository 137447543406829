import React, { useEffect } from 'react';
import styles from 'componets/registration/formStepsContent/fifthFormStep/stiles.module.scss';
import classNames from 'classnames';
import RegistrationLabelList from 'pages/registrationPage/components/registrationLabelList';
import { useDispatch, useSelector } from 'react-redux';
import { setAchievesSkillsStart } from 'store/reducers/registerData/actions/achievesSkills';
import {
  getAvailableAchieves,
  getUserAchieves,
} from 'store/reducers/registerData/selector';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';

const FifthFormStep = () => {
  const dispatch = useDispatch();
  const availableAchieves = useSelector(getAvailableAchieves);
  const userAchieves = useSelector(getUserAchieves);

  useEffect(() => {
    dispatch(
      setAchievesSkillsStart({
        queryDataKey: 'achieves',
        stateAchievesSkillsKey: 'availableAchieves',
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setRegisterStepError(!userAchieves.length));
  }, [userAchieves.length]);
  return (
    <div className={styles.wrapper}>
      {availableAchieves.map((achievesItem, index) => {
        const { achieves, area, id } = achievesItem;
        return (
          <div key={`availableAchieves_${index}`}>
            <h3 className={styles.sectionTitle}>{area}</h3>
            <div className={classNames(styles.bottomMargin, styles.flexRow)}>
              <RegistrationLabelList
                labelsArray={achieves}
                usersLabelList={userAchieves}
                storeObjectKey="userAchieves"
                showInputRow
                setFunction={setFormData}
                listId={id}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FifthFormStep;
