import React from 'react';
import styles from './styles.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import InputRow from 'componets/inputRow';
import {
  setInvoiceData,
  setInvoiceObjectData,
  setInvoicePaymentData,
  setUserInvoicesStart,
} from 'store/reducers/invoice';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';
import { projectType } from 'store/reducers/globalRequestedData';
import API from 'services/API';
import { getInvoiceData } from 'store/reducers/invoice/selectors';
import { debounce } from 'services/helpers';
import { URL_PROJECT_TOTAL } from 'const';
import { createInvoiceInputError } from 'services/enums';

const invoiceExpertContentList = [
  'popup.popup-list.invoice-text1',
  'popup.popup-list.invoice-text2',
];

type CreateInvoiceProps = {
  projectData: projectType | null;
  fieldError: Record<createInvoiceInputError, boolean>;
  setInvoiceInputError?: any;
  founderInvoiceData?: any;
};

const CreateInvoice = (props: CreateInvoiceProps) => {
  const { projectData, fieldError, setInvoiceInputError = () => {} } = props;

  const intl = useIntl();
  const invoiceData = useSelector(getInvoiceData);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  if (!projectData) {
    return null;
  }
  const { id, name, founder_id, invoice, expert, founder } = projectData;
  const {
    service_price,
    total,
    transfer_fee,
    due_date,
    invoiceDate,
    tax,
    address,
  } = invoiceData;

  const isFounder =
    // @ts-ignore
    user?.roles?.name === userRolesEnum.Founder;

  const getInvoiceDataObject = (
    id: number,
    price: string,
    tax: number | string,
    fee: string,
    totalPrice: string,
    date: Date,
    address: string
  ) => {
    const dateValue = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return {
      founder_id,
      project_id: id,
      service_price: +price,
      tax: +tax,
      transfer_fee: +fee,
      total: +totalPrice,
      due_date: dateValue,
      invoiceDate: date,
      address,
    };
  };

  const getInvoiceTotal = (key: string, value: number) => {
    const path = `${URL_PROJECT_TOTAL}?service_price=${
      key === 'service_price' ? value : service_price
    }&tax=${key === 'tax' ? value : tax}`;
    API.getStaticApiData(path)
      .then(async (res) => {
        const { total, transfer_fee, service_price, tax } = res;
        const data = await getInvoiceDataObject(
          id,
          service_price,
          tax,
          transfer_fee,
          total,
          invoiceDate,
          address
        );
        dispatch(setInvoicePaymentData(data));
      })
      .then(async () => {})
      .catch((error) => {
        console.log('Failed to get service price', error);
      });
  };

  const onInputChange = debounce((objectStoreKey: string, value: string) => {
    dispatch(setInvoiceData({ name: objectStoreKey, value }));
    getInvoiceTotal(objectStoreKey, +value);
  }, 500);
  const onAddressChange = debounce((objectStoreKey: string, value: string) => {
    dispatch(setInvoiceData({ name: objectStoreKey, value }));
  }, 500);

  return (
    <div>
      <div
        className={classNames(
          styles.delimiterRow,
          styles.column,
          styles.alignCenter
        )}
      >
        <div className={styles.topTitle}>
          {intl.formatMessage({ id: 'popup.popup-title.invoice-title' })} {id}
        </div>
        <div className={styles.titleStrong}>{name}</div>
      </div>
      <div
        className={classNames(
          styles.delimiterRow,
          styles.contentBetween,
          styles.noBorder
        )}
      >
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.from' })}
          </div>
          <div className={styles.row}>
            {!isFounder ? (
              <div className={styles.strongText}>
                {user?.name} {user?.surname}
              </div>
            ) : (
              <div className={styles.strongText}>
                {expert?.name} {expert?.surname}
              </div>
            )}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.to' })}
          </div>
          <div className={styles.row}>
            {!isFounder ? (
              <div className={styles.strongText}>
                {founder?.name} {founder?.surname}
              </div>
            ) : (
              <div className={styles.strongText}>
                {user?.name} {user?.surname}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.delimiterRow, styles.contentBetween, {
          [styles.hide]: isFounder && !invoice?.address,
        })}
      >
        <div className={classNames(styles.column, styles.w_100)}>
          {isFounder && invoice?.address && (
            <div className={styles.uppercaseText}>
              {intl.formatMessage({ id: 'component.labels.address' })}
            </div>
          )}
          {!isFounder && (
            <div className={styles.uppercaseText}>
              {intl.formatMessage({ id: 'component.labels.address' })}
            </div>
          )}
          <div className={styles.row}>
            {isFounder && invoice?.address && (
              <div className={styles.priceBlock}>{invoice?.address}</div>
            )}
            {!isFounder && (
              <InputRow
                name="address"
                onChange={onAddressChange}
                nativeStyles={classNames(
                  styles.input,
                  styles.w_100,
                  styles.inputW100
                )}
                customIconStyles={styles.icon}
                type="text"
                value={address}
                useDefaultValue={true}
                notValid={fieldError[createInvoiceInputError.address]}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classNames(styles.delimiterRow, styles.contentBetween)}>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.service-fee' })}
          </div>
          {isFounder ? (
            <div className={classNames(styles.strongText, styles.topMargin)}>
              ${invoice?.service_price}
            </div>
          ) : (
            <>
              <InputRow
                name="service_price"
                onChange={onInputChange}
                nativeStyles={classNames(styles.input)}
                customIconStyles={styles.icon}
                type="number"
                Icon="$"
                value={service_price}
                useDefaultValue={true}
                notValid={fieldError[createInvoiceInputError.service_price]}
              />
            </>
          )}
        </div>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.sales-tax' })}
          </div>
          {isFounder ? (
            <div
              className={classNames(
                styles.row,
                styles.strongText,
                styles.topMargin,
                styles.contentCenter
              )}
            >
              ${invoice?.tax}
            </div>
          ) : (
            <InputRow
              name="tax"
              onChange={onInputChange}
              nativeStyles={classNames(styles.input)}
              customIconStyles={styles.icon}
              type="number"
              Icon="$"
              value={tax}
              useDefaultValue={true}
            />
          )}
        </div>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.transfer-fee' })}
          </div>
          <div
            className={classNames(
              styles.row,
              styles.contentCenter,
              styles.topMargin
            )}
          >
            <div className={styles.strongText}>
              {intl.formatMessage({
                id: 'component.labels.transfer-fee-value',
              })}
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.total-price' })}
          </div>
          <div
            className={classNames(
              styles.row,
              styles.contentEnd,
              styles.topMargin
            )}
          >
            <div className={styles.strongText}>
              ${isFounder ? invoice?.total : total}
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.delimiterRow, styles.column)}>
        <div className={classNames(styles.titleStrong, styles.textCenter)}>
          {intl.formatMessage({
            id: 'popup.popup-title.invoice-title-payment',
          })}
        </div>
        <div className={classNames(styles.row, styles.contentBetween)}>
          <div className={styles.column}>
            <div className={styles.uppercaseText}>
              {intl.formatMessage({ id: 'component.labels.status' })}
            </div>
            <div className={styles.label}>
              {intl.formatMessage({ id: 'component.labels.unpaid' })}
            </div>
          </div>
          <div className={styles.column}>
            <div
              className={classNames(styles.uppercaseText, styles.textCenter)}
            >
              {intl.formatMessage({ id: 'component.labels.due-date' })}
            </div>
            <div
              className={classNames(
                styles.row,
                styles.contentCenter,
                styles.datePicker
              )}
            >
              {isFounder ? (
                <div className={styles.priceBlock}>{invoice?.due_date}</div>
              ) : (
                <DatePicker
                  selected={invoiceDate}
                  onChange={(date: Date) => {
                    const dateValue = `
                  ${
                    date.getMonth() + 1
                  }/${date.getDate()}/${date.getFullYear()}`;
                    dispatch(
                      setInvoiceData({ name: 'invoiceDate', value: date })
                    );
                    dispatch(
                      setInvoiceData({ name: 'due_date', value: dateValue })
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.uppercaseText}>
              {intl.formatMessage({ id: 'component.labels.amount-due' })}
            </div>
            <div className={classNames(styles.row, styles.contentEnd)}>
              <div className={styles.strongText}>
                {' '}
                ${isFounder ? invoice?.total : total}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.totalWrapper)}>
        <div className={classNames(styles.uppercaseText, styles.mbZero)}>
          {intl.formatMessage({ id: 'component.labels.subtotal' })}
        </div>
        <div className={styles.dashedDelimiter}></div>
        <div className={styles.strongText}>
          ${isFounder ? invoice?.service_price : service_price}
        </div>
      </div>
      <div className={classNames(styles.totalWrapper)}>
        <div className={classNames(styles.uppercaseText, styles.mbZero)}>
          {intl.formatMessage({ id: 'component.labels.sales-tax' })}
        </div>
        <div className={styles.dashedDelimiter}></div>
        <div className={styles.strongText}>
          ${isFounder ? invoice?.tax : tax}
        </div>
      </div>
      <div className={classNames(styles.totalWrapper)}>
        <div className={classNames(styles.uppercaseText, styles.mbZero)}>
          {intl.formatMessage({ id: 'component.labels.transfer-fee' })}
        </div>
        <div className={styles.dashedDelimiter}></div>
        <div className={styles.strongText}>
          ${isFounder ? invoice?.transfer_fee : transfer_fee}
        </div>
      </div>
      <div className={classNames(styles.totalWrapper)}>
        <div className={classNames(styles.uppercaseText, styles.mbZero)}>
          {intl.formatMessage({ id: 'component.labels.total' })}
        </div>
        <div className={styles.dashedDelimiter}></div>
        <div className={styles.strongText}>
          ${isFounder ? invoice?.total : total}
        </div>
      </div>

      {
        // @ts-ignore
        !isFounder && (
          <ol className={styles.list}>
            {invoiceExpertContentList.map((listItem: string) => {
              return (
                <li key={listItem.toString()}>
                  {intl.formatMessage({ id: listItem })}
                </li>
              );
            })}
          </ol>
        )
      }
      {isFounder && (
        <div className={styles.list}>
          <p>{intl.formatMessage({ id: 'popup.popup-list.invoice-text4' })}</p>
        </div>
      )}
    </div>
  );
};

export default CreateInvoice;
