import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export enum iconPositions {
  left,
  right,
}
type BtnWithIconProps = {
  Icon: any;
  iconPosition: iconPositions;
  children: React.ReactNode;
  nativeStyles?: string;
};

const BtnWithIcon = (props: BtnWithIconProps) => {
  const {
    Icon = null,
    iconPosition = iconPositions.left,
    children = null,
    nativeStyles = '',
  } = props;
  return (
    <div className={styles.wrapper}>
      {iconPosition === iconPositions.left && (
        <div className={styles.marginRight}>
          <Icon />
        </div>
      )}

      <div className={classNames(styles.text, nativeStyles)}>{children}</div>
      {iconPosition === iconPositions.right && (
        <div className={styles.marginLeft}>
          <Icon />
        </div>
      )}
    </div>
  );
};

export default BtnWithIcon;
