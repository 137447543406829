import React from 'react';
import AppHeader from '../appHeader';
import { IComponentWithChildren } from 'interfeaces/iComponentWithChildren';
import Spinner from 'componets/spiner';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getServerError } from 'store/reducers/globalRequestedData/selectors';
import ServerErrorPopup from 'componets/serverErrorPopup';

const LayoutWrapper = (props: IComponentWithChildren) => {
  const { children = null, loading = false } = props;
  const serverError = useSelector(getServerError);
  return (
    <>
      <AppHeader />
      {serverError?.show ? (
        <ServerErrorPopup message={serverError?.message} />
      ) : null}
      {loading && !serverError?.show ? (
        <div className={styles.wrapper}>
          <Spinner />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LayoutWrapper;
