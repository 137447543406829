import { fetchSingleExpertDetails } from 'store/reducers/globalRequestedData/actions';

export const setSingleExpertDetailsStart = (
  id: number | string | undefined,
  cb?: () => void
) => {
  return {
    type: fetchSingleExpertDetails.FetchStart,
    payload: id,
    cb,
  };
};

export const setSingleExpertDetailsSuccess = (data: any) => {
  return {
    type: fetchSingleExpertDetails.FetchSuccess,
    payload: data,
  };
};
export const setSingleExpertDetailsError = (error: Error) => {
  return {
    type: fetchSingleExpertDetails.FetchError,
    payload: error,
  };
};
