import { AVAILABLE_LANG_LIST } from 'i18n/langs/enums';
import { INIT_PUSHER, SET_APP_INIT, SET_LANG } from './actions';
const PusherJS = require('pusher-js');

export interface generalStoreInterface {
  lang: AVAILABLE_LANG_LIST;
  appInit: boolean;
  pusherClient: null | any;
}

const initialState: generalStoreInterface = {
  lang: AVAILABLE_LANG_LIST.EN,
  appInit: false,
  pusherClient: null,
};

export const setLang = (value: AVAILABLE_LANG_LIST) => {
  return {
    type: SET_LANG,
    payload: value,
  };
};

export const setRegistrationLoading = (value: boolean) => {
  return {
    type: SET_APP_INIT,
    payload: value,
  };
};

export const setClientPusher = (settings: any) => {
  return {
    type: INIT_PUSHER,
    payload: settings,
  };
};

const generalReducer = (
  state = initialState,
  action: { type: symbol; payload: any }
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LANG:
      return { ...state, lang: payload };
    case SET_APP_INIT:
      return { ...state, appInit: payload };
    case INIT_PUSHER:
      return {
        ...state,
        pusherClient: new PusherJS(process.env.REACT_APP_PUSHER_APP_KEY, {
          wsHost: process.env.REACT_APP_PUSHER_WS_HOST,
          wsPort: process.env.REACT_APP_PUSHER_WS_PORT,
          cluster: process.env.REACT_APP_PUSHER_CLUSTER,
          forceTLS: false,
          encrypted: true,
          disableStats: true,
          enabledTransports: ['ws'],
          ...payload,
        }),
      };
    default:
      return state;
  }
};

export default generalReducer;
