import * as React from 'react';
import { SVGProps } from 'react';
import { notifyLabelType } from 'pages/dashboardPage/components/projectsBlock/projectLIst/projectListItem/notifyLabel';

type svgProps = SVGProps<SVGSVGElement> & {
  type: notifyLabelType;
};

const NotifyIcon = (props: svgProps) => {
  const { type } = props;
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 .32a7.68 7.68 0 1 0 0 15.361A7.68 7.68 0 0 0 8 .321Zm.717 2.773c.749 0 .969.435.969.931 0 .62-.496 1.194-1.343 1.194-.71 0-1.047-.356-1.026-.946 0-.496.415-1.179 1.4-1.179ZM6.799 12.6c-.512 0-.886-.31-.528-1.675l.586-2.42c.102-.387.118-.542 0-.542-.153 0-.818.267-1.21.53l-.255-.417c1.244-1.04 2.675-1.65 3.287-1.65.512 0 .596.606.341 1.537l-.672 2.544c-.12.45-.068.604.051.604.154 0 .656-.185 1.15-.575l.29.39C8.63 12.134 7.31 12.6 6.8 12.6Z"
        fill={type === notifyLabelType.danger ? '#ffffff' : '#0294B2'}
      />
    </svg>
  );
};

export default NotifyIcon;
