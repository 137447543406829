import React from 'react';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import classNames from 'classnames';

const BudgetBlock = (props: { label?: number; nativeStyles?: string }) => {
  const { label, nativeStyles = '' } = props;
  const intl = useIntl();
  return (
    <div className={classNames(styles.col, nativeStyles)}>
      <div className={styles.titleGray}>
        {intl.formatMessage({ id: 'dashboard.title.title-budget' })}
      </div>
      <div className={styles.titleStrong}>${label}</div>
    </div>
  );
};

export default BudgetBlock;
