import * as React from 'react';
import { SVGProps } from 'react';

const StripeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={42}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#F8F9FB" d="M0 0h42v42H0z" />
    <g clipPath="url(#a)">
      <path
        d="M19.28 16.248c0-1.046.871-1.449 2.269-1.449 2.034 0 4.617.624 6.651 1.721v-6.295c-2.217-.886-4.43-1.223-6.647-1.223-5.423 0-9.037 2.831-9.037 7.565 0 7.402 10.162 6.202 10.162 9.394 0 1.238-1.073 1.636-2.564 1.636-2.213 0-5.072-.915-7.317-2.133v6.023a18.563 18.563 0 0 0 7.312 1.519c5.56 0 9.39-2.391 9.39-7.2 0-7.978-10.22-6.549-10.22-9.558Z"
        fill="#96A4BB"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(9 9)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default StripeIcon;
