import React from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import NotifyIcon from 'svgComponent/NotifyIcon';
import classNames from 'classnames';
export enum notifyLabelType {
  danger,
  success,
}
const NotifyLabel = (props: { label: string; type?: notifyLabelType }) => {
  const { label, type = notifyLabelType.success } = props;
  const intl = useIntl();
  return (
    <div
      className={classNames(styles.label, styles.info, {
        [styles.danger]: type === notifyLabelType.danger,
      })}
    >
      <NotifyIcon
        // @ts-ignore
        type={type}
      />
      <span className={styles.text}>{intl.formatMessage({ id: label })}</span>
    </div>
  );
};

export default NotifyLabel;
