export const AVAILABLE_CHATS = Symbol('AVAILABLE_CHATS');
export const ACTIVE_CHAT_ID = Symbol('ACTIVE_CHAT_ID');
export const ADD_CHAT_MESSAGE = Symbol('ADD_CHAT_MESSAGE');
export const ADD_CHAT_MESSAGE_SUCCESS = Symbol('ADD_CHAT_MESSAGE_SUCCESS');
export const RESET_ACTIVE_CHAT = Symbol('RESET_ACTIVE_CHAT');
export const SET_INVOICE_TRIGGER = Symbol('SET_INVOICE_TRIGGER');
export const SET_CHAT_SCROLL_TRIGGER = Symbol('SET_CHAT_SCROLL_TRIGGER');
export const UPDATE_CHAT_NAME = Symbol('UPDATE_CHAT_NAME');
export enum fetchChats {
  FetchStart = 'fetchChats/fetchStart',
  FetchSuccess = 'fetchChats/fetchSuccess',
  FetchError = 'fetchChats/fetchError',
}

export enum fetchActiveChat {
  FetchStart = 'fetchActiveChat/fetchStart',
  FetchSuccess = 'fetchActiveChat/fetchSuccess',
  FetchError = 'fetchActiveChat/fetchError',
}

export enum updateAvailableChats {
  FetchStart = 'updateAvailableChats/fetchStart',
  FetchSuccess = 'updateAvailableChats/fetchSuccess',
  FetchError = 'updateAvailableChats/fetchError',
}
