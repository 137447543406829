import { fetchUserHoursAvailability } from 'store/reducers/registerData/actions';
import { dataServicesType } from 'store/reducers/registerData/types';

export function setUserHoursAvailabilityStart() {
  return {
    type: fetchUserHoursAvailability.FetchStart,
  };
}

export function setUserHoursAvailabilitySuccess(data: dataServicesType[]) {
  return {
    type: fetchUserHoursAvailability.FetchSuccess,
    payload: data,
  };
}

export function setUserHoursAvailabilityError(error: Error) {
  return {
    type: fetchUserHoursAvailability.FetchError,
    payload: error,
  };
}
