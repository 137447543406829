import * as React from 'react';
import { SVGProps } from 'react';

const ExternalLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.917 9.666H1.501A1.167 1.167 0 0 1 .334 8.5V2.083A1.167 1.167 0 0 1 1.501.916h2.333v1.167H1.501V8.5h6.416V6.166h1.167V8.5a1.167 1.167 0 0 1-1.167 1.166Zm-3.091-3.67-.823-.825L7.674 1.5h-2.09V.333h4.083v4.083H8.501v-2.09l-3.675 3.67Z"
      fill="#2C4877"
    />
  </svg>
);

export default ExternalLinkIcon;
