import {
  fetchEditedProject,
  fetchFounderProject,
  fetchGlobalProjectById,
} from 'store/reducers/globalRequestedData/actions';

export const setGlobalProjectStart = (id: number | undefined) => {
  return {
    type: fetchGlobalProjectById.FetchStart,
    payload: id,
  };
};

export const setGlobalProjectSuccess = (data: any) => {
  return {
    type: fetchGlobalProjectById.FetchSuccess,
    payload: data,
  };
};

export const setGlobalProjectError = (value: Error) => {
  return {
    type: fetchGlobalProjectById.FetchError,
    payload: value,
  };
};

export const setFounderProjectStart = (id: number, cb?: () => void) => {
  return {
    type: fetchFounderProject.FetchStart,
    payload: { id, cb },
  };
};

export const setFounderProjectSuccess = (data: any) => {
  return {
    type: fetchFounderProject.FetchSuccess,
    payload: data,
  };
};

export const setFounderProjectError = (value: Error) => {
  return {
    type: fetchFounderProject.FetchError,
    payload: value,
  };
};

export const setEditedProjectStart = (id: number) => {
  return {
    type: fetchEditedProject.FetchStart,
    payload: id,
  };
};

export const setEditedProjectSuccess = (data: any) => {
  return {
    type: fetchEditedProject.FetchSuccess,
    payload: data,
  };
};

export const setEditedProjectError = (value: Error) => {
  return {
    type: fetchEditedProject.FetchError,
    payload: value,
  };
};
