import React, { useState } from 'react';
import CreateInvoice from 'componets/popup/createInvoice';
import StripeForm from 'componets/stripeForm';
import FormError from 'componets/stripeForm/FormError';
import { projectType } from 'store/reducers/globalRequestedData';
import { createInvoiceInputError } from 'services/enums';
import { useSelector } from 'react-redux';
import { getGlobalProjectData } from 'store/reducers/globalRequestedData/selectors';
import styles from './styles.module.scss';
import Popup from 'componets/popup';
import { useIntl } from 'react-intl';
import { getUser } from 'store/reducers/user/selector';
import Spinner from 'componets/spiner';
import API from 'services/API';
import { URL_PAY_INVOICE } from 'const';
import { userRolesEnum } from 'pages/dashboardPage';

export enum invoiceContentState {
  invoiceVisible,
  checkoutVisible,
  messageError,
  messageSuccess,
}

type iInvoicePayment = {
  contentState: invoiceContentState;
  itemData: projectType;
  setIsVisible: () => void;
};

const sendInvoicePayment = async (invoiceId: number | undefined) => {
  const response = await API.sendFormData(URL_PAY_INVOICE, {
    invoice_id: invoiceId,
  });
  return response;
};

const InvoicePayment = (props: iInvoicePayment) => {
  const { contentState, itemData, setIsVisible } = props;
  const initPopupState = {
    stripeSecret: '',
    popupContentState: contentState,
    contentMessage: '',
    loading: false,
  };

  const [invoiceInputError, setInvoiceInputError] = useState({
    [createInvoiceInputError.service_price]: false,
    [createInvoiceInputError.address]: false,
  });
  const intl = useIntl();
  const user = useSelector(getUser);
  const [popupState, setPopupState] = useState(initPopupState);
  const { stripeSecret, popupContentState, contentMessage, loading } =
    popupState;
  const { id: projectId, invoice, founder, expert } = itemData;
  const projectData = useSelector(getGlobalProjectData);
  const singleInvoiceData = { invoice, founder, expert };

  const onPopupButtonClick = async () => {
    if (popupContentState === invoiceContentState.invoiceVisible) {
      setPopupState((state) => {
        return {
          ...state,
          loading: true,
        };
      });
      const data = await sendInvoicePayment(invoice?.id);
      setPopupState((state) => ({
        ...state,
        stripeSecret: data[0],
        popupContentState: invoiceContentState.checkoutVisible,
        loading: false,
      }));
    }
    if (popupContentState === invoiceContentState.messageError) {
      setPopupState((state) => ({
        ...state,
        popupContentState: invoiceContentState.invoiceVisible,
        isVisible: true,
      }));
    }
  };

  const getPopupContent = (contentState: invoiceContentState) => {
    switch (contentState) {
      case invoiceContentState.invoiceVisible:
        return (
          <CreateInvoice
            projectData={itemData}
            fieldError={invoiceInputError}
          />
        );
      case invoiceContentState.checkoutVisible:
        return (
          <StripeForm
            clientSecret={stripeSecret}
            serviceFee={invoice?.service_price}
            transferFee={invoice?.transfer_fee}
            total={invoice?.total}
            setStateFunction={setPopupState}
            invoiceId={invoice?.id}
            tax={invoice?.tax}
          />
        );
      case invoiceContentState.messageError:
        return <FormError message={contentMessage} />;
      default:
        return (
          <CreateInvoice
            projectData={projectData}
            founderInvoiceData={singleInvoiceData}
            fieldError={invoiceInputError}
          />
        );
    }
  };

  if (!user) {
    return null;
  }
  const { roles } = user;
  const getPopupTitle = () => {
    if (popupContentState === invoiceContentState.messageError) {
      return false;
    }
    const invoiceTitle =
      // @ts-ignore
      roles.name === userRolesEnum.Expert
        ? 'popup.popup-title.invoice-payment'
        : 'popup.popup-title.invoice-payment-founder';
    return intl.formatMessage(
      {
        id: invoiceContentState.checkoutVisible
          ? invoiceTitle
          : 'popup.popup-title.invoice',
      },
      { invoiceId: invoice?.id }
    );
  };

  return (
    <Popup
      name={getPopupTitle()}
      buttonLabel={intl.formatMessage({
        id:
          popupContentState !== invoiceContentState.invoiceVisible
            ? 'component.button.pay-ok'
            : 'component.button.pay-now',
      })}
      onButtonClick={onPopupButtonClick}
      setPopupVisible={setIsVisible}
      // @ts-ignore
      userRoles={roles.name}
      isBtnVisible={popupContentState !== invoiceContentState.checkoutVisible}
      nativeBtnStyle={
        popupContentState !== invoiceContentState.invoiceVisible
          ? styles.toRight
          : ''
      }
      showFooterLabel={
        !!(popupContentState === invoiceContentState.invoiceVisible)
      }
    >
      {loading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        getPopupContent(popupContentState)
      )}
    </Popup>
  );
};

export default InvoicePayment;
