import store from 'store';
import { setActiveChatStart } from 'store/reducers/chat/actions/chatMessages';
import { ROUT_CHAT_PAGE } from 'const';
import { setUpdateAvailableChatsStart } from 'store/reducers/chat/actions/availableChats';
import { getAvailableChats } from 'store/reducers/chat/selectors';

export const onExpertContactClick = async (
  expert_id: number | string | null,
  project_id: number | string | null,
  navigateFunction: any,
  id: any
) => {
  const availableChat = getAvailableChats(store.getState());
  // @ts-ignore
  const existChat = availableChat?.filter((item) => {
    // @ts-ignore
    if (project_id && item.project_id === Number.parseInt(project_id)) {
      return item.expert[0]?.id === +id;
    }
    return false;
  });
  // @ts-ignore
  if (existChat?.length) {
    store.dispatch(
      setActiveChatStart(existChat[0]?.id, 1, () => {
        navigateFunction(ROUT_CHAT_PAGE);
      })
    );
    return;
  }
  if (expert_id && project_id) {
    store.dispatch(
      setUpdateAvailableChatsStart(expert_id, project_id, id, () => {
        navigateFunction(ROUT_CHAT_PAGE);
      })
    );
  }
};
