import { userAchievesType } from 'store/reducers/registerData/types';
import { fetchNewProjectAchieves } from 'store/reducers/createProjects/actions';

export const setNewProjectAchievesStart = () => {
  return {
    type: fetchNewProjectAchieves.FetchStart,
  };
};

export const setNewProjectAchievesSuccess = (data: userAchievesType[]) => {
  return {
    type: fetchNewProjectAchieves.FetchSuccess,
    payload: data,
  };
};

export const setNewProjectAchievesError = (value: Error) => {
  return {
    type: fetchNewProjectAchieves.FetchError,
    payload: value,
  };
};
