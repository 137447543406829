import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PageTitle from 'componets/pageTitle';
import PageSubtitle from 'componets/pageSubtitle';
import StepsButton from 'pages/registrationPage/components/stepsButton';
import { currentStepSelectorKeys } from 'services/enums';

interface StepsPropsInterface {
  title: string;
  stepsButton: boolean;
  children: React.ReactNode;
  subtitle?: string;
  subtitleValues?: object;
  stepKey: currentStepSelectorKeys;
}

const StepsContent = (props: StepsPropsInterface) => {
  const {
    title = '',
    stepsButton = '',
    subtitle = false,
    children = null,
    subtitleValues = {},
    stepKey,
  } = props;

  return (
    <div className={classNames(styles.stepsContent)}>
      {title && <PageTitle title={title} />}
      {subtitle && (
        <PageSubtitle title={subtitle} subtitleValues={subtitleValues} />
      )}
      {children}
      {stepsButton ? <StepsButton stepKey={stepKey} /> : null}
    </div>
  );
};

export default StepsContent;
