import * as React from 'react';
import { SVGProps } from 'react';

const LargeOkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={110}
    height={110}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={110} height={110} rx={55} fill="#0294B2" />
    <rect
      x={6}
      y={6}
      width={98}
      height={98}
      rx={49}
      stroke="#fff"
      strokeWidth={4}
    />
    <path
      d="M73 46 51 68 41 58"
      stroke="#fff"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LargeOkIcon;
