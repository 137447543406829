import React from 'react';
import Button, { buttonTypes } from 'componets/button';
import classNames from 'classnames';
import styles from 'pages/registrationPage/components/stepsButton/styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentRegisterStep,
  getExpertDataToSend,
  getFormData,
  getStepsError,
} from 'store/reducers/registerData/selector';
import {
  AVAILABLE_REGISTRATION_STEPS,
  AVAILABLE_TOTAL_STEPS,
  ROUT_CREATE_PROJECT,
  ROUT_EDIT_PROJECT,
  ROUT_REGISTRATION,
  ROUT_VIEW_PROFILE,
  URL_STARTUP_PROJECT,
} from 'const';
import { setRegisterStep } from 'store/reducers/registerData';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { onSendDataButtonPress } from 'componets/registration/formStepsContent/thankYouStep';
import { currentStepSelectorKeys } from 'services/enums';
import {
  setCreatedProjectId,
  setCreateProjectStep,
} from 'store/reducers/createProjects';
import {
  getCreatedNewProjectId,
  getNewProjectCurrentStep,
  getNewProjectData,
  getNewProjectDraftState,
} from 'store/reducers/createProjects/selector';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';
import StorageService from 'services/storageService';
import API from 'services/API';
import { setUser } from 'store/reducers/user';
import { setExpertDetailsStart } from 'store/reducers/createProjects/actions/expertDetails';
import { dependentSkillsOf } from 'store/reducers/registerData/saga';
import { setGlobalSkillsDataStart } from 'store/reducers/globalRequestedData/actions/skills';
import { setGlobalAchievesStart } from 'store/reducers/globalRequestedData/actions/achievesAction';
import {
  setAppLoading,
  setServerError,
} from 'store/reducers/globalRequestedData';
import { updateChatName } from 'store/reducers/chat';

type StepsButtonProps = {
  stepKey: currentStepSelectorKeys;
};

const sendData = async (
  data: any,
  projectState: boolean,
  projectId?: number | null
) => {
  if (!data) {
    return;
  }
  if (projectState) {
    const formData = await API.putFormData(
      `${URL_STARTUP_PROJECT}/${projectId}`,
      data
    );
    return formData;
  }
  const formData = await API.sendFormData(URL_STARTUP_PROJECT, data);
  return formData;
};

const StepsButton = (props: StepsButtonProps) => {
  const { stepKey } = props;
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const registerStep = useSelector(getCurrentRegisterStep);
  const createPageStep = useSelector(getNewProjectCurrentStep);
  const formData = useSelector(getFormData);
  const projectData = useSelector(getNewProjectData);
  const projectInDraft = useSelector(getNewProjectDraftState);
  const currentStep =
    stepKey === currentStepSelectorKeys.register
      ? registerStep
      : createPageStep;
  const intl = useIntl();
  const isTotalSteps = currentStep > AVAILABLE_REGISTRATION_STEPS;
  const navigate = useNavigate();
  const location = useLocation();
  const stepsError = useSelector(getStepsError);
  const dataToSend = useSelector(getExpertDataToSend);
  const newCreatedProjectId = useSelector(getCreatedNewProjectId);
  const dispatchStep = (key: number, stepValue: number) => {
    key === currentStepSelectorKeys.register
      ? dispatch(setRegisterStep({ nextStep: stepValue, error: '' }))
      : dispatch(setCreateProjectStep({ nextStep: stepValue, error: '' }));
  };

  const { typeStartup, typeOfGoals } = projectData;

  const isProjectRout =
    location.pathname === ROUT_CREATE_PROJECT ||
    location.pathname === ROUT_EDIT_PROJECT;
  const getData = (isProjectDraft: boolean) => {
    return new Promise((resolve, reject) => {
      const editFounder = isProjectDraft ? {} : { founder_id: user?.id };
      const {
        name,
        typeStartup,
        minRate,
        maxRate,
        budget,
        skills,
        typeOfAchieves,
        typeOfGoals,
        typeOfExpert,
      } = projectData;
      const dataToSend = {
        ...editFounder,
        name: name.trim(),
        // @ts-ignore
        type_startup_id: +typeStartup.id,
        min_rate: Number.parseInt(String(minRate)),
        max_rate: Number.parseInt(String(maxRate)),
        budget: Number.parseInt(String(budget)),
        skills: skills?.map((skill) => skill.id).join(','),
        // @ts-ignore
        areas: typeOfExpert?.map((areas) => areas.value).join(','),
        goals: typeOfGoals?.map((goals: any) => goals.value).join(','),
        achieves: typeOfAchieves
          ?.map((achieve: any) => achieve.value)
          .join(','),
      };
      resolve(dataToSend);
    });
  };
  const sendProjectData = () => {
    if (currentStep !== 1) {
      return;
    }
    if (typeStartup?.id) {
      dispatch(
        setGlobalSkillsDataStart({
          stateKey: 'skills',
          dependentType: dependentSkillsOf.areaId,
        })
      );
    }
    if (typeOfGoals?.length) {
      dispatch(
        setGlobalAchievesStart({
          stateKey: 'achieves',
        })
      );
      dispatch(
        setGlobalSkillsDataStart({
          stateKey: 'skills',
          dependentType: dependentSkillsOf.achieveId,
        })
      );
    }
  };

  const onNextClick = async () => {
    const stepValue =
      (currentStep < 1 && 1) ||
      (currentStep + 1 > AVAILABLE_TOTAL_STEPS && AVAILABLE_TOTAL_STEPS) ||
      currentStep + 1;
    dispatchStep(stepKey, stepValue);
    if (location.pathname === ROUT_REGISTRATION) {
      await StorageService.setByKey('formData', JSON.stringify(formData));
      await StorageService.setByKey(
        'currentRegisterStep',
        currentStep.toString()
      );
    }
    if (isProjectRout && currentStep === 2) {
      dispatch(setAppLoading(true));
      getData(projectInDraft)
        .then(async (result) => {
          const responseData = await sendData(
            result,
            projectInDraft,
            newCreatedProjectId
          );
          if (responseData) {
            const { data } = responseData;
            if (projectInDraft) {
              dispatch(
                setExpertDetailsStart({
                  page: 1,
                  id: data.id,
                })
              );
              dispatch(updateChatName(data.id, data.name));
            } else {
              dispatch(setCreatedProjectId(data.id));
            }
          }
        })
        .finally(() => {
          dispatch(setAppLoading(false));
        })
        .catch((error) => {
          console.log('Failed to send project data', error);
        });
    }
  };

  const onBackClick = async () => {
    const stepValue =
      (currentStep > AVAILABLE_TOTAL_STEPS && AVAILABLE_TOTAL_STEPS) ||
      (currentStep - 1 < 1 && 1) ||
      currentStep - 1;
    const storageStepValue = currentStep - 2;
    dispatchStep(stepKey, stepValue);
    if (location.pathname === ROUT_REGISTRATION) {
      await StorageService.setByKey('formData', JSON.stringify(formData));
      await StorageService.setByKey(
        'currentRegisterStep',
        storageStepValue.toString()
      );
    }
  };

  if (!isTotalSteps) {
    return (
      <div className={classNames(styles.btnsRow, styles.contentBetween)}>
        {currentStep !== 1 && (
          <Button
            onClick={onBackClick}
            type={buttonTypes.transparent}
            nativeStyles={styles.mrAuto}
          >
            {intl.formatMessage({ id: 'register.steps-button.button-back' })}
          </Button>
        )}
        <Button
          onClick={async () => {
            await onNextClick();
            if (isProjectRout) {
              sendProjectData();
            }
          }}
          disabled={!!stepsError}
          type={buttonTypes.info}
          nativeStyles={classNames(styles.mlAuto, {
            // @ts-ignore
            [styles.bgDanger]: user?.roles.name === userRolesEnum.Founder,
          })}
        >
          {intl.formatMessage({ id: 'register.steps-button.button-next' })}
        </Button>
      </div>
    );
  }

  return (
    <div className={classNames(styles.btnsRow, styles.contentStart)}>
      <Button
        onClick={async () => {
          onSendDataButtonPress(dataToSend)
            .then((response) => {
              // @ts-ignore
              if (!response?.data) {
                dispatch(setServerError(true, ''));
                return;
              }
              const { user } = response.data;
              dispatch(setUser(user));
            })
            .then(async () => {
              await StorageService.deleteByKey(
                'formData',
                'currentRegisterStep',
                'preliminaryCompleted'
              );
              window.location.reload();
            });
        }}
        type={buttonTypes.transparent}
        nativeStyles={classNames(styles.mlZero, styles.mrAuto, {
          // @ts-ignore
          [styles.bgDanger]: user?.roles.name === userRolesEnum.Founder,
          [styles.mrGrid]: currentStep !== 1,
        })}
      >
        {intl.formatMessage({ id: 'register.steps-button.button-skip' })}
      </Button>
      {currentStep !== 1 && (
        <Button
          onClick={async () => {
            navigate(ROUT_VIEW_PROFILE, { replace: true });
          }}
          type={buttonTypes.info}
          nativeStyles={styles.mrZero}
        >
          {intl.formatMessage({ id: 'register.steps-button.button-view' })}
        </Button>
      )}
    </div>
  );
};

export default StepsButton;
