import React, { useState } from 'react';
import styles from './styles.module.scss';
import RegisterSmallLabel, {
  SmallLabelsType,
} from 'pages/registrationPage/components/registrationLabelList/registerSmallLabel';
import classNames from 'classnames';
import InputRow from 'componets/inputRow';
import Button, { buttonTypes } from 'componets/button';
import { useIntl } from 'react-intl';
import { dataItemType } from 'store/reducers/registerData/types';
import { addToStore, findItemById, removeFromStore } from 'services/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';
import { getFormData } from 'store/reducers/registerData/selector';
import { setOthersSkillFormData } from 'store/reducers/registerData';

interface IRegistrationLabelList {
  labelsArray: dataItemType[] | null;
  usersLabelList: dataItemType[] | null;
  storeObjectKey: string;
  showInputRow?: boolean;
  setFunction: any;
  nativeStyle?: string;
  contentLimit?: number;
  selectedLimit?: number;
  listId?: number | undefined;
}

const otherFormDataKeys = {
  userAchieves: 'achieves_others',
  userSkills: 'skills_others',
  userTypeStartups: 'type_startups_others',
};

const otherFormDataObjectKeys = {
  achieves_others: 'achieve',
  skills_others: 'skill',
  type_startups_others: 'skill',
};

const RegistrationLabelList = (props: IRegistrationLabelList) => {
  const {
    labelsArray = [],
    showInputRow = false,
    usersLabelList = [],
    storeObjectKey = '',
    setFunction,
    nativeStyle = '',
    contentLimit,
    selectedLimit,
    listId = '',
  } = props;
  const user = useSelector(getUser);
  const [isShowMore, setIsShowMore] = useState(Boolean(contentLimit));
  const [visibleInputRow, setVisibleInputRow] = useState(showInputRow);
  const intl = useIntl();
  const formData = useSelector(getFormData);
  const dispatch = useDispatch();
  // @ts-ignore
  const inputOtherKey = otherFormDataKeys[storeObjectKey];
  // @ts-ignore
  const inputValue = formData[inputOtherKey]?.find(
    (item: any) => item.area_expertise_id === listId
  );
  const [isInputVisible, setIsInputVisible] = useState(Boolean(inputValue));

  const onInputChange = (name: any, value: any, skillId: any) => {
    // @ts-ignore
    const key = otherFormDataObjectKeys[name];
    dispatch(setOthersSkillFormData({ name, value, skillId, key }));
  };

  const onLabelClick = (title: string, id: number) => {
    const isSelected = findItemById(id, usersLabelList);

    if (isSelected.length) {
      if (!visibleInputRow && showInputRow) {
        setVisibleInputRow(true);
      }
      removeFromStore(id, storeObjectKey, usersLabelList, setFunction);
    } else {
      const value =
        inputOtherKey !== 'achieves_others'
          ? inputValue?.skill
          : inputValue?.achieve;
      const selectedDataLength =
        // @ts-ignore
        usersLabelList?.length + (value ? 1 : 0);
      if (
        selectedLimit &&
        usersLabelList &&
        selectedLimit - 1 <= selectedDataLength &&
        !value
      ) {
        setVisibleInputRow(false);
        dispatch(
          setOthersSkillFormData({
            name: inputOtherKey,
            value: '',
            skillId: listId,
            // @ts-ignore
            key: otherFormDataObjectKeys[inputOtherKey],
          })
        );
      }
      if (
        selectedLimit &&
        usersLabelList &&
        selectedLimit <= selectedDataLength
      ) {
        return;
      }
      addToStore(id, title, storeObjectKey, usersLabelList, setFunction);
    }
  };
  const slicedArray = isShowMore
    ? labelsArray?.slice(0, contentLimit)
    : labelsArray;
  const labelsNativeStyles = classNames(styles.wrapperMargin, nativeStyle);
  const slicedLength = slicedArray?.length;
  const labelsLength = labelsArray?.length;
  // @ts-ignore
  const moreLength = labelsLength - slicedLength;
  return (
    <div className={styles.flexRow}>
      {slicedArray?.map((label, index: number) => {
        const { name, id } = label;
        const isSelected = findItemById(id, usersLabelList);
        return (
          <RegisterSmallLabel
            title={name}
            id={id}
            key={`${name.toString()}_${index.toString()}`}
            type={
              // @ts-ignore
              user?.roles.name === userRolesEnum.Expert
                ? SmallLabelsType.info
                : SmallLabelsType.danger
            }
            nativeStyles={labelsNativeStyles}
            onClick={onLabelClick}
            isSelected={!!isSelected.length}
          />
        );
      })}

      {contentLimit && moreLength > 0 ? (
        <div
          className={classNames(styles.flexRow, styles.w100, styles.btnMore)}
        >
          <Button
            onClick={() => {
              setIsShowMore((state) => !state);
            }}
            type={buttonTypes.strongUnderline}
            nativeStyles={styles.btn}
          >
            {intl.formatMessage(
              { id: 'component.button.plus-more' },
              { number: moreLength }
            )}
          </Button>
        </div>
      ) : null}
      {visibleInputRow && (
        <>
          <Button
            type={buttonTypes.underline}
            nativeStyles={styles.wrapperMargin}
            onClick={() => {
              setIsInputVisible((state) => !state);
              dispatch(
                setOthersSkillFormData({
                  name: inputOtherKey,
                  value: '',
                  skillId: listId,
                  // @ts-ignore
                  key: otherFormDataObjectKeys[inputOtherKey],
                })
              );
            }}
          >
            {intl.formatMessage({ id: 'register.steps-button.button-other' })}
          </Button>
          {isInputVisible && (
            <div className={classNames(styles.inputWrapper, styles.flexRow)}>
              <InputRow
                title="register.step.title-input-other"
                name={inputOtherKey}
                placeholder="register.step.title-input-other-placeholder"
                onChange={onInputChange}
                // @ts-ignore
                value={
                  inputOtherKey !== 'achieves_others'
                    ? inputValue?.skill
                    : inputValue?.achieve
                }
                listId={listId}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default RegistrationLabelList;
