export const getSelectorStyles = (isActive: boolean) => {
  return {
    control: () => ({
      padding: '7px',
      borderRadius: 2,
      fontFamily: 'inherit',
      fontSize: 18,
      color: isActive ? 'var(--c-dark)' : 'var(--c-grey-darken)',
      lineHeight: 1.35,
      display: 'flex',
      border: '1px solid var(--c-grey)',
    }),
    indicatorSeparator: () => {
      return {
        opacity: 0,
        border: '1px solid var(--c-dark)',
      };
    },
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = 'color: var(--c-grey-darken)';
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition, color };
    },
  };
};
