import { all, fork } from 'redux-saga/effects';
import { registersDataRootSaga } from 'store/reducers/registerData/saga';
import { chatRootSaga } from 'store/reducers/chat/saga';
import { fetchUserInvoiceSaga } from 'store/reducers/invoice/saga';
export default function* rootSaga() {
  yield all([
    fork(registersDataRootSaga),
    fork(chatRootSaga),
    fork(fetchUserInvoiceSaga),
  ]);
}
