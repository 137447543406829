import * as React from 'react';
import { SVGProps } from 'react';

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      d="M17.121 15.897a8.767 8.767 0 0 0-1.889-2.8 8.804 8.804 0 0 0-2.8-1.89c-.01-.005-.02-.007-.029-.012A5.812 5.812 0 0 0 9 .672a5.811 5.811 0 0 0-3.403 10.526l-.028.011a8.727 8.727 0 0 0-2.801 1.89 8.804 8.804 0 0 0-1.889 2.8 8.712 8.712 0 0 0-.691 3.237.187.187 0 0 0 .187.192h1.406a.187.187 0 0 0 .188-.183 6.988 6.988 0 0 1 2.058-4.788A6.985 6.985 0 0 1 9 12.297c1.88 0 3.645.731 4.973 2.06a6.988 6.988 0 0 1 2.058 4.788.186.186 0 0 0 .188.183h1.406a.188.188 0 0 0 .188-.192 8.736 8.736 0 0 0-.692-3.24Zm-8.12-5.381a4.005 4.005 0 0 1-2.85-1.182 4.005 4.005 0 0 1-1.182-2.85c0-1.075.42-2.088 1.181-2.85A4.005 4.005 0 0 1 9 2.454c1.076 0 2.088.419 2.85 1.18a4.005 4.005 0 0 1 1.181 2.85c0 1.076-.42 2.089-1.18 2.85A4.005 4.005 0 0 1 9 10.516Z"
      fill="#96A4BB"
    />
  </svg>
);

export default UserIcon;
