const enMessages = {
  'register.step-1.h1-title': 'Getting started',
  'register.step-1.page-subtitle': 'Dear Expert!',
  'register.step-1.content-text':
    'Welcome to Upmarqt! In the coming few pages we will ask you to fill in some information about your area of expertise, general information about you as an expert and the type of founders you prefer to work with.\n\n We know how important it is to work with people you feel energised by. Therefore, we want to ensure that the aspirations and values of the founders you are matched with are in-line with yours.\n\n In order to ensure the best possible match we would like you to complete the following few steps as accurately and as completely as possible.\n\n',

  'register.step-2.h1-title': 'Your area of expertise',
  'register.step-2.page-subtitle': 'Select up to 3 areas of expertise',

  'register.step-3.h1-title': 'Type of service provider',
  'register.step-3.country-select-placeholder': 'Select a country',
  'register.step-3.region-select-placeholder': 'Select a state/region',
  'register.step-3.city-select-placeholder': 'Select a city',

  'register.step-4.h1-title': 'Location',

  'register.step-5.h1-title': 'What do you help founders achieve?',
  'register.step-5.page-subtitle': 'Select all that apply',
  'register.step-5.title-design': 'Design and graphic design',
  'register.step-5.title-brand': 'Brand',
  'register.step-5.title-development': 'Development',

  'register.step-6.h1-title': 'What are your skills?',
  'register.step-6.page-subtitle': 'Select all that apply',
  'register.step-6.title-design': 'Design and graphic design',
  'register.step-6.title-brand': 'Brand',
  'register.step-6.title-development': 'Development',

  'register.step-7.h1-title': 'What have you helped other startups achieve?',
  'register.step-7.page-subtitle':
    'List 1-3 of your key achievements for other startups. Be brief and precise.',
  'register.step-7.textarea-placeholder':
    'List your key achievements here. Numbers and results always help.',

  'register.step-8.h1-title':
    'Industries and types of startups you have experience with or preference for',
  'register.step-8.page-subtitle': 'Select up to 5 options',

  'register.step-9.h1-title': 'Your availability in hours per week',

  'register.step-10.h1-title': 'Hourly rate band',
  'register.step-10.page-subtitle':
    'Enter the approximate range of your hourly rate',
  'register.step-10.payment-method': 'Payout method ',
  'register.step-10.placeholder-stripe': 'You will be redirect to stripe',
  'register.step-10.placeholder-stripe-ready': 'Stripe account is ready',

  'register.step-11.h1-title': 'Portfolio upload',
  'register.step-11.page-subtitle':
    'Please upload one pdf file with your case studies or your portfolio. The file must be less than {number}MB.',
  'register.step-11.page-warning':
    'Please make sure to remove all links and your email address from your portfolio, otherwise your profile cannot be approved.',
  'register.step-11.select_other_photo': 'UPLOAD ANOTHER PHOTO',

  'register.step-12.h1-title': 'Profile picture upload',
  'register.step-12.page-subtitle':
    'Upload your picture or a logo of your agency',
  'register.step-12.input-upload-placeholder': 'Select image or drop here',
  'register.step-13.h1-title': 'Thank you',
  'register.step-13.page-subtitle':
    'We are finally done! Now you can check how others see your profile! ',
  'register.step-14.h1-title': 'Thank you',
  'register.step-14.page-subtitle':
    'We are finally done! Now you can check how others see your profile! ',

  'register.step-thank-you.h1-title': 'Thank you',
  'register.step-thank-you.page-subtitle':
    'We are finally done! Now you can check how others see your profile!',

  'register.steps-aside.step-1': 'Getting started',
  'register.steps-aside.step-2': 'Your area of expertise',
  'register.steps-aside.step-3': 'Type of service provider',
  'register.steps-aside.step-4': 'Location',
  'register.steps-aside.step-5': 'What do you help founders achieve?',
  'register.steps-aside.step-6': 'What are your skills?',
  'register.steps-aside.step-7': 'Your impact in other startups',
  'register.steps-aside.step-8': 'Experience and preferences',
  'register.steps-aside.step-9': 'Your availability in hours per week\n',
  'register.steps-aside.step-10': 'Hourly rate band',
  'register.steps-aside.step-11': 'Portfolio upload',
  'register.steps-aside.step-12': 'Profile picture upload',

  'register.steps-button.button-next': 'Next',
  'register.steps-button.button-skip': 'Submit',
  'register.steps-button.button-view': 'View my profile',
  'register.steps-button.button-back': 'Back',
  'register.steps-button.button-other': 'Other',
  'register.step.title-input-other':
    'ENTER AN OPTION YOU THINK SHOULD APPEAR ABOVE',
  'register.step.title-input-other-placeholder': 'Enter your option here',

  'view-page.top-bar.text':
    'If everything is correct, please submit your profile. Otherwise, your data will be lost.',
  'view-page.top-bar.link-text': 'Submit',
  'view-page.top-bar.link-text-edit': 'Edit profile',
  'view-page.top-bar.expert-title': 'Expert profile',
  'view-page.top-bar.reviews-title': 'Reviews',
  'view-page.top-bar.additional-title': 'Additional info',
  'view-page.additional-data.service-type-title': 'Type of service provider',
  'view-page.additional-data.availability-title': 'Availability to work',
  'view-page.additional-data.achieve-title': 'Can help founders achieve',
  'view-page.additional-data.skills-title': 'Skills',
  'view-page.additional-data.experience-title':
    'Experience in or preference for these industries',
  'view-page.additional-data.major-achievements-title':
    'Previous major achievements',
  'view-page.additional-data.portfolio-title': 'Portfolio',
  'view-page.additional-data.hours-per-week': 'per week',
  'view-page.additional-data.link.open-pdf': 'VIEW FILE',
  'view-page.review-text': 'This user is currently has no reviews',

  'dashboard.rate.rate-based-on':
    'Rating based on <strong>number</strong> reviews',
  'dashboard.available.available-label': 'Available to work now',
  'dashboard.available.available-confirm-message':
    'You will be available to work after review',
  'dashboard.available.available-text':
    "Turn on your availability in order for your profile to be searchable when founders are looking for your area of expertise. Don't forget to turn off your availability when you are not taking on work.",

  'create-new-project.steps-aside.step-1': 'Information about the project',
  'create-new-project.steps-aside.step-2': 'Expert selection criteria',
  'create-new-project.steps-aside.step-3': 'Select an expert',
  'create-new-project.step-1.h1-title': 'Information about the project',
  'create-new-project.step-1.page-subtitle':
    'Guide us through what you need so we can find the perfect expert for you.',
  'create-new-project.step-1.input-project-name': 'Project name',
  'create-new-project.step-1.select-title': 'What type of startup do you run? ',
  'create-new-project.step-1.buttons-title':
    'Do you know the area of expertise you need?',
  'create-new-project.step-1.expertise-yes': 'What kind of expert do you need?',
  'create-new-project.step-1.goal-no': 'What are your high level goals now?',
  'create-new-project.step-1.achieves-no': 'What are you looking to achieve?',
  'create-new-project.step-1.input-placeholder-surname': 'Enter your surname',
  'create-new-project.step-1.input-placeholder-name': 'Enter your name',
  'create-new-project.step-1.input-placeholder-project-name':
    'Enter the project name',
  'create-new-project.step-1.button-yes': 'Yes',
  'create-new-project.step-1.button-no': 'No, help me figure it out',

  'create-new-project.step-2.h1-title': 'Contractor selection criteria',
  'create-new-project.step-2.row-title-approximate':
    'What is the approximate price per hour range you are looking for?',
  'create-new-project.step-2.row-title-maximum':
    'What is your total maximum budget for this type of expert?',
  'create-new-project.step-2.row-title-skills':
    'Are there specific skills you are looking for?',
  'create-new-project.step-3.search-result-title': 'Your Top Matches',
  'create-new-project.step-3.button-next': 'Next',
  'create-new-project.step-3.button-prev': 'Previous',
  'create-new-project.step-3.h3-title1': 'Type of expert',
  'create-new-project.step-3.h3-title2': 'Areas of expertise',
  'create-new-project.step-3.h3-title3': 'Previous major achievements',
  'create-new-project.step-3.h3-title4': 'Location',
  'create-new-project.step-3.h3-title5': 'Skills',
  'create-new-project.step-3.h3-title6': 'Rate',
  'create-new-project.step-3.available-h': 'per hour',
  'create-new-project.no-result.title':
    "Sorry, we couldn't find the perfect match!",
  'create-new-project.no-result.sub-title':
    'Please fill in our <a>Contact Form</a> and a real Upmarqt human will be in touch with you shortly to help you find the perfect expert',

  'dashboard.button.button-edit-invoices': 'Invoices',
  'dashboard.button.button-edit-profile': 'Edit profile',
  'dashboard.button.button-reviews-profile': 'Reviews',
  'dashboard.button.button-logout-profile': 'Logout',
  'dashboard.button.button-all-show-chat': 'ALL CHATS',
  'dashboard.button.button-note-remove': 'Delete',
  'dashboard.button.button-view-profile': 'View Profile',
  'dashboard.button.button-find-expert': 'Find Expert',
  'dashboard.button.button-edit-project': 'Edit Project',
  'dashboard.button.button-report-user': 'Report user',
  'dashboard.button.button-pay-invoice': 'Pay invoice',
  'dashboard.button.button-release-invoice': 'Release invoice',
  'dashboard.button.button-rate': 'Rate expert',
  'dashboard.rate.popup.title': 'Release invoice',
  'dashboard.rate.popup.expert': 'Your expert',
  'dashboard.rate.popup.rate.expert': 'Rate your expert',
  'dashboard.rate.popup.textarea.placeholder':
    'Drop a few lines about your experience during this project ',
  'dashboard.rate.popup.textarea.label': 'Your feedback',
  'dashboard.button.error-rate-message': 'Rating field is required',
  'dashboard.button.error-text-message':
    'The message must be at least 10 characters.',

  'edit-profile.country': 'Country',
  'edit-profile.name': 'Name',
  'edit-profile.name-expert': 'Name or company name',
  'edit-profile.surname': 'Surname',
  'edit-profile.cancel': 'Cancel',
  'edit-profile.save': 'Save changes',
  'edit-profile.avatar-error': 'Сropped image required',

  'dashboard.title.title-expert-chats': 'Your chats',
  'dashboard.title.title-founder-chats': 'Your chats',
  'dashboard.title.title-projects': 'Projects',
  'dashboard.title.title-invoices': 'Projects',
  'dashboard.title.title-status': 'Status',
  'dashboard.title.title-budget': 'Budget',

  'dashboard.labels.label-notify-start': 'You can start working on the project',
  'dashboard.labels.label-notify-startup-type': 'Type of startup',
  'dashboard.labels.invoice-founder-success':
    'You are interacting with an expert',
  'dashboard.labels.invoice-expert-success': 'Invoice created successfully',
  'dashboard.labels.invoice-founder-error':
    'You are already interacting with an expert',
  'dashboard.labels.invoice-expert-error': 'Invoice has already been issued',
  'dashboard.labels.invoice-rel-success': 'Invoice completed successfully',
  'dashboard.labels.no-reviews': 'This user has no reviews yet',

  'file-upload-placeholder': 'Select files or drop here',
  'image-upload-placeholder': 'Select image or drop here',
  'input-upload-placeholder-error-pdf':
    'The uploaded file is not valid \n File size must be less than 20mb \n File type must be pdf',
  'input-upload-placeholder-error':
    'The uploaded file is not valid \n File size must be less than 3mb \n File type must be png or jpeg',
  'input-upload-placeholder-loading': 'Uploading...',
  'input-placeholder.label-from': 'From',
  'input-placeholder.label-send-message': 'Enter your message',
  'input-placeholder.label-to': 'To',
  'input-placeholder.label-budget': 'Enter your budget',
  'input-placeholder.label-chat': 'Enter your message',
  'btn-upload': 'Upload',
  'button.label.label-load-success': 'File uploaded successfully',
  'button.label.label-load-error': 'File uploaded failed.',
  'select-placeholder-type': 'Select a type',
  'multiply-select-placeholder-type': 'Select all that apply',

  'component.hourly-rate-range': 'Hourly Rate range',
  'component.button.btn-save-profile': 'Save profile',
  'component.button.btn-saved-profile': 'Saved',
  'component.button.find-expert': 'Find an expert',
  'component.button.back-expert': 'Back to experts',
  'component.button.back-chat': 'Back to chat',
  'component.button.back-dashboard': 'Back to dashboard',
  'component.button.contact-expert': 'Contact expert',
  'component.button.see-more': 'see more',
  'component.button.more-details': 'more details',
  'component.button.contact': 'Contact',
  'component.button.plus-more': '+ {number} more',
  'component.button.show-less': 'show less',
  'component.button.show-all': 'show all',
  'component.button.connected': 'Connected',
  'component.button.connect': 'Connect',
  'component.button.send-invoice': 'Send invoice',
  'component.button.pay-now': 'Pay now',
  'component.button.pay': 'Pay',
  'component.button.pay-ok': 'Ok',
  'component.button.interact': 'Hire',
  'component.button.create-invoice': 'Create invoice',
  'component.labels.online': 'Online',
  'component.labels.from': 'from',
  'component.labels.address': 'Address',
  'component.labels.to': 'to',
  'component.labels.service-fee': 'Service fee',
  'component.labels.status': 'status',
  'component.labels.subtotal': 'subtotal',
  'component.labels.total': 'total',
  'component.labels.due-date': 'Due Date',
  'component.labels.amount-due': 'amount due',
  'component.labels.transfer-fee': 'Transfer Fee',
  'component.labels.sales-tax': 'Sales Tax',
  'component.labels.transfer-fee-value': '3%',
  'component.labels.total-price': 'Total Price',
  'component.labels.unpaid': 'Unpaid',
  'component.labels.project': 'project',
  'component.labels.create-success': 'Invoice created successfully',
  'component.labels.cancel': 'Cancel',
  'component.labels.ago': 'ago',
  'component.labels.notvalid-email': 'Email address is not correct',
  'component.labels.via-upmarqt': 'Invoice created via Upmarqt',

  'popup.popup-title.invoice': 'Create invoice',
  'popup.popup-title.invoice-payment':
    "Payment of your expert's invoice  #{invoiceId}",
  'popup.popup-title.invoice-payment-founder': 'Invoice from your expert',
  'popup.popup-title.invoice-title': 'Invoice #',
  'popup.popup-title.invoice-title-payment': 'Payment Schedule',
  'popup.popup-list.invoice-text1':
    'You are creating an invoice for your client via Upmarqt. When your client pays the invoice, the transfer fee and 10% will be routed to Upmarqt. The rest will be routed to your account. Note that if there is a conversion from USD to another currency Stripe might charge additional fees as well.',
  'popup.popup-list.invoice-text2':
    'Please note that the invoice is not a contract. If you would like to have your new client sign a contract with your agency, please send it to your client separately.',
  'popup.popup-list.invoice-text4':
    'Please remember your contract is between you and the service provider.\n Upmarqt is a matching platform and does not intervene in any transaction between users. \n When you pay the invoice, the funds will go directly to your service provider. \n Please make sure you pay your invoices according to your contract agreed with your service provider.',
  'popup.popup-title.title-success': 'Thank you for paying the invoice',
  'popup.popup-title.500-error': 'Oops...',
  'popup.popup-title.500-message': 'Something went wrong',
  'popup.popup-message.message-success':
    'Thank you for paying the invoice. The payout is sent directly to your service provider.',
  'popup.popup-title.title-success-expert': 'Thank you for sending the invoice',
  'popup.popup-message.message-success-expert':
    'Your invoice has been sent to the founder',
  'popup.popup-message.message-remove-project':
    'The project you selected will be deleted',
  'popup.popup-message.message-confirm-invoice': 'The invoice will be released',

  'login.menu.contact': 'Contact Us',
  'login.menu.faq': 'FAQ’s',
  'login.menu.terms': 'Terms of service',
  'login.menu.privacy': 'Privacy policy',
  'login.button.login': 'Sign in',
  'login.button.register': 'Create an account',
  'login.button.forgot': 'Forgot password?',
  'login.copyright': 'Copyright © {year} Upmarqt. All rights reserved.',
  'login.developed': 'Developed by',
  'login.email': 'Email',
  'login.password': 'Password',
  'login.account-message': 'Don’t have an account?',
  'login.account-button': 'Create it now',

  'invoices.label-id': 'Invoice id',
  'invoices.label-date': 'Due date',
  'invoices.label-to': 'To',
  'invoices.label-from': 'From',
  'invoices.label-project': 'Project',
  'invoices.label-total': 'Total',
  'invoices.label-total-payout': 'Total payout',
  'invoices.label-status': 'Status',
  'invoices.label-download': 'Download',

  email: 'Email',
  password: 'Password',
  'forgot-password': 'Forgot password?',
  'account.dont-have': 'Don’t have an account?',
  'account.create-it': 'Create it now',

  'verification.verified': 'Verified!',
  'verification.verified.message':
    'You have successfully verified your account.',
  'verification.verified-resend.message':
    'A new confirmation email has been sent to you.',
  'verification.not-verified': 'Oops!',
  'verification.not-verified.message':
    'It seems that your email has not yet been verified.',
  'verification.already': 'You have already verified',
  'verification.expired': 'Oops!',
  'verification.expired.message': 'Verification link time expired.',
  'verification.expired.link': 'Send verification link again',
  'verification.wait-confirmation': 'Oops!',
  'verification.wait-confirmation.message':
    'Your account is under verification. Try again later.',
  'verification.rejected': 'Sorry!',
  'verification.rejected-message': 'Login is not possible.',

  'page.404.page-title': "Sorry, we couldn't find the such page!",

  'header.header-link.dashboard': 'Dashboard',
  'header.header-link.chats': 'Chats',
  'header.header-link.contact-us': 'Contact Us',
  'header.header-link.faqs': 'FAQs',

  'expert-placeholder-page':
    "you don't have any proposed projects yet. \n Make sure your profile is completed in the best possible way so that it is attractive to the customer",
  'expert-placeholder-page.subtitle': 'Collaboration offers will appear here',
  'founder-placeholder-page': "You don't have any projects created right now",
  'founder-placeholder-page.subtitle': 'Create your first project',
};

export default enMessages;
