import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface ITextArea {
  labelTitle?: string;
  placeholder: string;
  value: string;
  name: string;
  onTextAreaChange: (name: string, value: string) => void;
  autoHeight?: boolean;
  onEnterPress?: any;
  nativeStyles?: string;
}

const initState = { height: 50, shiftKey: false };

const TextArea = (props: ITextArea) => {
  const {
    labelTitle = '',
    placeholder = '',
    name = '',
    value = '',
    onTextAreaChange = () => {},
    autoHeight = false,
    onEnterPress,
    nativeStyles,
  } = props;

  const [textAreaState, setTextAreaState] = useState(initState);
  const textareaRef = useRef();
  useEffect(() => {
    if (value.trim().length) {
      return;
    }
    setTextAreaState((state) => ({
      ...state,
      height: initState.height,
    }));
  }, [value]);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = event.target;
    const newHeight = value.trim().length
      ? event.target.scrollHeight
      : textAreaState.height;
    setTextAreaState((state) => ({
      ...state,
      height: newHeight,
    }));
    onTextAreaChange(name, value);
  };

  const onTextAreaKeyDown = (event: any) => {
    if (!onEnterPress) {
      return;
    }
    if (event.shiftKey) {
      setTextAreaState((state) => ({
        ...state,
        shiftKey: event.shiftKey,
      }));
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      textAreaState.shiftKey
    ) {
      // @ts-ignore
      textareaRef.current.value = textareaRef.current.value + '\r\n';
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      !textAreaState.shiftKey
    ) {
      // @ts-ignore
      onEnterPress(event);
    }
  };

  return (
    <label className={styles.flexColumn}>
      {labelTitle && <span className={styles.title}>{labelTitle}</span>}
      <textarea
        // @ts-ignore
        ref={textareaRef}
        className={classNames(styles.textArea, nativeStyles)}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onTextAreaKeyDown}
        onKeyUp={() => {
          setTextAreaState((state) => ({
            ...state,
            shiftKey: false,
          }));
        }}
        value={value}
        style={autoHeight ? { height: textAreaState.height } : {}}
        autoComplete="off"
      ></textarea>
    </label>
  );
};

export default TextArea;
