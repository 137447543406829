import * as React from 'react';
import { SVGProps } from 'react';

const YellowStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      d="m12.416 4.828-3.472-.504-1.551-3.146a.44.44 0 0 0-.785 0L5.056 4.324l-3.471.504a.437.437 0 0 0-.242.747l2.511 2.448-.593 3.458a.436.436 0 0 0 .634.46L7 10.31l3.105 1.633a.437.437 0 0 0 .634-.46l-.593-3.459 2.512-2.448a.437.437 0 0 0-.242-.747Z"
      fill="#FFC000"
    />
  </svg>
);

export default YellowStar;
