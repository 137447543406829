import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import NumberedList from 'componets/numberedList';
import { currentStepSelectorKeys } from 'services/enums';

type StepsAsideProps = {
  config: any;
  setStepFunction: (index: number) => void;
  stepKey: currentStepSelectorKeys;
};

const StepsAside = (props: StepsAsideProps) => {
  const { config, setStepFunction, stepKey } = props;
  return (
    <div className={classNames(styles.sidebarContainer)}>
      <NumberedList
        list={config}
        onItemClick={setStepFunction}
        stepKey={stepKey}
      />
    </div>
  );
};

export default StepsAside;
