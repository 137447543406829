import { combineReducers } from 'redux';
import generalReducer from './general';
import userReducer from 'store/reducers/user';
import registerDataReducer from 'store/reducers/registerData';
import createProjectDataReducer from 'store/reducers/createProjects';
import globalRequestedDataReducer from 'store/reducers/globalRequestedData';
import invoiceReducer from 'store/reducers/invoice';
import chatReducer from 'store/reducers/chat';

const rootReducer = combineReducers({
  generalData: generalReducer,
  user: userReducer,
  registerData: registerDataReducer,
  createProjectData: createProjectDataReducer,
  globalRequestedDataReducer: globalRequestedDataReducer,
  invoiceData: invoiceReducer,
  chatData: chatReducer,
});

export default rootReducer;
