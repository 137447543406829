import * as React from 'react';
import { SVGProps } from 'react';

const PenIcon = (props: SVGProps<SVGSVGElement>) => {
  const { focusable = false } = props;
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.469 14.695H2.53a.562.562 0 0 0-.562.563v.633c0 .077.063.14.14.14h13.782a.14.14 0 0 0 .14-.14v-.633a.562.562 0 0 0-.562-.563ZM4.529 13.22c.036 0 .071-.004.106-.009l2.957-.519a.172.172 0 0 0 .093-.049l7.452-7.451A.176.176 0 0 0 15.175 5a.176.176 0 0 0-.039-.057L12.216 2.02a.174.174 0 0 0-.125-.051.174.174 0 0 0-.125.05l-7.45 7.452a.179.179 0 0 0-.05.093l-.519 2.957a.589.589 0 0 0 .166.524.596.596 0 0 0 .418.174Z"
        fill={focusable ? '#2C4877' : '#96A4BB'}
      />
    </svg>
  );
};

export default PenIcon;
