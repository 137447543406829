import * as React from 'react';
import { SVGProps } from 'react';

const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={26}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.125.022a3.125 3.125 0 1 0 0 6.248c1.723 0 3.123-1.4 3.123-3.124 0-1.724-1.4-3.124-3.123-3.124ZM5.818 8.64H.43v17.338h5.388V8.64ZM19.536 8.21c-2.621 0-4.379 1.437-5.098 2.8h-.072V8.64H9.198v17.337h5.383v-8.576c0-2.261.43-4.451 3.234-4.451 2.763 0 2.8 2.586 2.8 4.596v8.431H26v-9.509c0-4.668-1.007-8.259-6.464-8.259Z"
      fill="#2C4877"
    />
  </svg>
);

export default LinkedInIcon;
