import React, { useEffect } from 'react';
import TextArea from 'componets/textArea';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStartupAchieves } from 'store/reducers/registerData/selector';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';

const SeventhFormStep = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const textAreaValue = useSelector(getUserStartupAchieves);

  useEffect(() => {
    dispatch(setRegisterStepError(!textAreaValue.trim().length));
  }, [textAreaValue]);

  const onTextAreaChange = (name: string, value: string) => {
    dispatch(setFormData({ name, value }));
  };
  return (
    <TextArea
      name="userStartupAchieves"
      placeholder={intl.formatMessage({
        id: 'register.step-7.textarea-placeholder',
      })}
      value={textAreaValue}
      onTextAreaChange={onTextAreaChange}
    />
  );
};

export default SeventhFormStep;
