import * as React from 'react';
import { SVGProps } from 'react';

const SendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.392 9.047c-.522-.174-.527-.455.01-.634L21.49 2.051c.53-.176.832.12.684.638L16.72 21.775c-.15.529-.455.547-.679.045l-3.594-8.088 6-8-8 6-8.054-2.685Z"
      fill="#fff"
    />
  </svg>
);

export default SendIcon;
