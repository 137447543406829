import React, { useState } from 'react';
import styles from './styles.module.scss';
import NotifyLabel from './notifyLabel';
import StatusBlock from './statusBlock';
import RoundImage from 'componets/roundImage';
import classNames from 'classnames';
import BudgetBlock from './budgetBlock';
import MoreBlock from './moreBlock';
import { useIntl } from 'react-intl';
import { projectStatusEnum } from 'services/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage/index';
import Button, { buttonTypes } from 'componets/button';
import { setGlobalProjectStart } from 'store/reducers/globalRequestedData/actions/projectActions';
import API from 'services/API';
import { URL_PAY_INVOICE, URL_PAY_PROJECT } from 'const';
import {
  projectType,
  setFounderProjectState,
} from 'store/reducers/globalRequestedData';
import UserWithFind from 'svg-icons/UserWithFind';
import ReleaseInvoicePopup from 'pages/dashboardPage/components/projectsBlock/projectLIst/projectListItem/releseInvoicePopup';
import InvoicePayment, { invoiceContentState } from 'componets/invoicePayment';

type ProjectListItemProps = {
  itemData: projectType;
};

const initPopupState = {
  isVisible: false,
  popupContentState: invoiceContentState.invoiceVisible,
  loading: false,
};

const ProjectListItem = (props: ProjectListItemProps) => {
  const { itemData } = props;
  const {
    name,
    status,
    budget,
    id: projectId,
    invoice,
    founder,
    expert,
    expert_id,
    can_review,
    expert_detail_id,
  } = itemData;
  const intl = useIntl();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [popupState, setPopupState] = useState(initPopupState);
  const [ratePopupVisibility, setRatePopupVisibility] = useState(false);
  const [infoLabelVisible, setInfoLabelVisible] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const { isVisible, popupContentState } = popupState;
  // @ts-ignore
  const isExpert = user?.roles?.name === userRolesEnum.Expert;

  return (
    <div className={styles.wrapper}>
      <div className={styles.flexRow}>
        {status === projectStatusEnum.draft ? (
          <div className={styles.draft}>
            <UserWithFind />
          </div>
        ) : (
          <RoundImage
            src={
              // @ts-ignore
              user?.roles?.name === userRolesEnum.Founder
                ? // @ts-ignore
                  expert?.avatar?.[0]?.temporary_download
                : // @ts-ignore
                  founder?.avatar?.[0]?.temporary_download
            }
            alt={name}
            nativeStyles={styles.img}
          />
        )}

        <div className={classNames(styles.flexCol, styles.colStart)}>
          <div className={styles.titleGray}>
            {intl.formatMessage({ id: 'component.labels.project' })}
          </div>
          <div className={styles.titleStrong}>{name}</div>
        </div>
      </div>
      <div className={classNames(styles.flexRow, styles.alignEnd)}>
        {status === projectStatusEnum.in_escrow && isExpert ? (
          <NotifyLabel label={'dashboard.labels.label-notify-start'} />
        ) : null}
        {status === projectStatusEnum.waiting_for_payment && !isExpert ? (
          <Button
            onClick={() => {
              dispatch(setGlobalProjectStart(projectId));
              setPopupState((state) => ({
                ...state,
                isVisible: true,
              }));
            }}
            type={buttonTypes.nonBorder}
            nativeStyles={styles.btn}
          >
            {intl.formatMessage({
              id: 'dashboard.button.button-pay-invoice',
            })}
          </Button>
        ) : null}
        {status === projectStatusEnum.in_escrow && !isExpert ? (
          <div className={styles.flexRow}>
            <Button
              onClick={() => {
                setDisableBtn(true);
                setInfoLabelVisible(true);
              }}
              type={buttonTypes.nonBorder}
              nativeStyles={styles.btn}
              disabled={disableBtn}
            >
              {intl.formatMessage({
                id: 'dashboard.button.button-release-invoice',
              })}
            </Button>
          </div>
        ) : null}
        <StatusBlock status={status} />
        <div className={styles.blockWrapper}>
          <BudgetBlock label={budget} />
        </div>

        {
          // @ts-ignore
          user?.roles?.name === userRolesEnum.Founder && (
            <MoreBlock
              projectStatus={status}
              projectId={projectId}
              opponentId={expert_detail_id}
              userId={expert_id}
              onRateClick={() => {
                setRatePopupVisibility(true);
              }}
            />
          )
        }
      </div>
      <>
        {isVisible && (
          <InvoicePayment
            contentState={popupContentState}
            itemData={itemData}
            setIsVisible={() => {
              setPopupState((state) => ({
                ...state,
                isVisible: !state.isVisible,
              }));
            }}
          />
        )}
        {infoLabelVisible && (
          <ReleaseInvoicePopup
            budget={budget}
            expertName={expert?.name}
            projectName={name}
            dataState={dataLoading}
            expertId={expert_id}
            canReview={can_review}
            // @ts-ignore
            expertAva={expert?.avatar[0]?.temporary_download}
            // @ts-ignore
            rating={expert?.rating}
            projectId={projectId}
            onApproveClick={() => {
              setDataLoading(true);
              API.sendFormData(URL_PAY_PROJECT, {
                project_id: projectId,
              })
                .then(() => {
                  dispatch(
                    setFounderProjectState(
                      projectId,
                      projectStatusEnum.processing
                    )
                  );
                })
                .finally(() => {
                  setDisableBtn(false);
                  setInfoLabelVisible(false);
                  setDataLoading(false);
                });
            }}
            onAbortClick={() => {
              setDisableBtn(false);
              setInfoLabelVisible(false);
            }}
          />
        )}
      </>
    </div>
  );
};

export default ProjectListItem;
