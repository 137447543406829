import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import API from 'services/API';
import { getUser } from 'store/reducers/user/selector';
import {
  setUser,
  setVerificationId,
  setVerificationState,
} from 'store/reducers/user';
import {
  JWT_ACCESS_TOKEN_KEY,
  JWT_REFRESH_TOKEN_KEY,
  ROUT_HOME,
  ROUT_LOGIN,
  ROUT_LOGOUT,
  ROUT_REGISTRATION,
  URL_VERIFICATION,
} from 'const';
import {
  userStatus,
  userVerificationStateType,
} from 'store/reducers/user/types';
import storageService from 'services/storageService';
import { setAppLoading } from 'store/reducers/globalRequestedData';
const { REACT_APP_API_VERIFICATION, REACT_APP_API_DOMAIN } = process.env;

const useAuth = () => {
  const dispatch = useDispatch();
  const userData = useSelector(getUser);
  const navigate = useNavigate();
  const jwt = localStorage.getItem(JWT_ACCESS_TOKEN_KEY);
  const { pathname, search } = useLocation();

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(search);
      const id = queryParams.get('id');
      const token = queryParams.get('token');

      if (id && token) {
        dispatch(setAppLoading(true));
        dispatch(setVerificationId(''));
        await storageService.deleteByKey(
          JWT_ACCESS_TOKEN_KEY,
          JWT_REFRESH_TOKEN_KEY
        );
        await API.sendFormData(ROUT_LOGOUT, {});
        API.sendFormData(`${URL_VERIFICATION}`, {
          id: id,
          token: token,
        })
          .then((response) => {
            const verificationState = response.data.verificationState;
            dispatch(setVerificationId(id));
            dispatch(setVerificationState(verificationState));
          })
          .then(() => {
            dispatch(setAppLoading(false));
            navigate(ROUT_LOGIN);
          });
      }
    })();
  }, [search]);

  useEffect(() => {
    (async () => {
      if (userData) {
        return;
      }

      if (jwt) {
        try {
          dispatch(setAppLoading(true));
          const user = await API.getUser(jwt);
          dispatch(setUser(user));
          switch (user.status) {
            case userStatus.waitingConfirmation:
            case userStatus.email_verification:
            case userStatus.preliminary_confirmation:
              return;
            case userStatus.processBasicRegistration:
              navigate(ROUT_REGISTRATION);
              break;
            default:
              navigate(ROUT_HOME);
          }
        } catch (e) {
          dispatch(setUser(null));
          localStorage.removeItem(JWT_ACCESS_TOKEN_KEY);
          navigate(`/login`);
        } finally {
          dispatch(setAppLoading(false));
        }
      }
      if (!jwt && pathname !== '/login') {
        navigate(`/login`);
      }
    })();
  }, [jwt, userData]);
  return userData;
};

export default useAuth;
