import React from 'react';
import styles from './styles.module.scss';
import UserBlock from 'pages/chatPage/chatPageAside/chatPreviewList/userBlock';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';

type ChatPreviewItemProps = {
  counter: number | null;
  isActive: boolean;
  onItemClick: (
    index: number,
    chatId: string | null,
    counter: number | null
  ) => void;
  index: number;
  chatId: string | null;
  name: string;
  surname: string;
  src: string;
  expertise: { id: number; area: string }[] | null;
  areasInRow?: boolean;
  projectName?: string;
};

const ChatPreviewItem = (props: ChatPreviewItemProps) => {
  const {
    counter,
    isActive,
    index,
    onItemClick,
    chatId,
    name,
    surname,
    src,
    expertise,
    areasInRow = false,
    projectName = '',
  } = props;
  const userRoles = useSelector(getUser);
  return (
    <button
      onClick={() => {
        onItemClick(index, chatId, counter);
      }}
      className={classNames(styles.wrapper, { [styles.active]: isActive })}
    >
      <div
        className={classNames(styles.leftBorder, styles.bgInfo, {
          // @ts-ignore
          [styles.bgDanger]: userRoles?.roles.name === userRolesEnum.Founder,
        })}
      ></div>
      <UserBlock
        src={src}
        name={name}
        surname={surname}
        expertise={expertise}
        projectName={projectName}
        areasInRow={areasInRow}
      />
      {Boolean(counter) && (
        <div className={styles.counter}>
          <span>{counter}</span>
        </div>
      )}
    </button>
  );
};

export default ChatPreviewItem;
