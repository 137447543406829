import * as React from 'react';
import { SVGProps } from 'react';

const LargeStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 39h12v3H27v-3ZM27 33h18v3H27v-3ZM27 27h18v3H27v-3Z"
      fill="#96A4BB"
    />
    <path
      d="M30.822 16.826 23.998 3l-6.824 13.826-15.256 2.216 11.04 10.763L10.351 45l10.647-5.596v-3.39l-6.662 3.502 1.579-9.204.267-1.556-1.13-1.099-6.687-6.52 9.242-1.343 1.56-.227.698-1.414 4.133-8.374 4.133 8.374.698 1.415 1.56.226 11.182 1.628.427-2.972-11.176-1.624Z"
      fill="#96A4BB"
    />
  </svg>
);

export default LargeStar;
