import CreateProjectFirstStep from 'componets/createProject/stepsContent/firstStep';
import CreateProjectSecondStep from 'componets/createProject/stepsContent/secondStep';
import { projectDataType } from 'store/reducers/createProjects/types';

export const createProjectStepsConfig = [
  {
    title: 'create-new-project.step-1.h1-title',
    subtitle: 'create-new-project.step-1.page-subtitle',
    Component: CreateProjectFirstStep,
    continueValidator: (state: projectDataType) => {
      const { name, typeStartup, typeOfExpert, typeOfGoals, typeOfAchieves } =
        state;
      return (
        !name ||
        !typeStartup ||
        (!typeOfExpert?.length &&
          !typeOfGoals?.length &&
          !typeOfAchieves?.length)
      );
    },
  },
  {
    title: 'create-new-project.step-2.h1-title',
    Component: CreateProjectSecondStep,
    continueValidator: (state: projectDataType) => {
      const { minRate, maxRate, budget } = state;
      return +minRate >= +maxRate || !+budget;
    },
  },
];
