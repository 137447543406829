import React from 'react';
import { areaType } from 'store/reducers/registerData/types';
import TagItem from 'componets/tagList/tagItem';
import styles from './styles.module.scss';

type TagListProps = {
  data: any;
};

type tagItemType = {
  id: number;
  area: string;
};

const TagList = (props: TagListProps) => {
  const { data } = props;
  return (
    <div className={styles.flexRow}>
      {data?.map((tagItem: tagItemType, index: number) => {
        return (
          <TagItem key={`tagItem_${index.toString()}`} title={tagItem.area} />
        );
      })}
    </div>
  );
};

export default TagList;
