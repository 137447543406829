import {
  chatMessagesType,
  chatsType,
  chatType,
  singleMessageType,
} from 'store/reducers/chat/index';

export const getAvailableChats = (state: {
  chatData: chatsType;
}): chatType[] | null => state.chatData.availableChats;

export const getChatMessages = (state: {
  chatData: chatsType;
}): singleMessageType | null => state.chatData.chatMessages;

export const getActiveChatId = (state: {
  chatData: chatsType;
}): string | null => state.chatData.activeChatId;

export const getActiveChat = (state: {
  chatData: chatsType;
}): null | {
  id: string;
  data: singleMessageType[];
  totalPage: number | undefined;
} => state.chatData.activeChat;

export const getChatScrollTrigger = (state: { chatData: chatsType }): boolean =>
  state.chatData.chatScrollTrigger;
