import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { invoicesType } from 'store/reducers/invoice';
import CloudIcon from 'svg-icons/CloudIcon';
import classNames from 'classnames';
import API from 'services/API';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';
import InvoicePayment, { invoiceContentState } from 'componets/invoicePayment';
import {
  getFounderProjectData,
  getProjectDataByInvoiceId,
} from 'store/reducers/globalRequestedData/selectors';
import { useIntl } from 'react-intl';

type InvoicesItemProps = {
  data: invoicesType;
};

export enum invoiceStatus {
  paid = 'PAID',
  unpaid = 'UNPAID',
}

const initPopupState = {
  isVisible: false,
  popupContentState: invoiceContentState.invoiceVisible,
  loading: false,
};

const InvoicesItem = (props: InvoicesItemProps) => {
  const {
    data: { id, due_date, project, from, to, isDone, total, download, status },
  } = props;
  const user = useSelector(getUser);
  const projectData = useSelector(getProjectDataByInvoiceId(+id));
  const [popupState, setPopupState] = useState(initPopupState);
  const intl = useIntl();
  if (!user) {
    return null;
  }

  const { isVisible, popupContentState } = popupState;

  const { roles } = user;
  const isShowBtn =
    status === invoiceStatus.unpaid &&
    // @ts-ignore
    roles?.name === userRolesEnum.Founder;

  return (
    <div className={styles.row}>
      <div className={styles.item}>
        <div className={styles.strong}>{id}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.text}>{due_date}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.text}>
          {from?.name && from.name}
          {to?.name && to.name}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.text}>{project.name}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.smallStrong}>{total}</div>
      </div>
      <div className={styles.item}>
        {isShowBtn ? (
          <button
            onClick={() => {
              setPopupState((state) => ({
                ...state,
                isVisible: !state.isVisible,
              }));
            }}
            className={classNames(styles.status, styles.btnPay)}
          >
            {intl.formatMessage({ id: 'component.button.pay' })}
          </button>
        ) : (
          <div className={classNames(styles.status)}>
            {status && status.toLocaleLowerCase()}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.item, {
          [styles.notAvailable]: !download,
        })}
      >
        <a
          href={download || ''}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(styles.btn, {
            [styles.notAvailable]: !download,
          })}
        >
          <CloudIcon fill={download ? '#96A4BB' : '#DD8C93'} />
        </a>
      </div>
      {isVisible && projectData && (
        <InvoicePayment
          contentState={popupContentState}
          itemData={projectData!}
          setIsVisible={() => {
            setPopupState((state) => ({
              ...state,
              isVisible: !state.isVisible,
            }));
          }}
        />
      )}
    </div>
  );
};

export default InvoicesItem;
