import React from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

interface IInputRow {
  name: string;
  placeholder?: string;
  onChange: (name: string, value: string, skillId?: string | number) => void;
  value?: string | number;
  Icon?: React.ElementType | string;
  title?: string;
  nativeStyles?: string;
  type?: string;
  customIconStyles?: string;
  listId?: string | number;
  useDefaultValue?: any;
  notValid?: boolean;
}

const InputRow = (props: IInputRow) => {
  const {
    title = '',
    onChange = () => {},
    name = '',
    placeholder = '',
    nativeStyles = '',
    type = 'text',
    Icon = null,
    value = '',
    customIconStyles = '',
    listId,
    useDefaultValue = false,
    notValid = false,
  } = props;
  const intl = useIntl();
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(name, value, listId);
  };

  const key = useDefaultValue ? 'defaultValue' : 'value';

  const propsParam = {
    [key]: value,
  };

  return (
    <div className={nativeStyles}>
      <label className={classNames(styles.flexColumn)}>
        {title && (
          <span className={styles.title}>
            {intl.formatMessage({ id: title })}
          </span>
        )}
        <div className={styles.inputWrapper}>
          {Icon && (
            <div className={classNames(styles.iconWrapper, customIconStyles)}>
              {typeof Icon === 'string' ? Icon : <Icon />}
            </div>
          )}
          <input
            autoComplete="off"
            className={classNames(styles.input, {
              [styles.iconStyles]: Icon !== null,
              [styles.notValid]: notValid,
            })}
            type={type}
            onChange={onInputChange}
            placeholder={
              placeholder ? intl.formatMessage({ id: placeholder }) : ''
            }
            name={name}
            {...propsParam}
          />
        </div>
      </label>
    </div>
  );
};

export default InputRow;
