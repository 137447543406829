import * as React from 'react';
import { SVGProps } from 'react';

function DefaultUserIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={86}
      height={86}
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={1}
        y={1}
        width={84}
        height={84}
        rx={42}
        fill="#F4F4F4"
        stroke="#6B7584"
        strokeWidth={2}
      />
      <path
        d="M43 85c19 0 31-14.381 31-14.381a15.68 15.68 0 00-4.54-11.044A15.44 15.44 0 0058.5 55h-31a15.44 15.44 0 00-10.96 4.575A15.68 15.68 0 0012 70.619S24 85 43 85zM43 49c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
        fill="#6B7584"
      />
      <rect
        x={1}
        y={1}
        width={84}
        height={84}
        rx={42}
        stroke="#6B7584"
        strokeWidth={2}
      />
    </svg>
  );
}

export default DefaultUserIcon;
