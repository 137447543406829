import {
  APP_LOADING,
  fetchEditedProject,
  fetchExpertReviews,
  fetchFounderProject,
  fetchGlobalAchieves,
  fetchGlobalGoal,
  fetchGlobalProjectById,
  fetchGlobalSkills,
  fetchInvoiceSingle,
  fetchSingleExpertDetails,
  SET_FOUNDER_PROJECT_STATE,
  SET_INVOICE_PAYMENT_STATE,
  SET_REQUESTED_DATA,
  SET_SERVER_ERROR,
} from 'store/reducers/globalRequestedData/actions';
import { projectStatusEnum } from 'services/enums';

export type invoiceType = {
  id: number;
  founder_id: number;
  expert_id: number;
  project_id: number;
  service_price: number;
  transfer_fee: number;
  tax: number;
  total: number;
  due_date: string;
  address: string;
};
export type userType = {
  id: number;
  name: string;
  surname: string;
  email: string;
  status: string;
  phone: string;
  country_id: number;
  avatar: [];
};
export interface projectType {
  id: number;
  founder_id: number;
  expert_id: number;
  name: string;
  type_startup_id: number;
  min_rate: number;
  max_rate: number;
  budget: number;
  status: string;
  invoice: invoiceType | null;
  expert: userType | null;
  founder: userType | null;
  expert_detail_id?: number;
  type_startup?: { id: number; name: string };
  can_review: boolean;
}

export type invoiceSingleType = {
  id: number;
  founder_id: number;
  expert_id: number;
  project_id: number;
  service_price: number;
  transfer_fee: number;
  total: number;
  due_date: string;
};

export type singleExpertDetailsType = {
  achieves: { id: number; name: string }[];
  areas: { id: number; areas: string }[];
  hour_availability_id: number;
  id: number;
  max_rate: number;
  min_rate: number;
  portfolio: { client_name: string; temporary_download: string }[];
  rating: any;
  service_provider: {
    id: number;
    name: string;
    description: string;
  };
  skills: { id: number; name: string }[];
  full_info: string;
  city: { id: number; name: string };
  hour_availability: {
    id: number;
    name: string;
    description: string;
  };
  user: {
    avatar: [{ client_name: string; temporary_download: string }];
    country_id: number | null;
    email: string;
    id: number;
    name: string;
    phone: string;
    status: string;
    surname: string | null;
  };
  country: {
    id: number;
    name: string;
  };
  state?: {
    id: number;
    name: string;
  };
};

export interface editedProjectData extends projectType {
  type_startup: {
    id: number;
    name: string;
  };
  area_expertises: [];
  goals: [];
  achieves: [];
  skills: [];
}

export type expertReviewsType = {
  id: number;
  founder_id: number;
  expert_id: number;
  rate: number;
  message: string;
  created_at: string;
  user_name: string;
  user_surname: string;
  avatar: [
    {
      client_name: string;
      temporary_download: string;
    }
  ];
};

export type IGlobalRequestedDataType = {
  requestedData: {
    goal: { id: number; name: string }[] | [];
    achieves: { id: number; name: string }[] | null;
    skills: { id: number; area_id: number; name: string }[] | null;
  };
  projectData: projectType | null;
  founderProject: projectType[] | null;
  requestedError: Error | null;
  queryParams: string;
  invoiceSingleData: invoiceSingleType | null;
  singleExpertDetails: singleExpertDetailsType | null;
  appLoading: boolean;
  invoicePaymentSuccess: boolean;
  expertReview: null | expertReviewsType[];
  serverError: {
    show: boolean;
    message: string;
  };
};

const initState = {
  requestedData: {
    goal: [],
    achieves: null,
    skills: null,
  },
  projectData: null,
  founderProject: null,
  requestedError: null,
  queryParams: '',
  invoiceSingleData: null,
  singleExpertDetails: null,
  expertReview: null,
  appLoading: false,
  invoicePaymentSuccess: false,
  serverError: {
    show: false,
    message: '',
  },
};

export const setGlobalRequestedData = (value: any) => {
  return {
    type: SET_REQUESTED_DATA,
    payload: value,
  };
};

export const setServerError = (show: boolean, message: string) => {
  return {
    type: SET_SERVER_ERROR,
    payload: { show, message },
  };
};

export const setAppLoading = (value: boolean) => {
  return {
    type: APP_LOADING,
    payload: value,
  };
};

export const setInvoicePaymentState = (value: boolean) => {
  return {
    type: SET_INVOICE_PAYMENT_STATE,
    payload: value,
  };
};

export const setFounderProjectState = (
  projectId: number,
  value: projectStatusEnum
) => {
  return {
    type: SET_FOUNDER_PROJECT_STATE,
    payload: { projectId, value },
  };
};

const globalRequestedDataReducer = (
  state: IGlobalRequestedDataType = initState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case fetchGlobalGoal.FetchStart:
    case fetchGlobalProjectById.FetchStart:
    case fetchFounderProject.FetchStart:
    case fetchInvoiceSingle.FetchStart:
    case fetchSingleExpertDetails.FetchStart:
    case fetchEditedProject.FetchStart:
    case fetchExpertReviews.FetchStart:
      return {
        ...state,
        requestedError: null,
      };

    case fetchGlobalAchieves.FetchStart:
    case fetchGlobalSkills.FetchStart:
      return {
        ...state,
        queryParams: payload,
        requestedError: null,
      };

    case fetchGlobalProjectById.FetchSuccess:
      return {
        ...state,
        projectData: payload.data,
      };
    case fetchSingleExpertDetails.FetchSuccess: {
      return {
        ...state,
        singleExpertDetails: payload,
      };
    }

    case fetchEditedProject.FetchSuccess: {
      return {
        ...state,
        editedProjectData: payload,
      };
    }
    case fetchInvoiceSingle.FetchSuccess:
      return {
        ...state,
        invoiceSingleData: payload,
      };
    case fetchFounderProject.FetchSuccess: {
      return {
        ...state,
        founderProject: payload,
      };
    }

    case fetchGlobalAchieves.FetchSuccess:
    case fetchGlobalSkills.FetchSuccess: {
      const { requestedData } = state;
      const { name, value } = payload;
      return {
        ...state,
        requestedData: {
          ...requestedData,
          [name]: value,
        },
      };
    }

    case fetchGlobalGoal.FetchSuccess: {
      const { requestedData } = state;
      return {
        ...state,
        requestedData: {
          ...requestedData,
          goal: payload,
        },
        requestedError: null,
      };
    }
    case fetchGlobalGoal.FetchError:
    case fetchGlobalAchieves.FetchError:
    case fetchGlobalSkills.FetchError:
    case fetchFounderProject.FetchError:
    case fetchInvoiceSingle.FetchError:
    case fetchSingleExpertDetails.FetchError:
    case fetchEditedProject.FetchError:
    case fetchExpertReviews.FetchError:
      return {
        ...state,
        requestedError: payload,
      };
    case SET_REQUESTED_DATA: {
      const { requestedData } = state;
      const { name, value } = payload;
      return {
        ...state,
        requestedData: {
          ...requestedData,
          [name]: value,
        },
      };
    }
    case APP_LOADING:
      return {
        ...state,
        appLoading: payload,
      };
    case SET_INVOICE_PAYMENT_STATE:
      return {
        invoicePaymentSuccess: payload,
      };
    case SET_FOUNDER_PROJECT_STATE: {
      const { founderProject } = state;
      const { projectId, value } = payload;
      return {
        ...state,
        founderProject: founderProject?.map((project) => {
          if (project.id !== projectId) {
            return project;
          }
          project.status = value;
          return project;
        }),
      };
    }
    case fetchExpertReviews.FetchSuccess: {
      return {
        ...state,
        expertReview: payload,
      };
    }
    case SET_SERVER_ERROR: {
      return {
        ...state,
        serverError: {
          show: payload.show,
          message: payload.message,
        },
      };
    }

    default:
      return state;
  }
};

export default globalRequestedDataReducer;
