import { fetchChats, updateAvailableChats } from 'store/reducers/chat/actions';
import { chatsType } from 'store/reducers/chat/index';

export const setAvailableChatsStart = (page: number, currentUserId: number) => {
  return {
    type: fetchChats.FetchStart,
    payload: { page, currentUserId },
  };
};

export const setAvailableChatsSuccess = (data: chatsType[]) => {
  return {
    type: fetchChats.FetchSuccess,
    payload: data,
  };
};

export const setAvailableChatsError = (value: Error) => {
  return {
    type: fetchChats.FetchError,
    payload: value,
  };
};

export const setUpdateAvailableChatsStart = (
  expert_id: number | string,
  project_id: number | string,
  id: number,
  cb: () => void
) => {
  return {
    type: updateAvailableChats.FetchStart,
    payload: { expert_id, project_id, id, cb },
  };
};

export const setUpdateAvailableChatsSuccess = (data: any) => {
  return {
    type: updateAvailableChats.FetchSuccess,
    payload: data,
  };
};

export const setUpdateAvailableChatsError = (value: Error) => {
  return {
    type: updateAvailableChats.FetchError,
    payload: value,
  };
};
