import React from 'react';
import LargeErrorIcon from 'svg-icons/LargeErrorIcon';
import styles from './styles.module.scss';

const FormError = ({ message }: any) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <LargeErrorIcon />
      </div>
      <div className={styles.inner}>
        <h4 className={styles.title}>{message}</h4>
      </div>
    </div>
  );
};

export default FormError;
