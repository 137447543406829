import React, { useState } from 'react';
import styles from 'pages/registrationPage/components/registrationLabelList/registerSmallLabel/styles.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';

export enum SmallLabelsType {
  default,
  info,
  danger,
  primary,
}

interface IRegisterSmallLabel {
  title: string;
  id: number;
  isSelected: boolean;
  onClick: (title: string, id: number) => void;
  type?: SmallLabelsType;
  nativeStyles?: string;
}

const RegisterSmallLabel = (props: IRegisterSmallLabel) => {
  const {
    title = '',
    id,
    isSelected = false,
    onClick = () => {},
    type = SmallLabelsType.default,
    nativeStyles = '',
  } = props;

  const user = useSelector(getUser);

  const onBtnClick = () => {
    onClick(title, id);
  };

  const btnClass = classNames(styles.btn, {
    [styles.bntInfo]: type === SmallLabelsType.info && isSelected,
    [styles.bntDanger]: type === SmallLabelsType.danger && isSelected,
  });
  return (
    <div className={nativeStyles}>
      <button className={btnClass} onClick={onBtnClick}>
        {title}
      </button>
    </div>
  );
};

export default RegisterSmallLabel;
