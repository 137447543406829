import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { IPageTitle } from 'config/registrations/page-title';
import { useIntl } from 'react-intl';

const PageSubtitle = (props: IPageTitle) => {
  const intl = useIntl();
  const {
    title = '',
    children = null,
    nativeStyles = '',
    subtitleValues = {},
  } = props;
  return (
    <p className={classNames(styles.subtitle, nativeStyles)}>
      {' '}
      {intl.formatMessage({ id: title }, { ...subtitleValues })}
    </p>
  );
};

export default PageSubtitle;
