import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'services/API';
import {
  setAreaExpertiseSuccess,
  setAreaExpertiseError,
} from 'store/reducers/registerData/actions/areaExpertises';
import {
  areaType,
  dataItemType,
  dataServicesType,
  userAchievesType,
  userLocation,
} from 'store/reducers/registerData/types';
import {
  fetchAreaExpertise,
  fetchTypeOfServices,
  fetchCountryLocation,
  fetchRegionLocation,
  fetchCityLocation,
  fetchAchievesSkills,
  fetchTypeStartup,
  fetchUserHoursAvailability,
  SET_REGISTER_STEP,
} from 'store/reducers/registerData/actions';
import {
  setTypeOfServicesError,
  setTypeOfServicesSuccess,
} from 'store/reducers/registerData/actions/typeOfServices';
import {
  setCityUserLocationError,
  setCityUserLocationSuccess,
  setCountryUserLocationError,
  setCountryUserLocationSuccess,
  setRegionUserLocationSuccess,
  setRegionUserLocationError,
} from 'store/reducers/registerData/actions/userLocationList';
import store from 'store/index';
import {
  getQueryParams,
  getRegisterData,
  getUserExpertise,
} from 'store/reducers/registerData/selector';
import {
  setAchievesSkillsError,
  setAchievesSkillsSuccess,
} from 'store/reducers/registerData/actions/achievesSkills';
import {
  resetFormDataByKey,
  setFormDataByUserId,
  setRegisterStepsData,
} from 'store/reducers/registerData/index';
import {
  setStartupTypeError,
  setStartupTypeSuccess,
} from 'store/reducers/registerData/actions/typeStartup';
import {
  setUserHoursAvailabilityError,
  setUserHoursAvailabilitySuccess,
} from 'store/reducers/registerData/actions/userHoursAvailability';
import {
  fetchEditedProject,
  fetchExpertReviews,
  fetchFounderProject,
  fetchGlobalAchieves,
  fetchGlobalGoal,
  fetchGlobalProjectById,
  fetchGlobalSkills,
  fetchInvoiceSingle,
  fetchSingleExpertDetails,
} from 'store/reducers/globalRequestedData/actions';
import {
  setGlobalGoalDataSuccess,
  setGlobalGoalError,
} from 'store/reducers/globalRequestedData/actions/goalAction';
import {
  getNewProjectTypeOfAchieves,
  getNewProjectTypeOfExpert,
  getNewProjectTypeOfGoals,
} from 'store/reducers/createProjects/selector';
import { fetchExpertDetails } from 'store/reducers/createProjects/actions';
import {
  setGlobalAchievesError,
  setGlobalAchievesSuccess,
} from 'store/reducers/globalRequestedData/actions/achievesAction';
import {
  setGlobalSkillsDataSuccess,
  setGlobalSkillsError,
} from 'store/reducers/globalRequestedData/actions/skills';
import {
  setExpertDetailsError,
  setExpertDetailsSuccess,
} from 'store/reducers/createProjects/actions/expertDetails';
import {
  setEditedProjectError,
  setFounderProjectError,
  setFounderProjectSuccess,
  setGlobalProjectError,
  setGlobalProjectSuccess,
} from 'store/reducers/globalRequestedData/actions/projectActions';
import {
  setInvoiceSingleError,
  setInvoiceSingleSuccess,
} from 'store/reducers/globalRequestedData/actions/invoiceSingleAction';
import { setSingleExpertDetailsError } from 'store/reducers/globalRequestedData/actions/singleExpertDetailsActions';
import { setEditedProjectData } from 'store/reducers/createProjects';
import {
  convertExpertData,
  convertProjectData,
} from 'store/reducers/registerData/helpers';
import {
  URL_AREA_EXPERTISES,
  URL_CITY_LOCATION,
  URL_REGION_LOCATION,
  URL_COUNTRY_LOCATION,
  URL_EXPERT_REVIEW,
  URL_POST_INVOICE,
  URL_SEND_EXPERT_DETAILS,
  URL_SKILLS,
  URL_STARTUP_ACHIEVES,
  URL_STARTUP_GOAL,
  URL_STARTUP_PROJECT,
  URL_STARTUP_TYPE,
  URL_TYPE_OF_SERVICES,
  URL_USER_HOURS_AVAILABILITY,
} from 'const';

import { setExpertReviewsSuccess } from 'store/reducers/globalRequestedData/actions/expertReviews';
import { formRegistrationStepsConfig } from 'config/registrations/form-registration-steps.config';

type userLocationQueryPayload = {
  type: string;
  payload: string;
};

export enum dependentSkillsOf {
  areaId = 'area_id',
  achieveId = 'achieve_id',
}

export function* fetchAreaExpertisePage() {
  try {
    const data: areaType[] = yield call(
      API.getStaticApiData,
      URL_AREA_EXPERTISES
    );
    yield put(resetFormDataByKey());
    yield put(setAreaExpertiseSuccess(data));
  } catch (error: any) {
    yield put(setAreaExpertiseError(error.message));
  }
}

export function* fetchTypeOfServicesPage() {
  try {
    const data: dataServicesType[] = yield call(
      API.getStaticApiData,
      URL_TYPE_OF_SERVICES
    );
    yield put(setTypeOfServicesSuccess(data));
  } catch (error: any) {
    yield put(setTypeOfServicesError(error.message));
  }
}

export function* fetchUserCountryLocation() {
  try {
    const country_list: userLocation[] = yield call(
      API.getStaticApiData,
      URL_COUNTRY_LOCATION
    );

    yield put(setCountryUserLocationSuccess(country_list));
    yield put(setRegionUserLocationSuccess([]));
  } catch (error: any) {
    yield put(setCountryUserLocationError(error.message));
  }
}

export function* fetchUserRegionLocation(action: userLocationQueryPayload) {
  const { payload: countryId } = action;

  try {
    const path = countryId
      ? `${URL_REGION_LOCATION}?filter[country_id]=${countryId}`
      : URL_REGION_LOCATION;

    const regionsList: userLocation[] = yield call(API.getStaticApiData, path);

    yield put(setCityUserLocationSuccess([]));
    yield put(setRegionUserLocationSuccess(regionsList));
  } catch (error: any) {
    yield put(setRegionUserLocationError(error.message));
  }
}

export function* fetchUserCityLocation(action: userLocationQueryPayload) {
  const { payload: regionId } = action;

  try {
    const path = regionId
      ? `${URL_CITY_LOCATION}?filter[state_id]=${regionId}`
      : URL_CITY_LOCATION;

    const city_list: userLocation[] = yield call(API.getStaticApiData, path);
    yield put(setCityUserLocationSuccess(city_list));
  } catch (error: any) {
    yield put(setCityUserLocationError(error.message));
  }
}

export function* fetchAchievesSkillsData({ payload }: any) {
  const { queryDataKey, stateAchievesSkillsKey } = payload;
  try {
    const formData = getUserExpertise(store.getState());
    const queryIdsArray = formData.map(({ id }) => id);
    const path = `${URL_AREA_EXPERTISES}?filter[area_id]=${queryIdsArray.join(
      ','
    )}&include=${queryDataKey}`;
    const queryData: userAchievesType[] = yield call(
      API.getStaticApiData,
      path
    );
    yield put(setAchievesSkillsSuccess(stateAchievesSkillsKey, queryData));
  } catch (error: any) {
    yield put(setAchievesSkillsError(error.message));
  }
}

export function* fetchTypeStartupData() {
  try {
    const data: dataItemType[] = yield call(
      API.getStaticApiData,
      URL_STARTUP_TYPE
    );
    yield put(setStartupTypeSuccess(data));
  } catch (error: any) {
    yield put(setStartupTypeError(error));
  }
}

export function* fetchUserHoursAvailabilityData() {
  try {
    const data: dataServicesType[] = yield call(
      API.getStaticApiData,
      URL_USER_HOURS_AVAILABILITY
    );
    yield put(setUserHoursAvailabilitySuccess(data));
  } catch (error: any) {
    put(setUserHoursAvailabilityError(error));
  }
}

export function* fetchGoalData() {
  try {
    const data: dataItemType[] = yield call(
      API.getStaticApiData,
      URL_STARTUP_GOAL
    );
    yield put(setGlobalGoalDataSuccess(data));
  } catch (error: any) {
    yield put(setGlobalGoalError(error));
  }
}

export function* fetchGlobalAchievesData({ payload }: any) {
  const { stateKey } = payload;
  try {
    const requestData = getNewProjectTypeOfGoals(store.getState());
    // @ts-ignore
    const queryIdsArray = requestData?.map(({ value }) => value);
    if (!queryIdsArray?.length) {
      return [];
    }
    const path = `${URL_STARTUP_ACHIEVES}?filter[goal_id]=${queryIdsArray?.join(
      ','
    )}`;
    const queryData: userAchievesType[] = yield call(
      API.getStaticApiData,
      path
    );
    yield put(setGlobalAchievesSuccess(stateKey, queryData));
  } catch (error: any) {
    yield put(setGlobalAchievesError(error.message));
  }
}

export function* fetchGlobalSkillsData({ payload }: any) {
  const { stateKey, dependentType } = payload;
  try {
    const requestData =
      dependentType === dependentSkillsOf.achieveId
        ? getNewProjectTypeOfAchieves(store.getState())
        : getNewProjectTypeOfExpert(store.getState());
    // @ts-ignore
    const queryIdsArray = requestData?.map(({ value }) => value);
    const path = `${URL_SKILLS}?filter[${dependentType}]=${queryIdsArray?.join(
      ','
    )}`;

    const queryData: userAchievesType[] = yield call(
      API.getStaticApiData,
      path
    );
    yield put(setGlobalSkillsDataSuccess(stateKey, queryData));
  } catch (error: any) {
    yield put(setGlobalSkillsError(error.message));
  }
}

export function* fetchProjectsExpertDetailsList({ payload }: any) {
  try {
    const limitPerPage = 3;
    const { page, id, cb = () => {} } = payload;
    if (!id) {
      return;
    }
    const path = `${URL_SEND_EXPERT_DETAILS}?filter[project_id]=${id}&limit=${limitPerPage}&include=serviceProvider,userAreas,city,userSkills,expert&page=${page}`;
    // @ts-ignore
    const queryData: any = yield call(API.getStaticApiData, path, true);
    yield put(setExpertDetailsSuccess(queryData));
    yield call(cb);
  } catch (error: any) {
    yield put(setExpertDetailsError(error.message));
  }
}

export function* fetchProjectDataById({ payload }: any) {
  try {
    const path = `${URL_STARTUP_PROJECT}/${payload}?include=founder,expert
`;
    // @ts-ignore
    const data: any = yield call(API.getStaticApiData, path, true);
    yield put(setGlobalProjectSuccess(data));
  } catch (error: any) {
    yield put(setGlobalProjectError(error));
  }
}

export function* fetchFounderProjectData({ payload }: any) {
  try {
    const { id, cb = () => {} } = payload;
    const path = `${URL_STARTUP_PROJECT}/?filter[founder_id]=${id}&include=invoice,founder,expert`;
    // @ts-ignore
    const data: any = yield call(API.getStaticApiData, path, true);
    yield put(setFounderProjectSuccess(data.data));
    yield call(cb);
  } catch (error: any) {
    yield put(setFounderProjectError(error));
  }
}

export function* fetchSingleInvoiceData({ payload }: any) {
  try {
    const path = `${URL_POST_INVOICE}/${payload}`;
    // @ts-ignore
    const data: any = yield call(API.getStaticApiData, path, true);
    yield put(setInvoiceSingleSuccess(data.data));
  } catch (error: any) {
    yield put(setInvoiceSingleError(error));
  }
}

export function* fetchSingleExpertDetailsById({ payload, cb = () => {} }: any) {
  try {
    const path = `${URL_SEND_EXPERT_DETAILS}/${payload}?include=hourAvailability,serviceProvider,city,userAreas,userAchieves,userSkills,userTypeStartups,`;
    // @ts-ignore
    const data: any = yield call(API.getStaticApiData, path);
    // @ts-ignore
    const convertData = yield call(convertExpertData, data);
    yield put(setFormDataByUserId(convertData));
    yield call(cb);
  } catch (error: any) {
    yield put(setSingleExpertDetailsError(error));
  }
}

export function* fetchEditedProjectById({ payload }: any) {
  try {
    const path = `${URL_STARTUP_PROJECT}/${payload}?include=founder,typeStartup,projectAreas,projectGoals,projectAchieves,projectSkills`;
    // @ts-ignore
    const data: any = yield call(API.getStaticApiData, path, true);
    const { goals, achieves } = data.data;
    // @ts-ignore
    const convertedData: any = yield convertProjectData(data);
    yield put(setEditedProjectData(convertedData));
    yield put(setGlobalGoalDataSuccess(goals));
    yield put(setGlobalAchievesSuccess('achieves', achieves));
  } catch (error: any) {
    yield put(setEditedProjectError(error));
  }
}

export function* fetchSingleExpertReviews({ payload }: any) {
  const { page = 1, expertId, cb = () => {}, limit } = payload;
  try {
    const path = `${URL_EXPERT_REVIEW}?filter[expert_id]=${expertId}&page=${page}&limit=${limit}`;
    // @ts-ignore
    const data = yield call(API.getStaticApiData, path);
    yield put(setExpertReviewsSuccess(data));
    yield call(cb);
  } catch (error) {
    console.log('Failed to get reviews', error);
  }
}

export function* setRegisterStep({ payload }: any) {
  const state = getRegisterData(store.getState());
  const { nextStep, error } = payload;
  const { step } = state.stepsData;
  const stepConfig = formRegistrationStepsConfig[step - 1];
  if (nextStep <= step) {
    yield put(setRegisterStepsData({ stepsData: { step: nextStep, error } }));
    return;
  }

  if (typeof stepConfig.continueValidator === 'function') {
    // @ts-ignore
    const error = yield call(
      stepConfig.continueValidator,
      state.formData,
      stepConfig.callback
    );

    if (error) {
      yield put(
        setRegisterStepsData({ stepsData: { ...state.stepsData, error } })
      );
    }
  }
  yield put(setRegisterStepsData({ stepsData: { step: nextStep, error } }));
}

export function* registersDataRootSaga() {
  yield takeLatest(fetchAreaExpertise.FetchStart, fetchAreaExpertisePage);
  yield takeLatest(fetchTypeOfServices.FetchStart, fetchTypeOfServicesPage);
  yield takeLatest(fetchCountryLocation.FetchStart, fetchUserCountryLocation);
  yield takeLatest(fetchRegionLocation.FetchStart, fetchUserRegionLocation);
  yield takeLatest(fetchCityLocation.FetchStart, fetchUserCityLocation);
  yield takeLatest(fetchAchievesSkills.FetchStart, fetchAchievesSkillsData);
  yield takeLatest(fetchTypeStartup.FetchStart, fetchTypeStartupData);
  yield takeLatest(
    fetchUserHoursAvailability.FetchStart,
    fetchUserHoursAvailabilityData
  );
  yield takeLatest(fetchGlobalGoal.FetchStart, fetchGoalData);
  yield takeLatest(fetchGlobalAchieves.FetchStart, fetchGlobalAchievesData);
  yield takeLatest(fetchGlobalSkills.FetchStart, fetchGlobalSkillsData);
  yield takeLatest(
    fetchExpertDetails.FetchStart,
    fetchProjectsExpertDetailsList
  );
  yield takeLatest(fetchGlobalProjectById.FetchStart, fetchProjectDataById);
  yield takeLatest(fetchFounderProject.FetchStart, fetchFounderProjectData);
  yield takeLatest(fetchInvoiceSingle.FetchStart, fetchSingleInvoiceData);
  yield takeLatest(
    fetchSingleExpertDetails.FetchStart,
    fetchSingleExpertDetailsById
  );
  yield takeLatest(fetchEditedProject.FetchStart, fetchEditedProjectById);
  yield takeLatest(fetchExpertReviews.FetchStart, fetchSingleExpertReviews);
  yield takeLatest(SET_REGISTER_STEP, setRegisterStep);
}
