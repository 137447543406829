import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ExpertBlock from 'pages/profilePreviewPage/asideContent/expertBlock';
import { useIntl } from 'react-intl';
import Row from 'pages/profilePreviewPage/asideContent/row';
import TagList from 'componets/tagList';
import PdfIcon from 'svg-icons/PdfIcon';
import ButtonLink from 'componets/buttonLink';
import classNames from 'classnames';
import ExternalLinkIcon from 'svg-icons/ExternalLinkIcon';
import { openBase64Img, toBase64 } from 'services/helpers';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  ROUT_EXPERT_PAGE,
  ROUT_HOME,
  ROUT_USER_PAGE,
  ROUT_VIEW_PROFILE,
} from 'const';

type AsideContentProps = {
  contentData: any;
};

const AsideContent = (props: AsideContentProps) => {
  const { contentData } = props;
  const {
    userTypeServices,
    hourAvailabilityId,
    userSkills,
    userAchieves,
    areaExpertises,
    userStartupAchieves,
    portfolio,
  } = contentData;
  const intl = useIntl();
  const params = useParams();
  const { pathname } = useLocation();
  const [imageUrl, setImageUrl] = useState('');
  const isExpertPage =
    pathname === `${ROUT_USER_PAGE}/${params?.userId}` ||
    pathname === ROUT_EXPERT_PAGE;

  useEffect(() => {
    const temporaryDownload =
      pathname === ROUT_VIEW_PROFILE
        ? portfolio?.filePath
        : portfolio[0]?.temporary_download;

    setImageUrl(temporaryDownload);
  }, [portfolio]);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        {intl.formatMessage({ id: 'view-page.top-bar.expert-title' })}
      </h2>
      <ExpertBlock />
      <h2 className={styles.title}>
        {intl.formatMessage({ id: 'view-page.top-bar.additional-title' })}
      </h2>
      <div className={styles.additional}>
        <Row title="view-page.additional-data.service-type-title">
          {userTypeServices?.map((item: any, index: number) => (
            <div
              key={`type_services_${index.toString()}`}
              className={styles.boldLargeText}
            >
              {item.area}
            </div>
          ))}
        </Row>
        <Row title="view-page.additional-data.availability-title">
          {hourAvailabilityId?.map((item: any, index: number) => {
            return (
              <div
                key={`type_services_${index.toString()}`}
                className={styles.flexRow}
              >
                <div className={styles.boldLargeText}>{item.area}</div>
                <span className={styles.greyText}>
                  {intl.formatMessage({
                    id: 'view-page.additional-data.hours-per-week',
                  })}
                </span>
              </div>
            );
          })}
        </Row>
        <Row title="view-page.additional-data.achieve-title">
          <TagList data={userAchieves} />
        </Row>
        <Row title="view-page.additional-data.skills-title">
          <TagList data={userSkills} />
        </Row>
        <Row title="view-page.additional-data.experience-title">
          <TagList data={areaExpertises} />
        </Row>
        <Row title="view-page.additional-data.major-achievements-title">
          <div className={classNames(styles.achieves, styles.textSmall)}>
            {userStartupAchieves}
          </div>
        </Row>
        <Row title="view-page.additional-data.portfolio-title">
          <div
            className={classNames(
              styles.flexRow,
              styles.w100,
              styles.contentBetween
            )}
          >
            <div className={styles.flexRow}>
              <div className={styles.icon}>
                <PdfIcon />
              </div>
              <span className={styles.textMedium}>
                {isExpertPage && portfolio[0]?.client_name
                  ? portfolio[0].client_name
                  : portfolio?.fileData?.name ||
                    portfolio['portfolio[media_client_name]']}
              </span>
            </div>
            {isExpertPage ? (
              <a
                className={styles.btnLink}
                href={
                  portfolio && portfolio[0]?.temporary_download
                    ? portfolio[0]?.temporary_download
                    : ROUT_HOME
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'view-page.additional-data.link.open-pdf',
                })}
                <ExternalLinkIcon />
              </a>
            ) : (
              <a
                className={styles.btnLink}
                href={imageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'view-page.additional-data.link.open-pdf',
                })}
                <ExternalLinkIcon />
              </a>
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default AsideContent;
