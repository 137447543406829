import React, { useEffect } from 'react';
import Spinner from 'componets/spiner';
import { useLocation, useNavigate } from 'react-router-dom';
import API, { PostBodyType } from 'services/API';
import { ROUT_HOME, URL_PAY_INVOICE_APPROVE } from 'const';
import { useDispatch } from 'react-redux';
import { setInvoicePaymentState } from 'store/reducers/globalRequestedData';

const InvoiceSuccess = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    const invoice_id = params.get('invoiceId');
    if (invoice_id) {
      API.sendFormData(URL_PAY_INVOICE_APPROVE, { invoice_id })
        .then((result) => {
          dispatch(setInvoicePaymentState(true));
          return result;
        })
        .then(() => {
          navigation(ROUT_HOME);
        });
    }
  }, [params]);
  return (
    <div>
      <Spinner />
    </div>
  );
};

export default InvoiceSuccess;
