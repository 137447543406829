import React from 'react';
import styles from './styles.module.scss';
import ChatHeader from 'pages/chatPage/chatPageContent/chatHeader';
import ChatBody from 'pages/chatPage/chatPageContent/chatBody';
import { useSelector } from 'react-redux';
import { getActiveChat } from 'store/reducers/chat/selectors';
import { chatExpertType, chatType } from 'store/reducers/chat';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';
const ChatPageContent = (props: {
  availableChats: chatType[] | null;
  chatExpert: [chatExpertType] | null;
}) => {
  const { availableChats } = props;
  const chatData = useSelector(getActiveChat);
  const currentUser = useSelector(getUser);
  const currentActiveChat = availableChats?.filter((chat) => {
    return chat.id === chatData?.id;
  });
  // @ts-ignore
  const roles = currentUser?.roles.name;

  const rates = {
    minRate:
      roles === userRolesEnum.Founder
        ? currentActiveChat?.[0]?.expert[0]?.min_rate
        : null,
    maxRate:
      roles === userRolesEnum.Founder
        ? currentActiveChat?.[0]?.expert[0]?.max_rate
        : null,
  };

  if (!availableChats?.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <ChatHeader
          data={
            roles === userRolesEnum.Founder
              ? currentActiveChat?.[0]?.expert
              : currentActiveChat?.[0]?.founder
          }
          project={currentActiveChat?.[0]?.project}
          invoiceTrigger={currentActiveChat?.[0]?.can_invoice}
          chatId={chatData?.id}
          opponentId={currentActiveChat?.[0]?.expert[0]?.id}
          expertId={currentActiveChat?.[0]?.expert[0]?.user_id}
          {...rates}
        />
        <ChatBody
          chatId={chatData?.id}
          messages={chatData?.data}
          totalPage={chatData?.totalPage}
        />
      </div>
    </div>
  );
};

export default ChatPageContent;
