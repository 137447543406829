import * as React from 'react';
import { SVGProps } from 'react';

const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.299 6.357 4.942 4l2.357-2.357L6.356.7 4 3.057 1.642.7l-.943.943L3.056 4 .7 6.357l.943.943 2.357-2.357L6.356 7.3l.943-.943Z"
      fill="#96A4BB"
    />
  </svg>
);

export default CrossIcon;
