export const SET_REQUESTED_DATA = Symbol('SET_REQUESTED_DATA');
export const SET_SERVER_ERROR = Symbol('SET_SERVER_ERROR');
export const RESET_REQUESTED_DATA = Symbol('RESET_REQUESTED_DATA');
export const APP_LOADING = Symbol('APP_LOADING');
export const SET_INVOICE_PAYMENT_STATE = Symbol('SET_INVOICE_PAYMENT_STATE');
export const SET_FOUNDER_PROJECT_STATE = Symbol('SET_FOUNDER_PROJECT_STATE');

export enum fetchGlobalGoal {
  FetchStart = 'fetchGlobalGoal/fetchStart',
  FetchSuccess = 'fetchGlobalGoal/fetchSuccess',
  FetchError = 'fetchGlobalGoal/fetchError',
}

export enum fetchGlobalAchieves {
  FetchStart = 'fetchGlobalAchieves/fetchStart',
  FetchSuccess = 'fetchGlobalAchieves/fetchSuccess',
  FetchError = 'fetchGlobalAchieves/fetchError',
}

export enum fetchGlobalSkills {
  FetchStart = 'fetchGlobalSkills/fetchStart',
  FetchSuccess = 'fetchGlobalSkills/fetchSuccess',
  FetchError = 'fetchGlobalSkills/fetchError',
}

export enum fetchGlobalProjectById {
  FetchStart = 'fetchGlobalProjectById/fetchStart',
  FetchSuccess = 'fetchGlobalProjectById/fetchSuccess',
  FetchError = 'fetchGlobalProjectById/fetchError',
}

export enum fetchFounderProject {
  FetchStart = 'fetchFounderProject/fetchStart',
  FetchSuccess = 'fetchFounderProject/fetchSuccess',
  FetchError = 'fetchFounderProject/fetchError',
}

export enum fetchInvoiceSingle {
  FetchStart = 'fetchInvoiceSingle/fetchStart',
  FetchSuccess = 'fetchInvoiceSingle/fetchSuccess',
  FetchError = 'fetchInvoiceSingle/fetchError',
}

export enum fetchSingleExpertDetails {
  FetchStart = 'fetchSingleExpertDetails/fetchStart',
  FetchSuccess = 'fetchSingleExpertDetails/fetchSuccess',
  FetchError = 'fetchSingleExpertDetails/fetchError',
}

export enum fetchEditedProject {
  FetchStart = 'fetchEditedProject/fetchStart',
  FetchSuccess = 'fetchEditedProject/fetchSuccess',
  FetchError = 'fetchEditedProject/fetchError',
}

export enum fetchExpertReviews {
  FetchStart = 'fetchExpertReviews/fetchStart',
  FetchSuccess = 'fetchExpertReviews/fetchSuccess',
  FetchError = 'fetchExpertReviews/fetchError',
}
