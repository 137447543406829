import * as React from 'react';
import { SVGProps } from 'react';

const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 10.5 3.25 6l4.5-4.5L6.25 0l-6 6 6 6 1.5-1.5Z"
      fill="#DD8C93"
    />
  </svg>
);

export default ChevronLeft;
