import React from 'react';
import styles from './styles.module.scss';
import AppLogo from '../../../../svg-icons/AppLogo';
import UserIcon from '../../../../svg-icons/UserIcon';
import { FormattedMessage } from 'react-intl';

const { REACT_APP_API_DOMAIN } = process.env;

const Header = () => {
  return (
    <header data-element="header" className={styles.header}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="flex-v-center">
              <a href={REACT_APP_API_DOMAIN} className={styles.logo}>
                <AppLogo />
              </a>
              <div className="menu-bar">
                <span />
                <span />
                <span />
                <span />
              </div>
              <nav className={styles.nav}>
                <div className={styles.navItem}>
                  <a
                    href={`${REACT_APP_API_DOMAIN}/contact`}
                    className={styles.navItemLink}
                  >
                    <FormattedMessage id="login.menu.contact" />
                  </a>
                </div>
                <div className={styles.navItem}>
                  <a
                    href={`${REACT_APP_API_DOMAIN}/faq`}
                    className={styles.navItemLink}
                  >
                    <FormattedMessage id="login.menu.faq" />
                  </a>
                </div>
              </nav>
              <div className={styles.accountMenu}>
                <div className={styles.accountMenuItem}>
                  <a href="/login" className={styles.accountMenuLink}>
                    <UserIcon />
                    <FormattedMessage id="login.button.login" />
                  </a>
                </div>
                <div className={styles.accountMenuItem}>
                  <a
                    href={`${REACT_APP_API_DOMAIN}/registration/founder`}
                    className={styles.accountMenuButton}
                  >
                    <FormattedMessage id="login.button.register" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
