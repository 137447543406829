import React from 'react';
import classNames from 'classnames';
import styles from 'componets/button/styles.module.scss';
import { useIntl } from 'react-intl';

export enum buttonTypes {
  info,
  danger,
  transparent,
  underline,
  nonBorder,
  disabled,
  tagType,
  simple,
  strongUnderline,
}

export interface iButtonItem {
  onClick: () => void;
  type: buttonTypes;
  disabled?: boolean;
  children: React.ReactNode;
  nativeStyles?: string;
}

const Button = (props: iButtonItem) => {
  const {
    onClick = () => {},
    type = buttonTypes.info,
    disabled,
    children = null,
    nativeStyles = '',
  } = props;

  const onButtonClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  const btnClasses = classNames(
    type === buttonTypes.underline ? styles.btnUnderline : styles.bnt,
    {
      [styles.btnInfo]: type === buttonTypes.info,
      [styles.btnTransparent]: type === buttonTypes.transparent,
      [styles.nonBorder]: type === buttonTypes.nonBorder,
      [styles.danger]: type === buttonTypes.danger,
      [styles.tagType]: type === buttonTypes.tagType,
      [styles.simple]: type === buttonTypes.simple,
      [styles.strongUnderline]: type === buttonTypes.strongUnderline,
      [styles.disabled]: disabled,
    },
    nativeStyles
  );
  return (
    <button onClick={onButtonClick} className={btnClasses}>
      {children}
    </button>
  );
};

export default Button;
