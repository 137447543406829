import React, { useEffect, useState } from 'react';
import InputRow from 'componets/inputRow';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNewProjectName,
  getNewProjectStartupType,
  getNewProjectTypeOfAchieves,
  getNewProjectTypeOfExpert,
  getNewProjectTypeOfGoals,
} from 'store/reducers/createProjects/selector';
import {
  setCreateProjectStepError,
  setNewProjectData,
} from 'store/reducers/createProjects';
import {
  getAreaExpertises,
  getAvailableStartupTypes,
} from 'store/reducers/registerData/selector';
import { setStartupTypeStart } from 'store/reducers/registerData/actions/typeStartup';
import SelectDropdown from 'componets/selectDropdown';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import Button, { buttonTypes } from 'componets/button';
import classNames from 'classnames';
import { setAreaExpertiseStart } from 'store/reducers/registerData/actions/areaExpertises';
import {
  getGlobalAchievesData,
  getGlobalGoalData,
} from 'store/reducers/globalRequestedData/selectors';
import { setGlobalGoalDataStart } from 'store/reducers/globalRequestedData/actions/goalAction';
import { setGlobalAchievesStart } from 'store/reducers/globalRequestedData/actions/achievesAction';
import { setNewProjectAchievesStart } from 'store/reducers/createProjects/actions/achievesAction';
import { setGlobalRequestedData } from 'store/reducers/globalRequestedData';
import { convertArrayObjectKeys } from 'services/helpers';
import { useLocation } from 'react-router-dom';
import { setRegisterStepError } from 'store/reducers/registerData';

const initButtonState = {
  yesButtonActive: false,
  noButtonActive: false,
};

const CreateProjectFirstStep = () => {
  const dispatch = useDispatch();
  const projectName = useSelector(getNewProjectName);
  const startupTypes = useSelector(getAvailableStartupTypes);
  const goal = useSelector(getGlobalGoalData);
  const achieves = useSelector(getGlobalAchievesData);
  const newProjectStartupType = useSelector(getNewProjectStartupType);
  const typeOfExpert = useSelector(getNewProjectTypeOfExpert);
  const typeOfGoals = useSelector(getNewProjectTypeOfGoals);
  const typeOfAchieves = useSelector(getNewProjectTypeOfAchieves);
  const areaExpertises = convertArrayObjectKeys(useSelector(getAreaExpertises));
  const intl = useIntl();
  const [buttonsState, setButtonsState] = useState(initButtonState);
  const { yesButtonActive, noButtonActive } = buttonsState;

  useEffect(() => {
    dispatch(setNewProjectAchievesStart());
    dispatch(setCreateProjectStepError(projectName.length ? '' : 'error'));
  }, [startupTypes.length, typeOfGoals]);

  useEffect(() => {
    dispatch(setGlobalGoalDataStart());
  }, [goal?.length]);

  useEffect(() => {
    dispatch(setAreaExpertiseStart());
  }, [areaExpertises.length]);
  useEffect(() => {
    setButtonsState((state) => ({
      ...state,
      yesButtonActive: Boolean(typeOfExpert?.length),
      noButtonActive: Boolean(typeOfGoals?.length),
    }));
  }, [typeOfExpert?.length, typeOfGoals?.length]);

  useEffect(() => {
    dispatch(setStartupTypeStart());
  }, [startupTypes.length]);

  useEffect(() => {
    dispatch(
      setRegisterStepError(
        !projectName.trim().length ||
          !newProjectStartupType?.id ||
          (!typeOfExpert?.length &&
            (!typeOfGoals?.length || !typeOfAchieves?.length))
      )
    );
  }, [
    newProjectStartupType?.id,
    projectName.length,
    typeOfExpert?.length,
    typeOfGoals?.length,
    typeOfAchieves?.length,
  ]);

  const onStartupBlur = (args: any) => {
    if (args?.length) {
      dispatch(
        setGlobalAchievesStart({
          stateKey: 'achieves',
        })
      );
      return;
    }
    dispatch(setGlobalRequestedData({ name: 'achieves', value: null }));
  };

  const onTypeStartupChange = (value: string, label: string) => {
    dispatch(
      setNewProjectData({
        name: 'typeStartup',
        value: {
          id: Number.parseInt(value),
          name: label,
        },
      })
    );
  };
  const onTypeGoalsChange = (array: { value: number; label: string }[]) => {
    dispatch(
      setNewProjectData({
        name: 'typeOfGoals',
        value: array,
      })
    );
    if (!array.length) {
      dispatch(setNewProjectData({ name: 'typeOfAchieves', value: null }));
    }
    dispatch(
      setNewProjectData({
        name: 'typeOfExpert',
        value: null,
      })
    );
    dispatch(
      setNewProjectData({
        name: 'skills',
        value: [],
      })
    );
  };
  const onTypeAchievesChange = (array: { value: number; label: string }[]) => {
    dispatch(
      setNewProjectData({
        name: 'typeOfAchieves',
        value: array,
      })
    );
    dispatch(
      setNewProjectData({
        name: 'skills',
        value: [],
      })
    );
  };
  const onTypeExpertChange = (array: { value: number; label: string }[]) => {
    dispatch(
      setNewProjectData({
        name: 'typeOfExpert',
        value: array,
      })
    );
    dispatch(
      setNewProjectData({
        name: 'skills',
        value: [],
      })
    );
    dispatch(
      setNewProjectData({
        name: 'typeOfGoals',
        value: null,
      })
    );
    dispatch(
      setNewProjectData({
        name: 'typeOfAchieves',
        value: null,
      })
    );
  };

  const onInputChange = (objectStoreKey: string, value: string) => {
    dispatch(setNewProjectData({ name: objectStoreKey, value: value }));
  };

  useEffect(() => {
    dispatch(setGlobalRequestedData({ name: 'skills', value: null }));
  }, [buttonsState]);

  return (
    <div>
      <div className={styles.wrapper}>
        <InputRow
          title="create-new-project.step-1.input-project-name"
          name="name"
          placeholder="create-new-project.step-1.input-placeholder-project-name"
          onChange={onInputChange}
          value={projectName}
        />
      </div>
      <div className={styles.wrapper}>
        <SelectDropdown
          title="create-new-project.step-1.select-title"
          options={startupTypes}
          onSelectChange={onTypeStartupChange}
          placeholder={intl.formatMessage({
            id: 'select-placeholder-type',
          })}
          value={{
            value: newProjectStartupType?.id,
            label: newProjectStartupType?.name,
          }}
        />
      </div>
      <div className={classNames(styles.wrapper, styles.title)}>
        {intl.formatMessage({ id: 'create-new-project.step-1.buttons-title' })}
      </div>
      <div className={classNames(styles.wrapper, styles.flexRow)}>
        <Button
          onClick={async () => {
            setButtonsState((state) => ({
              ...state,
              yesButtonActive: true,
              noButtonActive: false,
            }));
          }}
          type={buttonTypes.tagType}
          nativeStyles={classNames({ [styles.activeButton]: yesButtonActive })}
        >
          {intl.formatMessage({ id: 'create-new-project.step-1.button-yes' })}
        </Button>
        <Button
          onClick={() => {
            setButtonsState((state) => ({
              ...state,
              yesButtonActive: false,
              noButtonActive: true,
            }));
          }}
          type={buttonTypes.tagType}
          nativeStyles={classNames({ [styles.activeButton]: noButtonActive })}
        >
          {intl.formatMessage({ id: 'create-new-project.step-1.button-no' })}
        </Button>
      </div>

      {yesButtonActive && (
        <div className={styles.wrapper}>
          <SelectDropdown
            isMulti
            title="create-new-project.step-1.expertise-yes"
            options={areaExpertises}
            onMultiplySelectChange={onTypeExpertChange}
            placeholder={intl.formatMessage({
              id: 'multiply-select-placeholder-type',
            })}
            // @ts-ignore
            value={typeOfExpert}
          />
        </div>
      )}
      {noButtonActive && goal?.length ? (
        <>
          <div className={styles.wrapper}>
            <SelectDropdown
              isMulti
              title="create-new-project.step-1.goal-no"
              options={goal}
              onMultiplySelectChange={onTypeGoalsChange}
              onSelectBlur={onStartupBlur}
              placeholder={intl.formatMessage({
                id: 'multiply-select-placeholder-type',
              })}
              // @ts-ignore
              value={typeOfGoals}
            />
          </div>
          <div className={styles.wrapper}>
            <SelectDropdown
              isMulti
              title="create-new-project.step-1.achieves-no"
              options={achieves}
              onMultiplySelectChange={onTypeAchievesChange}
              placeholder={intl.formatMessage({
                id: 'multiply-select-placeholder-type',
              })}
              // @ts-ignore
              value={typeOfAchieves}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CreateProjectFirstStep;
