import React from 'react';
import styles from './styles.module.scss';
import ChatPreviewList from 'pages/chatPage/chatPageAside/chatPreviewList';
import { chatType } from 'store/reducers/chat';

type ChatPageProps = {
  availableChats: chatType[] | null;
};

const ChatPageAside = (props: ChatPageProps) => {
  const { availableChats } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <ChatPreviewList data={availableChats} />
      </div>
    </div>
  );
};

export default ChatPageAside;
