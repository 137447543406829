import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import LargeStar from 'svg-icons/LargeStar';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { expertReviewsType } from 'store/reducers/globalRequestedData';
import { getExpertReviews } from 'store/reducers/globalRequestedData/selectors';
import { setExpertReviewStart } from 'store/reducers/globalRequestedData/actions/expertReviews';
import ReviewItem from 'pages/profilePreviewPage/ReviewContent/reviewItem';
import { REVIEW_EXPERT_DISPLAY_SKILLS_LIMIT } from 'const';

const ReviewContent = () => {
  const expertReviews = useSelector(getExpertReviews);
  const dispatch = useDispatch();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const userId = search.get('expertDetailsId');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!userId) {
      return;
    }
    dispatch(
      setExpertReviewStart(
        userId,
        1,
        () => {
          setLoading(false);
        },
        REVIEW_EXPERT_DISPLAY_SKILLS_LIMIT
      )
    );
  }, [userId]);
  const intl = useIntl();
  return (
    <div className={styles.wrapperCol}>
      <h2 className={styles.title}>
        {intl.formatMessage({ id: 'view-page.top-bar.reviews-title' })}
      </h2>

      {!expertReviews?.length ? (
        <div className={styles.block}>
          <div className={styles.flexCol}>
            <LargeStar />
            <div className={styles.text}>
              {intl.formatMessage({ id: 'view-page.review-text' })}
            </div>
          </div>
        </div>
      ) : (
        expertReviews?.map((item: expertReviewsType, index) => {
          return (
            <ReviewItem
              data={item}
              key={`review_item_${index}`}
              loading={loading}
            />
          );
        })
      )}
    </div>
  );
};

export default ReviewContent;
