import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { DEFAULT_FILE_SIZE_LIMIT } from 'const';
import { FormattedMessage, useIntl } from 'react-intl';
import UploadImageIcon from 'svg-icons/uploadImageIcon';
import Upload from 'componets/fileUpload/upload';
import classNames from 'classnames';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Loading from 'componets/fileUpload/loading';
import { validateFile } from 'componets/fileUpload/helpers';
import { fileTypes } from 'componets/fileUpload';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';
import { useDispatch, useSelector } from 'react-redux';
import { getStepsError } from 'store/reducers/registerData/selector';

export interface IImageCropper {
  type: fileTypes;
  acceptFileType: string[];
  name: string;
  limit?: number;
  onChange(name: string, data: File | null): void;
  labels: any;
  initialImageSrc?: string;
}

const initFileLoadState = {
  fileName: '',
  validFileError: false,
  loading: false,
  uploaded: false,
  upImage: '',
  crop: { unit: '%', width: 100, aspect: 1 / 1 },
};

const ImageCropper = (props: IImageCropper) => {
  const {
    type,
    acceptFileType,
    name,
    limit = DEFAULT_FILE_SIZE_LIMIT,
    onChange,
    labels = {},
    initialImageSrc = '',
  } = props;
  const [fileLoadState, setFileLoadState] = useState(
    initialImageSrc
      ? {
          ...initFileLoadState,
          ...{ upImage: initialImageSrc, uploaded: true },
        }
      : initFileLoadState
  );
  const pageStepError = useSelector(getStepsError);
  useEffect(() => {
    if (initialImageSrc) {
      setFileLoadState((state) => ({
        ...state,
        upImage: initialImageSrc,
        uploaded: true,
      }));
    }
  }, [initialImageSrc]);
  const { fileName, validFileError, loading, uploaded, upImage, crop } =
    fileLoadState;

  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const {
    defaultLabel,
    errorLabel,
    loadOtherPhotoLabel,
    uploadingLabel,
    btnLabel,
  } = labels;
  useEffect(() => {
    dispatch(setRegisterStepError(true));
  }, []);

  const onFileChange = (event: any) => {
    event.persist();
    const { files } = event.target;
    if (!files) {
      return;
    }

    const [file] = files;
    const { size = 0, type, name: fileName } = file;
    const isValid = validateFile(size, type, limit, acceptFileType);

    if (!isValid) {
      setFileLoadState((state) => ({
        ...state,
        validFileError: true,
      }));
      dispatch(setRegisterStepError(true));
      return;
    }
    setFileLoadState(
      // @ts-ignore
      (state) => {
        return {
          ...state,
          crop: {
            aspect: 1,
            unit: '%',
            height: 'auto',
            width: 100,
            x: 0,
            y: 0,
          },
        };
      }
    );

    const reader = new FileReader();

    reader.onload = () => {
      setFileLoadState(
        // @ts-ignore
        (state) => {
          return {
            ...state,
            upImage: reader.result,
            validFileError: false,
            uploaded: true,
            fileName,
          };
        }
      );
    };

    reader.readAsDataURL(file);
  };

  const onCropChange = (crop: any, percentCrop: any) => {
    setFileLoadState((state) => ({
      ...state,
      crop: percentCrop,
    }));
  };

  const getCroppedImg = (image: any, pixelCrop: any, fileName: any) => {
    image.crossorigin = 'Anonymous';
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = pixelCrop.width * pixelRatio * scaleX;
    canvas.height = pixelCrop.height * pixelRatio * scaleY;
    // @ts-ignore
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // @ts-ignore
    ctx.imageSmoothingQuality = 'high';
    ctx?.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        // @ts-ignore
        file.name = fileName;
        resolve(file);
      }, 'image/jpeg');
    });
  };

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;

    setFileLoadState((state) => ({
      ...state,
      crop: { unit: '%', width: 100, aspect: 1 / 1 },
    }));
  }, []);

  const makeClientCrop = async (crop: any) => {
    if (imgRef && crop.width && crop.height) {
      const croppedImageBlob = await getCroppedImg(
        imgRef.current,
        crop,
        'userAva.jpeg'
      );
      // @ts-ignore
      setFileLoadState((state) => ({ ...state, croppedImageBlob }));

      dispatch(setRegisterStepError(false));
      return croppedImageBlob;
    }
  };

  const clearFile = () => {
    setFileLoadState(initFileLoadState);
    onChange(name, null);
    // @ts-ignore
    inputRef?.current?.click();
    dispatch(setRegisterStepError(true));
  };

  const onCropComplete = (crop: any) => {
    makeClientCrop(crop)
      .then((resImage) => {
        // @ts-ignore
        const file = new File([resImage], resImage?.name);
        // @ts-ignore
        onChange('picture', file);
      })
      .catch((error) => {
        console.log('getCroppedImg error', error);
      });
  };

  const onInputClick = (event: any) => {
    event.target.value = null;
  };

  const cropperStyle = uploaded ? { maxWidth: 306 } : { width: 0, height: 0 };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperPadding]: !uploaded,
        [styles.error]: validFileError,
      })}
    >
      <ReactCrop
        style={cropperStyle}
        circularCrop
        crossorigin={'anonymous'}
        imageStyle={{
          width: '100%',
        }}
        src={upImage}
        onImageLoaded={(e) => {
          onLoad(e);
        }}
        // @ts-ignore
        crop={crop}
        onChange={(c, percentCrop) => {
          // @ts-ignore
          onCropChange(c, percentCrop);
        }}
        onComplete={(c, percentCrop) => {
          onCropComplete(c);
        }}
      />

      {!loading && (
        <div className={classNames({ [styles.w100]: uploaded })}>
          {!uploaded && (
            <>
              <UploadImageIcon error={validFileError} />
              <Upload
                validFileError={validFileError}
                fileName={fileName}
                placeholder={defaultLabel}
                label={errorLabel}
              />
            </>
          )}

          <input
            ref={inputRef}
            id="input"
            className={classNames(styles.input, {
              [styles.zIndexNegative]: uploaded,
            })}
            type="file"
            accept={acceptFileType.join(',')}
            onClick={onInputClick}
            onChange={onFileChange}
            name={name}
          />
          {uploaded && (
            <label
              className={styles.largeText}
              htmlFor="input"
              onClick={clearFile}
            >
              {pageStepError ? (
                <div className={styles.error}>
                  <FormattedMessage id={'button.label.label-load-error'} />
                </div>
              ) : (
                loadOtherPhotoLabel
              )}
            </label>
          )}
        </div>
      )}

      {loading && <Loading type={type} label={uploadingLabel} />}

      {!loading && !uploaded ? (
        <button
          className={styles.btn}
          onClick={() => {
            if (validFileError) {
              return false;
            }
          }}
        >
          {btnLabel}
        </button>
      ) : null}
    </div>
  );
};

export default ImageCropper;
