import React from 'react';
import ButtonLink from 'componets/buttonLink';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';

export interface NavItemInterface {
  title: string;
  link: string;
  externalType?: boolean;
}

interface NavItemProps {
  data: NavItemInterface;
}

const NavItem = (props: NavItemProps) => {
  const { data } = props;
  const { title, link, externalType } = data;
  const intl = useIntl();
  return (
    <div className={styles.navItem} key={link}>
      <ButtonLink
        path={link}
        nativeStyles={styles.navItemLink}
        externalType={externalType}
      >
        {intl.formatMessage({ id: title })}
      </ButtonLink>
    </div>
  );
};

export default NavItem;
