import React from 'react';
import NavItem, { NavItemInterface } from 'componets/appHeader/NavList/NavItem';
import styles from './styles.module.scss';

interface NavListProps {
  list: NavItemInterface[];
}

const NavList = (props: NavListProps) => {
  const { list } = props;

  return (
    <nav className={styles.nav}>
      {list.map((navElement: NavItemInterface, index: number) => (
        <NavItem key={`navElement_${index.toString()}`} data={navElement} />
      ))}
    </nav>
  );
};

export default NavList;
