import { URL_DOMAIN_CONTACT, URL_DOMAIN_FAQS } from 'const';

export const headerNavConfig = [
  {
    title: 'header.header-link.dashboard',
    link: '/',
  },
  {
    title: 'header.header-link.contact-us',
    link: URL_DOMAIN_CONTACT,
    externalType: true,
  },
  {
    title: 'header.header-link.faqs',
    link: URL_DOMAIN_FAQS,
    externalType: true,
  },
];
