import React, { useEffect } from 'react';
import { IComponentWithChildren } from 'interfeaces/iComponentWithChildren';
import classNames from 'classnames';
import styles from './styles.module.scss';

const LayoutMainContent = (props: IComponentWithChildren) => {
  const { children = null, nativeStyles = '' } = props;
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <main className={classNames(styles.innerPage, nativeStyles)}>
      {children}
    </main>
  );
};

export default LayoutMainContent;
