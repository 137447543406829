import axios, { AxiosError } from 'axios';
import {
  JWT_ACCESS_TOKEN_KEY,
  JWT_REFRESH_TOKEN_KEY,
  RESPONSE_CODE_SERVER_ERROR,
  RESPONSE_CODE_UNAUTHORIZED,
  ROUT_HOME,
  ROUT_LOGIN,
  URL_USER_INFO,
  URL_USER_OAUTH_TOKEN,
} from 'const';
import storageService from 'services/storageService';
import store from 'store';
import { setServerError } from 'store/reducers/globalRequestedData';

const { REACT_APP_API_URL, REACT_APP_CLIENT_SECRET, REACT_APP_CLIENT_ID } =
  process.env;

type grant = {
  grant_type: string;
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
};

const getGrantData = (username: string, password: string): grant => {
  return {
    grant_type: 'password',
    client_id: REACT_APP_CLIENT_ID || '',
    client_secret: REACT_APP_CLIENT_SECRET || '',
    username,
    password,
  };
};

export enum PostBodyType {
  JSON = 'json',
  FORM_DATA = 'form-data',
}

type RequestHeaders = {
  [key: string]: string;
};

type RequestOptions = {
  headers: RequestHeaders;
};

class Api {
  private static instance: Api;
  private headers: RequestHeaders = {};
  private apiUrl = REACT_APP_API_URL;

  constructor() {
    const jwt = localStorage.getItem(JWT_ACCESS_TOKEN_KEY);
    if (jwt) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${jwt}`,
      };
    }

    if (typeof Api.instance === 'object') {
      return Api.instance;
    }
    Api.instance = this;
    return Api.instance;
  }

  private getApiFullPath(path = '') {
    let urlPath: URL | string = '';

    try {
      urlPath = new URL(path);
      return urlPath.href;
    } catch (e) {
      urlPath = `${this.apiUrl}/${path}`;
    }
    return urlPath;
  }

  public async callGetUrl(path: string, params = {}, fullResponse = false) {
    try {
      const res = await axios.get(this.getApiFullPath(path), {
        params,
        headers: this.headers,
      });
      return (fullResponse && res.data) || res.data?.data || res.data;
    } catch (error) {
      const err = error as AxiosError;
      await this.errorListener(err.response);
      console.log('Failed to send get request', error);
      return error;
    }
  }

  public async callDeleteUrl(path: string, params = {}, fullResponse = false) {
    try {
      const res = await axios.delete(this.getApiFullPath(path), {
        params,
        headers: this.headers,
      });
      return (fullResponse && res.data) || res.data?.data || res.data;
    } catch (error) {
      console.log('Failed to send get request', error);
    }
  }

  public async callPutUrl(path: string = '', body: object = {}) {
    let data = [];
    const options: RequestOptions = {
      headers: { ...this.headers },
    };

    let formBody = [];
    for (const property in body) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(
        // @ts-ignore
        body[property]
      );
      formBody.push(encodedKey + '=' + encodedValue);
    }
    // @ts-ignore
    formBody = formBody.join('&');

    options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    data = formBody;
    try {
      const res = await axios
        .put(this.getApiFullPath(path), data, {
          ...options,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response) {
            console.log('Failed to to put response data', error.response.data);
            return error.response;
          }
        });
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      await this.errorListener(err.response);
      console.log('Failed to post get request', error);
      return error;
    }
  }

  public async callPostUrl(
    path: string = '',
    body: object = {},
    bodyType: PostBodyType = PostBodyType.JSON
  ) {
    let data = null;
    const options: RequestOptions = {
      headers: { ...this.headers },
    };

    if (bodyType === PostBodyType.FORM_DATA) {
      const formData = new FormData();
      for (const key in body) {
        // @ts-ignore
        formData.append(key, body[key]);
      }

      options.headers['Content-Type'] = 'multipart/form-data';
      data = formData;
    } else {
      data = JSON.stringify(body);
    }
    try {
      const res = await axios
        .post(this.getApiFullPath(path), data, {
          ...options,
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          if (error.response) {
            const err = error as AxiosError;
            await this.errorListener(err.response);
            console.log('Failed to to ret response data', error.response.data);
            return error.response;
          }
        });
      return res.data;
    } catch (error) {
      console.log('Failed to send post request', error);
    }
  }

  public async getUser(jwt?: string) {
    let user;
    try {
      user = await this.callGetUrl(
        this.getApiFullPath(`${URL_USER_INFO}?include=expertDetails`),
        {}
      );
    } catch (error) {
      console.log('Failed to get UserData', error);
    }
    if (!user) {
      console.log('Failed to get user !user');
      // ....
      return;
    }

    return user;
  }

  public async sendCodeToAuth(username: string, password: string) {
    const grantData = getGrantData(username, password);
    const userData = await this.callPostUrl(
      URL_USER_OAUTH_TOKEN,
      grantData,
      PostBodyType.FORM_DATA
    );

    if (!userData.error) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${userData.access_token}`,
      };
    }

    return userData;
  }

  public getStaticApiData = async (
    path: string | undefined,
    fullResponse?: boolean
  ) => {
    try {
      return await this.callGetUrl(this.getApiFullPath(path), {}, fullResponse);
    } catch (error) {
      console.log('Failed to get static api data', error);
    }
  };

  public sendFormData = async (
    path: string | undefined,
    data: any,
    bodyType?: PostBodyType
  ) => {
    try {
      return await this.callPostUrl(
        this.getApiFullPath(path),
        data,
        PostBodyType.FORM_DATA
      );
    } catch (error) {
      console.log('Send data failed', error);
    }
  };

  public putFormData = async (path: string | undefined, data: any) => {
    try {
      return await this.callPutUrl(this.getApiFullPath(path), data);
    } catch (error) {
      console.log('Send data failed', error);
    }
  };

  public errorListener = async (response: any) => {
    const location = window.location.pathname;
    const listeners = {
      [RESPONSE_CODE_UNAUTHORIZED]: () => {
        storageService.deleteByKey(JWT_ACCESS_TOKEN_KEY, JWT_REFRESH_TOKEN_KEY);
        if (location === ROUT_LOGIN) {
          return;
        }
        window.location.replace(ROUT_HOME);
      },
      [RESPONSE_CODE_SERVER_ERROR]: () => {
        store.dispatch(setServerError(true, 'popup.popup-title.500-message'));
      },
    };
    // @ts-ignore
    return listeners[response.status]?.();
  };
}

export default new Api();
