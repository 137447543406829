import React from 'react';
import classNames from 'classnames';
import PdfIcon from 'svg-icons/PdfIcon';
import Spinner from 'componets/spiner';
import { fileTypes } from 'componets/fileUpload/index';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';

type LoadingPropsType = {
  type: fileTypes;
  label: string;
};

const Loading = (props: LoadingPropsType) => {
  const { type = fileTypes.pdf, label = '' } = props;
  return (
    <div className={classNames(styles.textLoading, styles.flexColumn)}>
      {type === fileTypes.pdf && <PdfIcon focusable={false} />}
      <div className={styles.flexRow}>
        <Spinner />
        {label}
      </div>
    </div>
  );
};

export default Loading;
