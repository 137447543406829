import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import Button, { buttonTypes } from 'componets/button';
import { userRolesEnum } from 'pages/dashboardPage';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export enum popupTypesEnum {
  confirmChoose,
  simple,
}

type PopupProps = {
  children: React.ReactNode;
  name: any;
  setPopupVisible: () => void;
  onButtonClick: () => void;
  buttonLabel: string;
  userRoles: userRolesEnum;
  nativeBtnStyle?: string;
  isBtnVisible?: boolean;
  popupType?: popupTypesEnum;
  onAbortBtnClick?: () => void;
  buttonAbortLabel?: string;
  showFooterLabel?: boolean;
};

const Popup = (props: PopupProps) => {
  const {
    children,
    name,
    setPopupVisible,
    onButtonClick,
    buttonLabel,
    userRoles,
    isBtnVisible = true,
    nativeBtnStyle = '',
    popupType = popupTypesEnum.simple,
    onAbortBtnClick = () => {},
    buttonAbortLabel,
    showFooterLabel = false,
  } = props;
  const intl = useIntl();
  const getButtonsRow = (type: popupTypesEnum) => {
    if (type === popupTypesEnum.confirmChoose) {
      return (
        <div className={classNames(styles.footer, styles.contentBetween)}>
          <Button
            nativeStyles={nativeBtnStyle}
            type={buttonTypes.transparent}
            onClick={onAbortBtnClick}
          >
            {buttonAbortLabel}
          </Button>
          <Button
            nativeStyles={nativeBtnStyle}
            type={
              userRoles === userRolesEnum.Expert
                ? buttonTypes.info
                : buttonTypes.danger
            }
            onClick={onButtonClick}
          >
            {buttonLabel}
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.footer}>
        <Button
          nativeStyles={nativeBtnStyle}
          type={
            userRoles === userRolesEnum.Expert
              ? buttonTypes.info
              : buttonTypes.danger
          }
          onClick={onButtonClick}
        >
          {buttonLabel}
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.outline} onClick={setPopupVisible}></div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.title}>{name}</span>
          <button className={styles.cross} onClick={setPopupVisible}></button>
        </div>
        <div className={styles.body}>
          <div className={styles.children}>{children}</div>
          <div className={styles.row}>
            {isBtnVisible && getButtonsRow(popupType)}
            {showFooterLabel && (
              <div className={styles.bottomLabel}>
                {intl.formatMessage({ id: 'component.labels.via-upmarqt' })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
