import React from 'react';
import styles from './styles.module.scss';
import LargeOkIcon from 'svg-icons/LargeOkIkon';
import { useIntl } from 'react-intl';

type FormSuccessProps = {
  title?: string;
  message?: string;
};

const FormSuccess = (props: FormSuccessProps) => {
  const { title, message } = props;
  const intl = useIntl();
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <LargeOkIcon />
      </div>
      <div className={styles.inner}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.text}>{message}</div>
      </div>
    </div>
  );
};

export default FormSuccess;
