import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'componets/stripeForm/CheckoutForm';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import { invoiceContentState } from 'componets/invoicePayment';
const { REACT_APP_STRIPE_FORM_ID } = process.env;
const stripePromise = loadStripe(`${REACT_APP_STRIPE_FORM_ID}`);

type stripeFormProps = {
  clientSecret: string;
  serviceFee: number | undefined;
  transferFee: number | undefined;
  total: number | undefined;
  setStateFunction: (state: any) => void;
  invoiceId: number | undefined;
  tax?: number;
};

const appearance = {
  rules: {
    '.Input': {
      boxShadow: 'none',
      border: '1px solid rgba(44, 72, 119, 0.1)',
      borderRadius: '2px',
      backgroundColor: '#fff',
      fontFamily: '"Rubik", sans-serif',
      fontSize: '18px',
      lineHeight: '1.55',
      color: '#96A4BB',
      padding: '13px 24px 11px 24px',
    },
    '.Input:focus': {
      fontFamily: '"Rubik", sans-serif',
      border: '1px solid #0294B2',
      boxShadow: 'none',
      outline: 'none',
    },
    '.Input--invalid': {
      fontFamily: '"Rubik", sans-serif',
      color: '#DD8C93',
      boxShadow: 'none',
      outline: 'none',
      border: '1px solid #DD8C93',
    },
    '.Input::placeholder': {
      fontFamily: '"Rubik", sans-serif',
      fontSize: '18px',
      lineHeight: '1.55',
      color: '#96A4BB',
    },
    '.Label': {
      fontFamily: '"Rubik", sans-serif',
      fontSize: '0px',
      color: 'transparent',
    },
    '.Error': {
      fontFamily: '"Rubik", sans-serif',
      fontSize: '13px',
      color: '#DD8C93',
      marginTop: '6px',
    },
  },
};

export const StripeForm = (props: stripeFormProps) => {
  const {
    clientSecret,
    serviceFee,
    total,
    transferFee,
    setStateFunction,
    invoiceId,
    tax,
  } = props;
  const stripeOptions = { clientSecret, appearance };
  const intl = useIntl();
  return (
    <>
      <div className={classNames(styles.delimiterRow, styles.contentBetween)}>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.service-fee' })}
          </div>
          <div className={styles.strongText}>${serviceFee}</div>
        </div>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.sales-tax' })}
          </div>
          <div className={styles.strongText}>${tax}</div>
        </div>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.transfer-fee' })}
          </div>
          <div className={classNames(styles.row, styles.contentCenter)}>
            <div className={styles.strongText}>${transferFee}</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.uppercaseText}>
            {intl.formatMessage({ id: 'component.labels.total-price' })}
          </div>
          <div className={classNames(styles.row, styles.contentEnd)}>
            <div className={styles.strongText}>${total}</div>
          </div>
        </div>
      </div>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <CheckoutForm
          setStateFunction={setStateFunction}
          invoiceId={invoiceId}
        />
      </Elements>
    </>
  );
};

export default StripeForm;
