import {
  expertReviewsType,
  IGlobalRequestedDataType,
  projectType,
} from 'store/reducers/globalRequestedData/index';

export const getGlobalGoalData = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): { id: number; name: string }[] | [] =>
  state?.globalRequestedDataReducer?.requestedData?.goal;

export const getGlobalAchievesData = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): { id: number; name: string }[] | null =>
  state?.globalRequestedDataReducer?.requestedData?.achieves;

export const getGlobalSkillsData = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): { id: number; area_id: number; name: string }[] | null =>
  state.globalRequestedDataReducer.requestedData.skills;

export const getGlobalProjectData = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): projectType | null => state.globalRequestedDataReducer.projectData;

export const getFounderProjectData = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): projectType[] | null => state.globalRequestedDataReducer.founderProject;

export const getProjectDataByInvoiceId =
  (id: number) =>
  (state: {
    globalRequestedDataReducer: IGlobalRequestedDataType;
  }): projectType | undefined => {
    const projects = state.globalRequestedDataReducer.founderProject;
    return projects?.find((project) => project?.invoice?.id === id);
  };

export const getAppLoading = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): boolean => state.globalRequestedDataReducer.appLoading;

export const getInvoicePaymentState = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): boolean => state.globalRequestedDataReducer.invoicePaymentSuccess;

export const getExpertReviews = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): expertReviewsType[] | null => state.globalRequestedDataReducer.expertReview;

export const getServerError = (state: {
  globalRequestedDataReducer: IGlobalRequestedDataType;
}): { show: boolean; message: string } =>
  state.globalRequestedDataReducer.serverError;
