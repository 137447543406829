import React, { useEffect } from 'react';
import RegisterLargeLabel from 'pages/registrationPage/components/registerLargeLabel';
import ContentRow from 'componets/contentRow';
import { useDispatch, useSelector } from 'react-redux';
import { setTypeOfServicesStart } from 'store/reducers/registerData/actions/typeOfServices';
import {
  getTypeOFServicesProvide,
  getUserTypeServices,
} from 'store/reducers/registerData/selector';
import { findItemById, addToStore, removeFromStore } from 'services/helpers';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';

const ThirdFormStep = () => {
  const dispatch = useDispatch();

  const dataServices = useSelector(getTypeOFServicesProvide);
  const userServices = useSelector(getUserTypeServices);

  const onLabelClick = (title: string, id: number) => {
    const isSelected = findItemById(id, userServices);
    isSelected.length
      ? removeFromStore(id, 'userTypeServices', userServices, setFormData)
      : addToStore(id, title, 'userTypeServices', userServices, setFormData, 1);
  };
  useEffect(() => {
    dispatch(setTypeOfServicesStart());
  }, []);

  useEffect(() => {
    dispatch(setRegisterStepError(!userServices.length));
  }, [userServices.length]);

  return (
    <ContentRow>
      <>
        {dataServices.map(
          (
            btnItem: {
              id: number;
              name: string;
              description?: string;
            },
            index: number
          ) => {
            const { id, name, description } = btnItem;
            const isSelected = findItemById(id, userServices);
            return (
              <RegisterLargeLabel
                disabled={userServices.length >= 1}
                id={id}
                isSelected={!!isSelected.length}
                title={name}
                subTitle={description}
                onClick={onLabelClick}
                key={`${name.toString()}_${index.toString()}`}
              />
            );
          }
        )}
      </>
    </ContentRow>
  );
};

export default ThirdFormStep;
