import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { headerNavConfig } from 'config/header-nav.config';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import NavList from 'componets/appHeader/NavList';
import AppLogo from '../../svg-icons/AppLogo';
import styles from './styles.module.scss';
import HeaderButtonsSection from 'componets/appHeader/headerButtonsSection';

const AppHeader = () => {
  return (
    <header className={classNames(styles.header)}>
      <LayoutContainer>
        <div className={styles.headerContent}>
          <Link to="/" className={styles.logo}>
            <AppLogo />
          </Link>
          <NavList list={headerNavConfig} />
          <HeaderButtonsSection />
        </div>
      </LayoutContainer>
    </header>
  );
};

export default AppHeader;
