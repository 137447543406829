import {
  areaType,
  dataItemType,
  dataServicesType,
  expertDataToSendType,
  formDataType,
  IRegisterDataState,
  userAchievesType,
  userLocation,
  userSkillsType,
} from 'store/reducers/registerData/types';

export const getAreaExpertises = (state: {
  registerData: IRegisterDataState;
}): areaType[] => state.registerData?.dataExpertiseArea;

export const getCurrentRegisterStep = (state: {
  registerData: IRegisterDataState;
}): number => state.registerData?.stepsData.step;

export const getTypeOFServicesProvide = (state: {
  registerData: IRegisterDataState;
}): dataServicesType[] => state.registerData?.dataServices;

export const getUserCountryLocationList = (state: {
  registerData: IRegisterDataState;
}): userLocation[] => state.registerData?.userCountryList;

export const getUserCityLocationList = (state: {
  registerData: IRegisterDataState;
}): userLocation[] => state.registerData?.userCityList;

export const getUserRegionLocationList = (state: {
  registerData: IRegisterDataState;
}): userLocation[] => state.registerData?.userRegionList;

export const getUserCountryLocation = (state: {
  registerData: IRegisterDataState;
}): userLocation | null => state.registerData?.formData?.userCountry;

export const getUserRegionLocation = (state: {
  registerData: IRegisterDataState;
}): userLocation | null | undefined => state.registerData?.formData?.userRegion;

export const getUserCityLocation = (state: {
  registerData: IRegisterDataState;
}): userLocation | null => state.registerData?.formData?.userCity;

export const getQueryParams = (state: {
  registerData: IRegisterDataState;
}): string => state.registerData?.queryParams;

export const getUserExpertise = (state: {
  registerData: IRegisterDataState;
}): areaType[] => state.registerData?.formData?.areaExpertises;

export const getUserTypeServices = (state: {
  registerData: IRegisterDataState;
}): areaType[] => state.registerData?.formData?.userTypeServices;

export const getAvailableAchieves = (state: {
  registerData: IRegisterDataState;
}): userAchievesType[] => state.registerData?.availableAchieves;

export const getAvailableSkills = (state: {
  registerData: IRegisterDataState;
}): userSkillsType[] => state.registerData?.availableSkills;

export const getAvailableStartupTypes = (state: {
  registerData: IRegisterDataState;
}): dataItemType[] => state.registerData?.availableTypeStartup;

export const getAvailableHours = (state: {
  registerData: IRegisterDataState;
}): dataServicesType[] => state.registerData.availableHour;

export const getUserAchieves = (state: {
  registerData: IRegisterDataState;
}): dataItemType[] => state.registerData?.formData?.userAchieves;

export const getUserSkills = (state: {
  registerData: IRegisterDataState;
}): dataItemType[] => state.registerData?.formData?.userSkills;

export const getUserStartupAchieves = (state: {
  registerData: IRegisterDataState;
}): string => state.registerData?.formData?.userStartupAchieves;

export const getUserTypesStartup = (state: {
  registerData: IRegisterDataState;
}): dataItemType[] => state.registerData?.formData?.userTypeStartups;

export const getUserHoursAvailability = (state: {
  registerData: IRegisterDataState;
}) => state.registerData?.formData?.hourAvailabilityId;

export const getUserMinRate = (state: {
  registerData: IRegisterDataState;
}): string => state.registerData?.formData?.minRate;

export const getUserMaxRate = (state: {
  registerData: IRegisterDataState;
}): string => state.registerData?.formData?.maxRate;

export const getUserStripeCode = (state: {
  registerData: IRegisterDataState;
}): string => state.registerData?.formData?.stripeCode;

export const getUserPortfolio = (state: {
  registerData: IRegisterDataState;
}): File | null => state.registerData.formData.portfolio.fileData;

export const getStepsError = (state: {
  registerData: IRegisterDataState;
}): string | null => state.registerData.stepsData.error;

export const getAppLoading = (state: {
  registerData: IRegisterDataState;
}): boolean => state.registerData.registerStepLoading;

export const getFormData = (state: {
  registerData: IRegisterDataState;
}): formDataType => state.registerData.formData;

export const getExpertDataToSend = (state: {
  registerData: IRegisterDataState;
}): null | expertDataToSendType => state.registerData.formData.expertDataToSend;

export const getPortfolioFileData = (state: {
  registerData: IRegisterDataState;
}): null | File => state.registerData.formData.portfolioFileData;

export const getPictureFileData = (state: {
  registerData: IRegisterDataState;
}): null | File => state.registerData.formData.pictureFileData;

export const getRegisterData = (state: { registerData: IRegisterDataState }) =>
  state.registerData;
