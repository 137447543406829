import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import { IPageTitle } from 'config/registrations/page-title';

const PageTitle = (props: IPageTitle) => {
  const intl = useIntl();
  const { title = '', children = null, nativeStyles = '' } = props;

  return (
    <h2 className={classNames(styles.title, nativeStyles)}>
      {intl.formatMessage({ id: title })}
    </h2>
  );
};

export default PageTitle;
