import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData } from 'store/reducers/invoice/selectors';
import { setUserInvoicesStart } from 'store/reducers/invoice';
import Spinner from 'componets/spiner';
import InvoicesItem from 'pages/invoice/invoicesItem';
import { useIntl } from 'react-intl';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';

const InvoiceList = () => {
  const { invoicesData } = useSelector(getInvoiceData);
  const { data, meta, links } = invoicesData;
  const user = useSelector(getUser);
  const initState = {
    currentPage: meta?.current_page || 1,
    loading: true,
    hasMore: !!links?.next,
  };
  const [{ currentPage, loading, hasMore }, setListState] = useState(initState);
  const dispatch = useDispatch();
  const scrolledItemRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  useEffect(() => {
    dispatch(
      setUserInvoicesStart(currentPage, () => {
        setListState((state) => ({
          ...state,
          loading: false,
        }));
      })
    );
  }, [currentPage, dispatch]);
  const onScroll = useCallback(
    // @ts-ignore
    (e: UIEvent<HTMLDivElement, UIEvent>) => {
      const { current: scrolledItem } = scrolledItemRef;
      if (!scrolledItem || !loading || !hasMore) {
        return;
      }
      const { bottom: wrapperBottom } = (
        e.target as HTMLDivElement
      ).getBoundingClientRect();
      const { bottom: scrolledItemBottom } =
        scrolledItem.getBoundingClientRect();
      const scrollDiff = wrapperBottom - scrolledItemBottom;
      if (scrollDiff > 20) {
        return;
      }

      dispatch(
        setUserInvoicesStart(currentPage + 1, () => {
          setListState((state) => ({
            ...state,
            currentPage: currentPage + 1,
          }));
        })
      );
    },
    [hasMore, currentPage]
  );
  return (
    <div className={styles.listWrapper}>
      {loading && !data?.length ? (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className={styles.scrollWrapper} onScroll={onScroll}>
            <div className={styles.row}>
              <div className={styles.title}>
                {intl.formatMessage({ id: 'invoices.label-id' })}
              </div>
              <div className={styles.title}>
                {intl.formatMessage({ id: 'invoices.label-date' })}
              </div>
              <div className={styles.title}>
                {intl.formatMessage({
                  id:
                    // @ts-ignore
                    user?.roles?.name === userRolesEnum.Founder
                      ? 'invoices.label-from'
                      : 'invoices.label-to',
                })}
              </div>
              <div className={styles.title}>
                {intl.formatMessage({ id: 'invoices.label-project' })}
              </div>
              <div className={styles.title}>
                {
                  // @ts-ignore
                  user?.roles?.name === userRolesEnum.Expert
                    ? intl.formatMessage({ id: 'invoices.label-total-payout' })
                    : intl.formatMessage({ id: 'invoices.label-total' })
                }
              </div>
              <div className={styles.title}>
                {intl.formatMessage({ id: 'invoices.label-status' })}
              </div>
              <div className={styles.title}>
                {intl.formatMessage({ id: 'invoices.label-download' })}
              </div>
            </div>
            <div ref={scrolledItemRef}>
              {data?.map((invoice) => {
                return <InvoicesItem key={invoice.id} data={invoice} />;
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvoiceList;
