import React from 'react';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import PageTitle from 'componets/pageTitle';
import Button, { buttonTypes } from 'componets/button';
import { useNavigate } from 'react-router-dom';
import { ROUT_HOME } from 'const';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import LayoutWrapper from 'componets/layoutWrapper';

const Error404Page = () => {
  const navigation = useNavigate();
  const intl = useIntl();
  return (
    <LayoutWrapper>
      <LayoutMainContent nativeStyles={styles.hideBefore}>
        <LayoutContainer>
          <div className={styles.wrapper}>
            <PageTitle
              nativeStyles={styles.textCenter}
              title={'page.404.page-title'}
            />
            <div className={styles.btnWrapper}>
              <Button
                onClick={() => {
                  navigation(ROUT_HOME);
                }}
                type={buttonTypes.transparent}
                nativeStyles={styles.mrAuto}
              >
                {intl.formatMessage({
                  id: 'register.steps-button.button-back',
                })}
              </Button>
            </div>
          </div>
        </LayoutContainer>
      </LayoutMainContent>
    </LayoutWrapper>
  );
};

export default Error404Page;
