import React from 'react';
import { userRolesEnum } from 'pages/dashboardPage/index';
import styles from './styles.module.scss';
import Button, { buttonTypes } from 'componets/button';
import PenIcon from 'svgComponent/PenIcon';
import { useIntl } from 'react-intl';
import LogoutIcon from 'svgComponent/LogoutIcon';
import storageService from 'services/storageService';
import {
  JWT_ACCESS_TOKEN_KEY,
  JWT_REFRESH_TOKEN_KEY,
  ROUT_LOGOUT,
  ROUT_EDIT_PROFILE,
  ROUT_INVOICES,
} from 'const';
import { useNavigate } from 'react-router-dom';
import API from 'services/API';
import Invoices from 'svg-icons/Invoices';
const { REACT_APP_API_DOMAIN } = process.env;

type ProfileMenageButtonsProps = {
  role: userRolesEnum;
};

const ProfileMenageButtons = (props: ProfileMenageButtonsProps) => {
  const { role } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const onNavigateClick = (rout: string) => {
    navigate(rout);
  };
  const onLogoutClick = async () => {
    await storageService.deleteByKey(
      JWT_ACCESS_TOKEN_KEY,
      JWT_REFRESH_TOKEN_KEY
    );
    await API.sendFormData(ROUT_LOGOUT, {});
    window.location.replace(`${REACT_APP_API_DOMAIN}`);
  };
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => {
          onNavigateClick(ROUT_INVOICES);
        }}
        type={buttonTypes.nonBorder}
        nativeStyles={styles.fitContent}
      >
        <Invoices />
        {intl.formatMessage({ id: 'dashboard.button.button-edit-invoices' })}
      </Button>
      <Button
        onClick={() => {
          onNavigateClick(ROUT_EDIT_PROFILE);
        }}
        type={buttonTypes.nonBorder}
        nativeStyles={styles.fitContent}
      >
        <PenIcon />
        {intl.formatMessage({ id: 'dashboard.button.button-edit-profile' })}
      </Button>
      <Button
        onClick={onLogoutClick}
        type={buttonTypes.nonBorder}
        nativeStyles={styles.fitContent}
      >
        <LogoutIcon />
        {intl.formatMessage({ id: 'dashboard.button.button-logout-profile' })}
      </Button>
    </div>
  );
};

export default ProfileMenageButtons;
