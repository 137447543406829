import React, { useEffect } from 'react';
import styles from 'componets/registration/formStepsContent/thankYouStep/styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { getFormData } from 'store/reducers/registerData/selector';
import API from 'services/API';
import { setFormData } from 'store/reducers/registerData';
import { expertDataToSendType } from 'store/reducers/registerData/types';
import StorageService from 'services/storageService';
import store from 'store';
import { setUserStatus } from 'store/reducers/user';
import { userStatus } from 'store/reducers/user/types';
import { URL_SEND_EXPERT_DETAILS } from 'const';

export const extractArray = (array: any[]) => {
  return array.map(({ id }) => id);
};

const formatObjectToFullPathKeyString = (array: any, rootKey: string) => {
  const obj = {};
  array.forEach((item: any, index: any) => {
    Object.keys(item).forEach((key) => {
      if (!item[key].toString().trim()) {
        return;
      }
      // @ts-ignore
      obj[`${rootKey}[${index}][${key}]`] = item[key];
    });
  });
  return obj;
};

export const onSendDataButtonPress = async (
  data: expertDataToSendType | null
) => {
  if (!data) {
    return;
  }
  const response = await API.sendFormData(URL_SEND_EXPERT_DETAILS, data);
  await StorageService.deleteByKey(
    'formData',
    'currentRegisterStep',
    'preliminaryCompleted'
  );
  store.dispatch(setUserStatus(userStatus.waitingConfirmation));
  return response;
};

const ThankYouStep = () => {
  const dispatch = useDispatch();
  const formData = useSelector(getFormData);
  const {
    areaExpertises,
    userTypeServices,
    userAchieves,
    userSkills,
    userStartupAchieves,
    userTypeStartups,
    hourAvailabilityId,
    userCity,
    userRegion,
    minRate,
    maxRate,
    picture,
    portfolio,
    achieves_others,
    type_startups_others,
    skills_others,
  } = formData;
  const {
    'picture[media_client_name]': picture_media_client_name,
    'picture[media_storage_name]': picture_media_storage_name,
  } = picture;
  const {
    'portfolio[media_client_name]': portfolio_media_client_name,
    'portfolio[media_storage_name]': portfolio_media_storage_name,
  } = portfolio;
  const user = useSelector(getUser);
  const achievesOthers = formatObjectToFullPathKeyString(
    achieves_others,
    'achieves_others'
  );
  const skillsOthers = formatObjectToFullPathKeyString(
    skills_others,
    'skills_others'
  );
  // @ts-ignore
  const expertDataToSend: expertDataToSendType = {
    user_id: user?.id,
    area_expertises: extractArray(areaExpertises),
    service_provider_id: extractArray(userTypeServices),
    city_id: userCity?.id,
    state_id: userRegion?.id,
    achieves: extractArray(userAchieves),
    skills: extractArray(userSkills),
    full_info: userStartupAchieves.trim(),
    type_startups: extractArray(userTypeStartups),
    hour_availability_id: extractArray(hourAvailabilityId)[0],
    min_rate: Number.parseInt(minRate),
    max_rate: Number.parseInt(maxRate),
    'portfolio[media_client_name]': portfolio_media_client_name,
    'portfolio[media_storage_name]': portfolio_media_storage_name,
    'picture[media_client_name]': picture_media_client_name,
    'picture[media_storage_name]': picture_media_storage_name,
    type_startups_others: type_startups_others?.length
      ? type_startups_others[0].skill
      : '',
    ...achievesOthers,
    ...skillsOthers,
  };

  useEffect(() => {
    dispatch(
      setFormData({ name: 'expertDataToSend', value: expertDataToSend })
    );
  }, [picture]);

  useEffect(() => {
    StorageService.setByKey('preliminaryCompleted', 'false').catch(Error);
  }, []);

  return <div className={styles.content}></div>;
};

export default ThankYouStep;
