import { fetchGlobalAchieves } from 'store/reducers/globalRequestedData/actions';
import { userAchievesType } from 'store/reducers/registerData/types';

export const setGlobalAchievesStart = (data: { stateKey: string }) => {
  return {
    type: fetchGlobalAchieves.FetchStart,
    payload: data,
  };
};

export const setGlobalAchievesSuccess = (
  stateAchievesSkillsKey: string,
  data: userAchievesType[]
) => {
  return {
    type: fetchGlobalAchieves.FetchSuccess,
    payload: { name: stateAchievesSkillsKey, value: data },
  };
};

export const setGlobalAchievesError = (value: Error) => {
  return {
    type: fetchGlobalAchieves.FetchError,
    payload: value,
  };
};
