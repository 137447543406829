import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { userRolesEnum } from 'pages/dashboardPage/index';
import { useIntl } from 'react-intl';

const Heading = (props: { label: string; roles: string }) => {
  const { label, roles } = props;
  const intl = useIntl();
  // @ts-ignore

  const labelsStyle = classNames(
    styles.btn,
    { [styles.btnExpert]: roles === userRolesEnum.Expert },
    { [styles.btnFounder]: roles === userRolesEnum.Founder }
  );
  return (
    <div className={styles.wrapper}>
      <div className={labelsStyle}>{intl.formatMessage({ id: label })}</div>
    </div>
  );
};

export default Heading;
