import * as React from 'react';
import { SVGProps } from 'react';

const PdfIcon = (props: SVGProps<SVGSVGElement>) => {
  const { focusable } = props;
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m18.678 20.194.011-.05c.204-.84.46-1.887.26-2.837-.133-.748-.685-1.04-1.156-1.061-.556-.025-1.052.292-1.175.752-.232.844-.024 1.997.355 3.466-.478 1.14-1.24 2.795-1.8 3.78-1.04.538-2.436 1.367-2.643 2.415-.042.193.007.44.123.66.13.247.337.437.58.528.105.039.232.07.38.07.618 0 1.62-.499 2.956-2.79a41.6 41.6 0 0 0 .619-.208c.956-.324 1.948-.661 2.844-.812.991.53 2.12.872 2.886.872.76 0 1.059-.45 1.171-.721.197-.475.102-1.072-.218-1.392-.464-.457-1.592-.577-3.35-.359-.865-.527-1.431-1.245-1.843-2.313Zm-3.856 5.34c-.489.71-.858 1.065-1.058 1.22.235-.433.696-.89 1.058-1.22Zm3.08-8.28c.182.314.158 1.26.017 1.737-.172-.7-.197-1.69-.095-1.807.028.004.053.025.078.07Zm-.057 4.237c.377.65.851 1.21 1.375 1.624-.76.173-1.452.457-2.07.71l-.433.176c.467-.847.858-1.807 1.128-2.51Zm5.47 2.303c.004.007.008.017-.013.031h-.008l-.006.01c-.029.018-.317.187-1.558-.301 1.427-.067 1.582.256 1.586.26Zm6.73-13.648L22.479 2.58c-.211-.21-.496-.33-.795-.33H6.75c-.622 0-1.125.503-1.125 1.125v29.25c0 .622.503 1.125 1.125 1.125h22.5c.622 0 1.125-.503 1.125-1.125v-21.68c0-.3-.12-.588-.33-.799Zm-2.265 1.315h-6.616V4.845l6.616 6.616Zm.064 19.758H8.156V4.78h10.617v7.594a1.477 1.477 0 0 0 1.477 1.477h7.594v17.367Z"
        fill={focusable ? '#2C4877' : '#96A4BB'}
      />
    </svg>
  );
};

export default PdfIcon;
