import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Login from './components/login';
import styles from './styles.module.scss';

const LoginPage = () => {
  return (
    <>
      <Header />
      <main className={styles.loginPage}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Login />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default LoginPage;
