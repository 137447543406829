import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { IComponentWithChildren } from 'interfeaces/iComponentWithChildren';

const LayoutContainer = (props: IComponentWithChildren) => {
  const { children = null, nativeStyles = '' } = props;

  return (
    <div className={classNames(styles.container, nativeStyles)}>
      <div className={styles.row}>{children}</div>
    </div>
  );
};

export default LayoutContainer;
