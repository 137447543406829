import React from 'react';
import styles from './styles.module.scss';
import { expertReviewsType } from 'store/reducers/globalRequestedData';
import ReadMore from 'componets/readMore';
import { useIntl } from 'react-intl';
import RoundImage from 'componets/roundImage';
import YellowStar from 'svgComponent/YellowStar';
import Spinner from 'componets/spiner';
import moment from 'moment';

const ReviewItem = (props: { data: expertReviewsType; loading: boolean }) => {
  const { data, loading } = props;
  const { rate, message, created_at, avatar, user_name, user_surname } = data;
  const intl = useIntl();
  const date = new Date(created_at);
  return (
    <div className={styles.wrapper}>
      {loading ? (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className={styles.top}>
            <div className={styles.img}>
              <RoundImage
                src={avatar[0]?.temporary_download}
                alt={user_surname}
              />
            </div>
            <h3 className={styles.title}>
              {user_name} {user_surname}
            </h3>
            {Boolean(rate) && (
              <div className={styles.ratingBlock}>
                <YellowStar /> {rate}
              </div>
            )}
          </div>
          <ReadMore
            nativeWrapperStyles={styles.margin}
            content={message}
            buttonLabel={intl.formatMessage({
              id: 'component.button.show-all',
            })}
            contentLimitHeight={70}
          />
          <div className={styles.footer}>
            {moment(date).fromNow(true)}{' '}
            {intl.formatMessage({ id: 'component.labels.ago' })}
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewItem;
