import * as React from 'react';
import { SVGProps } from 'react';

const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 1.5a5.246 5.246 0 0 0-5.25 5.25C3.75 10.688 9 16.5 9 16.5s5.25-5.813 5.25-9.75A5.246 5.246 0 0 0 9 1.5Zm0 7.125a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z"
      fill="#96A4BB"
    />
  </svg>
);

export default LocationIcon;
