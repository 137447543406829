import { fetchGlobalGoal } from 'store/reducers/globalRequestedData/actions';
import { dataItemType } from 'store/reducers/registerData/types';

export const setGlobalGoalDataStart = () => {
  return {
    type: fetchGlobalGoal.FetchStart,
  };
};

export const setGlobalGoalDataSuccess = (data: dataItemType[]) => {
  return {
    type: fetchGlobalGoal.FetchSuccess,
    payload: data,
  };
};

export const setGlobalGoalError = (value: Error) => {
  return {
    type: fetchGlobalGoal.FetchError,
    payload: value,
  };
};
