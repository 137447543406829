import React, { useEffect } from 'react';
import RegisterLargeLabel from 'pages/registrationPage/components/registerLargeLabel';
import ContentRow from 'componets/contentRow';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailableHours,
  getUserHoursAvailability,
} from 'store/reducers/registerData/selector';
import { setUserHoursAvailabilityStart } from 'store/reducers/registerData/actions/userHoursAvailability';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';
import { findItemById, addToStore, removeFromStore } from 'services/helpers';

const NinthFormStep = () => {
  const dispatch = useDispatch();
  const availableHours = useSelector(getAvailableHours);
  const userHoursAvailability = useSelector(getUserHoursAvailability);

  const onLabelClick = (title: string, id: number) => {
    const isSelected = findItemById(id, userHoursAvailability);
    isSelected.length
      ? removeFromStore(
          id,
          'hourAvailabilityId',
          userHoursAvailability,
          setFormData
        )
      : addToStore(
          id,
          title,
          'hourAvailabilityId',
          userHoursAvailability,

          setFormData,
          1
        );
  };

  useEffect(() => {
    dispatch(setUserHoursAvailabilityStart());
    dispatch(setRegisterStepError(!userHoursAvailability.length));
  }, [userHoursAvailability]);
  return (
    <ContentRow>
      <>
        {availableHours.map(
          (
            btnItem: {
              id: number;
              name: string;
              description?: string;
            },
            index: number
          ) => {
            const { id, name, description } = btnItem;
            const isSelected = findItemById(id, userHoursAvailability);
            return (
              <RegisterLargeLabel
                disabled={userHoursAvailability.length >= 1}
                id={id}
                isSelected={!!isSelected.length}
                title={name}
                subTitle={description}
                onClick={onLabelClick}
                key={`${name.toString()}_${index.toString()}`}
              />
            );
          }
        )}
      </>
    </ContentRow>
  );
};

export default NinthFormStep;
