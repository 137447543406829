import React, { useEffect } from 'react';
import styles from 'componets/registration/formStepsContent/tenthFormStep/styles.module.scss';
import InputRow from 'componets/inputRow';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFormData,
  getUserMaxRate,
  getUserMinRate,
  getUserStripeCode,
} from 'store/reducers/registerData/selector';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';
import Button, { buttonTypes } from 'componets/button';
import { useIntl } from 'react-intl';
import StripeIcon from 'svg-icons/StripeIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import API, { PostBodyType } from 'services/API';
import { STRIPE_CLIENT_SECRET, URL_API_STRIPE, URL_REGISTRATION } from 'const';
import StorageService from 'services/storageService';
import store from 'store';
import { getUser } from 'store/reducers/user/selector';

const setDataToStore = async () => {
  const data = await getFormData(store.getState());
  StorageService.setByKey('formData', JSON.stringify(data)).catch(Error);
};

const TenthFormStep = () => {
  const dispatch = useDispatch();
  const query = useLocation();
  const navigate = useNavigate();
  const userMinRate = useSelector(getUserMinRate);
  const userMaxRate = useSelector(getUserMaxRate);
  const userStripeCode = useSelector(getUserStripeCode);
  const stripeCode = useSelector(getUserStripeCode);
  const intl = useIntl();
  const [code] = query.search.split('?code=').filter(Boolean);
  const user = useSelector(getUser);
  useEffect(() => {
    API.getStaticApiData(`${URL_API_STRIPE}/${user?.id}`).then((response) => {
      const isConnect = !!response.is_connect;
      dispatch(
        setFormData({
          name: 'stripeCode',
          value: isConnect ? response.is_connect.toString() : '',
        })
      );
    });
    dispatch(
      setRegisterStepError(
        !userMinRate.length ||
          !userMaxRate.length ||
          +userMinRate >= +userMaxRate ||
          Boolean(!stripeCode?.length)
      )
    );
    if (code) {
      API.sendFormData(`${URL_API_STRIPE}`, { code }, PostBodyType.JSON)
        .then(async () => {
          await dispatch(setFormData({ name: 'stripeCode', value: code }));
          await setDataToStore();
        })
        .then(() => {
          navigate('/registration');
        })
        .catch((error) => {
          console.log('Failed send stripe code', error);
        });
    }
  }, [userMinRate, userMaxRate, userStripeCode, query]);

  const onInputChange = async (objectStoreKey: string, value: string) => {
    dispatch(setFormData({ name: objectStoreKey, value: value }));
    await setDataToStore();
  };

  const onStripeConnectClick = async () => {
    if (user?.id) {
      API.getStaticApiData(`${URL_API_STRIPE}/${user?.id}`).then((response) => {
        if (response.is_connect === false) {
          window.location.replace(
            `https://connect.stripe.com/express/oauth/authorize?client_id=${STRIPE_CLIENT_SECRET}&redirect_uri=${URL_REGISTRATION}`
          );
        }
      });
    } else {
      window.location.replace(
        `https://connect.stripe.com/express/oauth/authorize?client_id=${STRIPE_CLIENT_SECRET}&redirect_uri=${URL_REGISTRATION}`
      );
    }
  };

  return (
    <div className={styles.flexCol}>
      <div className={styles.flexRow}>
        <InputRow
          placeholder="input-placeholder.label-from"
          name="minRate"
          onChange={onInputChange}
          nativeStyles={classNames(styles.input, styles.inputRate)}
          type="number"
          Icon="$"
          value={userMinRate}
        />
        <div className={styles.delimiter}></div>
        <InputRow
          placeholder="input-placeholder.label-to"
          name="maxRate"
          onChange={onInputChange}
          nativeStyles={classNames(styles.input, styles.inputRate)}
          type="number"
          Icon="$"
          value={userMaxRate}
        />
      </div>
      <h3 className={styles.subtitle}>
        {intl.formatMessage({ id: 'register.step-10.payment-method' })}
      </h3>
      <div className={classNames(styles.contentStart, styles.flexRow)}>
        <StripeIcon />
        <p className={styles.label}>
          {intl.formatMessage({
            id: userStripeCode
              ? 'register.step-10.placeholder-stripe-ready'
              : 'register.step-10.placeholder-stripe',
          })}
        </p>
        <Button
          onClick={onStripeConnectClick}
          type={buttonTypes.info}
          disabled={Boolean(stripeCode.length)}
        >
          {intl.formatMessage({
            id: userStripeCode
              ? 'component.button.connected'
              : 'component.button.connect',
          })}
        </Button>
      </div>
    </div>
  );
};

export default TenthFormStep;
