import React from 'react';
import RoundImage from 'componets/roundImage';
import styles from './styles.module.scss';
import YellowStar from 'svgComponent/YellowStar';
import { useLocation } from 'react-router-dom';
import { ROUT_CHAT_PAGE } from 'const';
import classNames from 'classnames';

const UserBlock = (props: {
  src: string;
  name: string;
  surname: string;
  expertise?: { id: number; area: string }[] | null;
  rating?: number;
  projectName?: string;
  areasInRow?: boolean;
}) => {
  const {
    src,
    name,
    surname,
    expertise = null,
    rating = 0,
    projectName,
    areasInRow,
  } = props;
  return (
    <div className={styles.box}>
      <div className={styles.imageWrapper}>
        <RoundImage nativeStyles={styles.image} src={src} alt={name} />
        {Boolean(rating) && (
          <div className={styles.ratingBlock}>
            <YellowStar /> {rating}
          </div>
        )}
      </div>
      <div className={styles.column}>
        <h4 className={classNames(styles.title, styles.overflow)}>
          {name} {surname}
        </h4>
        <p className={classNames(styles.subtitle)} title={projectName}>
          {projectName}
        </p>
        <div className={styles.flexRow}>
          {areasInRow &&
            expertise?.map((item, index) => {
              return (
                <span
                  className={styles.subtitle}
                  key={`expertise-${item.area}`}
                >
                  {item.area}
                  {index !== expertise.length - 1 && ','}
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UserBlock;
