import * as React from 'react';
import { SVGProps } from 'react';

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="96"
    height="16"
    viewBox="0 0 96 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.2418 8.00015C38.6256 8.43205 38.8765 8.96558 38.9644 9.53665C39.0523 10.1077 38.9734 10.692 38.7373 11.2193C38.5011 11.7467 38.1177 12.1946 37.6332 12.5093C37.1486 12.824 36.5835 12.9921 36.0057 12.9934H27.0049V2.9939H36.0057C36.5835 2.99517 37.1486 3.16327 37.6332 3.47796C38.1177 3.79266 38.5011 4.24057 38.7373 4.7679C38.9734 5.29523 39.0523 5.87954 38.9644 6.45061C38.8765 7.02167 38.6256 7.55521 38.2418 7.98711V8.00015ZM29.013 11.0004H36.0144C36.1496 11.0067 36.2847 10.9855 36.4114 10.9381C36.5382 10.8907 36.6541 10.8181 36.752 10.7246C36.8499 10.6312 36.9278 10.5188 36.9811 10.3944C37.0343 10.27 37.0618 10.136 37.0618 10.0007C37.0618 9.86533 37.0343 9.73137 36.9811 9.60693C36.9278 9.48249 36.8499 9.37015 36.752 9.2767C36.6541 9.18325 36.5382 9.11063 36.4114 9.06323C36.2847 9.01584 36.1496 8.99465 36.0144 9.00096H29.5037V6.99931H36.0057C36.1409 7.00561 36.276 6.98443 36.4028 6.93703C36.5295 6.88963 36.6454 6.81702 36.7433 6.72357C36.8412 6.63011 36.9191 6.51778 36.9724 6.39334C37.0256 6.2689 37.0531 6.13494 37.0531 5.99959C37.0531 5.86423 37.0256 5.7303 36.9724 5.60586C36.9191 5.48142 36.8412 5.36909 36.7433 5.27563C36.6454 5.18218 36.5295 5.10954 36.4028 5.06214C36.276 5.01474 36.1409 4.99356 36.0057 4.99986H29.013V11.0004Z"
      fill="#20272C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5032 3V4.99945H17.5024V6.99889H26.0039V9.00054H17.5024V11H26.5032V12.9994H15.5029V9.9753L18.1819 3H26.5032Z"
      fill="#20272C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.6582 3H60.659C61.4547 3 62.2179 3.31609 62.7805 3.87875C63.3432 4.44141 63.6593 5.20454 63.6593 6.00026C63.6593 6.79598 63.3432 7.55911 62.7805 8.12177C62.2179 8.68443 61.4547 9.00054 60.659 9.00054H53.6598V12.9994H51.6604V6.99889H60.659C60.7942 7.0052 60.9293 6.98401 61.0561 6.93662C61.1829 6.88922 61.2987 6.8166 61.3966 6.72315C61.4945 6.6297 61.5725 6.51736 61.6257 6.39292C61.679 6.26848 61.7064 6.13452 61.7064 5.99917C61.7064 5.86382 61.679 5.72988 61.6257 5.60544C61.5725 5.48101 61.4945 5.36867 61.3966 5.27522C61.2987 5.18176 61.1829 5.10912 61.0561 5.06172C60.9293 5.01433 60.7942 4.99314 60.659 4.99945H51.6582V3Z"
      fill="#20272C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.1582 11H48.159C48.2942 11.0063 48.4293 10.9851 48.5561 10.9377C48.6829 10.8903 48.7987 10.8177 48.8966 10.7242C48.9945 10.6308 49.0725 10.5184 49.1257 10.394C49.179 10.2696 49.2064 10.1356 49.2064 10.0003C49.2064 9.86491 49.179 9.73095 49.1257 9.60651C49.0725 9.48207 48.9945 9.36974 48.8966 9.27628C48.7987 9.18283 48.6829 9.11022 48.5561 9.06282C48.4293 9.01542 48.2942 8.99424 48.159 9.00054H42.1585C41.3627 9.00054 40.5996 8.68443 40.037 8.12177C39.4743 7.55911 39.1582 6.79598 39.1582 6.00026C39.1582 5.20454 39.4743 4.44141 40.037 3.87875C40.5996 3.31609 41.3627 3 42.1585 3H51.1571V4.99945H42.1585C41.9014 5.01143 41.6588 5.12198 41.4811 5.30816C41.3034 5.49433 41.2043 5.74181 41.2043 5.99917C41.2043 6.25653 41.3034 6.50401 41.4811 6.69018C41.6588 6.87636 41.9014 6.98691 42.1585 6.99889H48.159C48.9547 6.99889 49.7179 7.31501 50.2805 7.87767C50.8432 8.44033 51.1593 9.20346 51.1593 9.99918C51.1593 10.7949 50.8432 11.558 50.2805 12.1207C49.7179 12.6833 48.9547 12.9994 48.159 12.9994H39.1582V11Z"
      fill="#20272C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.0401 6.99918V12.9997H69.0406V9.00082H65.0157L58.0143 16H55.1855L71.1877 0H74.0165L67.0151 6.99918H71.0401Z"
      fill="#20272C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.0424 6.99889H82.0432C82.3003 6.98691 82.5429 6.87636 82.7206 6.69018C82.8983 6.50401 82.9975 6.25653 82.9975 5.99917C82.9975 5.74181 82.8983 5.49433 82.7206 5.30816C82.5429 5.12198 82.3003 5.01143 82.0432 4.99945H77.8945L79.3947 6.49959H76.5572L73.0576 3H82.0346C82.8303 3 83.5934 3.31609 84.1561 3.87875C84.7187 4.44141 85.0348 5.20454 85.0348 6.00026C85.0348 6.79598 84.7187 7.55911 84.1561 8.12177C83.5934 8.68443 82.8303 9.00054 82.0346 9.00054H75.0375V12.9994H73.0381V6.99889H73.0424ZM82.3949 9.49985L85.8924 12.9994H83.0701L79.5705 9.49985H82.3949Z"
      fill="#20272C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.8964 7.4982L92.4055 2.9978H95.2277L90.2345 7.99101L95.2277 12.9842H92.4055L87.8964 8.50336V13.0038H85.8926V3.00215H87.8942V7.50255L87.8964 7.4982Z"
      fill="#20272C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.83826 12.9994L0 3H2.14274L4.91072 10.2098L7.6787 3H9.83012L12.5981 10.2098L15.3661 3H17.5066L13.6684 12.9994H11.5256L8.75332 5.77664L5.981 12.9994H3.83826Z"
      fill="#20272C"
    />
  </svg>
);

export default TwitterIcon;
