import React from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import API from 'services/API';
import { URL_PAY_INVOICE_APPROVE } from 'const';
import { invoiceContentState } from 'componets/invoicePayment';
const { REACT_APP_STRIPE_REDIRECT_URI } = process.env;

const CheckoutForm = ({ setStateFunction, invoiceId }: any) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();

  const onClick = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${REACT_APP_STRIPE_REDIRECT_URI}/?invoiceId=${invoiceId}`,
        },
      })
      .then(async function (result) {
        if (result.error) {
          setStateFunction((state: any) => ({
            ...state,
            popupContentState: invoiceContentState.messageError,
            contentMessage: result.error.message,
          }));
        } else {
          setStateFunction((state: any) => ({
            ...state,
            popupContentState: invoiceContentState.messageSuccess,
          }));
          await API.sendFormData(URL_PAY_INVOICE_APPROVE, invoiceId);
        }
      })
      .catch((error) => {
        console.log('stripe error', error);
      });
  };

  return (
    <form>
      <PaymentElement />
      <button onClick={onClick} className={styles.btn}>
        {intl.formatMessage({ id: 'dashboard.button.button-pay-invoice' })}
      </button>
    </form>
  );
};

export default CheckoutForm;
