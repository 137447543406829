import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Error404Page from 'pages/404';
import LoginPage from 'pages/login';
import { getLang, getPusherClient } from 'store/reducers/general/selectors';
import RegistrationPage from './pages/registrationPage';
import ProfilePreviewPage from 'pages/profilePreviewPage';
import useAuth from 'hooks/useAuth';
import DashboardPage, { userRolesEnum } from 'pages/dashboardPage';
import EditProfile from 'pages/editProfile';
import CreateProject from 'pages/createProject';
import CreateProjectResult from 'pages/createProject/createProjectResult';
import {
  ROUT_EDIT_PROJECT,
  ROUT_CREATE_PROJECT,
  ROUT_CREATE_PROJECT_RESULT,
  ROUT_FIND_EXPERT,
  ROUT_HOME,
  ROUT_LOGIN,
  ROUT_REGISTRATION,
  ROUT_VIEW_PROFILE,
  ROUT_EXPERT_PAGE,
  ROUT_INVOICE_SUCCESS,
  ROUT_CHAT_PAGE,
  ROUT_USER_PAGE,
  ROUT_EDIT_PROFILE,
  ROUT_INVOICES,
} from 'const';
import FindExpert from 'pages/findExpert';
import EditProject from 'pages/editProject';
import ExpertPage from 'pages/expertPage';
import InvoiceSuccess from 'pages/invoiceSuccess';
import ChatPage from 'pages/chatPage';
import { setClientPusher } from 'store/reducers/general';
import { setAvailableChatsStart } from 'store/reducers/chat/actions/availableChats';
import { getUser } from 'store/reducers/user/selector';
import store from 'store';
import UserPage from 'pages/expertPage/userPage';
import ReactGA from 'react-ga';
import Invoices from 'pages/invoice';

const { REACT_APP_ENVIRONMEMT } = process.env;

const ANALYTIC_ID: string = process.env.REACT_APP_ANALYTICS_ID as string;
ReactGA.initialize(ANALYTIC_ID);

function App() {
  const activeLang = useSelector(getLang);
  const messages = require(`./i18n/${activeLang}/messages`).default;
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const location = useLocation();
  const { pathname } = useLocation();
  useEffect(() => {
    dispatch(setClientPusher({}));
  }, []);
  useAuth();
  useEffect(() => {
    if (!user) {
      return;
    }
    dispatch(setAvailableChatsStart(1, user.id));
    // @ts-ignore
    if (user?.roles?.name === userRolesEnum.Expert) {
      const client = getPusherClient(store.getState());
      client
        .subscribe(`user-chats-${user.id}`)
        .bind('create-chat', (message: any) => {
          dispatch(setAvailableChatsStart(1, user.id));
        });
    }
  }, [user]);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (REACT_APP_ENVIRONMEMT === 'dev') {
      return;
    }
    const script = document.createElement('script');
    script.innerHTML = `
    (function() {
        window.__insp = window.__insp || [];
        __insp.push(['wid', 1386068400]);
        var ldinsp = function(){
        if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1386068400&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
        setTimeout(ldinsp, 0);
        })();
    `;

    document.head.appendChild(script);

    return () => {
      document.removeChild(script);
    };
  }, []);
  return (
    <IntlProvider locale="en" messages={messages}>
      <Routes>
        <Route path="*" element={<Error404Page key="Error404Page" />} />
        <Route
          path={ROUT_HOME}
          key="dashboardPage"
          element={<DashboardPage />}
        />
        <Route
          path={ROUT_REGISTRATION}
          element={<RegistrationPage key="RegistrationPage" />}
        />
        <Route path={ROUT_LOGIN} element={<LoginPage />} />
        <Route path={ROUT_VIEW_PROFILE} element={<ProfilePreviewPage />} />
        <Route path={ROUT_CREATE_PROJECT} element={<CreateProject />} />
        <Route
          path={ROUT_CREATE_PROJECT_RESULT}
          element={<CreateProjectResult />}
        />
        <Route path={ROUT_FIND_EXPERT} element={<FindExpert />} />
        <Route path={ROUT_EDIT_PROJECT} element={<EditProject />} />
        <Route path={ROUT_EXPERT_PAGE} element={<ExpertPage />}></Route>
        <Route path={ROUT_INVOICE_SUCCESS} element={<InvoiceSuccess />} />
        <Route path={ROUT_CHAT_PAGE} element={<ChatPage />} />
        <Route path={`${ROUT_USER_PAGE}/:userId`} element={<UserPage />} />
        <Route path={ROUT_EDIT_PROFILE} element={<EditProfile />} />
        <Route path={ROUT_INVOICES} element={<Invoices />} />
      </Routes>
    </IntlProvider>
  );
}

export default App;
