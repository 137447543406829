import React, { useState } from 'react';
import Select from 'react-select';
import { getSelectorStyles } from './helpers';
import { userLocation } from 'store/reducers/registerData/types';
import { useIntl } from 'react-intl';
import selectStyles from './selectStyles.module.scss';

export type resultArrayType = {
  value?: number;
  label?: string;
};

type selectOptionsType = {
  id: number;
  name: string;
};

interface ISelectDropdown {
  options: selectOptionsType[] | null;
  value?: number | resultArrayType;
  onSelectChange?: (arg: string, agr1: string) => void;
  onMultiplySelectChange?: (arg: { value: number; label: string }[]) => void;
  placeholder?: string;
  convertFunction?: (option: any) => resultArrayType[];
  title?: string;
  isMulti?: boolean;
  onSelectBlur?: (arg: any) => void;
  isLoading?: boolean;
}

const convertArray = (locationArray: userLocation[] | null) =>
  locationArray?.map((item: userLocation) => ({
    value: item.id,
    label: item.name,
  }));

const SelectDropdown = (props: ISelectDropdown) => {
  const {
    options = [],
    onSelectChange = () => {},
    onMultiplySelectChange = () => {},
    value = null,
    placeholder,
    convertFunction = undefined,
    title = '',
    isMulti = false,
    onSelectBlur = () => {},
    isLoading = false,
  } = props;
  const intl = useIntl();

  const [isActive, setIsActive] = useState(false);

  const onChange = (e: any) => {
    if (isMulti) {
      onMultiplySelectChange(e);
    } else {
      const { value, label } = e;
      onSelectChange(value, label);
    }

    setIsActive((state) => Boolean(value));
  };

  const styles = getSelectorStyles(isActive);
  const getProps = () => {
    if (isMulti) {
      return value;
    }

    // @ts-ignore
    const { label } = value;
    return label
      ? value
      : {
          value: 0,
          label: placeholder,
        };
  };

  const getOption =
    convertFunction && options
      ? convertFunction(options)
      : convertArray(options);
  return (
    <div>
      {title && (
        <span className={selectStyles.title}>
          {intl.formatMessage({ id: title })}
        </span>
      )}
      <Select
        isMulti={isMulti}
        styles={styles}
        options={getOption}
        onChange={onChange}
        value={getProps()}
        onBlur={() => {
          onSelectBlur(getProps());
        }}
      />
    </div>
  );
};

export default SelectDropdown;
