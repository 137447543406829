import React, { useCallback, useEffect, useState } from 'react';
import Column from 'pages/createProject/createProjectResult/column';
import styles from 'pages/createProject/createProjectResult/styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import ToggleExpertButtons from 'pages/createProject/createProjectResult/toggleExpertButtons';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import Expert from 'pages/createProject/createProjectResult/expert';
import classNames from 'classnames';
import RegistrationLabelList from 'pages/registrationPage/components/registrationLabelList';
import ReadMore from 'componets/readMore';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCreatedNewProjectId,
  getExpertDetailsList,
} from 'store/reducers/createProjects/selector';
import { setExpertDetailsStart } from 'store/reducers/createProjects/actions/expertDetails';
import { expertDetailsType } from 'store/reducers/createProjects/types';
import Button, { buttonTypes } from 'componets/button';
import {
  setCreatedProjectId,
  setCreateProjectStep,
  setDraftProject,
} from 'store/reducers/createProjects';
import {
  DEFAULT_READ_MORE_CONTENT_HEIGHT,
  REGISTER_PROJECT_DISPLAY_SKILLS_LIMIT,
} from 'const';
import { convertArrayObjectKeys } from 'services/helpers';
import FooterButtons from 'pages/createProject/createProjectResult/footerButtons';
import PageTitle from 'componets/pageTitle';
import LayoutWrapper from 'componets/layoutWrapper';
import { setAppLoading } from 'store/reducers/globalRequestedData';
import { getAppLoading } from 'store/reducers/globalRequestedData/selectors';
type CreateProjectResultProps = {
  onBackButtonClick?: () => void;
};

const { REACT_APP_API_DOMAIN } = process.env;

const CreateProjectResult = (props: CreateProjectResultProps) => {
  const { onBackButtonClick } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const expertsData = useSelector(getExpertDetailsList);
  const newProjectId = useSelector(getCreatedNewProjectId);
  const [page, setPage] = useState(1);
  const loading = useSelector(getAppLoading);

  const updatePage = (page: number) => {
    setPage(page);
  };

  const onBackClick = useCallback(() => {
    if (onBackButtonClick) {
      onBackButtonClick();
      return;
    }
    dispatch(setCreateProjectStep({ nextStep: 2, error: '' }));
    dispatch(setDraftProject(true));
    dispatch(setCreatedProjectId(newProjectId));
  }, [newProjectId]);
  useEffect(() => {
    dispatch(setAppLoading(true));
    dispatch(
      setExpertDetailsStart({
        page,
        id: newProjectId,
        cb: () => {
          dispatch(setDraftProject(false));
          dispatch(setAppLoading(false));
        },
      })
    );
  }, [page, newProjectId]);

  if (!expertsData) {
    return null;
  }
  const { data: availableExpert, meta } = expertsData;

  if (!availableExpert?.length) {
    return (
      <LayoutWrapper loading={loading}>
        <LayoutMainContent nativeStyles={styles.hideBeforeWrapper}>
          <LayoutContainer nativeStyles={styles.center}>
            <div className={styles.wrapper}>
              <PageTitle
                nativeStyles={styles.textCenter}
                title={'create-new-project.no-result.title'}
              />
              <div className={styles.subtitle}>
                <FormattedMessage
                  id="create-new-project.no-result.sub-title"
                  values={{
                    a: (msg: string) => (
                      <a
                        className={styles.link}
                        target="_blank"
                        href={`${REACT_APP_API_DOMAIN}/contact`}
                        rel="noreferrer"
                      >
                        {msg}
                      </a>
                    ),
                  }}
                />
              </div>
            </div>
          </LayoutContainer>
          <LayoutContainer nativeStyles={styles.toBottomLeft}>
            <div className={styles.btnWrapper}>
              <Button
                onClick={onBackClick}
                type={buttonTypes.transparent}
                nativeStyles={styles.mrAuto}
              >
                {intl.formatMessage({
                  id: 'register.steps-button.button-back',
                })}
              </Button>
            </div>
          </LayoutContainer>
        </LayoutMainContent>
      </LayoutWrapper>
    );
  }

  return (
    <LayoutMainContent nativeStyles={styles.beforeHide}>
      <LayoutContainer nativeStyles={styles.bg}>
        <Column>
          <h2 className={styles.h2Title}>
            {intl.formatMessage({
              id: 'create-new-project.step-3.search-result-title',
            })}
          </h2>
          <ToggleExpertButtons
            currentPage={page}
            updatePage={updatePage}
            meta={meta}
          />
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { user, rating } = expert;
          const { name, surname, avatar } = user;
          const isProfileSaved = false;
          const onlineStatus = '';
          return (
            <Column key={`expert_${index}`}>
              <Expert
                img={
                  avatar && avatar.length ? avatar[0]?.temporary_download : ''
                }
                name={name}
                surname={surname}
                rating={rating?.rating ?? 0}
                isProfileSaved={isProfileSaved}
                onlineStatus={onlineStatus}
                reviewsNumber={rating?.count ?? 0}
              />
            </Column>
          );
        })}
      </LayoutContainer>
      <LayoutContainer
        nativeStyles={classNames(styles.verticalPadding, styles.bg)}
      >
        <Column>
          <div className={classNames(styles.contentPadding, styles.h3Title)}>
            {intl.formatMessage({
              id: 'create-new-project.step-3.h3-title1',
            })}
          </div>
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { service_provider } = expert;
          return (
            <Column key={`typeExpertise_${index}`}>
              <div
                className={classNames(
                  styles.contentPadding,
                  styles.alignCenter
                )}
              >
                <div className={styles.strongTitle}>
                  {service_provider.name}
                </div>
              </div>
            </Column>
          );
        })}
      </LayoutContainer>
      <LayoutContainer
        nativeStyles={classNames(styles.verticalPadding, styles.bottomPadding)}
      >
        <Column>
          <div className={classNames(styles.contentPadding, styles.h3Title)}>
            {intl.formatMessage({
              id: 'create-new-project.step-3.h3-title2',
            })}
          </div>
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { areas } = expert;
          return (
            <Column key={`typeService_${index}`}>
              <div className={classNames(styles.contentPadding)}>
                <RegistrationLabelList
                  labelsArray={convertArrayObjectKeys(areas)}
                  usersLabelList={[{ id: 0, name: '' }]}
                  storeObjectKey={''}
                  nativeStyle={styles.label}
                  setFunction={() => {
                    return false;
                  }}
                />
              </div>
            </Column>
          );
        })}
      </LayoutContainer>
      <LayoutContainer nativeStyles={styles.verticalPadding}>
        <Column>
          <div className={classNames(styles.contentPadding, styles.h3Title)}>
            {intl.formatMessage({
              id: 'create-new-project.step-3.h3-title3',
            })}
          </div>
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { full_info } = expert;
          return (
            <Column key={`typeService_${index}`}>
              <div
                className={classNames(styles.contentPadding, styles.flexColumn)}
              >
                <ReadMore
                  content={full_info}
                  nativeBtnStyles={styles.readMoreBtnText}
                  contentLimitHeight={DEFAULT_READ_MORE_CONTENT_HEIGHT}
                  nativeWrapperStyles={styles.contentLimitRows}
                  buttonLabel={intl.formatMessage({
                    id: 'component.button.see-more',
                  })}
                />
              </div>
            </Column>
          );
        })}
      </LayoutContainer>
      <LayoutContainer nativeStyles={styles.verticalPadding}>
        <Column>
          <div className={classNames(styles.contentPadding, styles.h3Title)}>
            {intl.formatMessage({
              id: 'create-new-project.step-3.h3-title4',
            })}
          </div>
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { city, country } = expert;
          return (
            <Column key={`location_${index}`}>
              <div
                className={classNames(
                  styles.contentPadding,
                  styles.alignCenter
                )}
              >
                <div className={styles.strongTitle}>
                  {country?.name} {city.name}
                </div>
              </div>
            </Column>
          );
        })}
      </LayoutContainer>
      <LayoutContainer nativeStyles={styles.verticalPadding}>
        <Column>
          <div className={classNames(styles.contentPadding, styles.h3Title)}>
            {intl.formatMessage({
              id: 'create-new-project.step-3.h3-title5',
            })}
          </div>
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { skills } = expert;
          return (
            <Column key={`skills_${index}`}>
              <div className={classNames(styles.contentPadding)}>
                <RegistrationLabelList
                  labelsArray={skills}
                  usersLabelList={[{ id: 0, name: '' }]}
                  storeObjectKey={''}
                  nativeStyle={styles.label}
                  contentLimit={REGISTER_PROJECT_DISPLAY_SKILLS_LIMIT}
                  setFunction={() => {
                    return false;
                  }}
                />
              </div>
            </Column>
          );
        })}
      </LayoutContainer>
      <LayoutContainer nativeStyles={styles.verticalPadding}>
        <Column>
          <div className={classNames(styles.contentPadding, styles.h3Title)}>
            {intl.formatMessage({
              id: 'create-new-project.step-3.h3-title6',
            })}
          </div>
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { min_rate, max_rate } = expert;
          return (
            <Column key={`skills_${index}`}>
              <div
                className={classNames(
                  styles.contentPadding,
                  styles.alignCenter
                )}
              >
                <span className={styles.strongTitle}>
                  $ {min_rate}-{max_rate}
                  <span>
                    {intl.formatMessage({
                      id: 'create-new-project.step-3.available-h',
                    })}
                  </span>
                </span>
              </div>
            </Column>
          );
        })}
      </LayoutContainer>
      <LayoutContainer
        nativeStyles={classNames(
          styles.verticalPadding,
          styles.hideBefore,
          styles.hideAfter
        )}
      >
        <Column>
          <Button
            onClick={onBackClick}
            type={buttonTypes.transparent}
            nativeStyles={styles.mrAuto}
          >
            {intl.formatMessage({ id: 'register.steps-button.button-back' })}
          </Button>
        </Column>
        {availableExpert?.map((expert: expertDetailsType, index) => {
          const { user_id, id } = expert;
          return (
            <Column key={`skills_${index}`}>
              <div
                className={classNames(
                  styles.contentPadding,
                  styles.alignCenter
                )}
              >
                <FooterButtons
                  id={id}
                  expertId={user_id}
                  projectId={newProjectId}
                />
              </div>
            </Column>
          );
        })}
      </LayoutContainer>
    </LayoutMainContent>
  );
};

export default CreateProjectResult;
