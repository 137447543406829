import React from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ButtonLink from 'componets/buttonLink';
import { useDispatch, useSelector } from 'react-redux';
import { getExpertDataToSend } from 'store/reducers/registerData/selector';
import { onSendDataButtonPress } from 'componets/registration/formStepsContent/thankYouStep';
import { setUser } from 'store/reducers/user';
import StorageService from 'services/storageService';
import InfoIcon from 'svg-icons/InforIcon';
import Button, { buttonTypes } from 'componets/button';
import { setRegisterStep } from 'store/reducers/registerData';
import { ROUT_REGISTRATION } from 'const';
import { setServerError } from 'store/reducers/globalRequestedData';

const TopBar = () => {
  const int = useIntl();
  const dataToSend = useSelector(getExpertDataToSend);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className={styles.see}>
      <div className={styles.seeYourProfile}>
        <InfoIcon />
        {int.formatMessage({ id: 'view-page.top-bar.text' })}
      </div>
      <div className={styles.btnContainer}>
        <Button
          onClick={async () => {
            await StorageService.setByKey('currentRegisterStep', '12');
            dispatch(setRegisterStep({ nextStep: 11, error: '' }));
            StorageService.setByKey('preliminaryCompleted', 'false').then(
              () => {
                navigate(ROUT_REGISTRATION);
              }
            );
          }}
          type={buttonTypes.transparent}
        >
          {int.formatMessage({ id: 'view-page.top-bar.link-text-edit' })}
        </Button>
        <ButtonLink
          onClick={async () => {
            onSendDataButtonPress(dataToSend)
              .then((response) => {
                if (!response?.data) {
                  dispatch(setServerError(true, ''));
                  return;
                }
                // @ts-ignore
                const { user } = response.data;
                dispatch(setUser(user));
              })
              .then(async () => {
                await StorageService.deleteByKey(
                  'formData',
                  'currentRegisterStep',
                  'preliminaryCompleted'
                );
                window.location.reload();
              });
          }}
          nativeStyles={styles.seeLink}
        >
          {int.formatMessage({ id: 'view-page.top-bar.link-text' })}
        </ButtonLink>
      </div>
    </div>
  );
};

export default TopBar;
