import * as React from 'react';
import { SVGProps } from 'react';

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    {...props}
  >
    <path
      d="M22.8282 20.8625C22.2387 19.4662 21.3832 18.1978 20.3095 17.1281C19.2389 16.0553 17.9708 15.2 16.5751 14.6094C16.5626 14.6031 16.5501 14.6 16.5376 14.5938C18.4845 13.1875 19.7501 10.8969 19.7501 8.3125C19.7501 4.03125 16.2813 0.5625 12.0001 0.5625C7.71883 0.5625 4.25008 4.03125 4.25008 8.3125C4.25008 10.8969 5.5157 13.1875 7.46258 14.5969C7.45008 14.6031 7.43758 14.6062 7.42508 14.6125C6.02508 15.2031 4.76883 16.05 3.6907 17.1313C2.61792 18.2018 1.76259 19.4699 1.17195 20.8656C0.59171 22.232 0.278772 23.697 0.250078 25.1812C0.249244 25.2146 0.255094 25.2478 0.267284 25.2788C0.279474 25.3099 0.297756 25.3382 0.321054 25.3621C0.344353 25.386 0.372195 25.4049 0.402941 25.4179C0.433687 25.4308 0.466715 25.4375 0.500078 25.4375H2.37508C2.51258 25.4375 2.62195 25.3281 2.62508 25.1938C2.68758 22.7812 3.65633 20.5219 5.36883 18.8094C7.1407 17.0375 9.49383 16.0625 12.0001 16.0625C14.5063 16.0625 16.8595 17.0375 18.6313 18.8094C20.3438 20.5219 21.3126 22.7812 21.3751 25.1938C21.3782 25.3313 21.4876 25.4375 21.6251 25.4375H23.5001C23.5334 25.4375 23.5665 25.4308 23.5972 25.4179C23.628 25.4049 23.6558 25.386 23.6791 25.3621C23.7024 25.3382 23.7207 25.3099 23.7329 25.2788C23.7451 25.2478 23.7509 25.2146 23.7501 25.1812C23.7188 23.6875 23.4095 22.2344 22.8282 20.8625ZM12.0001 13.6875C10.5657 13.6875 9.2157 13.1281 8.20008 12.1125C7.18445 11.0969 6.62508 9.74687 6.62508 8.3125C6.62508 6.87813 7.18445 5.52812 8.20008 4.5125C9.2157 3.49687 10.5657 2.9375 12.0001 2.9375C13.4345 2.9375 14.7845 3.49687 15.8001 4.5125C16.8157 5.52812 17.3751 6.87813 17.3751 8.3125C17.3751 9.74687 16.8157 11.0969 15.8001 12.1125C14.7845 13.1281 13.4345 13.6875 12.0001 13.6875Z"
      fill="#DD8C93"
    />
  </svg>
);

export default UserIcon;
