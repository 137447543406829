export const validateFile = (
  size: number,
  type: string,
  limit: number,
  acceptFileType: string[]
) => {
  const isValidSize = size < limit;
  const isValidType = acceptFileType.some(
    (string) => string.match(type) !== null
  );
  const isValidFile = isValidSize && isValidType;

  return isValidFile;
};
