import React from 'react';
import styles from './styles.module.scss';
import RoundImage from 'componets/roundImage';
import { useIntl } from 'react-intl';

const ChatMessage = (props: {
  src: string;
  userName: string;
  userSurname: string;
  time: string;
  message: string;
}) => {
  const { src, userName, userSurname, time, message } = props;
  const date = new Date(time);
  const intl = useIntl();
  return (
    <>
      <div className={styles.wrapper}>
        <RoundImage src={src} alt={userName} nativeStyles={styles.img} />
        <div className={styles.column}>
          <div className={styles.top}>
            <div className={styles.title}>
              {userName} {userSurname}
            </div>
            <div className={styles.data}>
              {intl.formatDate(date, {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          </div>
          <div className={styles.message}>{message}</div>
        </div>
      </div>
    </>
  );
};

export default ChatMessage;
