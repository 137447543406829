import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import LayoutWrapper from 'componets/layoutWrapper';
import DashboardAside from 'pages/dashboardPage/components/dashboardAside/dashboardAside';
import { getUser } from 'store/reducers/user/selector';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import DashboardContent from 'pages/dashboardPage/components/dashboardContent';
import { userStatus } from 'store/reducers/user/types';
import {
  setCreateProjectStep,
  setDraftProject,
  setProjectDataToDefault,
} from 'store/reducers/createProjects';
import { resetActiveChat } from 'store/reducers/chat';
import { getAppLoading } from 'store/reducers/globalRequestedData/selectors';
import Spinner from 'componets/spiner';

export enum userRolesEnum {
  Expert = 'Expert',
  Founder = 'Founder',
}

const DashboardPage = () => {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setProjectDataToDefault());
  dispatch(setCreateProjectStep({ nextStep: 1, error: '' }));
  const loading = useSelector(getAppLoading);

  useEffect(() => {
    if (user) {
      const { status, roles } = user;
      if (
        status === userStatus.processBasicRegistration &&
        // @ts-ignore
        roles.name === userRolesEnum.Expert
      ) {
        navigate('/registration');
      }
    }
  }, [user]);
  useEffect(() => {
    dispatch(setDraftProject(false));
    dispatch(resetActiveChat());
  }, []);
  if (!user) {
    return <div>redirecting...</div>;
  }
  return (
    <LayoutWrapper>
      {loading ? (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) : (
        <LayoutMainContent nativeStyles={styles.innerPage}>
          <LayoutContainer>
            <DashboardAside />
            <DashboardContent />
          </LayoutContainer>
        </LayoutMainContent>
      )}
    </LayoutWrapper>
  );
};

export default DashboardPage;
