import * as React from 'react';
import { SVGProps } from 'react';

const UploadFileIcon = (props: SVGProps<any>) => (
  <svg
    width={28}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.063 6.169h2.598v11.893c0 .155.126.281.28.281h2.11a.282.282 0 0 0 .281-.281V6.169h2.605a.28.28 0 0 0 .222-.454L14.222.73a.281.281 0 0 0-.444 0L9.841 5.712a.283.283 0 0 0 .222.457Zm16.804 10.838h-2.11a.282.282 0 0 0-.28.282v5.414H3.523v-5.414a.282.282 0 0 0-.28-.282h-2.11a.282.282 0 0 0-.281.282v6.96c0 .623.502 1.126 1.125 1.126h24.046c.623 0 1.125-.503 1.125-1.125v-6.961a.282.282 0 0 0-.28-.282Z"
      fill="#96A4BB"
    />
  </svg>
);

export default UploadFileIcon;
