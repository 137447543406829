import * as React from 'react';
import { SVGProps } from 'react';

const GrayStar = (props: SVGProps<SVGSVGElement>) => {
  const { focusable = false } = props;
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M15.963 6.207 11.5 5.56 9.505 1.514a.565.565 0 0 0-1.009 0L6.5 5.56l-4.463.648a.562.562 0 0 0-.31.96l3.228 3.148-.763 4.446a.562.562 0 0 0 .816.592L9 13.255l3.992 2.098a.562.562 0 0 0 .816-.592l-.763-4.446 3.229-3.148a.562.562 0 0 0-.311-.96Z"
        fill={focusable ? '#2C4877' : '#96A4BB'}
      />
    </svg>
  );
};

export default GrayStar;
