import React from 'react';
import PdfIcon from 'svg-icons/PdfIcon';
import CrossButton from 'componets/crossButton';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

type UploadedPropsType = {
  fileName: string;
  onClick: () => void;
  unknownError: boolean;
};

const Uploaded = (props: UploadedPropsType) => {
  const { fileName, onClick, unknownError } = props;
  const intl = useIntl();
  return (
    <div className={styles.wrapper}>
      <PdfIcon focusable={true} />
      <div className={classNames(styles.flexRow, styles.topWrapper)}>
        {!unknownError && (
          <div className={styles.flexRow}>{fileName.split('\\').pop()}</div>
        )}
        <CrossButton onClick={onClick} />
      </div>
      <div
        className={classNames(styles.textWrapper, {
          [styles.error]: unknownError,
        })}
      >
        <>
          {unknownError ? (
            intl.formatMessage({ id: 'button.label.label-load-error' })
          ) : (
            <>
              <div className={styles.ok} />
              {intl.formatMessage({ id: 'button.label.label-load-success' })}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Uploaded;
