import FirstFormStep from 'componets/registration/formStepsContent/firstFormStep';
import SecondFormStep from 'componets/registration/formStepsContent/secondFormStep';
import ThirdFormStep from 'componets/registration/formStepsContent/thirdFormStep';
import FourthFormStep from 'componets/registration/formStepsContent/fourthFormStep';
import FifthFormStep from 'componets/registration/formStepsContent/fifthFormStep';
import SixthFormStep from 'componets/registration/formStepsContent/sixthFormStep';
import SeventhFormStep from 'componets/registration/formStepsContent/seventhFormStep';
import EighthFormStep from 'componets/registration/formStepsContent/eighthFormStep';
import TenthFormStep from 'componets/registration/formStepsContent/tenthFormStep';
import EleventhFormStep from 'componets/registration/formStepsContent/eleventhFormStep';
import TwelfthFormStep from 'componets/registration/formStepsContent/twelfthFormStep';
import NinthFormStep from 'componets/registration/formStepsContent/ninthFormStep';
import ThankYouStep from 'componets/registration/formStepsContent/thankYouStep';
import store from 'store';
import {
  setFormData,
  setRegisterStepError,
  setRegistrationLoading,
} from 'store/reducers/registerData';
import API from 'services/API';
import { PDF_UPLOAD_FILE_SIZE_LIMIT, URL_EXPERT_DETAILS_FILE } from 'const';
import {
  getFormData,
  getPictureFileData,
  getPortfolioFileData,
} from 'store/reducers/registerData/selector';
import StorageService from 'services/storageService';

const sendFile = async (file: File, cb?: (data: any) => void) => {
  if (!file.name) {
    return;
  }
  const result = await API.sendFormData(URL_EXPERT_DETAILS_FILE, {
    file: file,
  })
    .then(async (result) => {
      if (!result) {
        store.dispatch(setRegistrationLoading(false));
        return true;
      }
      if (cb && typeof cb === 'function') {
        await cb(result);
      }
      store.dispatch(setRegistrationLoading(false));
      return false;
    })
    .then(async (result) => {
      if (result) {
        return true;
      }
      const formData = getFormData(store.getState());
      await StorageService.setByKey('formData', JSON.stringify(formData));
      return false;
    });
  return result;
};

export const formRegistrationStepsConfig = [
  {
    title: 'register.step-1.h1-title',
    subtitle: 'register.step-1.page-subtitle',
    Component: FirstFormStep,
  },
  {
    title: 'register.step-2.h1-title',
    subtitle: 'register.step-2.page-subtitle',
    Component: SecondFormStep,
    continueValidator: (state: any) => !state?.areaExpertises?.length,
  },
  {
    title: 'register.step-3.h1-title',
    Component: ThirdFormStep,
    continueValidator: (state: any) => !state?.userTypeServices?.length,
  },
  {
    title: 'register.step-4.h1-title',
    Component: FourthFormStep,
    continueValidator: (state: any) =>
      !state?.userCity?.id || !state?.userCountry?.id,
  },
  {
    title: 'register.step-5.h1-title',
    subtitle: 'register.step-5.page-subtitle',
    Component: FifthFormStep,
    continueValidator: (state: any) => !state?.userAchieves.length,
  },
  {
    title: 'register.step-6.h1-title',
    subtitle: 'register.step-6.page-subtitle',
    Component: SixthFormStep,
    continueValidator: (state: any) => !state?.userSkills?.length,
  },
  {
    title: 'register.step-7.h1-title',
    subtitle: 'register.step-7.page-subtitle',
    Component: SeventhFormStep,
    continueValidator: (state: any) => !state?.userStartupAchieves?.length,
  },
  {
    title: 'register.step-8.h1-title',
    subtitle: 'register.step-8.page-subtitle',
    Component: EighthFormStep,
    continueValidator: (state: any) =>
      state?.userTypeStartups?.length && state?.userTypeStartups?.length > 5,
  },
  {
    title: 'register.step-9.h1-title',
    Component: NinthFormStep,
    continueValidator: (state: any) => !state?.hourAvailabilityId?.length,
  },
  {
    title: 'register.step-10.h1-title',
    subtitle: 'register.step-10.page-subtitle',
    subtitleValues: { rateLower: 18, rateHighest: 30 },
    Component: TenthFormStep,
    continueValidator: (state: any) => {
      return (
        !state?.minRate.length ||
        !state?.maxRate.length ||
        // !state?.stripeCode.length ||
        +state?.minRate >= +state?.maxRate
      );
    },
  },
  {
    title: 'register.step-11.h1-title',
    subtitle: 'register.step-11.page-subtitle',
    subtitleValues: { number: 20 },
    Component: EleventhFormStep,
    continueValidator: (state: any) => {
      const file = state?.portfolio?.fileData;
      if (!file) {
        return true;
      }
      sendFile(file).then(() => false);
    },
  },
  {
    title: 'register.step-12.h1-title',
    subtitle: 'register.step-12.page-subtitle',
    Component: TwelfthFormStep,
    continueValidator: async (state: any, cb?: (data: any) => void) => {
      const picture = state?.pictureFileData;
      if (!picture) {
        return true;
      }
      const res = await sendFile(picture, cb);
      return res;
    },
    callback: async (res: any) => {
      const fileData = getPictureFileData(store.getState());
      store.dispatch(
        setFormData({
          name: 'picture',
          value: {
            fileData,
            'picture[media_client_name]': res.data.client_name,
            'picture[media_storage_name]': res.data.path,
            filePath: res?.data?.temporary_download,
          },
        })
      );
    },
  },
  {
    title: 'register.step-13.h1-title',
    subtitle: 'register.step-13.page-subtitle',
    Component: ThankYouStep,
  },
];
