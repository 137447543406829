import React, { useEffect } from 'react';
import styles from 'componets/registration/formStepsContent/eighthFormStep/stiles.module.scss';
import classNames from 'classnames';
import RegistrationLabelList from 'pages/registrationPage/components/registrationLabelList';
import { useDispatch, useSelector } from 'react-redux';
import { setStartupTypeStart } from 'store/reducers/registerData/actions/typeStartup';
import {
  getAvailableStartupTypes,
  getUserTypesStartup,
} from 'store/reducers/registerData/selector';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';

const EighthFormStep = () => {
  const dispatch = useDispatch();
  const availableStartupTypes = useSelector(getAvailableStartupTypes);
  const userStartupTypes = useSelector(getUserTypesStartup);

  useEffect(() => {
    dispatch(setStartupTypeStart());
  }, []);

  useEffect(() => {
    dispatch(setRegisterStepError(!userStartupTypes.length));
  }, [userStartupTypes]);

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.bottomMargin, styles.flexRow)}>
        <RegistrationLabelList
          labelsArray={availableStartupTypes}
          usersLabelList={userStartupTypes}
          storeObjectKey="userTypeStartups"
          showInputRow
          setFunction={setFormData}
          selectedLimit={5}
          listId={availableStartupTypes[0]?.id}
        />
      </div>
    </div>
  );
};

export default EighthFormStep;
