import {
  fetchAreaExpertise,
  fetchTypeOfServices,
  fetchCountryLocation,
  SET_REGISTER_STEP,
  fetchCityLocation,
  fetchRegionLocation,
  SET_FORM_DATA,
  fetchAchievesSkills,
  RESET_AVAILABLE_ACHIEVES,
  fetchTypeStartup,
  fetchUserHoursAvailability,
  SET_REGISTER_STEP_ERROR,
  SET_REGISTER_STEP_LOADING,
  SET_DATA_FROM_STORAGE,
  SET_FORM_DATA_BY_USER_ID,
  SET_OTHERS_SKILLS_FORM_DATA,
  SET_REGISTER_STEP_DATA,
} from 'store/reducers/registerData/actions';
import {
  formDataType,
  IRegisterDataState,
} from 'store/reducers/registerData/types';
import { formRegistrationStepsConfig } from 'config/registrations/form-registration-steps.config';

const initState = {
  registerStepLoading: false,
  dataExpertiseArea: [],
  dataServices: [],
  userCountryList: [],
  userRegionList: [],
  userCityList: [],
  availableAchieves: [],
  availableSkills: [],
  availableTypeStartup: [],
  availableHour: [],
  formData: {
    expertDataToSend: null,
    areaExpertises: [],
    userTypeServices: [],
    userCountry: null,
    userRegion: null,
    userCity: null,
    userAchieves: [],
    userSkills: [],
    userStartupAchieves: '',
    userTypeStartups: [],
    portfolio: {
      'portfolio[media_client_name]': '',
      'portfolio[media_storage_name]': '',
      fileData: null,
      filePath: '',
    },
    picture: {
      'picture[media_client_name]': '',
      'picture[media_storage_name]': '',
      fileData: null,
      filePath: '',
    },
    hourAvailabilityId: [],
    minRate: '',
    maxRate: '',
    stripeCode: '',
    type_startups_others: [],
    skills_others: [],
    achieves_others: [],
    pictureFileData: null,
    portfolioFileData: null,
  },
  queryParams: '',
  error: null,
  stepsData: {
    step: 1,
    error: null,
  },
};

export const setRegisterStep = (value: { nextStep: number; error: string }) => {
  return {
    type: SET_REGISTER_STEP,
    payload: value,
  };
};

export const setRegisterStepError = (value: boolean) => {
  return {
    type: SET_REGISTER_STEP_ERROR,
    payload: value,
  };
};

export const setFormData = (value: any) => {
  return {
    type: SET_FORM_DATA,
    payload: value,
  };
};

export const setOthersSkillFormData = (value: any) => {
  return {
    type: SET_OTHERS_SKILLS_FORM_DATA,
    payload: value,
  };
};

export const resetFormDataByKey = () => {
  return {
    type: RESET_AVAILABLE_ACHIEVES,
  };
};

export const setRegistrationLoading = (loading: boolean) => {
  return {
    type: SET_REGISTER_STEP_LOADING,
    payload: loading,
  };
};

export const setFormDataFromStorage = (storageValue: formDataType) => {
  return {
    type: SET_DATA_FROM_STORAGE,
    payload: storageValue,
  };
};

export const setFormDataByUserId = (value: any) => {
  return {
    type: SET_FORM_DATA_BY_USER_ID,
    payload: value,
  };
};

export const setRegisterStepsData = (stepData: any) => {
  return {
    type: SET_REGISTER_STEP_DATA,
    payload: stepData,
  };
};

const registerDataReducer = (
  state: IRegisterDataState = initState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case fetchAreaExpertise.FetchStart:
    case fetchTypeOfServices.FetchStart:
    case fetchCountryLocation.FetchStart:
    case fetchTypeStartup.FetchStart:
    case fetchUserHoursAvailability.FetchStart:
      return {
        ...state,
        registerStepLoading: true,
        error: null,
      };
    case fetchAreaExpertise.FetchError:
    case fetchTypeOfServices.FetchError:
    case fetchCountryLocation.FetchError:
    case fetchAchievesSkills.FetchError:
    case fetchTypeStartup.FetchError:
    case fetchUserHoursAvailability.FetchError:
      return {
        ...state,
        registerStepLoading: false,
        error: payload,
      };
    case fetchCityLocation.FetchStart:
    case fetchAchievesSkills.FetchStart:
      return {
        ...state,
        registerStepLoading: true,
        queryParams: payload,
        error: null,
      };
    case fetchAreaExpertise.FetchSuccess:
      return {
        ...state,
        registerStepLoading: false,
        dataExpertiseArea: payload,
        error: null,
      };
    case fetchTypeOfServices.FetchSuccess:
      return {
        ...state,
        registerStepLoading: false,
        dataServices: payload,
        error: null,
      };
    case fetchCountryLocation.FetchSuccess:
      return {
        ...state,
        registerStepLoading: false,
        userCountryList: payload,
        error: null,
      };
    case fetchRegionLocation.FetchSuccess:
      return {
        ...state,
        registerStepLoading: false,
        userRegionList: payload,
        queryParams: '',
        error: null,
      };
    case fetchCityLocation.FetchSuccess:
      return {
        ...state,
        registerStepLoading: false,
        userCityList: payload,
        queryParams: '',
        error: null,
      };
    case fetchUserHoursAvailability.FetchSuccess:
      return {
        ...state,
        availableHour: payload,
        error: null,
      };
    case fetchCityLocation.FetchError:
      return {
        ...state,
        registerStepLoading: false,
        queryParams: false,
        error: payload,
      };
    case fetchAchievesSkills.FetchSuccess: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    }
    case fetchTypeStartup.FetchSuccess: {
      return {
        ...state,
        availableTypeStartup: payload,
      };
    }
    case SET_REGISTER_STEP_DATA: {
      const { stepsData } = payload;

      return { ...state, stepsData: { ...stepsData } };
    }
    case RESET_AVAILABLE_ACHIEVES: {
      const { formData } = state;

      return {
        ...state,
        formData: {
          ...formData,
          userAchieves: [],
        },
      };
    }
    case SET_FORM_DATA: {
      const { formData } = state;
      const { name, value } = payload;
      return {
        ...state,
        formData: {
          ...formData,
          [name]: value,
        },
      };
    }
    case SET_OTHERS_SKILLS_FORM_DATA: {
      const { formData } = state;
      const { skillId, name, value, key } = payload;
      // @ts-ignore
      const existData = formData[name];
      const itemExist = existData.find(
        // @ts-ignore
        ({ area_expertise_id }) => area_expertise_id === skillId
      );
      const newArray = itemExist
        ? // @ts-ignore
          existData.map((item) => {
            if (item.area_expertise_id !== skillId) {
              return item;
            }
            item[key] = value;
            return item;
          })
        : [...existData, { area_expertise_id: skillId, [key]: value }];
      return {
        ...state,
        formData: {
          ...formData,
          [name]: newArray,
        },
      };
    }
    case SET_REGISTER_STEP_ERROR: {
      const { stepsData } = state;
      return {
        ...state,
        stepsData: {
          ...stepsData,
          error: payload,
        },
      };
    }
    case SET_REGISTER_STEP_LOADING:
      return {
        ...state,
        registerStepLoading: payload,
      };
    case SET_DATA_FROM_STORAGE:
      return {
        ...state,
        formData: payload,
      };
    case SET_FORM_DATA_BY_USER_ID:
      return {
        ...state,
        formData: payload,
      };
    default:
      return state;
  }
};

export default registerDataReducer;
