import React, { useState, ChangeEvent } from 'react';
import API from 'services/API';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setRegistrationLoading } from 'store/reducers/general';
import {
  JWT_ACCESS_TOKEN_KEY,
  JWT_REFRESH_TOKEN_KEY,
  URL_DOMAIN_RESET_PASSWORD,
  URL_DOMAIN_ACCOUNT_CREATE,
  ROUT_LOGIN,
  ROUT_REGISTRATION,
  ROUT_HOME,
} from 'const';
import styles from './styles.module.scss';
import UserIcon from '../../../../svg-icons/UserIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { setUser, setVerificationState } from 'store/reducers/user';
import { getUser, getUserVerification } from 'store/reducers/user/selector';
import { userStatus } from 'store/reducers/user/types';
import { userRolesEnum } from 'pages/dashboardPage';
import store from 'store';
import classNames from 'classnames';
import VerificationMessages from 'pages/login/components/verificationMessages';

const initState = {
  grant_type: 'password',
  username: '',
  password: '',
  error: false,
};

const Login = () => {
  const [formState, setFormState] = useState(initState);
  const [loaderState, setLoaderState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const verification = useSelector(getUserVerification);

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const intl = useIntl();
  const checkStatus = (status: any, role: any, navigationFunction: any) => {
    if (status === userStatus.email_verification) {
      dispatch(setVerificationState(5));
    } else if (
      status === userStatus.preliminary_confirmation &&
      role === userRolesEnum.Expert
    ) {
      dispatch(setVerificationState(4));
    } else if (
      status === userStatus.processBasicRegistration &&
      // @ts-ignore
      role === userRolesEnum.Expert
    ) {
      navigationFunction(ROUT_REGISTRATION);
    } else if (
      status === userStatus.rejected ||
      status === userStatus.preliminary_rejected
    ) {
      dispatch(setVerificationState(6));
      navigationFunction(ROUT_LOGIN);
    } else {
      navigationFunction(ROUT_HOME);
    }
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!loaderState) {
      if (formState.error) {
        return;
      }
      setLoaderState(true);
      API.sendCodeToAuth(formState.username, formState.password)
        .then(async (res) => {
          if (!res.error) {
            const { access_token, refresh_token } = res;
            const userData = await API.getUser();
            if (
              !userData ||
              userData.status === userStatus.email_verification ||
              userData.status === userStatus.preliminary_confirmation ||
              userData.status === userStatus.rejected ||
              userData.status === userStatus.preliminary_rejected
            ) {
              return res;
            }
            dispatch(setRegistrationLoading(false));
            localStorage.setItem(JWT_ACCESS_TOKEN_KEY, access_token);
            localStorage.setItem(JWT_REFRESH_TOKEN_KEY, refresh_token);
          }
          if (res.error) {
            setErrorMessage(res.message);
          }
          return res;
        })
        .then(async (res) => {
          const userData = await API.getUser();
          dispatch(setUser(userData));
          if (res.error) {
            setErrorMessage(res.message);
          } else {
            const user = getUser(store.getState());
            // @ts-ignore
            const { status, roles } = user;

            checkStatus(status, roles.name, navigation);
          }
          setLoaderState(false);
        })
        .catch((error) => {
          setLoaderState(false);
          console.log('error', error);
        });
    }
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const valid =
      /^([a-zA-Z0-9_.-\\+]+)@([a-zA-Z0-9_.-]+)\.([a-zA-Z.]{2,6})/gm.test(
        event.target.value
      );
    setFormState((state: any) => {
      return {
        ...state,
        error: !valid,
        username: event.target.value,
      };
    });
  };

  return (
    <div className={styles.loginBlockWrapper}>
      <div className={styles.loginBlock}>
        <h2 className={styles.sectionTitle}>
          <FormattedMessage id="login.button.login" />
          <UserIcon />
        </h2>
        {loaderState && ''}
        {verification && (
          <VerificationMessages verificationState={verification} />
        )}

        <form
          className={styles.loginForm}
          onSubmit={onSubmit}
          autoComplete="new-password"
        >
          <label className={styles.inputField}>
            <input
              required={true}
              className={styles.input}
              type="email"
              autoComplete="new-password"
              name="email"
              placeholder={intl.formatMessage({
                id: 'email',
              })}
              onChange={onEmailChange}
            />
            {formState.error && (
              <span className={classNames(styles.error)}>
                {intl.formatMessage({ id: 'component.labels.notvalid-email' })}
              </span>
            )}
          </label>
          <label className={classNames(styles.inputField)}>
            <input
              required={true}
              className={styles.input}
              autoComplete="new-password"
              type="password"
              name="password"
              placeholder={intl.formatMessage({
                id: 'password',
              })}
              onChange={(event) => {
                setFormState((state: any) => {
                  return {
                    ...state,
                    password: event.target.value,
                  };
                });
              }}
            />
            <span className="validation-error" data-error="password" />
          </label>
          {!!errorMessage && <div className={styles.error}>{errorMessage}</div>}
          <div className={styles.btnWrapper}>
            <button type="submit" className={styles.loginButton}>
              <FormattedMessage id="login.button.login" />
            </button>
            <a href={URL_DOMAIN_RESET_PASSWORD} className={styles.forgotLink}>
              <FormattedMessage id="forgot-password" />
            </a>
          </div>
        </form>

        <div className={styles.loginNotification}>
          <FormattedMessage id="account.dont-have" />
          <a
            href={URL_DOMAIN_ACCOUNT_CREATE}
            className={classNames(styles.dangerLink)}
          >
            <b>
              <FormattedMessage id="account.create-it" />
            </b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
