import React from 'react';
import NumberedListItem, {
  NumberedListConfigInterface,
} from 'componets/numberedList/numberedListItem';
import { useSelector } from 'react-redux';
import { getCurrentRegisterStep } from 'store/reducers/registerData/selector';
import { getNewProjectCurrentStep } from 'store/reducers/createProjects/selector';
import { currentStepSelectorKeys } from 'services/enums';

interface NumberedListProps {
  list: NumberedListConfigInterface[];
  onItemClick: (index: number) => void;
  nativeContentStyles?: string;
  stepKey: currentStepSelectorKeys;
}

const NumberedList = (props: NumberedListProps) => {
  const { list = [], nativeContentStyles = '', onItemClick, stepKey } = props;
  const currentRegistrationStep = useSelector(getCurrentRegisterStep);
  const currentNewProjectStep = useSelector(getNewProjectCurrentStep);
  const currentStep =
    stepKey === currentStepSelectorKeys.register
      ? currentRegistrationStep
      : currentNewProjectStep;
  const onLinkClick = (index: number) => {
    if (index + 1 > currentStep) {
      return;
    }
    onItemClick(index);
  };

  return (
    <ol>
      {list?.map((listItem: NumberedListConfigInterface, index: number) => {
        const { title } = listItem;
        return (
          <NumberedListItem
            key={`${title.toString()}_${index.toString()}`}
            title={title}
            nativeContentStyles={nativeContentStyles}
            index={index}
            onLinkClick={() => {
              onLinkClick(index);
            }}
            currentStep={currentStep}
          />
        );
      })}
    </ol>
  );
};

export default NumberedList;
