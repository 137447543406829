import * as React from 'react';
import { SVGProps } from 'react';

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.546 25H18.455C22.07 25 25 22.07 25 18.454V7.545C25 3.93 22.07 1 18.454 1H7.545C3.93 1 1 3.93 1 7.546V18.455C1 22.07 3.93 25 7.546 25Z"
      stroke="#2C4877"
      strokeWidth="2"
    />
    <path
      d="M13 7C11.4087 7 9.88258 7.63214 8.75736 8.75736C7.63214 9.88258 7 11.4087 7 13C7 14.5913 7.63214 16.1174 8.75736 17.2426C9.88258 18.3679 11.4087 19 13 19C14.5913 19 16.1174 18.3679 17.2426 17.2426C18.3679 16.1174 19 14.5913 19 13C19 11.4087 18.3679 9.88258 17.2426 8.75736C16.1174 7.63214 14.5913 7 13 7Z"
      stroke="#2C4877"
      strokeWidth="2"
    />
    <path
      d="M20.5 4C20.303 4 20.108 4.0388 19.926 4.11418C19.744 4.18956 19.5786 4.30005 19.4393 4.43934C19.3001 4.57863 19.1896 4.74399 19.1142 4.92597C19.0388 5.10796 19 5.30302 19 5.5C19 5.69698 19.0388 5.89204 19.1142 6.07403C19.1896 6.25601 19.3001 6.42137 19.4393 6.56066C19.5786 6.69995 19.744 6.81044 19.926 6.88582C20.108 6.9612 20.303 7 20.5 7C20.697 7 20.892 6.9612 21.074 6.88582C21.256 6.81044 21.4214 6.69995 21.5607 6.56066C21.6999 6.42137 21.8104 6.25601 21.8858 6.07403C21.9612 5.89204 22 5.69698 22 5.5C22 5.30302 21.9612 5.10796 21.8858 4.92597C21.8104 4.74399 21.6999 4.57863 21.5607 4.43934C21.4214 4.30005 21.256 4.18956 21.074 4.11418C20.892 4.0388 20.697 4 20.5 4Z"
      fill="#2C4877"
    />
  </svg>
);

export default InstagramIcon;
