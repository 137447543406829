import {
  userStoreInterface,
  userDataType,
  userVerificationStateType,
} from 'store/reducers/user/types';
export const getUser = (state: {
  user: userStoreInterface;
}): userDataType | null => state.user.userData;

export const getUserVerification = (state: {
  user: userStoreInterface;
}): userVerificationStateType | undefined =>
  state?.user?.userData?.verificationState;

export const getUserVerificationId = (state: {
  user: userStoreInterface;
}): string | undefined => state?.user?.userData?.verificationId;
