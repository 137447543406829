import React from 'react';
import styles from './styles.module.scss';
import YellowStar from 'svgComponent/YellowStar';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
type RateRowProps = {
  rate: { id: number; rating: number; count: number };
  nativeStyles?: string;
  showCount?: boolean;
};

const RateRow = (props: RateRowProps) => {
  const {
    rate = { id: 0, rating: 0, count: 0 },
    nativeStyles = '',
    showCount = true,
  } = props;
  const intl = useIntl();
  return (
    <div className={styles.flexRow}>
      <div className={classNames(styles.score, nativeStyles)}>
        <YellowStar />
        {rate?.rating}
      </div>
      {rate?.count && showCount ? (
        <div className={styles.text}>
          <FormattedMessage
            id="dashboard.rate.rate-based-on"
            values={{
              strong: (msg: string) => (
                <strong className={styles.strong}>{rate?.count}</strong>
              ),
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RateRow;
