import * as React from 'react';
import { SVGProps } from 'react';

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.125 0H4.875C2.182 0 0 2.182 0 4.875V21.125C0 23.818 2.182 26 4.875 26H21.125C23.818 26 26 23.818 26 21.125V4.875C26 2.182 23.818 0 21.125 0ZM20.464 14.002H18.031V23.006H13.968V14.002H12.392V10.969H13.968V9.037C13.969 6.504 15.021 5 18.006 5H21.031V8.022H19.274C18.112 8.022 18.036 8.455 18.036 9.265L18.031 10.968H20.795L20.464 14.002Z"
      fill="#2C4877"
    />
  </svg>
);

export default FacebookIcon;
