import React, { useEffect } from 'react';
import LayoutWrapper from 'componets/layoutWrapper';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import TopBar from 'pages/profilePreviewPage/topBar';
import AsideContent from 'pages/profilePreviewPage/asideContent';
import styles from './styles.module.scss';
import ReviewContent from 'pages/profilePreviewPage/ReviewContent';
import { useSelector } from 'react-redux';
import { getFormData } from 'store/reducers/registerData/selector';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUT_HOME, ROUT_VIEW_PROFILE } from 'const';
import classNames from 'classnames';
import { userStatus } from 'store/reducers/user/types';
import { getUser } from 'store/reducers/user/selector';
import StorageService from 'services/storageService';

const ProfilePreviewPage = () => {
  const contentPreviewData = useSelector(getFormData);
  const { pathname } = useLocation();
  const isPreviewRout = pathname === ROUT_VIEW_PROFILE;
  const user = useSelector(getUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      return;
    }
    const { status } = user;
    if (status === userStatus.waitingConfirmation) {
      navigate(ROUT_HOME);
    }
  }, [user?.status]);

  useEffect(() => {
    if (
      user &&
      isPreviewRout &&
      user.status === userStatus.processBasicRegistration
    ) {
      StorageService.setByKey('preliminaryCompleted', 'true').catch(Error);
    }
  }, []);
  return (
    <LayoutWrapper>
      <LayoutMainContent
        nativeStyles={classNames(styles.beforeHide, {
          [styles.contentPadding]: !isPreviewRout,
        })}
      >
        <LayoutContainer>
          {isPreviewRout && <TopBar />}
          <AsideContent contentData={contentPreviewData} />
          <ReviewContent />
        </LayoutContainer>
      </LayoutMainContent>
    </LayoutWrapper>
  );
};

export default ProfilePreviewPage;
