import { generalStoreInterface } from 'store/reducers/general';
import { AVAILABLE_LANG_LIST } from 'i18n/langs/enums';

export const getLang = (state: {
  generalData: generalStoreInterface;
}): AVAILABLE_LANG_LIST => state.generalData.lang;

export const getAppLoading = (state: {
  generalData: generalStoreInterface;
}): boolean => state.generalData.appInit;

export const getPusherClient = (state: {
  generalData: generalStoreInterface;
}): any => state.generalData.pusherClient;
