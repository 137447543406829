import React from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage/index';
import classNames from 'classnames';
import ButtonLink from 'componets/buttonLink';
import { ROUT_CREATE_PROJECT } from 'const';

const ProjectPlaceholder = () => {
  const intl = useIntl();
  const user = useSelector(getUser);
  return (
    <div className={styles.wrapper}>
      {
        // @ts-ignore
        user?.roles.name === userRolesEnum.Expert ? (
          <>
            <h2 className={styles.greyTitle}>
              {intl.formatMessage({ id: 'expert-placeholder-page' })}
            </h2>
            <p className={styles.strong}>
              {intl.formatMessage({ id: 'expert-placeholder-page.subtitle' })}
            </p>
          </>
        ) : (
          <>
            <h2 className={styles.greyTitle}>
              {intl.formatMessage({ id: 'founder-placeholder-page' })}
            </h2>
            <p className={styles.strong}>
              {intl.formatMessage({ id: 'founder-placeholder-page.subtitle' })}
            </p>
            <ButtonLink
              nativeStyles={classNames(styles.btn, styles.btnDanger)}
              path={ROUT_CREATE_PROJECT}
            >
              {intl.formatMessage({ id: 'component.button.find-expert' })}
            </ButtonLink>
          </>
        )
      }
    </div>
  );
};

export default ProjectPlaceholder;
