import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Button, { buttonTypes } from 'componets/button';
import { useIntl } from 'react-intl';

type ReadMoreProps = {
  content: string;
  buttonLabel: string;
  contentLimitHeight: number;
  nativeWrapperStyles?: string;
  nativeBtnStyles?: string;
};

const initContentState = {
  isActive: false,
  showAllContent: false,
};

const ReadMore = (props: ReadMoreProps) => {
  const {
    content,
    nativeWrapperStyles,
    buttonLabel,
    nativeBtnStyles,
    contentLimitHeight,
  } = props;
  const intl = useIntl();
  const [contentState, setSetContentState] = useState(initContentState);
  const blockRef = useRef<HTMLElement>();

  const { isActive, showAllContent } = contentState;

  useEffect(() => {
    if (blockRef.current) {
      const height = blockRef.current.getBoundingClientRect().height;
      setSetContentState((state) => {
        return {
          ...state,
          showAllContent: contentLimitHeight < +height.toFixed(),
        };
      });
    }
  }, [blockRef.current]);

  return (
    // @ts-ignore
    <div ref={blockRef} className={classNames(styles.wrapper)}>
      <p
        className={classNames(styles.content, nativeWrapperStyles, {
          [styles.isActive]: isActive,
        })}
      >
        {content}
      </p>
      {showAllContent && (
        <div className={styles.footer}>
          <Button
            onClick={() => {
              setSetContentState((state) => ({
                ...state,
                isActive: !state.isActive,
              }));
            }}
            type={buttonTypes.strongUnderline}
            nativeStyles={nativeBtnStyles}
          >
            {!isActive
              ? buttonLabel
              : intl.formatMessage({ id: 'component.button.show-less' })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReadMore;
