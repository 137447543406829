import * as React from 'react';
import { SVGProps } from 'react';

const CloudIcon = (props: SVGProps<SVGSVGElement>) => {
  const { fill } = props;
  return (
    <svg
      width={25}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.833 7.934C20.308 3.804 16.772.6 12.5.6 9.193.6 6.32 2.532 5.008 5.578 2.431 6.35.5 8.784.5 11.4c0 3.188 2.507 5.778 5.65 5.965v.035h13.538v-.004l.012.004c2.647 0 4.8-2.153 4.8-4.8a4.81 4.81 0 0 0-3.667-4.666ZM7.7 9h3.6V5.4h2.4V9h3.6l-4.8 6-4.8-6Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloudIcon;
