import React, { useEffect } from 'react';
import ProfilePreviewPage from 'pages/profilePreviewPage';
import { useParams } from 'react-router-dom';
import { setSingleExpertDetailsStart } from 'store/reducers/globalRequestedData/actions/singleExpertDetailsActions';
import { useDispatch } from 'react-redux';
import { setAppLoading } from 'store/reducers/globalRequestedData';

const UserPage = () => {
  const params = useParams();
  const { userId } = params;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAppLoading(true));
    dispatch(
      setSingleExpertDetailsStart(userId, () => {
        dispatch(setAppLoading(false));
      })
    );
  }, [userId]);

  return (
    <div>
      <ProfilePreviewPage />
    </div>
  );
};

export default UserPage;
