import React, { useEffect } from 'react';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import StepsAside from 'componets/stepsAside';
import LayoutWrapper from 'componets/layoutWrapper';
import { projectCreateStepsAsideConfig } from 'config/createProject/steps-aside.config';
import { useDispatch, useSelector } from 'react-redux';
import { createProjectStepsConfig } from 'config/createProject/create-project-steps.config';
import { toggleStepsInterface } from 'pages/registrationPage';
import { setCreateProjectStep } from 'store/reducers/createProjects';
import StepsContent from 'componets/stepsContent';
import { currentStepSelectorKeys } from 'services/enums';
import { getNewProjectCurrentStep } from 'store/reducers/createProjects/selector';
import CreateProjectResult from 'pages/createProject/createProjectResult';

const CreateProject = () => {
  const currentIndexStep = useSelector(getNewProjectCurrentStep) - 1;
  const data: toggleStepsInterface = createProjectStepsConfig[currentIndexStep];
  const dispatch = useDispatch();
  const setStepFunction = (index: number) => {
    dispatch(setCreateProjectStep({ nextStep: index + 1, error: '' }));
  };

  const getLayoutContent = () => {
    if (currentIndexStep !== createProjectStepsConfig.length && data) {
      const { title, subtitle, Component, subtitleValues } = data;
      return (
        <LayoutMainContent>
          <LayoutContainer>
            <StepsAside
              config={projectCreateStepsAsideConfig}
              setStepFunction={setStepFunction}
              stepKey={currentStepSelectorKeys.createProject}
            />
            <StepsContent
              title={title}
              subtitle={subtitle}
              subtitleValues={subtitleValues}
              stepsButton
              stepKey={currentStepSelectorKeys.createProject}
            >
              <Component />
            </StepsContent>
          </LayoutContainer>
        </LayoutMainContent>
      );
    }
    return <CreateProjectResult />;
  };

  return <LayoutWrapper>{getLayoutContent()}</LayoutWrapper>;
};

export default CreateProject;
