import React from 'react';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import LayoutWrapper from 'componets/layoutWrapper';
import ChatPageAside from 'pages/chatPage/chatPageAside';
import ChatPageContent from 'pages/chatPage/chatPageContent';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { getAvailableChats } from 'store/reducers/chat/selectors';
import { userRolesEnum } from 'pages/dashboardPage';
const getChatUser = (data: any, roles: userRolesEnum) => {
  if (!data) {
    return null;
  }
  return roles === userRolesEnum.Founder ? data[0]?.expert : data[0]?.founder;
};

const ChatPage = () => {
  const user = useSelector(getUser);
  const availableChats = useSelector(getAvailableChats);
  // @ts-ignore
  const userRole = user?.roles.name;
  return (
    <LayoutWrapper>
      <LayoutMainContent nativeStyles={styles.innerPage}>
        <LayoutContainer>
          <ChatPageAside availableChats={availableChats} />
          <ChatPageContent
            availableChats={availableChats}
            chatExpert={getChatUser(availableChats, userRole)}
          />
        </LayoutContainer>
      </LayoutMainContent>
    </LayoutWrapper>
  );
};

export default ChatPage;
