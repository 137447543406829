import { fetchTypeOfServices } from 'store/reducers/registerData/actions';
import { dataServicesType } from 'store/reducers/registerData/types';

export function setTypeOfServicesStart() {
  return {
    type: fetchTypeOfServices.FetchStart,
  };
}

export function setTypeOfServicesSuccess(dataServices: dataServicesType[]) {
  return {
    type: fetchTypeOfServices.FetchSuccess,
    payload: dataServices,
  };
}

export function setTypeOfServicesError(error: Error) {
  return {
    type: fetchTypeOfServices.FetchError,
    payload: error,
  };
}
