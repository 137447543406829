import React from 'react';
import styles from './styles.module.scss';
import RoundImage from 'componets/roundImage';
import YellowStar from 'svgComponent/YellowStar';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
type ExpertTypeProps = {
  img: string;
  name: string;
  surname: string;
  rating: number;
  isProfileSaved: boolean;
  onlineStatus?: string;
  reviewsNumber?: number;
};
const Expert = (props: ExpertTypeProps) => {
  const { img, name, surname, rating, reviewsNumber } = props;
  const intl = useIntl();
  const onSaveButtonClick = () => {
    console.log('saved');
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <RoundImage src={img} alt={name} nativeStyles={styles.img} />
        {!!rating && (
          <div className={styles.ratingBlock}>
            <YellowStar /> {rating}{' '}
          </div>
        )}
      </div>
      <div className={classNames(styles.block, styles.contentCenter)}>
        <h3 className={styles.name}>
          {name} {surname}
        </h3>
        {!!reviewsNumber && (
          <div className={styles.labelText}>
            <FormattedMessage
              id={'dashboard.rate.rate-based-on'}
              values={{
                strong: (msg: string) => (
                  <strong className={styles.strong}>{reviewsNumber}</strong>
                ),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Expert;
