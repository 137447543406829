import * as React from 'react';
import { SVGProps } from 'react';

const ChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.25 10.5 4.75 6 .25 1.5 1.75 0l6 6-6 6-1.5-1.5Z"
      fill="#DD8C93"
    />
  </svg>
);

export default ChevronRight;
