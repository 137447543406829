import { singleExpertDetailsType } from 'store/reducers/globalRequestedData';

export const convertProjectData = (objData: any) => {
  const {
    name,
    type_startup,
    min_rate,
    max_rate,
    budget,
    skills,
    area_expertises,
    goals,
    achieves,
  } = objData.data;
  return {
    name,
    typeStartup: type_startup,
    minRate: min_rate,
    maxRate: max_rate,
    budget: budget,
    skills: skills,
    typeOfExpert: area_expertises.map((expertise: any) => ({
      value: expertise.id,
      label: expertise.area,
    })),
    typeOfGoals: goals.map((goal: any) => ({
      value: goal.id,
      label: goal.name,
    })),
    typeOfAchieves: achieves.map((goal: any) => ({
      value: goal.id,
      label: goal.name,
    })),
  };
};

export const convertExpertData = (objData: singleExpertDetailsType) => {
  const {
    achieves,
    areas,
    id,
    max_rate,
    min_rate,
    portfolio,
    rating,
    service_provider,
    skills,
    full_info,
    user,
    city,
    hour_availability,
    country,
    state = undefined,
  } = objData;
  const stateExpert = state ? { userRegion: state } : {};
  return {
    areaExpertises: areas,
    userTypeServices: [{ area: service_provider.name }],
    userCountry: country,
    userCity: city,
    userAchieves: achieves.map((item) => ({ area: item.name })),
    userSkills: skills.map((item) => ({ area: item.name })),
    userStartupAchieves: full_info,
    portfolio: [...portfolio],
    picture: user.avatar,
    hourAvailabilityId: [{ area: hour_availability.name }],
    minRate: min_rate,
    maxRate: max_rate,
    user,
    ...stateExpert,
  };
};
