import React from 'react';
import styles from './styles.module.scss';
import FacebookIcon from '../../../../svg-icons/FacebookIcon';
import InstagramIcon from '../../../../svg-icons/InstagramIcon';
import WebsparkIcon from '../../../../svg-icons/WebsparkIcon';
import AppLogo from '../../../../svg-icons/AppLogo';
import { FormattedMessage } from 'react-intl';
import { URL_FACEBOOK, URL_INSTAGRAM, URL_LINKEDIN, URL_WEBSPARK } from 'const';
import LinkedInIcon from 'svg-icons/LinkedInIcon';

const { REACT_APP_API_DOMAIN } = process.env;

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className={styles.footerBlockInfo}>
              <a href={REACT_APP_API_DOMAIN} className="logo">
                <AppLogo />
              </a>
              <div className={styles.copyright}>
                <FormattedMessage
                  id="login.copyright"
                  values={{ year: new Date().getFullYear() }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className={styles.footerBlockContacts}>
              <nav className="nav">
                <div className={styles.navItem}>
                  <a
                    href={`${REACT_APP_API_DOMAIN}/contact`}
                    className={styles.navItemLink}
                    data-target="success-popup"
                  >
                    <FormattedMessage id="login.menu.contact" />
                  </a>
                </div>
                <div className={styles.navItem}>
                  <a
                    href={`${REACT_APP_API_DOMAIN}/faq`}
                    className={styles.navItemLink}
                  >
                    <FormattedMessage id="login.menu.faq" />
                  </a>
                </div>
              </nav>
            </div>
          </div>
          <div className="col-md-2">
            <div className={styles.footerBlockContacts}>
              <nav className="nav">
                <div className={styles.navItem}>
                  <a
                    href={`${REACT_APP_API_DOMAIN}/terms`}
                    className={styles.navItemLink}
                  >
                    <FormattedMessage id="login.menu.terms" />
                  </a>
                </div>
                <div className={styles.navItem}>
                  <a
                    href={`${REACT_APP_API_DOMAIN}/policy`}
                    className={styles.navItemLink}
                  >
                    <FormattedMessage id="login.menu.privacy" />
                  </a>
                </div>
              </nav>
            </div>
          </div>
          <div className="col-md-4 text-right">
            <div className={styles.footerBlockSocial}>
              <div className={styles.socialItem}>
                <a href={URL_FACEBOOK} className={styles.socialLink}>
                  <FacebookIcon />
                </a>
              </div>
              <div className={styles.socialItem}>
                <a href={URL_INSTAGRAM} className={styles.socialLink}>
                  <InstagramIcon />
                </a>
              </div>

              <div className={styles.socialItem}>
                <a href={URL_LINKEDIN} className={styles.socialLink}>
                  <LinkedInIcon />
                </a>
              </div>
            </div>
            <div className={styles.developed}>
              Developed by
              <a href={URL_WEBSPARK} className={styles.developedImg}>
                <WebsparkIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
