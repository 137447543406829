import React from 'react';
import styles from 'pages/createProject/createProjectResult/toggleExpertButtons/styles.module.scss';
import Button, { buttonTypes } from 'componets/button';
import { useIntl } from 'react-intl';
import BtnWithIcon, { iconPositions } from 'componets/button/btnWithIcon';
import ChevronLeft from 'svgComponent/ChevronLeft';
import ChevronRight from 'svgComponent/chevronRught';

const ToggleExpertButtons = (props: {
  currentPage: number;
  updatePage: (page: number) => void;
  meta: {
    currentPage: number;
    last_page: number;
  };
}) => {
  const { currentPage, updatePage, meta } = props;
  const { last_page } = meta;

  const intl = useIntl();
  const onNextClick = () => {
    updatePage(currentPage + 1);
  };

  const onPrevClick = () => {
    updatePage(currentPage - 1);
  };
  return (
    <div className={styles.wrapper}>
      <Button
        disabled={currentPage === 1}
        onClick={onPrevClick}
        type={buttonTypes.simple}
      >
        <BtnWithIcon Icon={ChevronLeft} iconPosition={iconPositions.left}>
          {intl.formatMessage({ id: 'create-new-project.step-3.button-prev' })}
        </BtnWithIcon>
      </Button>
      <Button
        onClick={onNextClick}
        type={buttonTypes.simple}
        disabled={currentPage >= last_page}
      >
        <BtnWithIcon Icon={ChevronRight} iconPosition={iconPositions.right}>
          {intl.formatMessage({ id: 'create-new-project.step-3.button-next' })}
        </BtnWithIcon>
      </Button>
    </div>
  );
};

export default ToggleExpertButtons;
