import * as React from 'react';
import { SVGProps } from 'react';

interface svgProps extends SVGProps<SVGSVGElement> {
  error?: boolean;
}

const UploadImageIcon = (props: svgProps) => {
  const { error } = props;
  return (
    <svg
      width={32}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.375 4.719h-4.781l-1.14-3.192A1.128 1.128 0 0 0 21.394.78H10.607c-.475 0-.9.3-1.058.746L8.406 4.719H3.625A2.812 2.812 0 0 0 .812 7.53v16.032a2.812 2.812 0 0 0 2.813 2.812h24.75a2.812 2.812 0 0 0 2.813-2.813V7.532a2.812 2.812 0 0 0-2.813-2.813Zm.281 18.843a.282.282 0 0 1-.281.282H3.625a.282.282 0 0 1-.281-.282V7.532c0-.155.126-.282.281-.282h6.564l.6-1.68.806-2.258h8.807l.805 2.258.6 1.68h6.568c.155 0 .281.127.281.281v16.032ZM16 9.5a5.623 5.623 0 0 0-5.625 5.625A5.623 5.623 0 0 0 16 20.75a5.623 5.623 0 0 0 5.625-5.625A5.623 5.623 0 0 0 16 9.5Zm0 9a3.376 3.376 0 1 1 .002-6.752A3.376 3.376 0 0 1 16 18.5Z"
        fill={error ? '#DD8C93' : '#96A4BB'}
      />
    </svg>
  );
};

export default UploadImageIcon;
