import * as React from 'react';
import { SVGProps } from 'react';

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  const { focusable = false } = props;
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.258 12.867h-1.235a.277.277 0 0 0-.217.102 6.22 6.22 0 0 1-4.799 2.253A6.202 6.202 0 0 1 4.602 13.4a6.21 6.21 0 0 1-1.336-1.978A6.162 6.162 0 0 1 2.778 9a6.193 6.193 0 0 1 1.824-4.4 6.22 6.22 0 0 1 4.405-1.823 6.22 6.22 0 0 1 4.799 2.254.28.28 0 0 0 .217.102h1.235a.14.14 0 0 0 .118-.216A7.562 7.562 0 0 0 8.988 1.44a7.55 7.55 0 0 0-7.546 7.636 7.563 7.563 0 0 0 7.565 7.482 7.564 7.564 0 0 0 6.369-3.476.14.14 0 0 0-.118-.216Zm1.563-3.978-2.494-1.968a.14.14 0 0 0-.229.11v1.336h-5.52a.141.141 0 0 0-.14.14v.985c0 .078.063.14.14.14h5.52v1.337c0 .118.137.184.229.11l2.494-1.968a.141.141 0 0 0 .04-.173.141.141 0 0 0-.04-.049Z"
        fill={focusable ? '#2C4877' : '#96A4BB'}
      />
    </svg>
  );
};

export default LogoutIcon;
