import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { DEFAULT_FILE_SIZE_LIMIT, PDF_UPLOAD_FILE_SIZE_LIMIT } from 'const';
import { useIntl } from 'react-intl';
import UploadFileIcon from 'svg-icons/UploadFileIcon';
import UploadImageIcon from 'svg-icons/uploadImageIcon';
import Upload from 'componets/fileUpload/upload';
import Loading from 'componets/fileUpload/loading';
import Uploaded from 'componets/fileUpload/uploaded';
import { validateFile } from 'componets/fileUpload/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setRegisterStepError } from 'store/reducers/registerData';
import { getCurrentRegisterStep } from 'store/reducers/registerData/selector';

export enum fileTypes {
  pdf,
  image,
}

export interface IFileUpload {
  type: fileTypes;
  acceptFileType: string[];
  name: string;
  limit?: number;
  loading: boolean;
  onChange(name: string, data: File | null, isReset?: boolean): void;
  uploadedFileName?: string;
  unknownError: boolean;
}

const initFileLoadState = {
  fileName: '',
  validFileError: false,
};

const FileUpload = (props: IFileUpload) => {
  const {
    type,
    acceptFileType,
    name,
    limit = PDF_UPLOAD_FILE_SIZE_LIMIT,
    onChange,
    uploadedFileName = '',
    loading = false,
    unknownError,
  } = props;

  const dispatch = useDispatch();
  const currentRegisterStep = useSelector(getCurrentRegisterStep);

  const intl = useIntl();
  const [state, setState] = useState(initFileLoadState);

  useEffect(() => {
    setState((state) => ({
      ...state,
      fileName: uploadedFileName,
    }));
  }, [currentRegisterStep, uploadedFileName]);
  const { fileName, validFileError } = state;

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    dispatch(setRegisterStepError(false));
    const { files } = event.target;
    if (!files) {
      return;
    }

    const [file] = files;
    const { size, type, name: fileName } = file;
    const isValid = validateFile(size, type, limit, acceptFileType);

    if (!isValid) {
      setState((state) => ({
        ...state,
        validFileError: true,
      }));
      dispatch(setRegisterStepError(true));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      validFileError: false,
      fileName,
    }));
    onChange(name, file);
  };

  const clearFile = () => {
    setState({
      ...initFileLoadState,
      fileName: '',
    });
    onChange(name, null, true);
  };

  const uploadMessages = {
    default:
      type === fileTypes.image
        ? intl.formatMessage({ id: 'image-upload-placeholder' })
        : intl.formatMessage({ id: 'file-upload-placeholder' }),
    error: intl.formatMessage({ id: 'input-upload-placeholder-error-pdf' }),
    uploading: intl.formatMessage({ id: 'input-upload-placeholder-loading' }),
    btn: intl.formatMessage({ id: 'btn-upload' }),
  };

  return (
    <div className={styles.wrapper}>
      {!loading && !fileName && (
        <div>
          {type === fileTypes.pdf && <UploadFileIcon />}
          {type === fileTypes.image && <UploadImageIcon />}
          <Upload
            validFileError={validFileError}
            fileName={fileName}
            placeholder={uploadMessages.default}
            label={uploadMessages.error}
          />
          <input
            className={styles.input}
            type="file"
            accept={acceptFileType.join(',')}
            onChange={onFileChange}
            name={name}
          />
        </div>
      )}
      {loading && <Loading type={type} label={uploadMessages.uploading} />}

      {fileName && !loading && (
        <div className={styles.contentWrapper}>
          <Uploaded
            unknownError={unknownError}
            fileName={fileName}
            onClick={clearFile}
          />
        </div>
      )}

      {!loading && !fileName ? (
        <button className={styles.btn} onClick={() => {}}>
          {uploadMessages.btn}
        </button>
      ) : null}
    </div>
  );
};

export default FileUpload;
