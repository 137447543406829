import { projectType } from 'store/reducers/globalRequestedData';
import {
  ACTIVE_CHAT_ID,
  ADD_CHAT_MESSAGE,
  ADD_CHAT_MESSAGE_SUCCESS,
  fetchActiveChat,
  fetchChats,
  RESET_ACTIVE_CHAT,
  SET_CHAT_SCROLL_TRIGGER,
  SET_INVOICE_TRIGGER,
  UPDATE_CHAT_NAME,
  updateAvailableChats,
} from 'store/reducers/chat/actions';
import { userStatus } from 'store/reducers/user/types';
import { invoiceTriggerEnum } from 'pages/chatPage/chatPageContent/chatHeader';
export type chatMessageUser = {
  id: number;
  name: string;
  surname: string;
  email: string;
  status: userStatus;
  phone: string;
  country_id: number;
  avatar: {
    client_name: string;
    temporary_download: string;
  };
};

export type chatExpertType = {
  id: number;
  user_id: number;
  service_provide_id: number;
  city_id: number;
  hour_availability_id: number;
  min_rate: number;
  max_rate: number;
  ful_info: string;
  available_to_work: number;
  expertise: { id: number; area: string }[];
  areas: { id: number; area: string }[];
  user: {
    id: number;
    name: string;
    surname: string;
    email: string;
    status: userStatus;
    phone: string;
    country_id: number;
    avatar: {
      client_name: string;
      temporary_download: string;
    };
  };
  portfolio: [];
  rating: { rating: number; count: number };
};

export type chatType = {
  id: string | null;
  project_id: number;
  project: projectType;
  expert: [chatExpertType];
  founder: [chatExpertType];
  can_invoice: number | null;
  unread_messages: number | null;
};

export type singleMessageType = {
  avatar: [{ client_name: string; temporary_download: string }];
  id: string;
  chat_id: string;
  user_id: number;
  message: string;
  type: string;
  is_read: boolean;
  created_at: string;
  user_name: string;
  user_surname: string;
};

export type chatMessagesType = {
  data: [];
  meta: {
    current_page: number;
    last_page: number;
  };
};

export type chatsType = {
  availableChats: chatType[] | null;
  chatMessages: singleMessageType | null;
  chatError: Error | null;
  activeChatId: string | null;
  activeChat: null | {
    id: string;
    data: singleMessageType[];
    totalPage: number;
  };
  chatScrollTrigger: boolean;
};

const initialState = {
  availableChats: null,
  id: null,
  project_id: null,
  project: null,
  expert: null,
  can_invoice: null,
  unread_messages: null,
  chatMessages: null,
  chatError: null,
  activeChatId: null,
  activeChat: null,
  chatScrollTrigger: false,
};

export const setNewChatMessage = (
  id: string | undefined,
  messages: any,
  cb: () => void
) => {
  return {
    type: ADD_CHAT_MESSAGE,
    payload: { id, messages, cb },
  };
};

export const resetActiveChat = () => {
  return {
    type: RESET_ACTIVE_CHAT,
  };
};

export const setInvoiceTrigger = (
  projectId: any,
  triggerValue: invoiceTriggerEnum
) => {
  return {
    type: SET_INVOICE_TRIGGER,
    payload: { projectId, triggerValue },
  };
};

export const setChatScrollTrigger = (value: boolean) => {
  return {
    type: SET_CHAT_SCROLL_TRIGGER,
    payload: value,
  };
};

export const updateChatName = (projectId: number, projectName: string) => {
  return {
    type: UPDATE_CHAT_NAME,
    payload: { projectId, projectName },
  };
};

const chatReducer = (
  state: chatsType = initialState,
  action: { type: symbol | string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case fetchActiveChat.FetchStart: {
      const { activeChat } = state;
      const { id } = payload;
      if (activeChat?.data && activeChat?.id === id) {
        return {
          ...state,
          activeChat: activeChat,
        };
      }

      return {
        ...state,
        activeChat: null,
      };
    }
    case fetchActiveChat.FetchSuccess: {
      const { availableChats, activeChat } = state;
      const { data: totalData, id } = payload;
      const { data, meta } = totalData;

      const newArrayData = () => {
        if (!activeChat?.data.length) {
          return data;
        }
        const uniqueComments = [
          ...new Map(
            [...activeChat.data, ...data].map((comment) => [
              comment.id,
              comment,
            ])
          ).values(),
        ];
        return uniqueComments;
      };
      return {
        ...state,
        availableChats: availableChats?.map((item) => {
          if (item.id !== id) {
            return item;
          }
          item.unread_messages = 0;
          return item;
        }),
        activeChat: {
          id,
          data: newArrayData(),
          totalPage: meta.last_page,
        },
      };
    }

    case fetchChats.FetchSuccess:
      return {
        ...state,
        availableChats: payload,
      };
    case fetchActiveChat.FetchError:
      return {
        ...state,
        activeChat: null,
        chatError: payload,
      };
    case updateAvailableChats.FetchSuccess: {
      const { availableChats } = state;
      const { id } = payload;
      const newChat = availableChats?.length
        ? [payload, ...availableChats]
        : [payload];
      return {
        ...state,
        availableChats: newChat,
        activeChat: { id, data: [] },
      };
    }
    case ACTIVE_CHAT_ID:
      return {
        ...state,
        activeChatId: payload,
      };
    case ADD_CHAT_MESSAGE_SUCCESS: {
      const { currentChatId, messages, currentUserId } = payload;
      const { activeChat, availableChats } = state;
      const newActiveChat = activeChat ? activeChat.data : [];
      if (currentChatId === activeChat?.id) {
        return {
          ...state,
          activeChat: {
            id: currentChatId,
            data: [messages, ...newActiveChat],
            totalPage: activeChat?.totalPage,
          },
          chatScrollTrigger: true,
        };
      }
      if (messages.user_id === currentUserId) {
        return state;
      }
      return {
        ...state,
        availableChats: availableChats?.length
          ? availableChats.map((item) => {
              const { unread_messages } = item;
              if (
                item.id !== currentChatId &&
                currentUserId !== messages.user_id
              ) {
                return item;
              }
              // @ts-ignore
              item.unread_messages = +unread_messages + 1;
              return item;
            })
          : availableChats,
      };
    }
    case RESET_ACTIVE_CHAT: {
      return {
        ...state,
        activeChat: {},
      };
    }
    case SET_INVOICE_TRIGGER: {
      const { availableChats } = state;
      const { projectId, triggerValue } = payload;
      const newChats = availableChats?.map((item) => {
        if (item.project_id !== projectId) {
          return item;
        }
        item.can_invoice = triggerValue;
        return item;
      });
      return {
        ...state,
        availableChats: newChats,
      };
    }
    case SET_CHAT_SCROLL_TRIGGER: {
      return {
        ...state,
        chatScrollTrigger: payload,
      };
    }

    case UPDATE_CHAT_NAME: {
      const { availableChats } = state;
      const { projectId, projectName } = payload;
      const newChats = availableChats?.map((item) => {
        if (item.project_id !== projectId) {
          return item;
        }
        item.project.name = projectName;
        return item;
      });
      return {
        ...state,
        availableChats: newChats,
      };
    }

    default:
      return state;
  }
};

export default chatReducer;
