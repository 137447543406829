import {
  SET_USER,
  SET_AUTH_TOKENS,
  SET_USER_STATUS,
  SET_USER_WORK_AVAILABILITY,
  USER_VERIFICATION_STATE,
  USER_VERIFICATION_ID,
} from 'store/reducers/user/action';
import { JWT_ACCESS_TOKEN_KEY, JWT_REFRESH_TOKEN_KEY } from 'const';
import UserDto from 'store/reducers/user/UserDto';
import {
  UserDataStoreType,
  userStatus,
  userStoreInterface,
  userVerificationStateType,
} from 'store/reducers/user/types';

const initialState: userStoreInterface = {
  userData: null,
};

export const setUser = (user: UserDataStoreType) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const setUserStatus = (userStatus: userStatus) => {
  return { type: SET_USER_STATUS, payload: userStatus };
};

export const setUserWorkAvailability = (value: boolean) => {
  return { type: SET_USER_WORK_AVAILABILITY, payload: value };
};

export const setVerificationState = (
  verificationState: userVerificationStateType
) => {
  return {
    type: USER_VERIFICATION_STATE,
    payload: verificationState,
  };
};

export const setVerificationId = (verificationId: string) => {
  return {
    type: USER_VERIFICATION_ID,
    payload: verificationId,
  };
};

const userReducer = (
  state = initialState,
  action: { type: symbol; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return { ...state, userData: new UserDto(payload).getUserData() };
    case SET_AUTH_TOKENS:
      localStorage.setItem(JWT_ACCESS_TOKEN_KEY, payload?.access_token);
      localStorage.setItem(JWT_REFRESH_TOKEN_KEY, payload?.refresh_token);
      return {
        ...state,
      };
    case SET_USER_STATUS: {
      const { userData } = state;
      return {
        ...state,
        userData: {
          ...userData,
          status: payload,
        },
      };
    }
    case SET_USER_WORK_AVAILABILITY: {
      const { userData } = state;
      return {
        ...state,
        userData: {
          ...userData,
          workAvailability: payload,
        },
      };
    }
    case USER_VERIFICATION_STATE: {
      const { userData } = state;
      return {
        ...state,
        userData: {
          ...userData,
          verificationState: payload,
        },
      };
    }
    case USER_VERIFICATION_ID: {
      const { userData } = state;
      return {
        ...state,
        userData: {
          ...userData,
          verificationId: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
