import React from 'react';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import StatusLabel from './statusLabel';

const StatusBlock = (props: { status: string }) => {
  const { status } = props;
  const intl = useIntl();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {intl.formatMessage({ id: 'dashboard.title.title-status' })}
      </div>
      <StatusLabel label={status} />
    </div>
  );
};

export default StatusBlock;
