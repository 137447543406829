import React from 'react';
import Button, { buttonTypes } from 'componets/button';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setSingleExpertDetailsStart } from 'store/reducers/globalRequestedData/actions/singleExpertDetailsActions';
import { useNavigate } from 'react-router-dom';
import { ROUT_EXPERT_PAGE } from 'const';
import { onExpertContactClick } from 'pages/createProject/createProjectResult/footerButtons/helpers';
import { buildURLQuery } from 'services/helpers';

type FooterButtonsProps = {
  id: number;
  expertId: number;
  projectId: number | null;
};

const FooterButtons = (props: FooterButtonsProps) => {
  const { expertId, projectId, id } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const onMoreClick = () => {
    const params = { userId: id, expertDetailsId: expertId, projectId };
    dispatch(
      setSingleExpertDetailsStart(id, () => {
        navigate({
          pathname: ROUT_EXPERT_PAGE,
          search: `?${buildURLQuery(params)}`,
        });
      })
    );
  };
  return (
    <div className={styles.flexRow}>
      <Button
        onClick={onMoreClick}
        type={buttonTypes.strongUnderline}
        nativeStyles={classNames(styles.btn, styles.underlineBtn)}
      >
        {intl.formatMessage({ id: 'component.button.more-details' })}
      </Button>
      <Button
        onClick={async () => {
          await onExpertContactClick(expertId, projectId, navigate, id);
        }}
        type={buttonTypes.danger}
        nativeStyles={classNames(styles.btn, styles.dangerBtn)}
      >
        {intl.formatMessage({ id: 'component.button.contact' })}
      </Button>
    </div>
  );
};

export default FooterButtons;
