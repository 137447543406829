import {
  areaType,
  dataItemType,
  dataServicesType,
} from 'store/reducers/registerData/types';
import store from 'store';
import { itemWithNameType } from 'store/reducers/createProjects/types';
type storeItemType = areaType[] | dataItemType[] | itemWithNameType[] | null;

export const findItemById = (id: number, target: storeItemType) => {
  // @ts-ignore
  return target?.filter((targetItem: areaType | dataItemType) => {
    return +targetItem.id === +id;
  });
};

export const toBase64 = (file: File | Blob | null) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // @ts-ignore
    if (!file) {
      return false;
    }
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const openBase64Img = (base64URL: any, isPdf = false) => {
  const win = window.open('', '');
  let stringToWrite = `
    <div style="width: calc(100% + 16px); height: calc(100%  + 16px); display: flex; align-items: center; justify-content: center; margin: -8px; background: #0e0e0e;">
        <img src="${base64URL}"/>
    </div>
  `;

  if (isPdf) {
    stringToWrite = `<iframe src="${base64URL}" style="height: calc(100% + 12px); width: calc(100% + 2px); margin: -8px"/>`;
  }

  win?.document.write(stringToWrite);
  win?.document.close();
};

export const removeFromStore = (
  id: number,
  storeObjectKey: string,
  targetArray: storeItemType,
  setFunction: any
) => {
  store.dispatch(
    setFunction({
      name: storeObjectKey,
      // @ts-ignore
      value: targetArray.filter(
        (expertiseItem: areaType | dataServicesType | dataItemType) => {
          return expertiseItem.id !== id;
        }
      ),
    })
  );
};

export const addToStore = (
  id: number,
  area: string,
  storeObjectKey: string,
  targetArray: areaType[] | dataItemType[] | null,
  setFunction: any,
  selectedLimit?: number
) => {
  if (!targetArray) {
    return;
  }
  if (selectedLimit && targetArray.length > selectedLimit) {
    return;
  }
  store.dispatch(
    setFunction({
      name: storeObjectKey,
      value: [
        ...targetArray,
        {
          id,
          area,
        },
      ],
    })
  );
};

export const convertArrayObjectKeys = (array: { id: number; area: string }[]) =>
  array.map((item: { id: number; area: string }) => ({
    id: item?.id,
    name: item?.area,
  }));

export function debounce(
  func: any,
  wait: number,
  immediate: boolean = false
): () => void {
  let timeout: any;

  return function executedFunction(...args) {
    const later = function () {
      timeout = null;
      if (!immediate) {
        func(...args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func(args);
    }
  };
}

export const buildURLQuery = (obj: any) =>
  Object.entries(obj) // @ts-ignore
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&');
