import React, { useState } from 'react';
import styles from './styles.module.scss';

type SwitcherProps = {
  onSwitcherChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  disabled?: boolean;
  userId?: number;
};

const Switcher = (props: SwitcherProps) => {
  const { onSwitcherChange, isChecked = false, disabled = false } = props;
  const [switcherState, setSwitcherState] = useState(isChecked);
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    setSwitcherState(event.target.checked);
    onSwitcherChange(event);
  };
  return (
    <div className={styles.wrapper}>
      <input
        type="checkbox"
        id="switcher"
        className={styles.checkbox}
        onChange={onChanged}
        checked={switcherState}
        disabled={disabled}
      />
      <label htmlFor={'switcher'} className={styles.switcher}></label>
    </div>
  );
};

export default Switcher;
