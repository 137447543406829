import React, { ChangeEvent, useState } from 'react';
import Popup, { popupTypesEnum } from 'componets/popup';
import { userRolesEnum } from 'pages/dashboardPage/index';
import { FormattedMessage, useIntl } from 'react-intl';
import Spinner from 'componets/spiner';
import styles from './styles.module.scss';
import classNames from 'classnames';
import BudgetBlock from 'pages/dashboardPage/components/projectsBlock/projectLIst/projectListItem/budgetBlock';
import RoundImage from 'componets/roundImage';
import RateRow from 'pages/dashboardPage/components/rateRow';
import YellowStar from 'svgComponent/YellowStar';
import GrayStar from 'svgComponent/GrayStar';
import TextArea from 'componets/textArea';
import FormError from 'componets/stripeForm/FormError';
import API from 'services/API';
import { URL_EXPERT_REVIEW } from 'const';

type ReleaseInvoicePopupProps = {
  onApproveClick: () => void;
  onAbortClick: () => void;
  dataState: boolean;
  budget: number;
  expertName: string | undefined;
  projectName: string;
  rating: { id: number; rating: number; count: number };
  expertAva: string;
  canReview?: boolean;
  expertId: number;
  projectId: number;
};

const initSate = {
  projectRate: 0,
  reviewMessage: '',
  errors: {
    projectRate: false,
    reviewMessage: false,
  },
};

const ReleaseInvoicePopup = (props: ReleaseInvoicePopupProps) => {
  const {
    onApproveClick,
    onAbortClick,
    dataState,
    budget,
    expertName,
    projectName,
    rating,
    expertAva = '',
    canReview,
    expertId,
    projectId,
  } = props;

  const intl = useIntl();
  const [rateFormState, setRateFormState] = useState(initSate);
  const { projectRate, reviewMessage, errors } = rateFormState;
  const onTextAreaChange = (name: string, value: string) => {
    setRateFormState((state) => ({
      ...state,
      reviewMessage: value,
    }));
  };
  const onBntClick = async () => {
    if (!canReview) {
      onApproveClick();
      return;
    }
    if (!projectRate || reviewMessage.trim().length < 10) {
      setRateFormState((state) => {
        return {
          ...state,
          errors: {
            projectRate: !state.projectRate,
            reviewMessage: state.reviewMessage.trim().length < 10,
          },
        };
      });
      return;
    }
    await API.sendFormData(URL_EXPERT_REVIEW, {
      project_id: projectId,
      expert_id: expertId,
      rate: projectRate,
      message: reviewMessage,
    });
    await onApproveClick();
    setRateFormState(initSate);
  };
  return (
    <Popup
      name={intl.formatMessage({ id: 'dashboard.rate.popup.title' })}
      buttonLabel={intl.formatMessage({ id: 'dashboard.rate.popup.title' })}
      buttonAbortLabel={intl.formatMessage({
        id: 'component.labels.cancel',
      })}
      onButtonClick={onBntClick}
      onAbortBtnClick={onAbortClick}
      setPopupVisible={onAbortClick}
      userRoles={userRolesEnum.Founder}
      popupType={
        canReview ? popupTypesEnum.simple : popupTypesEnum.confirmChoose
      }
    >
      {dataState && (
        <div className={styles.wrapper}>
          <Spinner />
        </div>
      )}
      {!canReview && !dataState ? (
        <FormError
          message={intl.formatMessage({
            id: 'popup.popup-message.message-confirm-invoice',
          })}
        />
      ) : (
        <>
          <div className={styles.popupWrapper}>
            <div className={styles.flexRow}>
              <div className={classNames(styles.flexCol, styles.colStart)}>
                <div className={styles.titleGray}>
                  {intl.formatMessage({ id: 'component.labels.project' })}
                </div>
                <div className={styles.titleStrong}>{projectName}</div>
              </div>
            </div>
            <div className={classNames(styles.flexRow, styles.alignEnd)}>
              {budget && (
                <BudgetBlock label={budget} nativeStyles={styles.popupBudget} />
              )}
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.popupWrapper}>
            <div className={styles.flexRow}>
              <div className={styles.flexCol}>
                <RoundImage
                  src={expertAva}
                  alt={expertName || ''}
                  nativeStyles={styles.img}
                />
                {Boolean(rating?.count) && (
                  <RateRow
                    rate={rating}
                    nativeStyles={styles.rateBadge}
                    showCount={false}
                  />
                )}
              </div>
              <div className={styles.flexCol}>
                <div className={styles.titleGray}>
                  <FormattedMessage id="dashboard.rate.popup.expert" />
                </div>
                <div className={styles.expertName}>{expertName}</div>
              </div>
            </div>
            <div className={classNames(styles.flexCol, styles.alignEnd)}>
              <div className={styles.titleGray}>
                <FormattedMessage id="dashboard.rate.popup.rate.expert" />
              </div>
              <div className={styles.rateSection}>
                {Array.from(Array(5).keys()).map((item) =>
                  item + 1 <= projectRate ? (
                    <YellowStar
                      key={item}
                      width={14}
                      height={14}
                      onClick={() => {
                        setRateFormState((state) => ({
                          ...state,
                          projectRate: item,
                        }));
                      }}
                    />
                  ) : (
                    <GrayStar
                      key={item}
                      width={14}
                      height={14}
                      onClick={() => {
                        setRateFormState((state) => ({
                          ...state,
                          projectRate: item + 1,
                        }));
                      }}
                    />
                  )
                )}
              </div>
              {errors.projectRate && (
                <p className={styles.error}>
                  {intl.formatMessage({
                    id: 'dashboard.button.error-rate-message',
                  })}
                </p>
              )}
            </div>
          </div>
          <div className={classNames(styles.titleGray, styles.textareaTitle)}>
            <FormattedMessage id="dashboard.rate.popup.textarea.label" />
            {errors.reviewMessage && (
              <p className={styles.error}>
                {intl.formatMessage({
                  id: 'dashboard.button.error-text-message',
                })}
              </p>
            )}
          </div>
          <TextArea
            name="startups_achieve"
            nativeStyles={styles.textarea}
            placeholder={intl.formatMessage({
              id: 'dashboard.rate.popup.textarea.placeholder',
            })}
            value={reviewMessage}
            onTextAreaChange={onTextAreaChange}
          />
        </>
      )}
    </Popup>
  );
};

export default ReleaseInvoicePopup;
