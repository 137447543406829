import React, { useEffect } from 'react';
import RegisterLargeLabel from 'pages/registrationPage/components/registerLargeLabel';
import ContentRow from 'componets/contentRow';
import { useDispatch, useSelector } from 'react-redux';
import { setAreaExpertiseStart } from 'store/reducers/registerData/actions/areaExpertises';
import {
  getAreaExpertises,
  getUserExpertise,
} from 'store/reducers/registerData/selector';
import { addToStore, findItemById, removeFromStore } from 'services/helpers';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';

const SecondFormStep = () => {
  const dispatch = useDispatch();
  const areaExpertises = useSelector(getAreaExpertises);
  const userExpertise = useSelector(getUserExpertise);

  const onLabelClick = (title: string, id: number) => {
    const isSelected = findItemById(id, userExpertise);

    isSelected.length
      ? removeFromStore(id, 'areaExpertises', userExpertise, setFormData)
      : addToStore(id, title, 'areaExpertises', userExpertise, setFormData, 2);
  };

  useEffect(() => {
    dispatch(setAreaExpertiseStart());
  }, []);

  useEffect(() => {
    dispatch(setRegisterStepError(!userExpertise.length));
  }, [userExpertise.length]);

  return (
    <ContentRow>
      <>
        {areaExpertises.map((btnItem: { id: number; area: string }) => {
          const { id, area } = btnItem;
          const isSelected = findItemById(id, userExpertise);
          return (
            <RegisterLargeLabel
              disabled={userExpertise.length >= 3}
              id={id}
              isSelected={!!isSelected.length}
              title={area}
              onClick={onLabelClick}
              key={`${area.toString()}_${id.toString()}`}
            />
          );
        })}
      </>
    </ContentRow>
  );
};

export default SecondFormStep;
