import React from 'react';
import styles from './styles.module.scss';
import { userVerificationStateType } from 'store/reducers/user/types';
import { FormattedMessage } from 'react-intl';
import { AUTH_STATES, URL_RESET_VERIFICATION } from 'const';
import ButtonLink from 'componets/buttonLink';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserVerificationId } from 'store/reducers/user/selector';
import API from 'services/API';
import store from 'store';
import { setVerificationState } from 'store/reducers/user';

const verificationMessages = {
  1: {
    title: 'verification.verified',
    message: 'verification.verified.message',
  },
  2: {
    title: '',
    message: 'verification.already',
  },
  3: {
    title: 'verification.expired',
    message: 'verification.expired.message',
  },
  4: {
    title: 'verification.wait-confirmation',
    message: 'verification.wait-confirmation.message',
  },
  5: {
    title: 'verification.not-verified',
    message: 'verification.not-verified.message',
  },
  6: {
    title: 'verification.rejected',
    message: 'verification.rejected-message',
  },
  7: {
    title: '',
    message: 'verification.verified-resend.message',
  },
};

const onRestoreClick = async (id: string | undefined) => {
  if (!id) {
    return;
  }
  API.sendFormData(URL_RESET_VERIFICATION, { id }).then((response) => {
    store.dispatch(setVerificationState(7));
  });
};

const VerificationMessages = (props: { verificationState: any }) => {
  const { verificationState } = props;
  const verificationId = useSelector(getUserVerificationId);

  // @ts-ignore
  const messageObj = verificationMessages[verificationState];
  return (
    <div className={styles.verificationBlock}>
      {Boolean(messageObj?.title) && (
        <div className={styles.verificationBlockStatus}>
          <FormattedMessage id={messageObj?.title} />
        </div>
      )}
      <div className={styles.verificationBlockMessage}>
        <FormattedMessage id={messageObj?.message} />
      </div>
      {verificationState === AUTH_STATES.RESET && (
        <ButtonLink
          onClick={async () => {
            await onRestoreClick(verificationId);
          }}
          nativeStyles={styles.verificationBlockLink}
        >
          <FormattedMessage id="verification.expired.link" />
        </ButtonLink>
      )}
    </div>
  );
};

export default VerificationMessages;
