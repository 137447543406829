import { fetchInvoiceSingle } from 'store/reducers/globalRequestedData/actions';

export const setInvoiceSingleStart = (id: number) => {
  return {
    type: fetchInvoiceSingle.FetchStart,
    payload: id,
  };
};

export const setInvoiceSingleSuccess = (data: any) => {
  return {
    type: fetchInvoiceSingle.FetchSuccess,
    payload: data,
  };
};

export const setInvoiceSingleError = (error: Error) => {
  return {
    type: fetchInvoiceSingle.FetchError,
    payload: error,
  };
};
