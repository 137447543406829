export enum currentStepSelectorKeys {
  register,
  createProject,
}

export enum projectStatusEnum {
  draft = 'Live',
  waiting_for_payment = 'Waiting for payment',
  in_escrow = 'In escrow',
  paid_out = 'Payout sent',
  processing = 'Processing payout',
}

export enum createInvoiceInputError {
  service_price = 'service_price',
  address = 'address',
}
