import React from 'react';
import styles from './styles.module.scss';
import CrossIcon from 'svg-icons/CrossIcon';

interface CrossIconProps {
  onClick(): void;
}

const CrossButton = (props: CrossIconProps) => {
  const { onClick } = props;

  return (
    <button className={styles.cross} onClick={onClick}>
      <CrossIcon />
    </button>
  );
};

export default CrossButton;
