import { areaType } from 'store/reducers/registerData/types';
import { fetchAreaExpertise } from 'store/reducers/registerData/actions';

export function setAreaExpertiseStart() {
  return {
    type: fetchAreaExpertise.FetchStart,
  };
}

export function setAreaExpertiseSuccess(dataExpertiseArea: areaType[]) {
  return {
    type: fetchAreaExpertise.FetchSuccess,
    payload: dataExpertiseArea,
  };
}

export function setAreaExpertiseError(error: Error) {
  return {
    type: fetchAreaExpertise.FetchError,
    payload: error,
  };
}
