import { fetchAchievesSkills } from 'store/reducers/registerData/actions';
import { userAchievesType } from 'store/reducers/registerData/types';

export function setAchievesSkillsStart(data: {
  queryDataKey: string;
  stateAchievesSkillsKey: string;
}) {
  return {
    type: fetchAchievesSkills.FetchStart,
    payload: data,
  };
}

export function setAchievesSkillsSuccess(
  stateAchievesSkillsKey: string,
  data: userAchievesType[]
) {
  return {
    type: fetchAchievesSkills.FetchSuccess,
    payload: { name: stateAchievesSkillsKey, value: data },
  };
}

export function setAchievesSkillsError(error: Error) {
  return {
    type: fetchAchievesSkills.FetchError,
    payload: error,
  };
}
