import React, { useEffect, useState } from 'react';
import SelectDropdown from 'componets/selectDropdown';
import styles from 'componets/registration/formStepsContent/fourthFormStep/styles.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserCityLocation,
  getUserCityLocationList,
  getUserCountryLocation,
  getUserCountryLocationList,
  getUserRegionLocation,
  getUserRegionLocationList,
} from 'store/reducers/registerData/selector';
import {
  setCityUserLocationStart,
  setRegionUserLocationStart,
  setCountryUserLocationStart,
} from 'store/reducers/registerData/actions/userLocationList';
import { useIntl } from 'react-intl';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';

const optionsConfigList: {
  [a: string]: (query: string) => { type: string; payload: string };
} = {
  userCountry: setRegionUserLocationStart,
  userRegion: setCityUserLocationStart,
};

const FourthFormStep = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [queryParams, setQueryParams] = useState('');
  const userCountryList = useSelector(getUserCountryLocationList);
  const userCityList = useSelector(getUserCityLocationList);
  const userRegionList = useSelector(getUserRegionLocationList);

  useEffect(() => {
    dispatch(setCountryUserLocationStart());
  }, []);

  useEffect(() => {
    if (!queryParams) {
      return;
    }

    const [name, value] = queryParams.split('|');

    const getOptionsFn = optionsConfigList[name];

    if (typeof getOptionsFn !== 'function') {
      return;
    }

    dispatch(getOptionsFn(value));
  }, [dispatch, queryParams]);

  const userCountry = useSelector(getUserCountryLocation);
  const userCity = useSelector(getUserCityLocation);
  const userRegion = useSelector(getUserRegionLocation);

  useEffect(() => {
    dispatch(setRegisterStepError(Boolean(!userCity?.id || !userCountry?.id)));
  }, [userCity?.id, userCountry?.id, userRegion?.id]);

  const onSelectCountryChange = (value: string, label: string) => {
    dispatch(
      setFormData({
        name: 'userCountry',
        value: {
          id: Number.parseInt(value),
          name: label,
        },
      })
    );
    dispatch(
      setFormData({
        name: 'userCity',
        value: {
          id: undefined,
          name: undefined,
        },
      })
    );

    dispatch(
      setFormData({
        name: 'userRegion',
        value: {
          id: undefined,
          name: undefined,
        },
      })
    );

    setQueryParams(`userCountry|${value}`);
  };

  const onSelectRegionChange = (value: string, label: string) => {
    dispatch(
      setFormData({
        name: 'userRegion',
        value: {
          id: Number.parseInt(value),
          name: label,
        },
      })
    );

    dispatch(
      setFormData({
        name: 'userCity',
        value: {
          id: undefined,
          name: undefined,
        },
      })
    );

    setQueryParams(`userRegion|${value}`);
  };

  const onSelectCityChange = (value: string, label: string) => {
    dispatch(
      setFormData({
        name: 'userCity',
        value: {
          id: Number.parseInt(value),
          name: label,
        },
      })
    );
  };

  return (
    <div className={styles.row}>
      <div className={classNames(styles.wrapper, styles.mr)}>
        <SelectDropdown
          options={userCountryList}
          onSelectChange={onSelectCountryChange}
          placeholder={intl.formatMessage({
            id: 'register.step-3.country-select-placeholder',
          })}
          value={{
            value: userCountry?.id,
            label: userCountry?.name,
          }}
        />
      </div>
      <div className={styles.wrapper}>
        <SelectDropdown
          options={userRegionList}
          onSelectChange={onSelectRegionChange}
          placeholder={intl.formatMessage({
            id: 'register.step-3.region-select-placeholder',
          })}
          value={{
            value: userRegion?.id,
            label: userRegion?.name,
          }}
        />
      </div>
      <div className={styles.wrapper}>
        <SelectDropdown
          options={userCityList}
          onSelectChange={onSelectCityChange}
          placeholder={intl.formatMessage({
            id: 'register.step-3.city-select-placeholder',
          })}
          value={{
            value: userCity?.id,
            label: userCity?.name,
          }}
        />
      </div>
    </div>
  );
};

export default FourthFormStep;
