import React from 'react';
import { userRolesEnum } from 'pages/dashboardPage';
import styles from 'componets/appHeader/styles.module.scss';
import ButtonLink from 'componets/buttonLink';
import classNames from 'classnames';
import {
  ROUT_CREATE_PROJECT,
  ROUT_EXPERT_PAGE,
  ROUT_HOME,
  ROUT_USER_PAGE,
} from 'const';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { onExpertContactClick } from 'pages/createProject/createProjectResult/footerButtons/helpers';

const HeaderButtonsSection = () => {
  const user = useSelector(getUser);
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const pageParams = useParams();
  const params = location.search;
  const search = new URLSearchParams(params);
  const isExpertPage =
    // @ts-ignore
    location.pathname === `${ROUT_USER_PAGE}/${pageParams?.userId}` ||
    location.pathname === ROUT_EXPERT_PAGE;
  const getBtnLabelText = () => {
    // @ts-ignore
    if (location?.state?.prevState === ROUT_HOME.toString()) {
      return intl.formatMessage({ id: 'component.button.back-dashboard' });
    }
    return location.pathname === ROUT_EXPERT_PAGE
      ? intl.formatMessage({ id: 'component.button.back-expert' })
      : intl.formatMessage({ id: 'component.button.back-chat' });
  };
  return (
    <>
      {
        // @ts-ignore
        user?.roles?.name === userRolesEnum.Founder &&
        location.pathname === '/' ? (
          <div className={styles.leftMargin}>
            <ButtonLink
              nativeStyles={classNames(styles.btn, styles.btnDanger)}
              path={ROUT_CREATE_PROJECT}
            >
              {intl.formatMessage({ id: 'component.button.find-expert' })}
            </ButtonLink>
          </div>
        ) : null
      }
      {
        // @ts-ignore
        user?.roles?.name === userRolesEnum.Founder && isExpertPage ? (
          <div className={styles.leftMargin}>
            <ButtonLink
              nativeStyles={classNames(styles.btn, styles.btnTransparent)}
              onClick={() => {
                navigate(-1);
              }}
            >
              {getBtnLabelText()}
            </ButtonLink>
            {location.pathname === ROUT_EXPERT_PAGE && (
              <ButtonLink
                nativeStyles={classNames(styles.btn, styles.btnDanger)}
                onClick={async () => {
                  await onExpertContactClick(
                    search.get('expertDetailsId'),
                    search.get('projectId'),
                    navigate,
                    search.get('userId')
                  );
                }}
              >
                {intl.formatMessage({
                  id: 'component.button.contact-expert',
                })}
              </ButtonLink>
            )}
          </div>
        ) : null
      }
    </>
  );
};

export default HeaderButtonsSection;
