import React from 'react';
import styles from './styles.module.scss';

type TagItemProps = {
  title: string;
};

const TagItem = (props: TagItemProps) => {
  const { title = '' } = props;
  return <div className={styles.tag}>{title}</div>;
};

export default TagItem;
