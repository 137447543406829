import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import InputRow from 'componets/inputRow';
import { useDispatch, useSelector } from 'react-redux';
import { setNewProjectData } from 'store/reducers/createProjects';
import {
  getNewProjectBudget,
  getNewProjectMaxRate,
  getNewProjectMinRate,
  getNewProjectSkills,
} from 'store/reducers/createProjects/selector';
import { getGlobalSkillsData } from 'store/reducers/globalRequestedData/selectors';
import RegistrationLabelList from 'pages/registrationPage/components/registrationLabelList';
import { setRegisterStepError } from 'store/reducers/registerData';

const CreateProjectSecondStep = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const minRate = useSelector(getNewProjectMinRate);
  const maxRate = useSelector(getNewProjectMaxRate);
  const budget = useSelector(getNewProjectBudget);
  const availableSkills = useSelector(getGlobalSkillsData);
  const selectedSkills = useSelector(getNewProjectSkills);
  const onInputChange = (objectStoreKey: string, value: string) => {
    const parseValue = Number.parseInt(value);
    dispatch(
      setNewProjectData({
        name: objectStoreKey,
        value: parseValue,
      })
    );
  };
  useEffect(() => {
    dispatch(
      setRegisterStepError(
        !minRate ||
          !maxRate ||
          +minRate >= +maxRate ||
          Boolean(!budget) ||
          !selectedSkills?.length
      )
    );
  }, [minRate, maxRate, budget, selectedSkills?.length]);
  return (
    <div className={styles.flexCol}>
      <h2 className={styles.title}>
        {intl.formatMessage({
          id: 'create-new-project.step-2.row-title-approximate',
        })}
      </h2>
      <div className={styles.inputWrapper}>
        <InputRow
          placeholder="input-placeholder.label-from"
          name="minRate"
          onChange={onInputChange}
          nativeStyles={classNames(styles.inputRate)}
          type="number"
          Icon="$"
          value={minRate}
        />
        <div className={styles.delimiter}></div>
        <InputRow
          placeholder="input-placeholder.label-to"
          name="maxRate"
          onChange={onInputChange}
          nativeStyles={classNames(styles.inputRate)}
          type="number"
          Icon="$"
          value={maxRate}
        />
      </div>
      <h2 className={styles.title}>
        {intl.formatMessage({
          id: 'create-new-project.step-2.row-title-maximum',
        })}
      </h2>
      <div className={styles.inputWrapper}>
        <InputRow
          placeholder="input-placeholder.label-budget"
          name="budget"
          onChange={onInputChange}
          nativeStyles={classNames(styles.inputBudget)}
          type="number"
          Icon="$"
          value={budget}
        />
      </div>
      <h2 className={styles.title}>
        {intl.formatMessage({
          id: 'create-new-project.step-2.row-title-skills',
        })}
      </h2>
      <div className={classNames(styles.inputWrapper, styles.flexWrap)}>
        <RegistrationLabelList
          labelsArray={availableSkills}
          usersLabelList={selectedSkills}
          storeObjectKey="skills"
          showInputRow={false}
          setFunction={setNewProjectData}
        />
      </div>
    </div>
  );
};

export default CreateProjectSecondStep;
