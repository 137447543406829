import React from 'react';
import Heading from 'pages/dashboardPage/components/projectsBlock/heading';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage/index';
import ProjectLIst from 'pages/dashboardPage/components/projectsBlock/projectLIst';

const ProjectBlock = () => {
  // @ts-ignore
  const { roles } = useSelector(getUser);
  return (
    <div>
      <Heading
        label={
          roles === userRolesEnum.Expert
            ? 'dashboard.title.title-projects'
            : 'dashboard.title.title-invoices'
        }
        roles={roles.name}
      />
      <ProjectLIst />
    </div>
  );
};
export default ProjectBlock;
