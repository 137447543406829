import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import ButtonLink from 'componets/buttonLink';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';

export interface NumberedListItemInterface {
  index: number;
  currentStep: number;
  onLinkClick: () => void;
  nativeContentStyles?: string;
}

export interface NumberedListConfigInterface {
  title: string;
}

const NumberedListItem = (
  props: NumberedListItemInterface & NumberedListConfigInterface
) => {
  const {
    title = '',
    nativeContentStyles = '',
    onLinkClick = () => {},
    index,
    currentStep,
  } = props;
  const user = useSelector(getUser);
  const intl = useIntl();
  return (
    <li
      className={classNames(
        styles.sidebarItem,
        { [styles.sidebarItemCurrent]: currentStep === index + 1 },
        {
          [styles.sidebarItemDoneExpert]:
            currentStep > index + 1 &&
            // @ts-ignore
            user?.roles.name === userRolesEnum.Expert,
        },
        {
          [styles.sidebarItemDoneFounder]:
            currentStep > index + 1 &&
            // @ts-ignore
            user?.roles.name === userRolesEnum.Founder,
        },
        nativeContentStyles
      )}
      key={index}
    >
      <ButtonLink onClick={onLinkClick}>
        <div className={styles.counter}>{+index + 1}</div>
        {intl.formatMessage({ id: title })}
      </ButtonLink>
    </li>
  );
};

export default NumberedListItem;
