import { avatarType, rolesType, userDataType } from 'store/reducers/user/types';
import { expertDetailsType } from 'store/reducers/createProjects/types';

class UserDto {
  private readonly id: number;
  private readonly name: string;
  private readonly surname: string;
  private readonly email: string;
  private readonly status: string;
  private readonly phone: string;
  private readonly country_id: number;
  private readonly roles: rolesType[];
  private readonly avatar: avatarType[];
  private readonly expert_detail: expertDetailsType;

  constructor(rawUser: userDataType) {
    this.id = rawUser?.id;
    this.name = rawUser?.name;
    this.surname = rawUser?.surname;
    this.email = rawUser?.email;
    this.status = rawUser?.status;
    this.phone = rawUser?.phone;
    this.country_id = rawUser?.country_id;
    this.roles = rawUser?.roles;
    this.avatar = rawUser?.avatar;
    this.expert_detail = rawUser.expert_detail;
  }

  getUserData() {
    return {
      id: this.getId(),
      name: this.getName(),
      surname: this.getSurname(),
      email: this.getEmail(),
      status: this.getStatus(),
      phone: this.getPhone(),
      country_id: this.getCountryId(),
      roles: this.getRoles(),
      avatar: this.getAvatar(),
      expertDetails: this.getUserExpertDetails(),
      workAvailability: this.getUserWorkAvailability(),
      userExpertDetailsId: this.getUserExpertDetailsId(),
    };
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getSurname() {
    return this.surname;
  }

  getEmail() {
    return this.email;
  }

  getStatus() {
    return this.status;
  }

  getPhone() {
    return this.phone;
  }

  getCountryId() {
    return this.country_id;
  }

  getRoles() {
    return this.roles?.map((role) => ({
      id: role.id,
      name: role.name,
    }))[0];
  }

  getAvatar() {
    return this.avatar?.map((avatar) => ({
      client_name: avatar.client_name,
      temporary_download: avatar.temporary_download,
    }))[0];
  }

  getUserExpertDetails() {
    return this.expert_detail ?? {};
  }

  getUserWorkAvailability() {
    return this.expert_detail?.available_to_work ?? 0;
  }

  getUserExpertDetailsId() {
    return this.expert_detail?.id;
  }
}

export default UserDto;
