class StorageService {
  private readonly storage: any;
  constructor(localStorage: any) {
    this.storage = localStorage;
  }

  public async getByKey(key: string) {
    try {
      return this.storage.getItem(key);
    } catch (error) {
      console.log('get from storage failed', error);
    }
  }

  public async setByKey(key: string, value: string) {
    try {
      return this.storage.setItem(key, value);
    } catch (error) {
      console.log('Set to storage failed', error);
    }
  }

  public async deleteByKey(...args: any) {
    try {
      args.map((storageKey: string) => {
        return this.storage.removeItem(storageKey);
      });
    } catch (error) {
      console.log('Delete from storage failed', error);
    }
  }
}

export default new StorageService(localStorage);
