import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface ButtonLinkProps {
  children: any;
  path?: any;
  onClick?: (ev: React.MouseEvent) => void;
  nativeStyles?: string;
  externalType?: boolean;
  options?: any;
}

const ButtonLink = (props: ButtonLinkProps) => {
  const {
    children,
    path = null,
    onClick = () => {},
    nativeStyles = '',
    externalType = false,
    options = '',
  } = props;

  const propsParams = {
    onClick,
    className: classNames(nativeStyles),
    to: '',
  };

  const Tag = path ? Link : 'div';

  if (path) {
    propsParams.to = path;
  }
  if (externalType) {
    return (
      <a href={`${path}`} className={nativeStyles}>
        {children}
      </a>
    );
  }

  return (
    <Tag {...propsParams} state={options}>
      {children}
    </Tag>
  );
};

export default ButtonLink;
