import { URL_CHAT_MESSAGES, URL_CHAT, URL_CHAT_MESSAGES_UPDATE } from 'const';
import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import API from 'services/API';
import {
  setAvailableChatsError,
  setAvailableChatsSuccess,
  setUpdateAvailableChatsError,
  setUpdateAvailableChatsSuccess,
} from 'store/reducers/chat/actions/availableChats';
import {
  ADD_CHAT_MESSAGE,
  ADD_CHAT_MESSAGE_SUCCESS,
  fetchActiveChat,
  fetchChats,
  SET_CHAT_SCROLL_TRIGGER,
  SET_INVOICE_TRIGGER,
  updateAvailableChats,
} from 'store/reducers/chat/actions';
import {
  setActiveChatError,
  setActiveChatSuccess,
} from 'store/reducers/chat/actions/chatMessages';
import { getPusherClient } from 'store/reducers/general/selectors';
import store from 'store/index';
import { getUser } from 'store/reducers/user/selector';
const initListener = (chat: any, userId: number) => {
  const client = getPusherClient(store.getState());
  client
    .subscribe(`chat-${chat.id}`)
    .bind('send-message', async (messages: any) => {
      const [message] = messages;
      const { user_id: senderId } = message;

      const state: any = store.getState();
      const activeChat = state.chatData.activeChat;

      if (senderId !== userId && activeChat?.id === chat.id) {
        await API.sendFormData(`${URL_CHAT_MESSAGES_UPDATE}`, {
          chat_id: chat.id,
        });
      }

      store.dispatch({
        type: ADD_CHAT_MESSAGE_SUCCESS,
        payload: {
          currentChatId: chat.id,
          currentUserId: userId,
          messages: message,
        },
      });
    })
    .bind(`create-invoice`, (response: any) => {
      if (!response) {
        return;
      }
      store.dispatch({
        type: SET_INVOICE_TRIGGER,
        payload: { projectId: response[0].project.id, triggerValue: 1 },
      });
    });
};

export function* fetchAvailableChats({ payload }: any) {
  try {
    const { page = 1, currentUserId } = payload;
    if (!currentUserId) {
      return;
    }
    const path = `${URL_CHAT}`;
    // @ts-ignore
    const data = yield call(API.getStaticApiData, path);
    try {
      data.forEach((item: any) => {
        initListener(item, currentUserId);
      });
    } catch (error) {
      console.log('error listener', error);
    }
    yield put(setAvailableChatsSuccess(data));
  } catch (error: any) {
    yield put(setAvailableChatsError(error));
  }
}

export function* fetchChatMessages({ payload }: any) {
  try {
    const { id, page = 1, cb } = payload;
    const path = `${URL_CHAT_MESSAGES}?filter[chat_id]=${id}&limit=10&page=${page}`;

    // @ts-ignore
    const data = yield call(API.getStaticApiData, path, true);
    // @ts-ignore
    yield put(setActiveChatSuccess({ id, data }));
    yield call(cb);
  } catch (error: any) {
    yield put(setActiveChatError(error));
  }
}

export function* updateChatMessage({ payload }: any) {
  try {
    const { id, messages, cb } = payload;
    // @ts-ignore
    yield call(API.sendFormData, URL_CHAT_MESSAGES, {
      chat_id: id,
      message: messages,
    });
    yield call(cb);
  } catch (error: any) {
    yield put(setActiveChatError(error));
  }
}

export function* updateAvailableChatsSaga({ payload, ...arg }: any) {
  // @ts-ignore
  const project = yield select(getUser);
  try {
    const { expert_id, project_id, id, cb } = payload;
    // @ts-ignore
    const response = yield call(API.sendFormData, URL_CHAT, {
      expert_id,
      project_id,
    });
    const { data } = response;
    initListener(data, project.id);
    yield delay(1000);
    yield put(setUpdateAvailableChatsSuccess(data));
    yield call(cb);
  } catch (error: any) {
    yield put(setUpdateAvailableChatsError(error));
  }
}

export function* chatRootSaga() {
  yield takeLatest(fetchChats.FetchStart, fetchAvailableChats);
  yield takeLatest(fetchActiveChat.FetchStart, fetchChatMessages);
  yield takeLatest(ADD_CHAT_MESSAGE, updateChatMessage);
  yield takeLatest(updateAvailableChats.FetchStart, updateAvailableChatsSaga);
}
