import * as React from 'react';
import { SVGProps } from 'react';

const Invoices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.75 12h-4.5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5Zm-3-4.5h1.5a.75.75 0 1 0 0-1.5h-1.5a.75.75 0 1 0 0 1.5Zm9 1.5H13.5V2.25a.75.75 0 0 0-1.125-.653l-2.25 1.29-2.25-1.29a.75.75 0 0 0-.75 0l-2.25 1.29-2.25-1.29a.75.75 0 0 0-1.125.652v12a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-4.5a.75.75 0 0 0-.75-.75Zm-12 6a.75.75 0 0 1-.75-.75V3.546l1.5.855a.81.81 0 0 0 .75 0l2.25-1.29 2.25 1.29a.81.81 0 0 0 .75 0l1.5-.855v10.702c.002.256.048.51.135.75H3.75ZM15 14.25a.75.75 0 1 1-1.5 0V10.5H15v3.75ZM9.75 9h-4.5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5Z"
      fill="#2C4877"
    />
  </svg>
);

export default Invoices;
