import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ProjectListItem from './projectListItem';
import { projectType } from 'store/reducers/globalRequestedData';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { getFounderProjectData } from 'store/reducers/globalRequestedData/selectors';
import { setFounderProjectStart } from 'store/reducers/globalRequestedData/actions/projectActions';
import ProjectPlaceholder from 'pages/dashboardPage/components/projectPlaceholder';
import Spinner from 'componets/spiner';

const ProjectLIst = () => {
  const projectsData = useSelector(getFounderProjectData);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!user) {
      return;
    }
    dispatch(
      setFounderProjectStart(user.id, () => {
        setLoading(false);
      })
    );
  }, [projectsData?.length]);

  if (loading) {
    return (
      <div className={styles.topMargin}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.topMargin}>
      {projectsData?.length && !loading ? (
        projectsData?.map((dataItem: projectType, index: number) => {
          return (
            <ProjectListItem
              key={`project_list_${index.toString()}`}
              itemData={dataItem}
            />
          );
        })
      ) : (
        <ProjectPlaceholder />
      )}
    </div>
  );
};

export default ProjectLIst;
