import {
  fetchGlobalSkills,
  RESET_REQUESTED_DATA,
} from 'store/reducers/globalRequestedData/actions';
import { userAchievesType } from 'store/reducers/registerData/types';

export const setGlobalSkillsDataStart = (data: {
  stateKey: string;
  dependentType: string;
}) => {
  return {
    type: fetchGlobalSkills.FetchStart,
    payload: data,
  };
};

export const setGlobalSkillsDataSuccess = (
  stateAchievesSkillsKey: string,
  data: userAchievesType[]
) => {
  return {
    type: fetchGlobalSkills.FetchSuccess,
    payload: { name: stateAchievesSkillsKey, value: data },
  };
};

export const setGlobalSkillsError = (value: Error) => {
  return {
    type: fetchGlobalSkills.FetchError,
    payload: value,
  };
};
