import React, { useState } from 'react';
import styles from './styles.module.scss';
import UserBlock from 'pages/chatPage/chatPageAside/chatPreviewList/userBlock';
import Button, { buttonTypes } from 'componets/button';
import { useIntl } from 'react-intl';
import RateBlock from 'componets/rateBlock';
import MoreDots from 'svgComponent/MoreDots';
import { userRolesEnum } from 'pages/dashboardPage';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import classNames from 'classnames';
import { projectType } from 'store/reducers/globalRequestedData';
import { ROUT_HOME, URL_CHAT, URL_POST_INVOICE } from 'const';
import API from 'services/API';
import { setGlobalProjectStart } from 'store/reducers/globalRequestedData/actions/projectActions';
import CreateInvoice from 'componets/popup/createInvoice';
import FormSuccess from 'componets/stripeForm/formSuccess';
import FormError from 'componets/stripeForm/FormError';
import { getGlobalProjectData } from 'store/reducers/globalRequestedData/selectors';
import { getInvoiceData } from 'store/reducers/invoice/selectors';
import store from 'store';
import { useNavigate } from 'react-router-dom';
import Popup, { popupTypesEnum } from 'componets/popup';
import { setInvoiceTrigger } from 'store/reducers/chat';
import NotifyLabel, {
  notifyLabelType,
} from 'pages/dashboardPage/components/projectsBlock/projectLIst/projectListItem/notifyLabel';
import ViewProfileDropdown from 'componets/viewProfileDropdown';
import BudgetBlock from 'pages/dashboardPage/components/projectsBlock/projectLIst/projectListItem/budgetBlock';
import { createInvoiceInputError } from 'services/enums';
import { invoiceContentState } from 'componets/invoicePayment';

export enum invoiceTriggerEnum {
  interact,
  invoice,
  waitingForInvoice,
  confirmed,
}

type ChatHeaderProps = {
  data: any;
  project: projectType | undefined;
  invoiceTrigger: invoiceTriggerEnum | null | undefined;
  minRate?: string | number | null;
  maxRate?: string | number | null;
  chatId?: string | undefined;
  opponentId: number | undefined;
  expertId?: number;
};

const initPopupState = {
  isVisible: false,
  popupContentState: invoiceContentState.invoiceVisible,
  contentMessage: '',
  inputError: false,
};

const initInvoiceState = {
  labelVisible: false,
  error: false,
  success: false,
  loading: false,
};

const ChatHeader = (props: ChatHeaderProps) => {
  const {
    data,
    project,
    maxRate = null,
    minRate = null,
    invoiceTrigger = invoiceTriggerEnum.interact,
    chatId,
    opponentId,
    expertId,
  } = props;

  const currentUser = useSelector(getUser);
  const globalProjectData = useSelector(getGlobalProjectData);
  const navigate = useNavigate();
  const intl = useIntl();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [popupState, setPopupState] = useState(initPopupState);
  const [invoiceState, setInvoiceState] = useState(initInvoiceState);
  const { isVisible, popupContentState, contentMessage } = popupState;
  const [invoiceInputError, setInvoiceInputError] = useState({
    [createInvoiceInputError.service_price]: false,
    [createInvoiceInputError.address]: false,
  });
  const onPopupButtonClick = () => {
    if (popupContentState === invoiceContentState.invoiceVisible) {
      const data = getInvoiceData(store.getState());
      const { service_price, address } = data;
      if (!service_price || !address) {
        setInvoiceInputError((state) => ({
          ...state,
          [createInvoiceInputError.service_price]: !service_price,
          [createInvoiceInputError.address]: !address,
        }));
        return;
      }
      setInvoiceInputError((state) => ({
        ...state,
        [createInvoiceInputError.service_price]: false,
        [createInvoiceInputError.address]: false,
      }));

      API.sendFormData(`${URL_POST_INVOICE}`, data).then((result) => {
        if (!result.message) {
          setPopupState((state) => ({
            ...state,
            popupContentState: invoiceContentState.messageSuccess,
            contentMessage: '',
          }));
          dispatch(
            setInvoiceTrigger(project?.id, invoiceTriggerEnum.waitingForInvoice)
          );
          return;
        }
        const message = Object.keys(result.errors).map((key) => {
          return result.errors[key][0];
        });

        setPopupState((state) => ({
          ...state,
          popupContentState: invoiceContentState.messageError,
          contentMessage: message.join(','),
        }));
      });
    }
    if (popupContentState === invoiceContentState.messageSuccess) {
      navigate(ROUT_HOME, { replace: true });
      setPopupState((state) => ({
        ...state,
        ...initPopupState,
      }));
    }
    if (popupContentState === invoiceContentState.messageError) {
      setPopupState((state) => ({
        ...state,
        popupContentState: invoiceContentState.invoiceVisible,
        contentMessage: '',
      }));
    }
  };

  // @ts-ignore
  const isFounder = currentUser?.roles.name === userRolesEnum.Founder;
  if (!data) {
    return null;
  }
  // @ts-ignore
  const userRoles = currentUser?.roles?.name;
  const src = isFounder
    ? // @ts-ignore
      data[0]?.user?.avatar[0]?.temporary_download
    : // @ts-ignore
      data?.avatar[0]?.temporary_download;
  const getPopupContent = (contentState: invoiceContentState) => {
    switch (contentState) {
      case invoiceContentState.invoiceVisible:
        return (
          <CreateInvoice
            projectData={globalProjectData}
            fieldError={invoiceInputError}
            setInvoiceInputError={setInvoiceInputError}
          />
        );
      case invoiceContentState.messageSuccess:
        return (
          <FormSuccess
            title={intl.formatMessage({
              id: 'popup.popup-title.title-success-expert',
            })}
            message={intl.formatMessage({
              id: 'popup.popup-message.message-success-expert',
            })}
          />
        );
      case invoiceContentState.messageError:
        return <FormError message={contentMessage} />;
      default:
        return (
          <CreateInvoice
            projectData={globalProjectData}
            fieldError={invoiceInputError}
            setInvoiceInputError={setInvoiceInputError}
          />
        );
    }
  };

  const checkChatsBeforeInteract = async (
    chatId: string | undefined,
    invoiceCurrentTrigger: invoiceTriggerEnum,
    invoiceNextTrigger: invoiceTriggerEnum
  ) => {
    if (!chatId) {
      return;
    }
    const result = await API.getStaticApiData(`${URL_CHAT}/${chatId}`);

    if (result.can_invoice > invoiceCurrentTrigger) {
      setInvoiceState((state) => ({
        ...state,
        labelVisible: true,
        error: true,
      }));
      dispatch(setInvoiceTrigger(project?.id, invoiceNextTrigger));
      setTimeout(() => {
        setInvoiceState(initInvoiceState);
      }, 4000);
      return false;
    }
    return true;
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.top, {
          [styles.bottomMargin]: Boolean(data[0]?.rating?.rating),
        })}
      >
        <UserBlock
          src={src}
          name={data[0]?.user?.name ?? data.name}
          surname={data[0]?.user?.surname ?? data.surname}
          expertise={data[0]?.areas ?? data.areas}
          areasInRow={true}
          rating={data[0]?.rating?.rating ?? 0}
        />
        <div className={styles.flex}>
          {invoiceState.success && (
            <NotifyLabel
              label={
                isFounder
                  ? 'dashboard.labels.invoice-founder-success'
                  : 'dashboard.labels.invoice-expert-success'
              }
            />
          )}
          {invoiceState.error && (
            <NotifyLabel
              type={notifyLabelType.danger}
              label={
                isFounder
                  ? 'dashboard.labels.invoice-founder-error'
                  : 'dashboard.labels.invoice-expert-error'
              }
            />
          )}
          <>
            {
              // @ts-ignore
              currentUser?.roles.name === userRolesEnum.Founder &&
              invoiceTrigger === invoiceTriggerEnum.interact &&
              !invoiceState.error ? (
                <Button
                  onClick={async () => {
                    setInvoiceState((state) => ({
                      ...state,
                      loading: true,
                    }));
                    const interacting = await checkChatsBeforeInteract(
                      chatId,
                      invoiceTriggerEnum.interact,
                      invoiceTriggerEnum.confirmed
                    );

                    if (!interacting) {
                      return;
                    }

                    API.putFormData(`${URL_CHAT}/${chatId}`, {
                      chat: chatId,
                    }).then(() => {
                      setInvoiceState((state) => ({
                        ...state,
                        labelVisible: true,
                        success: true,
                      }));
                      dispatch(
                        setInvoiceTrigger(
                          project?.id,
                          invoiceTriggerEnum.invoice
                        )
                      );
                      setTimeout(() => {
                        setInvoiceState(initInvoiceState);
                      }, 2000);
                    });
                  }}
                  type={buttonTypes.danger}
                  disabled={invoiceState.loading}
                >
                  {intl.formatMessage({ id: 'component.button.interact' })}
                </Button>
              ) : null
            }
            {isFounder && (
              <div className={styles.rateWrapper}>
                <RateBlock
                  minRate={minRate}
                  maxRate={maxRate}
                  rateClassName={styles.rateText}
                />
              </div>
            )}
          </>

          {invoiceTrigger === invoiceTriggerEnum.invoice &&
          // @ts-ignore
          currentUser?.roles?.name === userRolesEnum.Expert ? (
            <Button
              onClick={async () => {
                const interacting = await checkChatsBeforeInteract(
                  chatId,
                  invoiceTriggerEnum.invoice,
                  invoiceTriggerEnum.waitingForInvoice
                );
                if (!interacting) {
                  return;
                }
                setPopupState((state) => ({
                  ...state,
                  isVisible: true,
                }));
                dispatch(setGlobalProjectStart(project?.id));
              }}
              type={buttonTypes.info}
              nativeStyles={styles.spacer}
            >
              {intl.formatMessage({
                id: 'component.button.create-invoice',
              })}
            </Button>
          ) : null}

          {
            // @ts-ignore
            user?.roles?.name === userRolesEnum.Founder && (
              <div className={styles.popoverWrapper}>
                <div className={styles.dots}>
                  <MoreDots
                    focusable={
                      // @ts-ignore
                      currentUser?.roles.name !== userRolesEnum.Expert
                    }
                  />
                </div>
                <div className={styles.popover}>
                  <ViewProfileDropdown
                    opponentId={opponentId}
                    projectId={project?.id}
                    userId={expertId}
                  />
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div
        className={classNames(
          styles.delimiter,
          // @ts-ignore
          {
            [styles.bgDanger]:
              // @ts-ignore
              currentUser?.roles.name === userRolesEnum.Founder,
          },
          // @ts-ignore
          { [styles.bgInfo]: currentUser?.roles.name === userRolesEnum.Expert }
        )}
      ></div>
      <div className={styles.headerBottom}>
        <div>
          <h3 className={styles.bottomTitle}>{project?.name}</h3>
          <h4 className={styles.bottomSubTitle}>
            {project?.type_startup?.name}
          </h4>
        </div>
        <BudgetBlock label={project?.budget} />
      </div>
      {isVisible && (
        <Popup
          name={intl.formatMessage({ id: 'popup.popup-title.invoice' })}
          buttonLabel={intl.formatMessage({
            id:
              popupContentState !== invoiceContentState.invoiceVisible
                ? 'component.button.pay-ok'
                : 'component.button.send-invoice',
          })}
          popupType={popupTypesEnum.simple}
          onButtonClick={onPopupButtonClick}
          setPopupVisible={() => {
            setPopupState((state) => ({
              ...state,
              isVisible: !state.isVisible,
            }));
          }}
          userRoles={userRoles}
          showFooterLabel={true}
        >
          {getPopupContent(popupContentState)}
        </Popup>
      )}
    </div>
  );
};

export default ChatHeader;
