import React, { useEffect } from 'react';
import styles from 'componets/registration/formStepsContent/sixthFormStep/stiles.module.scss';
import classNames from 'classnames';
import RegistrationLabelList from 'pages/registrationPage/components/registrationLabelList';
import { useDispatch, useSelector } from 'react-redux';
import { setAchievesSkillsStart } from 'store/reducers/registerData/actions/achievesSkills';
import {
  getAvailableSkills,
  getUserSkills,
} from 'store/reducers/registerData/selector';
import { setFormData, setRegisterStepError } from 'store/reducers/registerData';

const SixthFormStep = () => {
  const dispatch = useDispatch();
  const availableSkills = useSelector(getAvailableSkills);
  const userSkills = useSelector(getUserSkills);

  useEffect(() => {
    dispatch(
      setAchievesSkillsStart({
        queryDataKey: 'skills',
        stateAchievesSkillsKey: 'availableSkills',
      })
    );
  }, []);
  useEffect(() => {
    dispatch(setRegisterStepError(!userSkills.length));
  }, [userSkills.length]);
  return (
    <div className={styles.wrapper}>
      {availableSkills.map((skillsItem, index) => {
        const { skills, area, id } = skillsItem;
        return (
          <div key={`${area}_${index.toString()}`}>
            <h3 className={styles.sectionTitle}>{area}</h3>
            <div className={classNames(styles.bottomMargin, styles.flexRow)}>
              <RegistrationLabelList
                labelsArray={skills}
                usersLabelList={userSkills}
                storeObjectKey="userSkills"
                showInputRow
                setFunction={setFormData}
                listId={id}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SixthFormStep;
