export const stepsAsideConfig = [
  { title: 'register.steps-aside.step-1' },
  { title: 'register.steps-aside.step-2' },
  { title: 'register.steps-aside.step-3' },
  { title: 'register.steps-aside.step-4' },
  { title: 'register.steps-aside.step-5' },
  { title: 'register.steps-aside.step-6' },
  { title: 'register.steps-aside.step-7' },
  { title: 'register.steps-aside.step-8' },
  { title: 'register.steps-aside.step-9' },
  { title: 'register.steps-aside.step-10' },
  { title: 'register.steps-aside.step-11' },
  { title: 'register.steps-aside.step-12' },
];
