import { URL_USER_INVOICES } from 'const';
import API from 'services/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setUserInvoices, invoicesType } from 'store/reducers/invoice/index';
import { GET_USER_INVOICE_START } from 'store/reducers/invoice/actions';

export function* fetchUserInvoices({
  payload,
}: {
  payload: {
    currentPage: number;
    cb: () => void;
  };
}) {
  const { currentPage, cb = () => {} } = payload;
  try {
    const path = `${URL_USER_INVOICES}?page=${currentPage}`;
    const response: {
      data: [] | invoicesType[];
      links: {
        next: null | string;
      };
      meta: {
        current_page: number;
      };
    } = yield call(API.getStaticApiData, path, true);
    yield put(setUserInvoices(response));
    cb();
  } catch (error) {
    console.log('fetch invoices error', error);
    cb();
  }
}

export function* fetchUserInvoiceSaga() {
  // @ts-ignore
  yield takeLatest(GET_USER_INVOICE_START, fetchUserInvoices);
}
