import React from 'react';
import styles from 'pages/dashboardPage/components/dashboardAside/styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import RoundImage from 'componets/roundImage';
import RateRow from 'pages/dashboardPage/components/rateRow';
import { userRolesEnum } from 'pages/dashboardPage/index';
import ProfileMenageButtons from 'pages/dashboardPage/components/dashboardAside/profileMenageButtons';
import classNames from 'classnames';
import Switcher from 'componets/switcher';
import { useIntl } from 'react-intl';
import { userStatus } from 'store/reducers/user/types';
import API from 'services/API';
import { setUserWorkAvailability } from 'store/reducers/user';
import { URL_SEND_EXPERT_DETAILS } from 'const';

const userRate = {
  rate: 4.5,
  based_on: 217,
};

const DashboardAside = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const intl = useIntl();

  if (!user) {
    return null;
  }

  const {
    name,
    surname,
    roles,
    avatar,
    status,
    userExpertDetailsId,
    workAvailability,
  } = user;

  // @ts-ignore

  const { based_on } = userRate;
  const onSwitcherChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const path = `${URL_SEND_EXPERT_DETAILS}/${userExpertDetailsId}`;
    const value = event.target.checked;
    API.putFormData(path, { available_to_work: value }).then((response) => {
      dispatch(setUserWorkAvailability(response.data.available_to_work));
    });
  };

  // @ts-ignore
  const src = avatar?.temporary_download;
  // @ts-ignore
  const userRoles = roles?.name;
  const isExpert = userRoles === userRolesEnum.Expert;
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={classNames(styles.asideBlock, styles.alignCenter)}>
          <RoundImage
            src={src}
            alt={name}
            nativeStyles={classNames(styles.img, {
              [styles.outlineExpert]: isExpert,
              [styles.outlineFounder]: !isExpert,
            })}
          />
          <div className={styles.title}>
            {name} {surname}
          </div>
          {
            // @ts-ignore
            isExpert && user?.expertDetails?.rating ? (
              // @ts-ignore
              <RateRow rate={user?.expertDetails?.rating} />
            ) : null
          }
        </div>
        <div className={styles.asideBlock}>
          <ProfileMenageButtons role={userRoles} />
        </div>
        {isExpert && (
          <div className={styles.asideBlock}>
            <div className={styles.sidebarWrapper}>
              <div className={styles.available}>
                {intl.formatMessage({
                  id: 'dashboard.available.available-label',
                })}
              </div>
              <div className={styles.relative}>
                {status !== userStatus.confirmed && (
                  <div className={styles.errorMsg}>
                    {intl.formatMessage({
                      id: 'dashboard.available.available-confirm-message',
                    })}
                  </div>
                )}
                <Switcher
                  onSwitcherChange={onSwitcherChange}
                  disabled={status !== userStatus.confirmed}
                  isChecked={Boolean(workAvailability)}
                />
              </div>
            </div>
            <div className={styles.textGray}>
              {intl.formatMessage({ id: 'dashboard.available.available-text' })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardAside;
