import * as React from 'react';
import { SVGProps } from 'react';

const UserWithFind = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.14 12.562A5.812 5.812 0 0 0 12 2.672a5.812 5.812 0 0 0-3.403 10.526.138.138 0 0 1-.014.005l-.014.006a8.727 8.727 0 0 0-2.801 1.89 8.804 8.804 0 0 0-1.889 2.8 8.712 8.712 0 0 0-.691 3.237.187.187 0 0 0 .187.192h1.406a.187.187 0 0 0 .188-.183 6.988 6.988 0 0 1 2.058-4.788A6.985 6.985 0 0 1 12 14.297c.807 0 1.592.135 2.331.393a5.79 5.79 0 0 1 1.81-2.128Zm-6.99-1.228A4.005 4.005 0 0 0 12 12.516c1.076 0 2.088-.42 2.85-1.182a4.005 4.005 0 0 0 1.181-2.85c0-1.075-.42-2.088-1.18-2.85A4.005 4.005 0 0 0 12 4.454c-1.077 0-2.09.419-2.85 1.18a4.005 4.005 0 0 0-1.182 2.85c0 1.076.42 2.089 1.181 2.85ZM21.824 18.467 23 19.643l-1.106 1.106-1.176-1.176a3.13 3.13 0 1 1 1.106-1.106Zm-2.694-.044a1.552 1.552 0 1 0 0-3.104 1.552 1.552 0 0 0 0 3.104Z"
      fill="#96A4BB"
    />
  </svg>
);

export default UserWithFind;
