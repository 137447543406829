import React from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';

type RowProps = {
  title: string;
  children: React.ReactNode;
};

const Row = (props: RowProps) => {
  const { title = '', children = null } = props;
  const intl = useIntl();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{intl.formatMessage({ id: title })}</div>
      <div className={styles.flexWrap}>{children}</div>
    </div>
  );
};

export default Row;
