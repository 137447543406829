export enum fetchAreaExpertise {
  Fetch = 'fetchAreaExpertise/fetch',
  FetchStart = 'fetchAreaExpertise/fetchStart',
  FetchSuccess = 'fetchAreaExpertise/fetchSuccess',
  FetchError = 'fetchAreaExpertise/fetchError',
}

export enum fetchTypeOfServices {
  Fetch = 'fetchTypeOfServices/fetch',
  FetchStart = 'fetchTypeOfServices/fetchStart',
  FetchSuccess = 'fetchTypeOfServices/fetchSuccess',
  FetchError = 'fetchTypeOfServices/fetchError',
}

export enum fetchCountryLocation {
  Fetch = 'fetchCountryLocation/fetch',
  FetchStart = 'fetchCountryLocation/fetchStart',
  FetchSuccess = 'fetchCountryLocation/fetchSuccess',
  FetchError = 'fetchCountryLocation/fetchError',
}

export enum fetchRegionLocation {
  Fetch = 'fetchRegionLocation/fetch',
  FetchStart = 'fetchRegionLocation/fetchStart',
  FetchSuccess = 'fetchRegionLocation/fetchSuccess',
  FetchError = 'fetchRegionLocation/fetchError',
}

export enum fetchCityLocation {
  Fetch = 'fetchCityLocation/fetch',
  FetchStart = 'fetchCityLocation/fetchStart',
  FetchSuccess = 'fetchCityLocation/fetchSuccess',
  FetchError = 'fetchCityLocation/fetchError',
}

export enum fetchAchievesSkills {
  Fetch = 'fetchAchievesSkills/fetch',
  FetchStart = 'fetchAchievesSkills/fetchStart',
  FetchSuccess = 'fetchAchievesSkills/fetchSuccess',
  FetchError = 'fetchAchievesSkills/fetchError',
}

export enum fetchTypeStartup {
  Fetch = 'fetchTypeStartup/fetch',
  FetchStart = 'fetchTypeStartup/fetchStart',
  FetchSuccess = 'fetchTypeStartup/fetchSuccess',
  FetchError = 'fetchTypeStartup/fetchError',
}

export enum fetchUserHoursAvailability {
  Fetch = 'fetchUserHoursAvailability/fetch',
  FetchStart = 'fetchUserHoursAvailability/fetchStart',
  FetchSuccess = 'fetchUserHoursAvailability/fetchSuccess',
  FetchError = 'fetchUserHoursAvailability/fetchError',
}

export const SET_REGISTER_STEP = Symbol('SET_REGISTER_STEP');
export const SET_REGISTER_STEP_DATA = Symbol('SET_REGISTER_STEP_DATA');
export const SET_REGISTER_STEP_ERROR = Symbol('SET_REGISTER_STEP_ERROR');
export const SET_FORM_DATA = Symbol('SET_FORM_DATA');
export const SET_OTHERS_SKILLS_FORM_DATA = Symbol(
  'SET_OTHERS_SKILLS_FORM_DATA'
);
export const RESET_AVAILABLE_ACHIEVES = Symbol('RESET_AVAILABLE_ACHIEVES');
export const SET_REGISTER_STEP_LOADING = Symbol('SET_REGISTER_STEP_LOADING');
export const SET_APP_LOADED = Symbol('SET_APP_LOADED');
export const SET_DATA_FROM_STORAGE = Symbol('SET_DATA_FROM_STORAGE');
export const SET_FORM_DATA_BY_USER_ID = Symbol('SET_FORM_DATA_BY_USER_ID');
