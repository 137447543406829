import { ICreateProjectInterface } from 'store/reducers/createProjects/types';
import {
  fetchExpertDetails,
  fetchNewProjectAchieves,
  SET_CREATE_PROJECT_PAGE,
  SET_CREATE_PROJECT_PAGE_ERROR,
  SET_CREATED_NEW_PROJECT_ID,
  SET_EDITED_PROJECT,
  SET_NEW_PROJECT_DATA,
  SET_PROJECT_DATA_DEFAULT,
  SET_PROJECT_IN_DRAFT,
} from 'store/reducers/createProjects/actions';
import { createProjectStepsConfig } from 'config/createProject/create-project-steps.config';

const initProjectData = {
  id: null,
  founder_id: null,
  typeOfExpert: null,
  typeOfGoals: null,
  typeOfAchieves: null,
  name: '',
  typeStartup: null,
  minRate: '',
  maxRate: '',
  budget: '',
  status: '',
  skills: [],
  expertDetails: {
    data: null,
    meta: {
      currentPage: 1,
      last_page: 1,
    },
  },
};

const initState = {
  projectData: initProjectData,
  stepsData: {
    step: 1,
    error: null,
  },
  projectInDraft: false,
  createdNewProjectId: null,
};

export const setProjectDataToDefault = () => {
  return {
    type: SET_PROJECT_DATA_DEFAULT,
  };
};

export const setCreateProjectStep = (value: {
  nextStep: number;
  error: string;
}) => {
  return {
    type: SET_CREATE_PROJECT_PAGE,
    payload: value,
  };
};

export const setCreateProjectStepError = (value: string) => {
  return {
    type: SET_CREATE_PROJECT_PAGE_ERROR,
    payload: value,
  };
};

export const setCreatedProjectId = (id: number | null) => {
  return {
    type: SET_CREATED_NEW_PROJECT_ID,
    payload: id,
  };
};

export const setNewProjectData = (value: any) => {
  return {
    type: SET_NEW_PROJECT_DATA,
    payload: value,
  };
};

export const setDraftProject = (value: boolean) => {
  return {
    type: SET_PROJECT_IN_DRAFT,
    payload: value,
  };
};

export const setEditedProjectData = (value: any) => {
  return {
    type: SET_EDITED_PROJECT,
    payload: value,
  };
};

const createProjectDataReducer = (
  state: ICreateProjectInterface = initState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case fetchNewProjectAchieves.FetchStart:
    case fetchExpertDetails.FetchStart: {
      const { stepsData } = state;
      return {
        ...state,
        stepsData: {
          ...stepsData,
          error: null,
        },
      };
    }
    case fetchNewProjectAchieves.FetchSuccess: {
      const { projectData, stepsData } = state;
      return {
        ...state,
        projectData: {
          ...projectData,
          typeOfAchieves: payload,
        },
        stepsData: {
          ...stepsData,
          error: null,
        },
      };
    }
    case fetchExpertDetails.FetchSuccess: {
      const { projectData } = state;
      return {
        ...state,
        projectData: {
          ...projectData,
          expertDetails: payload,
        },
      };
    }
    case fetchNewProjectAchieves.FetchError:
    case fetchExpertDetails.FetchError: {
      const { stepsData } = state;
      return {
        ...state,
        stepsData: {
          ...stepsData,
          error: payload,
        },
      };
    }
    case SET_CREATE_PROJECT_PAGE: {
      const { nextStep, error } = payload;
      const { step } = state.stepsData;
      const stepConfig = createProjectStepsConfig[step - 1];
      if (nextStep < step) {
        return {
          ...state,
          stepsData: { step: nextStep, error },
        };
      }

      if (typeof stepConfig?.continueValidator === 'function') {
        const error = stepConfig.continueValidator(state.projectData);

        if (error) {
          return {
            ...state,
            stepsData: {
              ...state.stepsData,
              error,
            },
          };
        }
      }

      return {
        ...state,
        stepsData: { step: nextStep, error },
      };
    }
    case SET_CREATE_PROJECT_PAGE_ERROR: {
      const { stepsData } = state;
      return {
        ...state,
        stepsData: {
          ...stepsData,
          error: payload,
        },
      };
    }
    case SET_NEW_PROJECT_DATA: {
      const { projectData } = state;
      const { name, value } = payload;
      return {
        ...state,
        projectData: {
          ...projectData,
          [name]: value,
        },
      };
    }
    case SET_CREATED_NEW_PROJECT_ID:
      return {
        ...state,
        createdNewProjectId: payload,
      };
    case SET_PROJECT_IN_DRAFT:
      return {
        ...state,
        projectInDraft: payload,
      };
    case SET_EDITED_PROJECT: {
      const { projectData } = state;
      return {
        ...state,
        projectData: { ...projectData, ...payload },
      };
    }
    case SET_PROJECT_DATA_DEFAULT: {
      return {
        ...state,
        projectData: initProjectData,
      };
    }
    default:
      return state;
  }
};

export default createProjectDataReducer;
