import React from 'react';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import CreateProjectResult from 'pages/createProject/createProjectResult';
import LayoutWrapper from 'componets/layoutWrapper';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUT_HOME } from 'const';

const FindExpert = () => {
  const navigate = useNavigate();
  return (
    <LayoutWrapper>
      <LayoutMainContent nativeStyles={styles.innerPage}>
        <LayoutContainer>
          <CreateProjectResult
            onBackButtonClick={() => {
              navigate(ROUT_HOME, { replace: true });
            }}
          />
        </LayoutContainer>
      </LayoutMainContent>
    </LayoutWrapper>
  );
};

export default FindExpert;
