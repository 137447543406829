import React from 'react';
import styles from 'pages/createProject/createProjectResult/column/styles.module.scss';
type ColumnProps = {
  children: React.ReactNode;
};

const Column = (props: ColumnProps) => {
  const { children } = props;
  return <div className={styles.wrapper}>{children}</div>;
};

export default Column;
