import {
  GET_USER_INVOICE,
  GET_USER_INVOICE_START,
  SET_INVOICE_DATA,
  SET_INVOICE_OBJECT_DATA,
  SET_INVOICE_PAYMENT_DATA,
} from 'store/reducers/invoice/actions';
import { invoiceStatus } from 'pages/invoice/invoicesItem';

export interface invoiceDataTypeInitState extends invoiceDataType {
  invoicesData: {
    data: [] | invoicesType[];
    links: {
      next: null | string;
    };
    meta: {
      current_page: number;
    };
  };
}

export interface invoiceDataType {
  founder_id: number;
  project_id: number;
  service_price: number | string;
  transfer_fee: number;
  total: number;
  due_date: string;
  invoiceDate: Date;
  tax: number | string;
  address: string;
}

type invoiceUser = {
  id: string;
  name: string;
  surname: string;
};

export interface invoicesType {
  id: string;
  to?: invoiceUser;
  from?: invoiceUser;
  project: {
    id: string;
    name: string;
    status: invoiceStatus;
  };
  status: invoiceStatus;
  due_date: string;
  total: number;
  download: string;
  isDone: boolean;
}

const initState = {
  invoicesData: {
    data: [],
    links: {
      next: null,
    },
    meta: {
      current_page: 1,
      from: 1,
    },
  },
  founder_id: 0,
  project_id: 0,
  service_price: '',
  tax: 0,
  transfer_fee: 0,
  total: 0,
  due_date: '',
  invoiceDate: new Date(Date.now()),
  address: '',
};

export const setInvoiceObjectData = (data: invoiceDataType) => {
  return {
    type: SET_INVOICE_OBJECT_DATA,
    payload: data,
  };
};

export const setInvoicePaymentData = (data: invoiceDataType) => {
  return {
    type: SET_INVOICE_PAYMENT_DATA,
    payload: data,
  };
};

export const setInvoiceData = (data: { name: string; value: any }) => {
  return {
    type: SET_INVOICE_DATA,
    payload: data,
  };
};

export const setUserInvoices = (data: {
  data: [] | invoicesType[];
  links: {
    next: null | string;
  };
  meta: {
    current_page: number;
  };
}) => {
  return {
    type: GET_USER_INVOICE,
    payload: { data: data.data, links: data.links, meta: data.meta },
  };
};

export const setUserInvoicesStart = (currentPage: number, cb: () => void) => {
  return {
    type: GET_USER_INVOICE_START,
    payload: { currentPage, cb },
  };
};

const invoiceReducer = (
  state: invoiceDataTypeInitState = initState,
  action: { type: symbol; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INVOICE_OBJECT_DATA: {
      return {
        ...state,
        invoicesData: {
          ...state.invoicesData,
          links: payload.links,
          data: payload.data,
          meta: payload.meta,
        },
      };
    }
    case SET_INVOICE_PAYMENT_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_INVOICE_DATA: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    }
    case GET_USER_INVOICE: {
      return {
        ...state,
        invoicesData: payload,
      };
    }
    default:
      return state;
  }
};

export default invoiceReducer;
