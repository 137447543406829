import React from 'react';
import styles from './styles.module.scss';
import RoundImage from 'componets/roundImage';
import classNames from 'classnames';
import { userRolesEnum } from 'pages/dashboardPage/index';
import { useLocation } from 'react-router-dom';
import { ROUT_HOME } from 'const';

type ChatPreviewBtnProps = {
  onBntClick: () => void;
  name: string;
  surname: string;
  userRole: userRolesEnum;
  imageSrc: string;
  projectName: string;
  activeNewMessages?: number | null;
  userOnlineStatus?: string;
};

const ChatPreviewBtn = (props: ChatPreviewBtnProps) => {
  const {
    onBntClick,
    userOnlineStatus = false,
    name,
    surname,
    activeNewMessages,
    userRole,
    imageSrc,
    projectName,
  } = props;

  const { pathname } = useLocation();

  return (
    <button
      className={classNames(styles.wrapper, {
        [styles.lgWrapper]: pathname === ROUT_HOME,
      })}
      onClick={onBntClick}
    >
      <div className={styles.imageWrapper}>
        <RoundImage src={imageSrc} alt={name} nativeStyles={styles.img} />
      </div>
      <div className={styles.personal}>
        <div className={styles.name} title={name}>
          {name} {surname}
        </div>
        <div className={styles.textGray} title={projectName}>
          {projectName}
        </div>
      </div>
      {Boolean(activeNewMessages) && (
        <div
          className={classNames(styles.activeNewMessages, {
            [styles.activeNewMessagesExpert]: userRole === userRolesEnum.Expert,
            [styles.activeNewMessagesFounder]:
              userRole === userRolesEnum.Founder,
          })}
        >
          <span>{activeNewMessages}</span>
        </div>
      )}
    </button>
  );
};

export default ChatPreviewBtn;
