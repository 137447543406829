import { fetchExpertDetails } from 'store/reducers/createProjects/actions';
import { expertDetailsType } from 'store/reducers/createProjects/types';

export const setExpertDetailsStart = (data: {
  page: number;
  id: number | null;
  cb?: () => void;
}) => {
  return {
    type: fetchExpertDetails.FetchStart,
    payload: data,
  };
};

export const setExpertDetailsSuccess = (data: expertDetailsType[]) => {
  return {
    type: fetchExpertDetails.FetchSuccess,
    payload: data,
  };
};

export const setExpertDetailsError = (value: Error) => {
  return {
    type: fetchExpertDetails.FetchError,
    payload: value,
  };
};
