const {
  REACT_APP_API_URL,
  REACT_APP_API_DOMAIN,
  REACT_APP_SPRIPE_CLIENT_SECRET,
} = process.env;
export const AVAILABLE_TOTAL_STEPS = 14;
export const AVAILABLE_REGISTRATION_STEPS = 12;
export const JWT_ACCESS_TOKEN_KEY = 'jwt-access-token';
export const JWT_REFRESH_TOKEN_KEY = 'jwt-refresh-token';
export const PDF_UPLOAD_FILE_SIZE_LIMIT = 20 * 1024 * 1024;
export const DEFAULT_FILE_SIZE_LIMIT = 3 * 1024 * 1024;
export const DEFAULT_READ_MORE_CONTENT_HEIGHT = 70;
export const REGISTER_PROJECT_DISPLAY_SKILLS_LIMIT = 5;
export const REVIEW_EXPERT_DISPLAY_SKILLS_LIMIT = 5;
export const AUTH_STATES = {
  SUCCESS: 1,
  ALREADY: 2,
  RESET: 3,
  WAIT_APPROVE: 4,
  WAIT_EMAIL_VERIFY: 5,
  REJECTED: 6,
  RESEND: 7,
};

export const ROUT_HOME = '/';
export const ROUT_LOGIN = '/login';
export const ROUT_VIEW_PROFILE = '/view-profile';
export const ROUT_CREATE_PROJECT = '/create-project';
export const ROUT_CREATE_PROJECT_RESULT = '/create-project-result';
export const ROUT_REGISTRATION = '/registration';
export const ROUT_FIND_EXPERT = '/find-expert';
export const ROUT_EDIT_PROJECT = '/project-edit';
export const ROUT_EDIT_PROFILE = '/profile-edit';
export const ROUT_EXPERT_PAGE = '/expert-page';
export const ROUT_USER_PAGE = `${ROUT_EXPERT_PAGE}/userPage`;
export const ROUT_INVOICE_SUCCESS = '/invoice-success';
export const ROUT_CHAT_PAGE = '/chat';
export const ROUT_INVOICES = '/invoices';
export const ROUT_LOGOUT = 'logout';
export const STRIPE_CLIENT_SECRET = `${REACT_APP_SPRIPE_CLIENT_SECRET}`;

export const URL_PAY_INVOICE = `${REACT_APP_API_URL}/pay-invoice`;
export const URL_PAY_INVOICE_APPROVE = `${URL_PAY_INVOICE}/approve`;
export const URL_CHAT = `${REACT_APP_API_URL}/chat`;
export const URL_CHAT_MESSAGES = `${REACT_APP_API_URL}/message`;
export const URL_USER_INVOICES = `${REACT_APP_API_URL}/invoice`;
export const URL_CHAT_MESSAGES_UPDATE = `${URL_CHAT_MESSAGES}/update`;
export const URL_RESET_VERIFICATION = `${REACT_APP_API_URL}/verification-resend`;
export const URL_PAY_PROJECT = `${REACT_APP_API_URL}/pay-project`;
export const URL_EXPERT_REVIEW = `${REACT_APP_API_URL}/review`;

export const URL_DOMAIN_FAQS = `${REACT_APP_API_DOMAIN}/faq`;
export const URL_DOMAIN_CONTACT = `${REACT_APP_API_DOMAIN}/contact`;
export const URL_DOMAIN_RESET_PASSWORD = `${REACT_APP_API_DOMAIN}/password/reset`;
export const URL_DOMAIN_ACCOUNT_CREATE = `${REACT_APP_API_DOMAIN}/registration/founder`;

export const URL_FACEBOOK = `https://www.instagram.com/upmarqt/`;
export const URL_INSTAGRAM = `https://www.facebook.com/upmarqt`;
export const URL_LINKEDIN = `https://www.linkedin.com/company/upmarqt-platform/?viewAsMember=true`;
export const URL_WEBSPARK = `https://www.webspark.com/`;

export const URL_USER_INFO = `${REACT_APP_API_URL}/me`;
export const URL_USER_HOURS_AVAILABILITY = `${REACT_APP_API_URL}/hour-availability`;
export const URL_AREA_EXPERTISES = `${REACT_APP_API_URL}/area-expertise`;
export const URL_TYPE_OF_SERVICES = `${REACT_APP_API_URL}/service-provider`;
export const URL_STARTUP_TYPE = `${REACT_APP_API_URL}/type-startup`;
export const URL_COUNTRY_LOCATION = `${REACT_APP_API_URL}/country`;
export const URL_REGION_LOCATION = `${REACT_APP_API_URL}/state`;
export const URL_CITY_LOCATION = `${REACT_APP_API_URL}/city`;
export const URL_EXPERT_DETAILS_FILE = `${REACT_APP_API_URL}/upload`;
export const URL_SEND_EXPERT_DETAILS = `${REACT_APP_API_URL}/expert-detail`;
export const URL_STARTUP_GOAL = `${REACT_APP_API_URL}/goal`;
export const URL_STARTUP_ACHIEVES = `${REACT_APP_API_URL}/achieve`;
export const URL_SKILLS = `${REACT_APP_API_URL}/skill`;
export const URL_STARTUP_PROJECT = `${REACT_APP_API_URL}/project`;
export const URL_REGISTRATION = `${REACT_APP_API_URL}/registration`;
export const URL_API_STRIPE = `${REACT_APP_API_URL}/stripe`;
export const URL_PROJECT_TOTAL = `${REACT_APP_API_URL}/total`;
export const URL_POST_INVOICE = `${REACT_APP_API_URL}/invoice`;
export const URL_VERIFICATION = `${REACT_APP_API_URL}/verification`;
export const URL_USER_OAUTH_TOKEN = `${REACT_APP_API_DOMAIN}/oauth/token`;

export const RESPONSE_CODE_UNAUTHORIZED = 401;
export const RESPONSE_CODE_SERVER_ERROR = 500;
