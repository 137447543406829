import * as React from 'react';
import { SVGProps } from 'react';

const EditFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.625 19.594H3.375a.75.75 0 0 0-.75.75v.843c0 .104.084.188.188.188h18.375a.188.188 0 0 0 .187-.188v-.843a.75.75 0 0 0-.75-.75ZM6.04 17.625a.953.953 0 0 0 .14-.012l3.943-.691a.23.23 0 0 0 .124-.066l9.935-9.935a.233.233 0 0 0 0-.33l-3.895-3.898a.232.232 0 0 0-.167-.068.232.232 0 0 0-.166.068l-9.935 9.935a.238.238 0 0 0-.066.124l-.691 3.943a.785.785 0 0 0 .22.698c.155.15.35.232.558.232Z"
      fill="#96A4BB"
    />
  </svg>
);

export default EditFileIcon;
