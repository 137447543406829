import React, { useEffect, useState } from 'react';
import styles from 'componets/registration/formStepsContent/twelfthFormStep/styles.module.scss';
import ImageCropper from 'componets/imageCropper';
import {
  setRegistrationLoading,
  setFormData,
} from 'store/reducers/registerData';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { uploadRegistrationFilesConfig } from 'config/registrations/uploadFileConfig';
import { getCurrentRegisterStep } from 'store/reducers/registerData/selector';
import StorageService from 'services/storageService';

const TwelfthFormStep: React.FC = () => {
  const { profile_ava } = uploadRegistrationFilesConfig;
  const { acceptFileType, limit, type, name } = profile_ava;
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentRegisterStep = useSelector(getCurrentRegisterStep);
  const [initImage, setInitImage] = useState('');
  const uploadMessages = {
    defaultLabel: intl.formatMessage({ id: 'image-upload-placeholder' }),
    errorLabel: intl.formatMessage({ id: 'input-upload-placeholder-error' }),
    loadOtherPhotoLabel: intl.formatMessage({
      id: 'register.step-11.select_other_photo',
    }),
    uploadingLabel: intl.formatMessage({
      id: 'input-upload-placeholder-loading',
    }),
    btnLabel: intl.formatMessage({ id: 'btn-upload' }),
  };

  useEffect(() => {
    StorageService.getByKey('formData').then((result) => {
      if (!result) {
        return;
      }
      const { picture } = JSON.parse(result);
      setInitImage(picture?.filePath);
    });
  }, [currentRegisterStep]);

  const onChangeFile = async (name: string, fileData: File | null) => {
    if (!fileData) {
      return;
    }
    dispatch(setFormData({ name: 'pictureFileData', value: fileData }));
    dispatch(setRegistrationLoading(false));
  };
  return (
    <div className={styles.wrapper}>
      <ImageCropper
        onChange={onChangeFile}
        type={type}
        acceptFileType={acceptFileType}
        name={name}
        limit={limit}
        labels={uploadMessages}
        initialImageSrc={initImage}
      />
    </div>
  );
};

export default TwelfthFormStep;
