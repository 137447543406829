import React from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage/index';
import { useIntl } from 'react-intl';
import ChatPreviewBtn from 'pages/dashboardPage/components/chatPreviewBtn';
import ProjectBlock from 'pages/dashboardPage/components/projectsBlock';
import { getInvoicePaymentState } from 'store/reducers/globalRequestedData/selectors';
import Popup from 'componets/popup';
import FormSuccess from 'componets/stripeForm/formSuccess';
import { setInvoicePaymentState } from 'store/reducers/globalRequestedData';
import { useNavigate } from 'react-router-dom';
import { ROUT_CHAT_PAGE, URL_CHAT_MESSAGES_UPDATE } from 'const';
import { getAvailableChats } from 'store/reducers/chat/selectors';
import { chatType } from 'store/reducers/chat';
import { setActiveChatStart } from 'store/reducers/chat/actions/chatMessages';
import API from 'services/API';

const DashboardContent = () => {
  const user = useSelector(getUser);
  const intl = useIntl();
  const isInvoicePaid = useSelector(getInvoicePaymentState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const availableChats = useSelector(getAvailableChats);

  if (!user) {
    return null;
  }

  const { roles } = user;
  // @ts-ignore
  const isFounder = roles?.name === userRolesEnum.Founder;
  return (
    <div className={styles.wrapper}>
      {availableChats?.length ? (
        <div className={styles.topBlock}>
          <div className={styles.topBlockLabel}>
            {
              // @ts-ignore
              roles.name === userRolesEnum.Expert
                ? intl.formatMessage({
                    id: 'dashboard.title.title-expert-chats',
                  })
                : intl.formatMessage({
                    id: 'dashboard.title.title-founder-chats',
                  })
            }
          </div>
          <div className={styles.btnsWrapper}>
            {availableChats?.map((chatPreviewBtn: chatType, index: number) => {
              const { expert, unread_messages, id, founder, project } =
                chatPreviewBtn;
              const src =
                // @ts-ignore
                isFounder
                  ? // @ts-ignore
                    expert?.[0]?.user?.avatar?.[0]?.temporary_download
                  : // @ts-ignore
                    founder?.avatar?.[0]?.temporary_download;
              if (index > 3) {
                return false;
              }
              return (
                <ChatPreviewBtn
                  key={`chat_btn_${index.toString()}`}
                  userOnlineStatus={'Online'}
                  // @ts-ignore
                  name={isFounder ? expert?.[0]?.user?.name : founder?.name}
                  // @ts-ignore
                  surname={
                    // @ts-ignore
                    isFounder ? expert?.[0]?.user?.surname : founder?.surname
                  }
                  activeNewMessages={unread_messages}
                  imageSrc={src}
                  projectName={project?.name}
                  onBntClick={() => {
                    dispatch(
                      setActiveChatStart(id, 1, () => {
                        navigate(ROUT_CHAT_PAGE);

                        if (!unread_messages) {
                          return;
                        }

                        API.sendFormData(`${URL_CHAT_MESSAGES_UPDATE}`, {
                          chat_id: id,
                        }).catch((error) => {
                          console.log('failed to update messages', error);
                        });
                      })
                    );
                  }}
                  userRole={
                    // @ts-ignore
                    roles?.name
                  }
                />
              );
            })}
          </div>
        </div>
      ) : null}

      <ProjectBlock />
      {isInvoicePaid && (
        <Popup
          name={''}
          buttonLabel={intl.formatMessage({
            id: 'component.button.pay-ok',
          })}
          onButtonClick={() => {
            dispatch(setInvoicePaymentState(false));
          }}
          setPopupVisible={() => {
            dispatch(setInvoicePaymentState(false));
          }}
          userRoles={
            // @ts-ignore
            roles?.name ? roles.name : userRolesEnum.Founder
          }
        >
          <FormSuccess
            title={intl.formatMessage({
              id: 'popup.popup-title.title-success',
            })}
            message={intl.formatMessage({
              id: 'popup.popup-message.message-success',
            })}
          />
        </Popup>
      )}
    </div>
  );
};

export default DashboardContent;
