import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';

type RateBlockProps = {
  minRate: string | number | null;
  maxRate: string | number | null;
  titleClassname?: string;
  rateClassName?: string;
};

const RateBlock = (props: RateBlockProps) => {
  const {
    titleClassname = '',
    rateClassName = '',
    minRate = '',
    maxRate = '',
  } = props;
  const intl = useIntl();
  return (
    <div className={styles.wrapper}>
      <div className={classNames([titleClassname || styles.expertHourly])}>
        {intl.formatMessage({ id: 'component.hourly-rate-range' })}
      </div>
      <div
        className={classNames(styles.text, [
          rateClassName || styles.expertRate,
        ])}
      >
        ${minRate} - ${maxRate}
      </div>
    </div>
  );
};

export default RateBlock;
