import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import DefaultUserIcon from 'svg-icons/DefaultUserIcon';
import { useSelector } from 'react-redux';
import { getAppLoading } from 'store/reducers/globalRequestedData/selectors';
import Spinner from 'componets/spiner';
type roundImageProps = {
  src: string;
  alt: string;
  nativeStyles?: string;
};

const RoundImage = (props: roundImageProps) => {
  const { src = '', nativeStyles = '', alt = '' } = props;
  const appLoading = useSelector(getAppLoading);
  if (appLoading) {
    return (
      <div className={classNames(styles.wrapper, nativeStyles)}>
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(styles.wrapper, nativeStyles)}>
      {src ? (
        <img src={src} alt={alt} title={alt} />
      ) : (
        <div className={styles.icon} title={alt}>
          <DefaultUserIcon />
        </div>
      )}
    </div>
  );
};

export default RoundImage;
