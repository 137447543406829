export const SET_CREATE_PROJECT_PAGE = Symbol('SET_CREATE_PROJECT_PAGE');
export const SET_CREATE_PROJECT_PAGE_ERROR = Symbol(
  'SET_CREATE_PROJECT_PAGE_ERROR'
);
export const SET_NEW_PROJECT_DATA = Symbol('SET_NEW_PROJECT_DATA');
export const SET_CREATED_NEW_PROJECT_ID = Symbol('SET_CREATED_NEW_PROJECT_ID');
export const SET_PROJECT_IN_DRAFT = Symbol('SET_PROJECT_IN_DRAFT');
export const SET_EDITED_PROJECT = Symbol('SET_EDITED_PROJECT');
export const SET_PROJECT_DATA_DEFAULT = Symbol('SET_PROJECT_DATA_DEFAULT');
export enum fetchNewProjectAchieves {
  FetchStart = 'fetchNewProjectAchieves/fetchStart',
  FetchSuccess = 'fetchNewProjectAchieves/fetchSuccess',
  FetchError = 'fetchNewProjectAchieves/fetchError',
}

export enum fetchExpertDetails {
  FetchStart = 'fetchExpertDetails/fetchStart',
  FetchSuccess = 'fetchExpertDetails/fetchSuccess',
  FetchError = 'fetchExpertDetails/fetchError',
}
