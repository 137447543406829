import { fetchTypeStartup } from 'store/reducers/registerData/actions';
import { dataItemType } from 'store/reducers/registerData/types';

export function setStartupTypeStart() {
  return {
    type: fetchTypeStartup.FetchStart,
  };
}

export function setStartupTypeSuccess(data: dataItemType[]) {
  return {
    type: fetchTypeStartup.FetchSuccess,
    payload: data,
  };
}

export function setStartupTypeError(error: Error) {
  return {
    type: fetchTypeStartup.FetchError,
    payload: error,
  };
}
