import React from 'react';
import styles from './styles.module.scss';
import { IComponentWithChildren } from 'interfeaces/iComponentWithChildren';

const ContentRow = (props: IComponentWithChildren) => {
  const { children } = props;
  return <div className={styles.row}>{children}</div>;
};

export default ContentRow;
