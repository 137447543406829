import React, { useEffect, useState } from 'react';
import styles from 'componets/registration/formStepsContent/eleventhFormStep/styles.module.scss';
import FileUpload from 'componets/fileUpload';

import { useDispatch, useSelector } from 'react-redux';
import {
  setRegistrationLoading,
  setFormData,
  setRegisterStepError,
} from 'store/reducers/registerData';
import {
  getAppLoading,
  getCurrentRegisterStep,
  getStepsError,
  getUserPortfolio,
} from 'store/reducers/registerData/selector';
import API from 'services/API';
import { uploadRegistrationFilesConfig } from 'config/registrations/uploadFileConfig';
import { URL_EXPERT_DETAILS_FILE } from 'const';
import StorageService from 'services/storageService';
import { useIntl } from 'react-intl';

const EleventhFormStep: React.FC = () => {
  const { portfolio } = uploadRegistrationFilesConfig;
  const { acceptFileType, limit, type, name } = portfolio;
  const userPortfolio = useSelector(getUserPortfolio);
  const [initFileName, setInitFileName] = useState('');
  const [unknownError, setUnknownError] = useState(false);
  const currentRegisterStep = useSelector(getCurrentRegisterStep);
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRegistrationLoading(false));
    dispatch(setRegisterStepError(!userPortfolio));
  }, []);

  const loading = useSelector(getAppLoading);
  const onChangeFile = async (
    name: string,
    fileData: File | null,
    isReset?: boolean
  ) => {
    setUnknownError(false);
    if (isReset) {
      dispatch(
        setFormData({
          name: 'portfolio',
          value: {},
        })
      );
    }
    if (!fileData) {
      return;
    }
    dispatch(setRegisterStepError(true));
    dispatch(setRegistrationLoading(true));

    const result = await API.sendFormData(URL_EXPERT_DETAILS_FILE, {
      file: fileData,
    });
    if (!result) {
      dispatch(setRegisterStepError(true));
      dispatch(setRegistrationLoading(false));
      setUnknownError(true);
      return;
    }
    dispatch(
      setFormData({
        name: 'portfolio',
        value: {
          fileData,
          'portfolio[media_client_name]': result.data.client_name,
          'portfolio[media_storage_name]': result.data.path,
          filePath: result?.data?.temporary_download,
        },
      })
    );
    dispatch(setRegistrationLoading(false));
    dispatch(setRegisterStepError(false));
  };
  const pageStepError = useSelector(getStepsError);

  useEffect(() => {
    StorageService.getByKey('formData').then((result) => {
      if (!result) {
        return;
      }
      const { portfolio } = JSON.parse(result);
      setInitFileName(portfolio?.['portfolio[media_client_name]']);
    });
  }, [currentRegisterStep]);
  return (
    <>
      <div className={styles.warning}>
        {intl.formatMessage({ id: 'register.step-11.page-warning' })}
      </div>
      <div className={styles.wrapper}>
        <FileUpload
          onChange={onChangeFile}
          type={type}
          acceptFileType={acceptFileType}
          name={name}
          limit={limit}
          uploadedFileName={initFileName}
          loading={loading}
          unknownError={unknownError}
        />
      </div>
    </>
  );
};

export default EleventhFormStep;
