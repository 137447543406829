import React, { useCallback, useState } from 'react';
import ChatMessage from 'pages/chatPage/chatPageContent/chatBody/chatMessage';
import styles from './styles.module.scss';
import classNames from 'classnames';
import SendIcon from 'svg-icons/SendIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';
import { setNewChatMessage } from 'store/reducers/chat';
import Spinner from 'componets/spiner';
import TextArea from 'componets/textArea';
import { useIntl } from 'react-intl';
import InfiniteScrollApi from 'pages/chatPage/chatPageAside/chatPreviewList/infinityScroll';
import { setActiveChatStart } from 'store/reducers/chat/actions/chatMessages';

import moment from 'moment';

function groupedDays(messages: any) {
  return messages.reduce((acc: any, el: any, i: any) => {
    const newDateLocal = moment.utc(el.created_at);
    const localDate = newDateLocal.local();
    const messageDay = localDate.format('ddd MMM DD');
    if (acc[messageDay]) {
      return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
    }
    return { ...acc, [messageDay]: [el] };
  }, {});
}

function generateItems(messages: any) {
  if (!messages) {
    return;
  }
  const days = groupedDays(messages);
  const sortedDays = Object.keys(days).sort((x, y) => {
    return moment(y, 'ddd MMM DD').unix() - moment(x, 'ddd MMM DD').unix();
  });
  const items = sortedDays.reduce((acc, date) => {
    const sortedMessages = days[date].sort(
      // @ts-ignore
      (x, y) => new Date(y.created_at) - new Date(x.created_at)
    ); // @ts-ignore
    return acc.concat([...sortedMessages, { type: 'day', date, id: date }]);
  }, []);
  return items;
}

const Root = (props: any) => {
  return (
    <div className={styles.messagesWrapper} ref={props.rootRef}>
      {props.children}
    </div>
  );
};

const ChatBody = (props: {
  chatId: string | undefined;
  messages: any;
  totalPage: number | undefined;
}) => {
  const { chatId, messages, totalPage = 1 } = props;
  const user = useSelector(getUser);
  const intl = useIntl();
  const [inputState, setInputState] = useState({
    loading: false,
    inputValue: '',
  });
  const [chatLoading, setChatLoading] = useState(false);
  const { loading, inputValue } = inputState;
  const dispatch = useDispatch();
  const onInputChange = (key: string, value: string) => {
    setInputState((state) => ({ ...state, inputValue: value }));
  };
  const onSendButtonClick = useCallback(
    async (event) => {
      event.preventDefault();
      if (!inputValue.trim().length) {
        return;
      }
      setInputState((state) => ({ ...state, loading: true }));
      dispatch(
        setNewChatMessage(chatId, inputValue.trim(), () => {
          setInputState((state) => ({
            ...state,
            loading: false,
            inputValue: '',
          }));
        })
      );
    },
    [loading, inputValue]
  );

  const setPageCallback = async (page: any) => {
    if (page === 1 || page > totalPage) {
      return;
    }
    setChatLoading(true);
    dispatch(
      // @ts-ignore
      setActiveChatStart(chatId, page, () => {
        setChatLoading(false);
      })
    );
  };

  const sortedMessage = generateItems(messages);
  return (
    <div className={styles.wrapper}>
      <InfiniteScrollApi
        RenderComponent={ChatMessage}
        RootComponent={Root}
        setPageCallback={setPageCallback}
        data={sortedMessage}
        totalPage={totalPage}
        chatId={chatId}
        chatLoading={chatLoading}
      />
      <div className={styles.inputWrapper}>
        <form className={styles.inputWrapper} onSubmit={onSendButtonClick}>
          {loading && (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          )}
          <div className={classNames(styles.input)}>
            <TextArea
              onEnterPress={onSendButtonClick}
              name="chatMessage"
              placeholder={intl.formatMessage({
                id: 'input-placeholder.label-send-message',
              })}
              value={inputValue}
              onTextAreaChange={onInputChange}
              autoHeight={true}
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className={classNames(styles.btn, styles.bgInfo, {
              // @ts-ignore
              [styles.bgDanger]: user?.roles.name === userRolesEnum.Founder,
              [styles.disabled]: loading,
            })}
          >
            <SendIcon />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatBody;
