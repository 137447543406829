import { fileTypes } from 'componets/fileUpload';
import { DEFAULT_FILE_SIZE_LIMIT, PDF_UPLOAD_FILE_SIZE_LIMIT } from 'const';

export const uploadRegistrationFilesConfig = {
  portfolio: {
    acceptFileType: ['application/pdf'],
    limit: PDF_UPLOAD_FILE_SIZE_LIMIT,
    type: fileTypes.pdf,
    name: 'portfolio',
  },
  profile_ava: {
    acceptFileType: ['image/png', 'image/jpeg'],
    limit: DEFAULT_FILE_SIZE_LIMIT,
    type: fileTypes.image,
    name: 'profile_ava',
  },
};
