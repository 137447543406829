import React from 'react';
import styles from './styles.module.scss';
import { areaType, userLocation } from 'store/reducers/registerData/types';
import LocationIcon from 'svg-icons/LocationIcon';

export type ExpertBlockInfoProps = {
  name?: string;
  surname?: string;
  areaExpertises: areaType[];
  time?: string;
  userCountry: null | userLocation;
  userRegion?: null | userLocation;
  userCity: null | userLocation;
};

const ExpertBlockInfo = (props: ExpertBlockInfoProps) => {
  const {
    name,
    surname,
    areaExpertises,
    time,
    userCountry,
    userCity,
    userRegion,
  } = props;
  return (
    <div className={styles.expertInfo}>
      <div className={styles.title}>
        {name} {surname}
      </div>
      <div className={styles.row}>
        {areaExpertises.map((expertiseItem: areaType, index) => {
          const { area } = expertiseItem;
          return (
            <div key={index} className={styles.area}>
              {area}
              {index !== areaExpertises.length - 1 && ','}
            </div>
          );
        })}
      </div>
      {time && <div className={styles.expertLastSeen}>{time}</div>}
      <div className={styles.expertAdress}>
        <div className={styles.mr}>
          <LocationIcon />
        </div>
        {userCountry?.name}, {userRegion?.name}, {userCity?.name}
      </div>
    </div>
  );
};

export default ExpertBlockInfo;
