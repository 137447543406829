import { expertDetailsType } from 'store/reducers/createProjects/types';

export type userDataType = {
  id: number;
  name: string;
  surname: string;
  email: string;
  status: string;
  phone: string;
  country_id: number;
  roles: [rolesType];
  avatar: [avatarType];
  expert_detail: expertDetailsType;
  workAvailability: boolean | number;
  userExpertDetailsId: number;
  verificationState: userVerificationStateType | undefined;
  verificationId: string | undefined;
};

export type UserDataStoreType = userDataType | null;

export type avatarType = {
  client_name: string;
  temporary_download: string;
};

export type rolesType = {
  id: string;
  name: string;
};

export interface userStoreInterface {
  userData: UserDataStoreType;
}

export enum userStatus {
  confirmed = 'Confirmed',
  waitingConfirmation = 'Waiting confirmation',
  processBasicRegistration = 'Process basic registration',
  preliminary_confirmation = 'Waiting preliminary confirmation',
  email_verification = 'Waiting verification email',
  rejected = 'Reject',
  preliminary_rejected = 'Preliminary reject',
}

export type userVerificationStateType = 1 | 2 | 3 | 4 | 5 | 6 | 7;
