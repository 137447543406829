import React from 'react';
import styles from './styles.module.scss';
import Spinner from 'componets/spiner';
import LayoutMainContent from 'componets/layoutWrapper/layoutContainer/layoutMainContent';
import LayoutContainer from 'componets/layoutWrapper/layoutContainer';
import DashboardAside from 'pages/dashboardPage/components/dashboardAside/dashboardAside';
import LayoutWrapper from 'componets/layoutWrapper';
import { useSelector } from 'react-redux';
import { getAppLoading } from 'store/reducers/globalRequestedData/selectors';
import InvoiceList from 'pages/invoice/invoiceList';

const Invoices = () => {
  const loading = useSelector(getAppLoading);
  return (
    <LayoutWrapper>
      {loading ? (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) : (
        <LayoutMainContent nativeStyles={styles.innerPage}>
          <LayoutContainer>
            <DashboardAside />
            <InvoiceList />
          </LayoutContainer>
        </LayoutMainContent>
      )}
    </LayoutWrapper>
  );
};

export default Invoices;
