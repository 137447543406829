import { fetchExpertReviews } from 'store/reducers/globalRequestedData/actions';

export const setExpertReviewStart = (
  expertId: string | undefined,
  page: number,
  cb: () => void,
  limit: number
) => {
  return {
    type: fetchExpertReviews.FetchStart,
    payload: { expertId, page, cb, limit },
  };
};

export const setExpertReviewsSuccess = (data: any) => {
  return {
    type: fetchExpertReviews.FetchSuccess,
    payload: data,
  };
};

export const setExpertReviewsError = (error: Error) => {
  return {
    type: fetchExpertReviews.FetchError,
    payload: error,
  };
};
