import React from 'react';
import styles from './styles.module.scss';
import UserIcon from 'svgComponent/UserIcon';
import ButtonLink from 'componets/buttonLink';
import { ROUT_USER_PAGE } from 'const';
import { useIntl } from 'react-intl';

const ViewProfileDropdown = (props: {
  projectId?: number | undefined;
  opponentId?: number | undefined;
  userId?: number | undefined;
  options?: any;
}) => {
  const { opponentId, userId, options = undefined } = props;
  const intl = useIntl();

  return (
    <ButtonLink
      path={`${ROUT_USER_PAGE}/${opponentId}?expertDetailsId=${userId}`}
      options={options}
      nativeStyles={styles.navItemLink}
    >
      <UserIcon />
      <div>
        {intl.formatMessage({
          id: 'dashboard.button.button-view-profile',
        })}
      </div>
    </ButtonLink>
  );
};

export default ViewProfileDropdown;
