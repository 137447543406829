import React from 'react';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
const FirstFormStep = () => {
  const intl = useIntl();
  const content = intl.formatMessage({ id: 'register.step-1.content-text' });
  return <div className={styles.content}>{content}</div>;
};

export default FirstFormStep;
