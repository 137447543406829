import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface IRegisterLargeLabel {
  title: string;
  id: number;
  isSelected?: boolean;
  onClick?: (title: string, id: number) => void;
  subTitle?: string;
  disabled?: boolean;
}
const RegisterLargeLabel = (props: IRegisterLargeLabel) => {
  const {
    id,
    isSelected = false,
    title = '',
    onClick = () => {},
    subTitle = '',
    disabled = false,
  } = props;

  return (
    <div className={styles.bntWrapper}>
      <button
        className={classNames(
          styles.bnt,
          {
            [styles.bntActive]: isSelected,
          },
          {
            [styles.bntInActive]: disabled && !isSelected,
          }
        )}
        onClick={() => {
          if (disabled && !isSelected) {
            return;
          }
          onClick(title, id);
        }}
      >
        <span>{title}</span>
        {subTitle && (
          <span
            className={classNames(styles.subtitle, {
              [styles.subtitleActive]: isSelected,
            })}
          >
            {subTitle}
          </span>
        )}
      </button>
    </div>
  );
};

export default RegisterLargeLabel;
