import React from 'react';
import { userRolesEnum } from 'pages/dashboardPage';
import Popup, { popupTypesEnum } from 'componets/popup';
import FormError from 'componets/stripeForm/FormError';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { setServerError } from 'store/reducers/globalRequestedData';

const ServerErrorPopup = (props: { message: string }) => {
  const { message } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  return (
    <Popup
      name={''}
      buttonLabel={intl.formatMessage({
        id: 'component.button.pay-ok',
      })}
      onButtonClick={() => {
        dispatch(setServerError(false, ''));
        window.location.reload();
      }}
      setPopupVisible={() => {
        dispatch(setServerError(false, ''));
        window.location.reload();
      }}
      userRoles={userRolesEnum.Founder}
      popupType={popupTypesEnum.simple}
    >
      <FormError
        title={intl.formatMessage({
          id: 'popup.popup-title.500-error',
        })}
        message={message ? intl.formatMessage({ id: message }) : ''}
      />
    </Popup>
  );
};
export default ServerErrorPopup;
