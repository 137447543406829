import React, { useCallback } from 'react';
import ChatPreviewItem from 'pages/chatPage/chatPageAside/chatPreviewList/chatPreviewItem';
import { chatType } from 'store/reducers/chat';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveChat } from 'store/reducers/chat/selectors';
import { getUser } from 'store/reducers/user/selector';
import { userRolesEnum } from 'pages/dashboardPage';
import { setActiveChatStart } from 'store/reducers/chat/actions/chatMessages';
import API from 'services/API';
import { URL_CHAT_MESSAGES_UPDATE } from 'const';
type ChatPreviewListProps = {
  data: chatType[] | null;
};

const ChatPreviewList = (props: ChatPreviewListProps) => {
  const { data } = props;
  const dispatch = useDispatch();
  const activeChatData = useSelector(getActiveChat);
  const user = useSelector(getUser);
  const onItemClick = useCallback(
    (index: number, chatId: string | null, counter: number | null) => {
      if (chatId && chatId !== activeChatData?.id) {
        dispatch(
          setActiveChatStart(chatId, 1, async () => {
            if (!counter) {
              return;
            }

            await API.sendFormData(`${URL_CHAT_MESSAGES_UPDATE}`, {
              chat_id: chatId,
            });
          })
        );
      }
    },
    [data]
  );

  if (!activeChatData) {
    return null;
  }

  // @ts-ignore
  const isFounder = user?.roles.name === userRolesEnum.Founder;

  return (
    <ul>
      {data?.map((listItem, index) => {
        const { expert, unread_messages, id, founder, project } = listItem;
        const src = isFounder
          ? // @ts-ignore
            expert[0]?.user?.avatar[0]?.temporary_download
          : // @ts-ignore
            founder?.avatar[0]?.temporary_download;
        // @ts-ignore
        const name = isFounder ? expert[0]?.user?.name : founder?.name;
        // @ts-ignore
        const surname = isFounder ? '' : founder?.surmane;
        const areas = isFounder ? expert[0]?.areas : null;
        return (
          <ChatPreviewItem
            name={name}
            surname={surname}
            src={src}
            key={`item-${index}`}
            counter={unread_messages}
            isActive={activeChatData?.id === id}
            onItemClick={onItemClick}
            index={index}
            chatId={id}
            expertise={areas}
            projectName={project.name}
          />
        );
      })}
    </ul>
  );
};

export default ChatPreviewList;
