import React from 'react';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface UploadProps {
  validFileError: boolean;
  fileName: string;
  placeholder: string;
  label: string;
}

const Upload = (props: UploadProps) => {
  const {
    validFileError = false,
    fileName = '',
    placeholder = '',
    label = '',
  } = props;
  return (
    <div
      className={classNames(styles.text, { [styles.error]: validFileError })}
    >
      {validFileError && label}
      {!fileName && !validFileError && placeholder}
      {fileName && !validFileError && fileName.split('\\').pop()}
    </div>
  );
};

export default Upload;
