import React, { useEffect, useState, useRef, useCallback } from 'react';
import Spinner from 'componets/spiner';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getChatScrollTrigger } from 'store/reducers/chat/selectors';
import { setChatScrollTrigger } from 'store/reducers/chat';
const InfiniteScrollApi = (props: any) => {
  const {
    RenderComponent,
    RootComponent,
    initialPage = 1,
    data = [],
    setDataCallback = () => {},
    setPageCallback = () => {},
    locale,
    totalPage,
    chatId,
    chatLoading,
    ...rest
  } = props;
  const observer = useRef(null);
  const chatScrollTrigger = useSelector(getChatScrollTrigger);
  const [page, setPage] = useState(initialPage);
  const [isVisible, setIsVisible] = useState(false);
  const loader = useRef(null);
  const needFirstUpdate = useRef(false);
  const intl = useIntl();
  const rootRef = useRef();
  const innerRef = useRef();
  const dispatch = useDispatch();
  const handleScroll = (e: any) => {
    const element = e.target;
    if (element.scrollTop === -element.offsetHeight) {
      setPage((page: any) => page + 1);
    }
  };

  const handleObserver = useCallback(
    (entities: any) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((page: any) => page + 1);
      }
    },
    [chatId]
  );

  const initObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    // @ts-ignore
    observer.current = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      if (observer.current) {
        // @ts-ignore
        observer.current.observe(loader.current);
      }
    }
  };

  useEffect(() => {
    if (observer.current) {
      // @ts-ignore
      observer.current.unobserve(loader.current);
    }
    setPage(1);
    initObserver();
  }, [chatId]);

  useEffect(() => {
    setIsVisible(
      // @ts-ignore
      innerRef?.current?.offsetHeight < rootRef?.current?.offsetHeight
    );
  }, [chatId]);

  useEffect(() => {
    if (page === 1) {
      return;
    }
    (async function () {
      await setPageCallback(page);
    })();
    // @ts-ignore
    rootRef?.current?.scrollTo(
      0,
      // @ts-ignore
      -innerRef?.current?.offsetHeight + rootRef?.current?.offsetHeight + 20
    );
  }, [page]);

  const reversedData = [...data].reverse();
  useEffect(() => {
    if (!chatScrollTrigger) {
      return;
    }
    // @ts-ignore
    rootRef?.current?.scrollTo(0, 0);
    dispatch(setChatScrollTrigger(false));
  }, [chatScrollTrigger]);

  const styleLoader = () => {
    return isVisible || totalPage <= page ? 'none' : 'block';
  };
  return (
    <RootComponent rootRef={rootRef} handleScroll={handleScroll}>
      <div {...rest} ref={innerRef}>
        {reversedData.map((item: any, index: any) => {
          const { created_at, message, avatar, user_surname, user_name } = item;
          if (item.type === 'day') {
            return (
              <div className={styles.wrapper} key={`item_${index}`}>
                <div className={styles.inner}>{item.date}</div>
              </div>
            );
          }
          return (
            <RenderComponent
              message={message}
              time={created_at}
              userName={user_name}
              userSurname={user_surname}
              src={avatar[0]?.temporary_download}
              data={item}
              key={item.id || index}
              {...rest}
            />
          );
        })}
      </div>
      <div className={styles.loader}>
        <div ref={loader} style={{ display: styleLoader() }}>
          <Spinner />
        </div>
      </div>
    </RootComponent>
  );
};

export default InfiniteScrollApi;
