import {
  fetchCityLocation,
  fetchCountryLocation,
  fetchRegionLocation,
} from 'store/reducers/registerData/actions';
import { userLocation } from 'store/reducers/registerData/types';

export function setCountryUserLocationStart() {
  return {
    type: fetchCountryLocation.FetchStart,
  };
}

export function setCountryUserLocationSuccess(list: userLocation[]) {
  return {
    type: fetchCountryLocation.FetchSuccess,
    payload: list,
  };
}

export function setCountryUserLocationError(error: Error) {
  return {
    type: fetchCountryLocation.FetchError,
    payload: error,
  };
}

export function setRegionUserLocationStart(query: string = '') {
  return {
    type: fetchRegionLocation.FetchStart,
    payload: query,
  };
}

export function setRegionUserLocationSuccess(list: userLocation[]) {
  return {
    type: fetchRegionLocation.FetchSuccess,
    payload: list,
  };
}

export function setRegionUserLocationError(error: Error) {
  return {
    type: fetchRegionLocation.FetchError,
    error: error,
  };
}

export function setCityUserLocationStart(query: string = '') {
  return {
    type: fetchCityLocation.FetchStart,
    payload: query,
  };
}

export function setCityUserLocationSuccess(list: userLocation[]) {
  return {
    type: fetchCityLocation.FetchSuccess,
    payload: list,
  };
}

export function setCityUserLocationError(error: Error) {
  return {
    type: fetchCityLocation.FetchError,
    error: error,
  };
}
